import React from 'react';
import {useSetting} from "../../../provider/setting";
import {useLocation} from "react-router-dom";
import styled from "styled-components";

const UserBreadcrumb = () => {
  const [setting] = useSetting();

  const location = useLocation();

  const params = location.pathname.split("/")

  return (
    <UserBreadcrumbWrap>
      <div className="container">
        <div className="breadcrumb-wrap">
          {setting?.auth?.username} &nbsp;&gt;&nbsp;
          <span> Username &nbsp;&gt;&nbsp; </span>
          <span className={params.length <= 4 ? "breadcrumb-active" : ""}>{params?.[3]} {params.length > 4 && <>&nbsp;&gt;&nbsp;</>} </span>
          {
            params.length > 4 && (
              <span className="breadcrumb-active" style={{textTransform: "capitalize"}}>{params?.[4]}</span>
            )
          }
        </div>
      </div>
    </UserBreadcrumbWrap>
  );
};

const UserBreadcrumbWrap = styled.div`
  padding: 24px 0;
  
  .breadcrumb-wrap{
    font-size: 18px;
    text-transform: capitalize;
    .breadcrumb-active{
      color: #cc0000;
      font-weight: 500;
    }
  }
`

export default UserBreadcrumb;
