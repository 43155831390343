import React, {Fragment, useEffect, useState} from 'react';
import Layout from "../../../layouts/dashboard";
import Tab from "../../../layouts/dashboard/components/checkout-tab";
import {useNavigate} from "react-router-dom";
import {useSetting} from "../../../provider/setting";
import {Avatar, AutoComplete, Button, Col, Input, Row, Modal, Table} from "antd";
import {Icon} from "@iconify/react";
import {NotificationManager} from "react-notifications";
import { getAll, remove } from '../../../api/extra';
import { getAll as getAllCategories } from '../../../api/category';

const StoreAddonsPage = () => {
    const { Search } = Input;
    const navigate = useNavigate();
    const [setting] = useSetting();
    const [modal, contextHolder] = Modal.useModal();
    const [addons, setAddons] = useState([]);
    const [categories, setCategories] = useState([]);
    const [keyworkd, setKeyword] = useState('')

    const getHandle = async () => {
        if (setting?.auth?.signin_merchant_code) {
            getAll(setting?.auth?.signin_merchant_code)
                .then((data) => {
                    setAddons(data.map((i) => ({...i, key: i.id})))
                })
                .catch((e) => console.warn(e))
        }
    }

    const getCategoriesHandle = async () => {
        if (setting?.auth?.signin_merchant_code) {
            getAllCategories(setting.auth.signin_merchant_code)
                .then((data) => {
                    setCategories(data);
                })
                .catch(e => console.warn(e))
        }
    }

    const deleteHandle = (addon) => {
        modal.confirm({
            title: <h5>Delete Add on!</h5>,
            icon: <Icon style={{width: 60, height: 60, color: "#cc0000"}} icon="ep:warn-triangle-filled"/>,
            content: <p>Are you sure to delete <b>{addon.name}</b> Addon?</p>,
            okText: 'Delete',
            cancelText: 'Cancel',
            okType: "danger",
            onOk: () => {
                remove(setting?.auth?.signin_merchant_code, addon.id)
                    .then(() => {
                        getHandle();
                        NotificationManager.success("Successfully deleted!", "Success", 3000)
                    })
                    .catch((e) => {
                        console.warn(e);
                        NotificationManager.error("An error was created. try again later!", "Error", 3000)
                    })
            },
        });
    }

    const columns = [
        {
            title: 'Add On',
            width: '70%',
            // dataIndex: 'name',
            // key: 'name',
            render: (i) => {
                return (
                  <Fragment>
                    <h4>{i.name}</h4>
                  </Fragment>
                )
              }
        },
        {
            title: 'Category',
            dataIndex: 'id',
            key: 'id',
            align: "left",
            width: 100,
            render: (i) => {
              let z = ""
              categories.map((category) => {
                  if(JSON.parse(category.other || "[]").includes(i)) {
                    z = category.name
                  }
              })
              return z
            }
        },
        {
            title: 'Price',
            width: 100,
            dataIndex: 'price',
            key: 'price',
            render: (i) => "$" + parseFloat(i).toFixed(2)
        },
        {
            title: '',
            width: 100,
            align: "center",
            render: (i) => {
                return (
                    <Fragment>
                        <Button
                            onClick={() => {navigate(`/store/checkout/addons/edit/?id=${i.id}`)
                            }}
                            className="icon-button"
                            style={{marginRight: 6}}
                            type="link">
                            <Icon color="#006000" icon="mdi:circle-edit-outline"/>
                        </Button>
                        <Button
                            onClick={()=>{deleteHandle(i)}}
                            className="icon-button"
                            type="link"
                            danger>
                            <Icon icon="material-symbols:delete-outline-sharp"/>
                        </Button>
                    </Fragment>
                )
            }
        }
    ]

    useEffect(() => {
        getHandle();
        getCategoriesHandle();
    }, [setting])

    console.log(categories)

    return (
        <Layout>
        <Tab/>    
            <div className="row">
                {/* <div className="col col-lg-3">
                    <Sidebar/>
                </div> */}
                <div className="col col-lg-12">
                    <h4>Add Ons</h4>
                    <Row gutter={12} className="mb-4">
                        <Col span={14}>
                            <Search
                            placeholder='Search Items'
                            size="large"
                            allowClear
                            onSearch={setKeyword}/>
                        </Col>
                        <Col span={5}>
                            
                        </Col>
                        <Col span={5}>
                            <Button 
                            block
                            type="primary"
                            size="large"
                            onClick={() => {navigate('/store/checkout/addons/create')
                            }}>
                            + New Add On
                            </Button>
                        </Col>
                    </Row>
                    <Table
                        dataSource={addons}
                        columns={columns}
                        pagination={{
                            pageSize: 5
                        }}
                    />
                </div>
            </div>
            {contextHolder}
        </Layout>
    );
};

export default StoreAddonsPage;
