import React from 'react'
import { Link } from 'react-router-dom'
import { Styles } from '../../layouts/style/footerPageStyle'
import { Col, Row } from "antd";

const FooterHome = () => {

  return (
    <Styles>
      <footerfree style={{zIndex: 999}}>
        <Row gutter={[0, 0]}>
          <Col span={24} sm={{ span: 1, offset: 1 }} style={{ textAlign: 'center', display: 'flex', alignItems: 'center' }}>
            <div className="link-item">
              
            </div>
          </Col>
          <Col span={24} sm={{ span: 16, offset: 0 }} style={{ textAlign: 'center', display: 'flex', alignItems: 'center' }}>
            <div className="link-item">
              &nbsp;
            </div>
            {/* <div className="link-item">
              <p><Link to="/app">App</Link></p>
            </div> */}
            <div className="link-item">
              <p><Link to="/business">Business</Link></p>
            </div>
            <div className="link-item">
              <p><Link to="/privacy">Privacy</Link></p>
            </div>
            <div className="link-item">
              <p><Link to="/terms">Terms</Link></p>
            </div>
            {/* <div className="link-item">
              <p><Link to="/coin">VEEV Coin</Link></p>
            </div> */}
          </Col>
          <Col span={24} sm={{ span: 4, offset: 0 }} style={{ textAlign: 'center', alignItems: 'center' }}>
          VEEV&reg; Inc.
          </Col>
          <Col span={24} sm={{ span: 2, offset: 1 }} style={{ textAlign: 'center', display: 'flex', alignItems: 'center' }}>
            <div className="link-item">
              
            </div>
          </Col>
        </Row>
      </footerfree>
    </Styles>
  )
}
export default FooterHome
