import React from 'react'
import { Navigate } from 'react-router-dom'
import {getCookie} from '../service/cookie'

export const AuthRoute = ({ children, ...rest }) => {
  const token = getCookie("current_ident_token");

  return (!token || token === "") ? (
    children
  ) : (
    (
      <Navigate
        to={'/store/checkout/transactions'}
      />
    )
  );
}

export const PrivateRoute = ({ children, ...rest }) => {
  const token = getCookie("current_ident_token");

  return (
    (token && token !== "") ? (
      children
    ) : (
      <Navigate
        to={'/signin'}
      />
    )
  );
}
