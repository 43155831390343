import styled from "styled-components";

export const Styles = styled.div`

footerfree {
  padding: 22px 40px 22px 40px;
  height: 60px;
  margin-top: 60px;
  @media screen and (min-width: 768px) {
    right: 0;
    left: 0;
    bottom: 0;
    margin-top: 0;
    padding: 22px 0px 22px 0px;
  }
  
  div.link-item {
    float: left;
    padding: 0px 10px 0px 17px;
    p {
      font-size: 16px;
      text-align: left;
      color: black;
    }
  }   
}

/* div.copyright {
  float: right;
  padding: 0px 0px 0px 0px;
  p {
    font-size: 16px;
    text-align: right;
    color: black;
  }
} */
}

footer {
    padding: 22px 40px 22px 40px;
    height: 60px;
    margin-top: 60px;
    @media screen and (min-width: 768px) {
      position: fixed;
      right: 0;
      left: 0;
      bottom: 0;
      margin-top: 0;
      padding: 22px 0px 22px 0px;
    }
    
    div.link-item {
      float: left;
      padding: 0px 10px 0px 17px;
      p {
        font-size: 16px;
        text-align: left;
        color: black;
      }
    }   
  }
`;