import React, {useEffect, useMemo, useState} from 'react';
//import AuthLayout from '../../layouts/landing/';
import HeaderHome from "../../../components/header/authhome";
import FooterHome from "../../../components/footer/footerPage";
import VideoPlayer from "../../../components/player";
import {HashLink as Link} from 'react-router-hash-link';
import {Avatar, Button, Dropdown, Space} from "antd";
import {Icon} from "@iconify/react";
import {Styles} from '../../../layouts/style/homeStyle';
import Plans from './plans'
import {useNavigate} from 'react-router-dom'

const Solutions = (props, toggleModal) => {
    /// Login popup materials
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);

  const [slider, setSlider] = useState('')
  const sliders = useMemo(() => [
    "../assets/img/recommendations.png",
    "../assets/img/store.png",
    "../assets/img/pos_store.PNG",
    "../assets/img/pos_sales.png",
  ], []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    setSlider(sliders[0])
    let index = 0
    let timer = setInterval(() => {
      index += 1;
      if (index >= sliders.length)
        index = 0
      setSlider(sliders[index])
    }, 2000);
    return () => clearInterval(timer);
  }, [sliders])

  const [slider2, setSlider2] = useState('')
  const sliders2 = useMemo(() => [
    // "../assets/img/veev_connect.png",
    "../assets/img/recommendations.png",
    "../assets/img/store.png",
    "../assets/img/veevreviews.png",
    // "../assets/img/veevcheckin.png",    
    // "../assets/img/veevstore.png",
  ], []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    setSlider2(sliders2[0])
    let index = 0
    let timer = setInterval(() => {
      index += 1;
      if (index >= sliders2.length)
        index = 0
      setSlider2(sliders2[index])
    }, 2000);
    return () => clearInterval(timer);
  }, [sliders2])

  const [slider3, setSlider3] = useState('')
  const sliders3 = useMemo(() => [
    "../assets/img/veev_connect.png",
    "../assets/img/checkout.png",
    "../assets/img/mobilepos1.png",
    "../assets/img/mobilepos2.png",
  ], []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    setSlider3(sliders3[0])
    let index = 0
    let timer = setInterval(() => {
      index += 1;
      if (index >= sliders3.length)
        index = 0
      setSlider3(sliders3[index])
    }, 2000);
    return () => clearInterval(timer);
  }, [sliders3])

  const [slider4, setSlider4] = useState('')
  const sliders4 = useMemo(() => [
    "../assets/img/veev_sales.png",
    "../assets/img/veev_event_signups.png",
    "../assets/img/veev_marketing.png",
  ], []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    setSlider4(sliders4[0])
    let index = 0
    let timer = setInterval(() => {
      index += 1;
      if (index >= sliders4.length)
        index = 0
      setSlider4(sliders4[index])
    }, 2000);
    return () => clearInterval(timer);
  }, [sliders4])

  const [slider5, setSlider5] = useState('')
  const sliders5 = useMemo(() => [
    "../assets/img/veev_checkin.png",
    "../assets/img/veev_event_signups.png",
    "../assets/img/veev_marketing.png",
  ], []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    setSlider5(sliders5[0])
    let index = 0
    let timer = setInterval(() => {
      index += 1;
      if (index >= sliders5.length)
        index = 0
      setSlider5(sliders5[index])
    }, 2000);
    return () => clearInterval(timer);
  }, [sliders5])

  const [slider6, setSlider6] = useState('')
  const sliders6 = useMemo(() => [
    "../assets/img/pos2.png",
    "../assets/img/pos3.png",
    "../assets/img/pos4.png",
    "../assets/img/veev_sales.png",
    "../assets/img/pos5.png",
  ], []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    setSlider6(sliders6[0])
    let index = 0
    let timer = setInterval(() => {
      index += 1;
      if (index >= sliders6.length)
        index = 0
      setSlider6(sliders6[index])
    }, 2000);
    return () => clearInterval(timer);
  }, [sliders6])

  return (
  <>
  {/* <AuthLayout> */}
  <HeaderHome />
    <Styles>
      <div className="guide" id="app" style={{paddingTop: 0, paddingBottom: 0}}>   
        <section className="download bg-primary text-center" id="foodtrucks">
          <div className="row h-100">
            <div>  
              <div className="row">
                <div className="col-lg-6 lazy" style={{padding: '0', marginTop: '33px'}}>
                  <VideoPlayer url={'https://www.youtube.com/watch?v=AzlFY3AmARE'}/>
                </div>
                <div className="col-lg-6 image">
                  <img src="https://veev-prod-bucket.s3.us-west-1.amazonaws.com/uploaded_event_image/88_1679419512616.png" className="img-fluid" alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-12 text-left" style={{width: '100%', paddingTop: '0px', paddingLeft: '40px', backgroundColor: '#ffffff'}}> 
            <div className="row">
              <div className="col-lg-2" style={{ width: '100%', paddingBottom: '5px', paddingTop: '10px', paddingRight: '20px', color: 'grey' }}>
              <p className="title4">Scan</p>
              <p style={{ width: '100%', color: 'grey' }}>
              - Scan & Order<br></br>
              - Dynamic Menu Management
              </p>
              </div>
              <div className="col-lg-2" style={{ width: '100%', paddingBottom: '5px', paddingTop: '10px', paddingRight: '20px', color: 'grey' }}>
              <p className="title4">Order</p>
              <p style={{ width: '100%', color: 'grey' }}>
              - In-person, Online & Mobile<br></br>
              - Order Printing
              </p>
              </div>
              <div className="col-lg-2" style={{ width: '100%', paddingBottom: '5px', paddingTop: '10px', paddingRight: '20px', color: 'grey' }}>
              <p className="title4">Checkout</p>
              <p style={{ width: '100%', color: 'grey' }}>
              - POS<br></br>
              - Card, Apple & Google Pay
              </p>
              </div>
              <div className="col-lg-2" style={{ width: '100%', paddingBottom: '5px', paddingTop: '10px', paddingRight: '20px', color: 'grey' }}>
              <p className="title4">Notify</p>
              <p style={{ width: '100%', color: 'grey' }}>
              - Order completion notificatoin<br></br>
              </p>
              </div>
              <div className="col-lg-2" style={{ width: '100%', paddingBottom: '5px', paddingTop: '10px', paddingRight: '20px', color: 'grey' }}>
              <p className="title4">Review</p>
              <p style={{ width: '100%', color: 'grey' }}>
              - Customer recommendations<br></br>
              </p>
              </div>
              <div className="col-lg-2" style={{ width: '100%', paddingBottom: '5px', paddingTop: '10px', paddingRight: '20px', color: 'grey' }}>
              <p className="title4">Promote</p>
              <p style={{ width: '100%', color: 'grey' }}>
              - In-App promotions<br></br>
              </p>
              </div>
            </div>
            <div className="row" style={{ width: '100%', paddingBottom: '30px', paddingTop: '5px', paddingRight: '0px'}}>
              <div className="col-lg-5">
              
              </div>
              <div className="col-lg-2">
                <Button
                  block
                  type="primary"
                  size="large"
                  onClick={toggleModal || null}>
                  Get Started
                  </Button>
              </div>
              <div className="col-lg-5">
              
              </div>
            </div>
          </div>
          <div className="col-lg-12 text-center" style={{padding: 0, backgroundColor: '#ffffff'}}>
            <a href="#howitworks">
            <img src={"../assets/img/512px-chevron_fontawesome.svg.png"} width={"30px"} style={{ paddingTop: '0px', paddingBottom: '20px', textAlign: 'center', alignItems: 'center' }} className="img-fluid" />
            </a>
          </div>        
        </section>

        <section>       
        </section>

        <section className="cta text-center" style={{paddingTop: 70, paddingBottom: 50}} id="checkout">
          <div className="container">
            <div className="col-lg-12 my-auto text-center">
              <p className="title1" style={{paddingBottom: '50px' }}>Self Order & Checkout for FoodTrucks</p>  
            </div>
          </div>
          <div className="container">
            <div className="col-lg-12 my-auto">
              <div className="container-fluid">
                <div className="row">   
                  <div className="col-lg-3">  
                    <div className="device-mockup iphone6 portrait gold">
                      <div className="device">
                        <div className="screen">
                        <img src="../assets/img/veev-qr-code.png" className="img-fluid2" alt="" />
                        </div>
                      </div>
                    </div>
                    <div className="section-heading text-center">
                    <p className="title4">Scan</p>
                    </div>
                  </div>
                  <div className="col-lg-3">  
                    <div className="device-mockup iphone6 portrait gold">
                      <div className="device">
                        <div className="screen">
                        <img src="../assets/img/veev_app_order.png" className="img-fluid2" alt="" />
                        </div>
                      </div>
                    </div>
                    <div className="section-heading text-center">
                    <p className="title4">Order</p>
                    </div>
                  </div>  
                  <div className="col-lg-3">  
                    <div className="device-mockup iphone6 portrait gold">
                      <div className="device">
                        <div className="screen">
                        <img src="../assets/img/veev_app_pay.png" className="img-fluid2" alt="" />
                        </div>
                      </div>
                    </div>
                    <div className="section-heading text-center">
                    <p className="title4">Pay</p>
                    </div>
                  </div>
                  <div className="col-lg-3">  
                    <div className="device-mockup iphone6 portrait gold">
                      <div className="device">
                        <div className="screen">
                        <img src="../assets/img/veev_app_receipt.png" className="img-fluid2" alt="" />
                        </div>
                      </div>
                    </div>
                    <div className="section-heading text-center">
                    <p className="title4">Recommend</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12 my-auto">
              <div className="container-fluid">
                <div className="row">   
                  <div className="col-lg-3">  
                    <div style={{paddingBottom:'5px', paddingTop:'0px', color:'grey'}}> 
                    <p>Reduce order time to seconds</p>
                    </div>
                  </div>
                  <div className="col-lg-3">  
                    <div style={{paddingBottom:'5px', paddingTop:'0px', color:'grey'}}> 
                    <p>Eliminate order errors</p>
                    </div>
                  </div> 
                  <div className="col-lg-3">  
                    <div style={{paddingBottom:'5px', paddingTop:'0px', color:'grey'}}> 
                    <p>Pre-Pay at time of order</p>
                    </div>
                  </div>
                  <div className="col-lg-3">  
                    <div style={{paddingBottom:'5px', paddingTop:'0px', color:'grey'}}> 
                    <p>Customer reviews increase sales</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row" style={{ width: '100%', paddingBottom: '30px', paddingTop: '5px', paddingRight: '0px'}}>
              <div className="col-lg-5">
              
              </div>
              <div className="col-lg-2">
                <Button
                  block
                  type="primary"
                  size="large"
                  onClick={toggleModal || null}>
                  Sign Me Up
                  </Button>
              </div>
              <div className="col-lg-5">
              
              </div>
            </div>
          </div>
        </section>

        <section className="cta text-center" style={{paddingTop: '70px', paddingBottom: '50px' }} id="preorder">
          <div className="container">
            <div className="col-lg-12 my-auto text-center">
              <p className="title1" style={{paddingBottom: '30px' }}>FoodTruck PoS</p>  
            </div>
          </div>
          <div className="container" style={{paddingTop: '20px', paddingBottom: "20px"}}>
            <div className="col-lg-12 my-auto">
              <div className="container-fluid">
                <div className="row"> 
                  <div className="col-lg-3">  
                    <div className="device-mockup iphone6 portrait gold">
                      <div className="device" style={{marginTop: 0, marginBottom: 40}}>
                        <div className="screen">	
                          <div className="slideshow-container">
                            <div className="mySlides">
                              <div className="veevslogan">
                                <img src={slider2} alt="VEEV Checkin" className="img-fluid2" />
                              </div>
                            </div>
                          </div>
                          <div style={{ textAlign: 'center' }}>
                            <span className="dot"></span>
                            <span className="dot"></span>
                            <span className="dot"></span>
                            <span className="dot"></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-9 text-left" style={{width: '100%', paddingTop: '0px', paddingLeft: '40px' }}> 
                    <div style={{ width: '100%', paddingBottom: '5px', paddingTop: '10px', paddingRight: '20px', color: 'grey' }}>
                    <p className="title4">New Orders</p>
                    <p style={{ width: '100%', color: 'grey' }}>
                      Order queue displaying new incoming orders with customer name, date and time.
                    </p>
                    <p className="title4">Order Printout</p>
                    <p style={{ width: '100%', color: 'grey' }}>
                      Printout orders in realtime and elimante order transcripting errors.
                    </p>
                    <p className="title4">Order Completion Notification via Mobile</p>
                    <p style={{ width: '100%', color: 'grey' }}>
                      Notify customers of completed orders to their mobile phone
                    </p>
                    <p className="title4">Payment</p>
                    <p style={{ width: '100%', color: 'grey' }}>
                      Mobile pay, Tap-to-Pay, credit card, Apple and Google Pay
                    </p>
                    <p className="title4">Sales Tracking</p>
                    <p style={{ width: '100%', color: 'grey' }}>
                      Daily sales tracking
                    </p>
                    <p className="title4">Store Menu</p>
                    <p style={{ width: '100%', color: 'grey' }}>
                      Easy store menu setup, dynamically modification, updates and pause.
                    </p>
                    </div>
                    <div style={{width:'150px', paddingTop: '20px', textAlign:'center'}}>
                      <Button
                      block
                      type="primary"
                      size="large"
                      onClick={toggleModal || null}>
                      Sign Me Up
                      </Button>
                  </div>
                  </div>
                </div>  
              </div>
            </div>
          </div> 
        </section>

        <section className="cta text-center" style={{paddingTop: 70, height: "100%", paddingBottom: 90}}>
          <div className="container">
            <div className="col-lg-12 my-auto text-center">
              <p className="title1" style={{paddingBottom: '30px' }}>Marketing & Promotions</p>  
            </div>
          </div>
          <div className="container" style={{paddingTop: '20px', paddingBottom: "20px"}}>
            <div className="col-lg-12 my-auto">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-4 my-auto text-center" style={{ paddingTop: '3px', paddingBottom: '0px'}}>    
                    <img src="../assets/img/veev_solution.png" width={'100%'}></img>
                  </div>
                  <div className="col-lg-1 dv-loadmoney">
                  </div>
                  <div className="col-lg-3 dv-loadmoney text-center" style={{ paddingBottom: '0px', marginBottom: '50px' }}>
                    <div className="device-container">
                      <div className="device-mockup iphone6 portrait gold">
                        <div className="device" style={{marginTop: 20, marginBottom: 0}}>
                          <div className="screen">	
                            <div className="slideshow-container">
                              <div className="mySlides">
                                <div className="veevslogan">
                                  <img src={slider} alt="VEEV Checkin" className="img-fluid2" />
                                </div>
                              </div>
                            </div>
                            <div style={{ textAlign: 'center' }}>
                              <span className="dot"></span>
                              <span className="dot"></span>
                              <span className="dot"></span>
                              <span className="dot"></span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 my-auto text-center" style={{ paddingTop: '3px', paddingBottom: '0px'}}>    
                    <p className="title3">Promote products In-App &</p>
                    <p className="title0">Sell Out</p>
                    <div style={{paddingTop: '20px', marginLeft: "35px", marginRight: "35px"}}>
                      <Button
                      block
                      type="primary"
                      size="large"
                      onClick={toggleModal || null}>
                      Get Started
                      </Button>
                    </div> 
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="cta text-center" style={{paddingTop: 70, paddingBottom: 50}} id="checkout">
          <div className="container">
            <div className="col-lg-12 my-auto text-center">
              <p className="title1" style={{paddingBottom: '50px' }}>Get Orders From Social Media</p>
            </div>
          </div>
          <div className="container">
            <div className="col-lg-12 my-auto">
              <div className="container-fluid">
                <div className="row">   
                  <div className="col-lg-3">  
                    <div className="device-mockup iphone6 portrait gold">
                      <div className="device">
                        <div className="screen">
                        <img src="../assets/img/storelink1.png" className="img-fluid2" alt="" />
                        </div>
                      </div>
                    </div>
                    <div className="section-heading text-center">
                    <p className="title4">Click "Edit Profile'</p>
                    </div>
                  </div>
                  <div className="col-lg-3">  
                    <div className="device-mockup iphone6 portrait gold">
                      <div className="device">
                        <div className="screen">
                        <img src="../assets/img/storelink2.png" className="img-fluid2" alt="" />
                        </div>
                      </div>
                    </div>
                    <div className="section-heading text-center">
                    <p className="title4">Click 'Links'</p>
                    </div>
                  </div>  
                  <div className="col-lg-3">  
                    <div className="device-mockup iphone6 portrait gold">
                      <div className="device">
                        <div className="screen">
                        <img src="../assets/img/storelink3.png" className="img-fluid2" alt="" />
                        </div>
                      </div>
                    </div>
                    <div className="section-heading text-center">
                    <p className="title4">Click link icon</p>
                    </div>
                  </div>
                  <div className="col-lg-3">  
                    <div className="device-mockup iphone6 portrait gold">
                      <div className="device">
                        <div className="screen">
                        <img src="../assets/img/storelink4.png" className="img-fluid2" alt="" />
                        </div>
                      </div>
                    </div>
                    <div className="section-heading text-center">
                    <p className="title4">Paste StoreLink</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="cta text-center" style={{paddingTop: '70px', paddingBottom: '60px' }} id="checkin">
          <div className="container">
            <div className="col-lg-12 my-auto text-center">
              <p className="title1">One Location To Manage Sales, Marketing & Operations</p>  
              <h5 style={{ width: '100%', paddingBottom: '30px', paddingTop: '10px', paddingLeft: '0px', color: '#000000'}}>
                Single location to manage sales, marketing and operations on Tiktok, Instagram, YouTube, Twitter, online and at Stores. 
                {/* Create your store, add your link to your social media profile, let your followers know and watch orders come. */}
              </h5>
            </div>
          </div>
          <div className="container">
            <div className="col-lg-12 my-auto">
              <div className="container-fluid">
                <div className="row" style={{marginTop: 0, marginBottom: 0}}>
                  <div className="col-lg-12 text-center" style={{ maxHeight: 'auto' }}>
                  <img src="../assets/img/socialmediasales.png" className="img-fluid2" alt="" />
                  </div>
                  <div className="col-lg-12 text-center" style={{ width: '100%', paddingBottom: '5px', paddingTop: '5px', color: '#000000' }}>
                    <div className="row">
                      <div className="col-lg-3"> 
                        <div style={{ width: '100%', paddingBottom: '5px', paddingTop: '20px', color: '#000000' }}>  
                        <p className="title4">Sales & Orders</p>
                        <p style={{ width: '100%', color: 'grey' }}>Sales & order tracking and processing.</p>
                        </div>
                      </div>
                      <div className="col-lg-3"> 
                        <div style={{ width: '100%', paddingBottom: '5px', paddingTop: '20px', color: '#000000' }}>  
                        <p className="title4">Payment & Checkout</p>
                        <p style={{ width: '100%', color: 'grey' }}>In-App & In-Store order pay and checkout PoS.</p>
                        </div>
                      </div> 
                      <div className="col-lg-3"> 
                        <div style={{ width: '100%', paddingBottom: '5px', paddingTop: '20px', color: '#000000' }}> 
                        <p className="title4">Store Operations</p>
                        <p style={{ width: '100%', color: 'grey' }}>Setup, location, store hours, pricing etc.</p>
                        </div>  
                      </div>
                      <div className="col-lg-3"> 
                        <div style={{ width: '100%', paddingBottom: '5px', paddingTop: '20px', color: '#000000' }}>  
                        <p className="title4">Marketing & Promotions</p>
                        <p style={{ width: '100%', color: 'grey' }}>Manage customer marketing and promotions.</p>
                        </div>
                      </div>
                      <div className="col-lg-2 dv-loadmoney text-center" style={{ maxHeight: 'auto' }}>

                      </div>
                    </div>  
                  </div> 
                </div>
              </div>
            </div>
          </div>
          <div className="section-heading text-center submit-button" style={{ paddingTop: '25px', paddingBottom: '30px'}}>
            <a href="/signin">
              <button className="btn btn-success btn-sm btn-create-account" id="signup_btn" type='signup' style={{ margin: '0px'}}>
              Sign Up
              </button>
            </a>
          </div>
        </section>

        <section className="cta text-center" style={{paddingTop: '70px', paddingBottom: '60px' }} id="crm">
          <div className="container">
            <div className="col-lg-12 my-auto text-center">
              <p className="title1" style={{paddingBottom: '30px' }}>Recommendations & Rewards</p>  
            </div>
          </div>
          <div className="container">
            <div className="col-lg-12 my-auto">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-6 dv-loadmoney text-center" style={{ maxHeight: 'auto' }}>
                    <div className="device-container">
                      <div className="device-mockup imac imac">
                        <div className="device" style={{marginTop: 0, marginBottom: 40}}>
                          <div className="screen">	
                            <div className="slideshow-container">
                              <div className="mySlides">
                                <div className="veevslogan">
                                  <img src={slider4} className="img-fluid" />
                                </div>
                              </div>
                            </div>
                            <div style={{ textAlign: 'center' }}>
                              <span className="dot"></span>
                              <span className="dot"></span>
                              <span className="dot"></span>
                              <span className="dot"></span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 text-left" style={{width: '100%', paddingTop: 30, paddingLeft: '0px' }}>
                    <div style={{ width: '100%', paddingBottom: '8px', paddingRight: '20px', color: 'grey' }}> 
                    <p className="title4">78% Purchases Influenced by Recommendations</p>
                    <p style={{ width: '100%', color: 'grey' }}>And are refer 50% more people than one time buyers.</p>
                    </div>
                    <div style={{ width: '100%', paddingBottom: '12px', paddingRight: '20px', color: 'grey' }}>
                    <p className="title4">81% Purchase from Retailers Offering Rewards</p>
                    <p style={{ width: '100%', color: 'grey' }}>Which increases customer retention, a 5% retention increases profitability by 75%.</p>
                    </div>
                    <div style={{ width: '100%', paddingBottom: '20px', paddingRight: '20px', color: 'grey' }}> 
                    <p className="title4">67% More Spent by Repeat Customers</p>
                    <p style={{ width: '100%', color: 'grey' }}>And 80% of future profits come from 20% of customers.</p>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-4"> 
                    <div className="device-mockup imac imac">
                      <div className="device">
                        <div className="screen">
                        <img src="../assets/img/veev_sales.png" className="img-fluid" alt="" />
                        </div>
                      </div>
                    </div>
                    <div className="section-heading">
                    <h3>Sales</h3>
                    </div>
                    <div style={{ width: '100%', paddingBottom: '5px', paddingTop: '0px', paddingRight: '20px', color: 'grey' }}>
                    <p style={{ width: '100%', color: 'grey' }}>Sales transactions by customer and product, daily, monthly, by region and many other parameters.</p>
                    </div>
                  </div>
                  <div className="col-lg-4"> 
                    <div className="device-mockup imac imac">
                      <div className="device">
                        <div className="screen">
                        <img src="../assets/img/veev_marketing.png" className="img-fluid" alt="" />
                        </div>
                      </div>
                    </div>
                    <div className="section-heading">
                    <h3>Marketing</h3>
                    </div>
                    <div style={{ width: '100%', paddingBottom: '5px', paddingTop: '0px', paddingRight: '20px', color: 'grey' }}>
                    <p style={{ width: '100%', color: 'grey' }}>Offers & Promotions targeted by customer, by product and other metrics to generate demand.</p>
                    </div>
                  </div>
                  <div className="col-lg-4"> 
                    <div className="device-mockup imac imac">
                      <div className="device">
                        <div className="screen">
                        <img src="../assets/img/veev_event_signups.png" className="img-fluid" alt="" />
                        </div>
                      </div>
                    </div>
                    <div className="section-heading">
                    <h3>CRM</h3> 
                    </div>
                    <div style={{ width: '100%', paddingBottom: '5px', paddingTop: '0px', paddingRight: '20px', color: 'grey' }}>
                    <p style={{ width: '100%', color: 'grey' }}>Dynamic CRM to own your customers in case you get hacked or shut out from social media.</p>
                    </div>
                  </div> 
                </div>
              </div>
            </div>
          </div> 
          <div className="section-heading text-center submit-button" style={{ paddingTop: '25px', paddingBottom: '30px'}}>
            <a href="/signin">
              <button className="btn btn-success btn-sm btn-create-account" id="signup_btn" type='signup' style={{ margin: '0px'}}>
              Sign Up
              </button>
            </a>
          </div>     
        </section>

        <section className="cta text-center" style={{paddingTop: '70px', paddingBottom: '60px' }} id="pricing">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 my-auto">
                <div className="container-fluid">
                  <p className="title1">Pricing</p>
                  <p></p>
                </div>
              </div>
            </div>
          </div>
          <div className="container" id="#pricing">
            <Plans/>
          </div>
          <div className="section-heading text-center submit-button" style={{ paddingTop: '25px', paddingBottom: '30px'}}>
            <a href="/signin">
              <button className="btn btn-success btn-sm btn-create-account" id="signup_btn" type='signup' style={{ margin: '0px'}}>
              Sign Up
              </button>
            </a>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-lg-12 my-auto">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-lg-12"> 
                     
                      One system to run your entire operations. VEEV Pay provides your business Mobile Ordering and fullfilment capability for items listed in you POS. Mobile orders initiated from VEEV Wallets are automatically organized into customer’s fulfillment preference, pickup, deliver, or ship. 

                      Supports instant mobile ordering and pay from any smart phone with simple Click & Pay, approve transaction. Payment are processed instantly. Purchase receipt is displayed instantly.<br /><br />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>    
        </section>
      </div>
    </Styles>
  <FooterHome />
  {/* </AuthLayout> */}
  </>  
  )
}
export default Solutions
