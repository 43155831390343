import React, {useEffect, useState} from 'react';
import Layout from "../../../layouts/dashboard";
import Tab from "../../../layouts/dashboard/components/settings-tab";
import Sidebar from "./sidebar";
import {cities, countries, states} from "country-cities";
import {Button, Col, Form, Input, Row, Select} from "antd";
import {useSetting} from "../../../provider/setting";
import { toast } from 'react-toastify';
import { get as getMerchantApi, update as updateMerchantApi } from '../../../api/merchant';

const {Item} = Form;
const {Option} = Select;

const StoreProfileSettingsPage = () => {
  const [setting] = useSetting();
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [merchant, setMerchant] = useState({});
  const [loading, setLoading] = useState(false);

  const getMerchantHandle = () => {
    if (!!setting?.auth?.signin_merchant_id) {
      getMerchantApi(setting?.auth?.signin_merchant_id)
        .then((data) => {
          setMerchant({
            ...data,
            tax_info: parseFloat(data.tax_info || "0")
          })
        })
        .catch((e) => {
          console.warn(e)
        })
    }
  }

  const finishHandle = async (values) => {
    console.log(values)
    setLoading(true)
    try {
      const data = {
        user: {
          handle: values.legal_name.replace("/ /gi", "").toLowerCase(),
          merchant_id: setting?.auth?.signin_merchant_id,
          merchant_user_id: setting?.auth?.signin_merchant_user_id,
          merchant: {
            ...values,
            tax_info: values.tax_info || "0",
            country: undefined,
            handle_name: undefined,
          }
        },
      };
      const res = await updateMerchantApi(setting?.auth?.signin_merchant_id, {...data})
      console.log("UPDATE", res)
      getMerchantHandle();
    } catch (e) {
      console.log(e.response?.data?.message)
    
      toast(e.response?.data?.message, {
        position: "bottom-left",
        type: "error"
      })
    }
    setLoading(false)
  }

  useEffect(() => {
    if (!!setting?.auth?.signin_merchant_id) {
      getMerchantHandle();
    }
  }, [setting?.auth])

  return (
    <Layout>
    <Tab/>
      <div className="row">
        <div className="col col-lg-3">
          <Sidebar/>
        </div>
        <div className="col col-lg-9">
          <h4>Legal Information</h4>
          <Form
            onFinish={finishHandle}
            fields={[
              {name: "legal_name", value: merchant.legal_name},
              {name: "dba", value: merchant.dba},
              {name: "handle_name", value: merchant.handle_name || "TESTING...."},
              // {name: "wesite", value: merchant.website },
              {name: "business_email", value: merchant.business_email},
              {name: "phone", value: merchant.phone},
              {name: "first_name", value: merchant.first_name},
              {name: "last_name", value: merchant.last_name},
              {name: "address", value: merchant.address},
              {name: "suite", value: merchant.suite},
              {name: "state", value: merchant.state},
              {name: "city", value: merchant.city},
              {name: "country", value: "US"},
              {name: "zip", value: merchant.zip},
              {name: "tax_id", value: merchant.tax_id},
              {name: "tax_info", value: merchant.tax_info},
              {name: "state_incorporated", value: merchant.state_incorporated},
              {name: "account_number", value: merchant.account_number},
              {name: "routing_number", value: merchant.routing_number},
              {name: "ssn", value: merchant.ssn},
              {name: "dob_day", value: merchant.dob_day},
              {name: "dob_month", value: merchant.dob_month},
              {name: "dob_year", value: merchant.dob_year},
            ]}
            layout="vertical">

            <h5 className="mt-5">Business Information</h5>
            <Row gutter={12}>
              <Col span={12}>
                <Item
                  name="legal_name"
                  id="legal_name"
                  label="Legal Name"
                  rules={[{required: true, message: "Legal Name is required"}]}>
                  <Input
                    placeholder="Enter Legal Name"
                    size="large"/>
                </Item>
              </Col>
              <Col span={12}>
                <Item
                  name="dba"
                  id="dba"
                  label="DBA"
                  rules={[{required: true, message: "DBA is required"}]}>
                  <Input
                    placeholder="Enter DBA"
                    size="large"/>
                </Item>
              </Col>
              {/* <Col span={8}>
                <Item
                  name="handle_name"
                  id="handle_name"
                  label="StoreLink Name">
                  <Input
                    placeholder="Enter StoreLink Name"
                    size="large"/>
                </Item>
              </Col> */}

              <Col span={12}>
                <Item
                  name="business_email"
                  id="business_email"
                  rules={[{required: true, message: "Business Email is required"}]}
                  label="Business Email">
                  <Input
                    placeholder="Business Email"
                    size="large"/>
                </Item>
              </Col>
              <Col span={12}>
                <Item
                  name="phone"
                  id="phone"
                  rules={[{required: true, message: "Phone Number is required"}]}
                  label="Phone">
                  <Input
                    placeholder="Phone Number"
                    size="large"/>
                </Item>
              </Col>

              {/*<Col span={24}>*/}
              {/*  <Item*/}
              {/*    name="website"*/}
              {/*    id="website"*/}
              {/*    rules={[{required: true, message: "Business Website is required"}]}*/}
              {/*    label="Business Website">*/}
              {/*    <Input*/}
              {/*      placeholder="Business Email"*/}
              {/*      size="large"/>*/}
              {/*  </Item>*/}
              {/*</Col>*/}
            </Row>

            <h5 className="mt-5">Contact Information</h5>
            <Row gutter={12}>
              <Col span={12}>
                <Item
                  name="first_name"
                  id="first_name"
                  rules={[{required: true, message: "Owner Firstname is required"}]}
                  label="Owner Firstname">
                  <Input
                    size="large"
                    placeholder="Owner Firstname"/>
                </Item>
              </Col>
              <Col span={12}>
                <Item
                  name="last_name"
                  id="last_name"
                  rules={[{required: true, message: "Owner Lastname is required"}]}
                  label="Owner Lastname">
                  <Input
                    size="large"
                    placeholder="Owner Lastname"/>
                </Item>
              </Col>

              <Col span={18}>
                <Item
                  name="address"
                  id="address"
                  rules={[{required: true, message: "Address is required"}]}
                  label="Address">
                  <Input
                    size="large"
                    placeholder="Address"/>
                </Item>
              </Col>
              <Col span={6}>
                <Item
                  name="suite"
                  id="suite"
                  label="Suite">
                  <Input
                    size="large"
                    placeholder="Suite"/>
                </Item>
              </Col>

              <Col span={6}>
                <Item
                  label="Country"
                  rules={[{required: true, message: "Country is required."}]}
                  name="country"
                  id="country">
                  <Select
                    filterOption={(input, option) =>
                      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    showSearch
                    placeholder="Select Country"
                    onChange={(e) => {
                      setCountry(e)
                    }}
                    options={countries.all().map((i) => {
                      i.value = i.isoCode
                      i.label = i.name
                      return i
                    })}
                    size="large">
                  </Select>
                </Item>
              </Col>
              <Col span={6}>
                <Item
                  label="State"
                  rules={[{required: true, message: "State is required."}]}
                  name="state"
                  id="state">
                  <Select
                    filterOption={(input, option) =>
                      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    showSearch
                    placeholder="Select State"
                    onChange={(e) => {
                      setState(e)
                    }}
                    options={states.getByCountry(country).map((i) => {
                      i.value = i.isoCode
                      i.label = i.name
                      return i
                    })}
                    size="large">
                  </Select>
                </Item>
              </Col>
              <Col span={6}>
                <Item
                  label="City"
                  rules={[{required: true, message: "City is required."}]}
                  name="city"
                  id="city">
                  <Select
                    filterOption={(input, option) =>
                      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    showSearch
                    placeholder="Select City"
                    options={cities.getByState(state, country).map((i) => {
                      i.value = i.name
                      i.label = i.name
                      return i
                    })}
                    size="large">
                  </Select>
                </Item>
              </Col>
              <Col span={6}>
                <Item
                  name="zip"
                  id="zip"
                  rules={[{required: true, message: "Zipcode is required"}]}
                  label="Zipcode">
                  <Input
                    size="large"
                    placeholder="Zipcode"/>
                </Item>
              </Col>
            </Row>

            <h5 className="mt-5">Tax Information</h5>
            <Row gutter={12}>
              <Col span={8}>
                <Item
                  rules={[{required: true, message: "Tax ID / EIN is required"}]}
                  label="Tax ID / EIN"
                  id="tax_id"
                  name="tax_id">
                  <Input
                    placeholder="Tax ID / EIN"
                    size="large"/>
                </Item>
              </Col>
              <Col span={8}>
                <Item
                  rules={[{required: true, message: "Tax Rate is required"}]}
                  label="Tax Rate (%)"
                  id="tax_info"
                  name="tax_info">
                  <Input
                    placeholder="Tax Rate (%)"
                    size="large"/>
                </Item>
              </Col>
              <Col span={8}>
                <Item
                  rules={[{required: true, message: "State Incorporated is required"}]}
                  label="State Incorporated"
                  id="state_incorporated"
                  name="state_incorporated">
                  <Input
                    placeholder="State Incorporated"
                    size="large"/>
                </Item>
              </Col>
            </Row>

            <h5 className="mt-5">Payment Information</h5>
            <Row gutter={12}>
              <Col span={12}>
                <Item
                  rules={[{required: true, message: "Account Number is required"}]}
                  id="account_number"
                  name="account_number"
                  label="Account Number">
                  <Input
                    placeholder="Account Number"
                    size="large"/>
                </Item>
              </Col>
              <Col span={12}>
                <Item
                  rules={[{required: true, message: "Routing Number is required"}]}
                  id="routing_number"
                  name="routing_number"
                  label="Routing Number">
                  <Input
                    placeholder="Routing Number"
                    size="large"/>
                </Item>
              </Col>
              <Col span={9}>
                <Item
                  rules={[{required: true, message: "Last 4 SSN is required"}]}
                  id="ssn"
                  name="ssn"
                  label="Last 4 SSN">
                  <Input
                    placeholder="Last 4 SSN"
                    size="large"/>
                </Item>
              </Col>
              <Col span={5}>
                <Item
                  id="dob_day"
                  name="dob_day"
                  label="Day of Birth">
                  <Select size="large">
                    {
                      [...new Array(31)].map((_, index) => (
                        <Option key={`day-${index}`} value={`${index < 9 ? "0" : ""}${index + 1}`}>
                          {index < 9 && "0"}{index + 1}
                        </Option>
                      ))
                    }
                  </Select>
                </Item>
              </Col>
              <Col span={5}>
                <Item
                  id="dob_month"
                  name="dob_month"
                  label="Month of Birth">
                  <Select size="large">
                    {
                      [...new Array(12)].map((_, index) => (
                        <Option key={`month-${index}`} value={`${index < 9 ? "0" : ""}${index + 1}`}>
                          {index < 9 && "0"}{index + 1}
                        </Option>
                      ))
                    }
                  </Select>
                </Item>
              </Col>
              <Col span={5}>
                <Item
                  id="dob_year"
                  name="dob_year"
                  label="Year if Birth">
                  <Select size="large">
                    {
                      [...new Array(90)].map((_, index) => (
                        <Option key={`year-${index}`} value={new Date().getFullYear() - index - 10}>
                          {new Date().getFullYear() - index - 10}
                        </Option>
                      ))
                    }
                  </Select>
                </Item>
              </Col>
            </Row>

            <Item style={{marginBottom:'50', textAlign:'center'}}>
            <Button 
                loading={loading}
                htmlType="submit"
                type="primary"
                size="large">
                Save Legal Information
              </Button>
            </Item>
          </Form>
        </div>
      </div>
    </Layout>
    );
};

export default StoreProfileSettingsPage;
