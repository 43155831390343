import React from 'react'
import { Styles } from "../../layouts/style/footerPageStyle";

const FooterEmpty = () => {

  return (
    <Styles>
      <div id="holder"/>
        <footer id="footer2">
          <div>
            <p style={{ textAlign: 'center', color: 'black' }}>
              VEEV&reg; registered VEEV Inc.
            </p>
          </div>
        </footer>
    </Styles>
  );
}
export default FooterEmpty
