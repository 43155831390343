import { Form, Input, InputNumber } from "antd";

const { Item } = Form;

const AddOnForm = ({
  categories,
  category: categoryId,
  name,
  setName,
  price,
  setPrice,
}) => {
  let categoryName =
    categories && categories.find((m) => m.id === categoryId)?.name;
  return (
    <div className="row">
      <div className="col col-lg-12">
        <Form
          layout="vertical"
          initialValues={{
            category: {
              value: categoryId,
              label: categoryName,
            },
            name: name,
            price: price,
          }}
        >
          <Item name="name" id="name" label="Name">
            <Input
              placeholder="Enter Extra Name"
              size="large"
              onChange={(e) => setName(e.target.value)}
            />
          </Item>

          <Item name="price" id="price" label="Price">
            <InputNumber
              step="0.01"
              prefix="$"
              suffix="USD"
              placeholder="0.00"
              style={{ width: "100%" }}
              onChange={(value) => setPrice(value)}
            />
          </Item>
        </Form>
      </div>
    </div>
  );
};

export default AddOnForm;
