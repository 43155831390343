import React, { useEffect, useState } from 'react'
import Layout from "../../../../layouts/dashboard";
import CRMTab from "../../../../layouts/dashboard/components/crm-tab";
import ProfileSidebar from "./sidebar";
import { useAsync } from "../../../../service/utils";
import { useSetting } from '../../../../provider/setting';
import { getAllWithMerchantId as getGroups } from '../../../../api/groups';

const Followers = () => {
  const { data, status, error, run } = useAsync({
    status: "idle",
  });
  const [setting] = useSetting()
  const [followers, setFollowers] = useState([])
  
  useEffect(() => {
    if (setting?.auth) {
      run(getGroups(setting?.auth?.signin_merchant_id))
    }
  }, [run, setting?.auth])
  useEffect(() => {
    if (status === 'resolved') {
      setFollowers(data?.members)
    }
    else if (status === 'rejected') {
      console.log(error)
    }
  }, [status])
  return (
    <>
      <Layout>
      <CRMTab/>  
      <div className="row">
        <div className="col col-lg-2">
          <ProfileSidebar/>
        </div>
        <div className='col col-lg-10'>
        <h4>Followers</h4>
          {/* <div className="devsite-section-article" id="id_sec_group_members"> */}
            <div className="row" id="id_sec_group_members">
            <div className="col-md-12">
              <fieldset>
                <label htmlFor="" className="ak-is-overlaid cursor">Followers from <a href='/bank/checkin/access' style={{ color: '#cc0000' }}>Checkin Access</a> page on VEEV app.</label>
              </fieldset>
              <div className="row">
                <div className="col-md-8">
                  <input className="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search" id="id_txt_crm_user_search" />
                </div>
                <div className="col-md-2">
                  <button className="btn2 btn2-outline js-scroll-trigger crm-tab-btn form-control" type="button" id="id_btn_crm_user_search">Search</button>
                </div>
                
              </div>
              <div className="row">
                <div className="col-md-12 mt-sm-2">
                  <table id="id_tbl_metrc_list" style={{ width: '100%'}}>
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>User</th>
                        <th>Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {followers.map((follower, index) => (
                        <tr key={index}>
                          <td>{follower.id}</td>
                          <td>{follower.name}</td>
                          <td>{follower.created_at}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div> 
        </div>
      </div>
    </Layout>
    </>
  );
}
export default Followers
