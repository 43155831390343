import React, {useEffect, useState} from 'react';
import Layout from "../../../layouts/dashboard";
import ProfileSidebar from "./sidebar2";
import {useSetting} from "../../../provider/setting";
import {getEventsApi} from "../../../api/api";
import siteConfig from "../../../config/site.config";
import {Icon} from "@iconify/react";
import {NotificationManager} from "react-notifications";

const ActivateAppQrPage = () => {
  const [setting] = useSetting();

  const [activeEvents, setActiveEvents] = useState([]);
  const [events, setEvents] = useState([])
  const [accessUrl, setAccessUrl] = useState('')

  const handleShare = () => {

  }

  useEffect(()=>{
    if(setting?.auth?.signin_merchant_id) {
      getEventsApi(setting?.auth?.signin_merchant_id)
        .then(({data}) => {
          console.log(data)
          setActiveEvents(data['active_events'] || [])
          const evts = data
          evts.map((item)=>{
            const params = [
              "veev-events",
              1,
              item["id"],
              item["merchant_id"],
              "events",
              siteConfig.baseUrl + "/events/" + item["id"],
            ]

            const data_str = params.join("|||");
            const qr_src = "https://chart.googleapis.com/chart?cht=qr&chs=200x200&chld=H7C0&chl=" + data_str;
            item.qrcode = qr_src;
            return item
          })
          setEvents(evts)
        })
        .catch((e) => {
          console.warn(e)
        })
    }
  }, [setting?.auth])

  console.log(events)

  return (
    <Layout>
      <div className="row">
        <div className="col col-lg-3">
          <ProfileSidebar/>
        </div>
        <div className='col col-lg-9'>
          <h4 className="mb-4">Checkin QR & App URL</h4>
          {events.map((event, index) => (
            <div className="card card-body mb-3" key={index}>
              <div className="row no-gutters">
                <div className="col-lg-3 mb-0">
                  <img
                    width={200}
                    height={200}
                    className="card-img qrcode"
                    src={event.qrcode}
                    alt="QR Code" />
                </div>
                <div className="col-lg-9 pr-5">
                  <div className="row py-3">
                    <div className="col-lg-12">
                      <p>Print QR for Scan & Checkin</p>
                      <h5 className="card-title">{event?.['event_title']}</h5>
                    </div>
                  </div>
                  <div>
                    <label>
                      Checkin App URL. Use link to access app from browser and checkin attendees at the door.
                    </label>
                    <div className="icon-input">
                      <input
                        type="text"
                        className="form-control"
                        value={event['access_token_url']}
                        onChange={(e) => setAccessUrl(e.target.value)}/>
                      <button onClick={()=>{
                        navigator.clipboard.writeText(event['access_token_url']);
                        NotificationManager.success("Copied to clipboard!", "Success")}}>
                        <Icon icon="iconoir:copy"/>
                      </button>
                    </div>
                  </div>
                  <div className="right-absolute">
                    <button className="btn-icon">
                      <Icon icon="ic:baseline-share"/>
                    </button>
                    <button className="btn-icon">
                      <Icon icon="material-symbols:print"/>
                    </button>
                  </div>
                </div>

              </div>
            </div>
          ))}
        </div>
      </div>
    </Layout>
  );
};

export default ActivateAppQrPage;
