import React, {useState} from 'react';
import {Button, Checkbox, Divider, Form, Input, message} from "antd";
import styled from "styled-components";
import {useNavigate} from "react-router-dom";
import {signupApi, loginWithEmail} from "../../api/api/auth";
import {setCookie} from "../../service/cookie";
import siteConfig from "../../config/site.config";
const { Item } = Form;

const SignupPopup = (props) => {
  const {setScreen} = props;
  const navigate = useNavigate();

  const [allowMerchant, setAllowMerchant] = useState(false);

    const finishHandle = async (values) => {
      console.log(values)
        const payload = {
            user: {
                email: values.email,
                handle: values.username,
                password: values.password,
                profile: {
                    first_name: values.firstname,
                    last_name: values.lastname,
                    phone: values.phone
                }
            }
        }

        try {
            const data = await signupApi(payload);
            if(data.id) {
                const res = await loginWithEmail(values.email, values.password)
                console.log("logging in ...", res);
                setCookie("veev_current_user_username", data?.handle, siteConfig.expired);
                setCookie("current_ident_token", data.ident_token, siteConfig.expired);
                setCookie("current_user_email", data.email, siteConfig.expired);
                // if (allowMerchant) {
                //     navigate("/merchant/register")
                // } else {
                //     navigate("/store/settings/store")
                // }
                //Goes to user page on signup
                if (allowMerchant) {
                    navigate("/merchant/register")
                } else {
                    navigate(`/user/${data.handle}`)
                }
            } else {
                message.error(data.message)
            }


        } catch(e)
        {
            message.error(e.response?.data?.message || "Sign up failed")
            console.warn(e)
        }
    }

    return (
        <CustomForm
            onFinish={finishHandle}
            layout="vertical">
            <Item
                name="phone"
                label="Phone Number">
                <Input placeholder="Enter Phone Number" size="large"/>
            </Item>
            
            <Item
                name="email"
                label="Email">
                <Input placeholder="Email Address" size="large"/>
            </Item>

            <Item
                name="firstname"
                label="First Name">
                <Input placeholder="Firstname" size="large"/>
            </Item>

            <Item
                name="lastname"
                label="Last Name">
                <Input placeholder="Lastname" size="large"/>
            </Item>

            <Item
                name="username"
                label="User Name">
                <Input placeholder="Username" size="large"/>
            </Item>

            <Item
                name="password"
                label="Password">
                <Input.Password placeholder="Password" size="large"/>
            </Item>

            <div className="checkbox-wrap">
                <Checkbox>
                    I Agree to Terms of Use.
                </Checkbox>
            </div>
            <Item>
                <Button
                    htmlType="submit"
                    type="primary"
                    block
                    size="large">
                    Create Account
                </Button>
            </Item>
            <Item style={{ padding: "0px", marginBottom: "12px"}}>
                <Divider>Already have an account?</Divider>
            </Item>
            <Item>
                <Button
                    onClick={() => {
                      setScreen("login")
                    }}
                    style={{
                      border: "2px solid #e74c3c",
                      color: "#e74c3c",
                    }}
                    type="default"
                    block
                    size="large">
                    Sign In
                </Button>
            </Item>
        </CustomForm>
    );
};


const CustomForm = styled(Form)`
  .ant-form-item-label {
    display: block;
    padding-bottom: 0;
    padding-left: 6px;

    label {
      font-weight: 600;
    }
  }

  .checkbox-wrap {
    padding-top: 12px;
    margin-bottom: 12px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    span {
      height: 100%;

      .ant-checkbox-inner {
        height: 24px;
        width: 24px;
        display: flex;
        align-items: center;
        justify-content: center;

        &:after {
          top: 44%;
          inset-inline-start: 20.5%;
          width: 8.71429px;
          height: 16.1429px;
          border-width: 3px;
        }
      }
    }

    .ant-checkbox-wrapper {
      span {
        font-size: 16px;
        line-height: 1.8;
        font-weight: 400;
      }
    }
  }
  
  .ant-form-item {
    margin-top: 8px;
    margin-bottom: 8px;

    .ant-form-item-control-input-content {
      height: 50px;
      display: flex;
      align-items: center;

      span {
        height: 100%;

        .ant-checkbox-inner {
          height: 24px;
          width: 24px;
          display: flex;
          align-items: center;
          justify-content: center;

          &:after {
            top: 44%;
            inset-inline-start: 20.5%;
            width: 8.71429px;
            height: 16.1429px;
            border-width: 3px;
          }
        }
      }

      .ant-checkbox-wrapper {
        span {
          font-size: 16px;
          line-height: 1.8;
          font-weight: 400;
        }
      }
    }

    input {
      margin: 0;
      font-weight: 500;
      font-size: 16px;
      height: 100%;
    }
  }

  .ant-btn {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 14px;
    font-weight: 700;

    span {
      line-height: 1;
      display: block;
      font-size: 18px;
      text-transform: uppercase;
    }

    :focus {
      outline: none;
    }
  }

  .forget-button {
    font-size: 14px;
    padding: 0;
    color: #e74c3c;
    font-weight: 600;
    text-decoration: underline;
    margin-bottom: 6px;

    :focus {
      outline: none;
    }
  }

  .notify-text {
    text-align: center;
    font-size: 16px;
    margin-top: 20px;
    margin-bottom: -10px;
  }
`

export default SignupPopup;