import React, {useEffect, useMemo, useState} from 'react';
//import AuthLayout from '../../layouts/landing/';
import HeaderHome from "../../../components/header/authhome";
import Footer from "../../../components/footer/footerPage";
import {HashLink as Link} from 'react-router-hash-link';
import {Avatar, Button, Dropdown, Space} from "antd";
import {Icon} from "@iconify/react";
import {Styles} from '../../../layouts/style/homeStyle';
import Plans from './plans'
import {useNavigate} from 'react-router-dom'

const Solutions = (props, toggleModal) => {
    /// Login popup materials
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);

  const [slider, setSlider] = useState('')
  const sliders = useMemo(() => [
    "../assets/img/recommendations.png",
    "../assets/img/store.png",
    "../assets/img/pos_store.PNG",
    "../assets/img/pos_sales.png",
  ], []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    setSlider(sliders[0])
    let index = 0
    let timer = setInterval(() => {
      index += 1;
      if (index >= sliders.length)
        index = 0
      setSlider(sliders[index])
    }, 2000);
    return () => clearInterval(timer);
  }, [sliders])

  const [slider2, setSlider2] = useState('')
  const sliders2 = useMemo(() => [
    // "../assets/img/veev_connect.png",
    "../assets/img/recommendations.png",
    "../assets/img/store.png",
    "../assets/img/veevreviews.png",
    // "../assets/img/veevcheckin.png",    
    // "../assets/img/veevstore.png",
  ], []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    setSlider2(sliders2[0])
    let index = 0
    let timer = setInterval(() => {
      index += 1;
      if (index >= sliders2.length)
        index = 0
      setSlider2(sliders2[index])
    }, 2000);
    return () => clearInterval(timer);
  }, [sliders2])

  const [slider3, setSlider3] = useState('')
  const sliders3 = useMemo(() => [
    "../assets/img/veev_connect.png",
    "../assets/img/checkout.png",
    "../assets/img/mobilepos1.png",
    "../assets/img/mobilepos2.png",
  ], []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    setSlider3(sliders3[0])
    let index = 0
    let timer = setInterval(() => {
      index += 1;
      if (index >= sliders3.length)
        index = 0
      setSlider3(sliders3[index])
    }, 2000);
    return () => clearInterval(timer);
  }, [sliders3])

  const [slider4, setSlider4] = useState('')
  const sliders4 = useMemo(() => [
    "../assets/img/veev_sales.png",
    "../assets/img/veev_event_signups.png",
    "../assets/img/veev_marketing.png",
  ], []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    setSlider4(sliders4[0])
    let index = 0
    let timer = setInterval(() => {
      index += 1;
      if (index >= sliders4.length)
        index = 0
      setSlider4(sliders4[index])
    }, 2000);
    return () => clearInterval(timer);
  }, [sliders4])

  const [slider5, setSlider5] = useState('')
  const sliders5 = useMemo(() => [
    "../assets/img/veev_checkin.png",
    "../assets/img/veev_event_signups.png",
    "../assets/img/veev_marketing.png",
  ], []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    setSlider5(sliders5[0])
    let index = 0
    let timer = setInterval(() => {
      index += 1;
      if (index >= sliders5.length)
        index = 0
      setSlider5(sliders5[index])
    }, 2000);
    return () => clearInterval(timer);
  }, [sliders5])

  const [slider6, setSlider6] = useState('')
  const sliders6 = useMemo(() => [
    "../assets/img/pos2.png",
    "../assets/img/pos3.png",
    "../assets/img/pos4.png",
    "../assets/img/veev_sales.png",
    "../assets/img/pos5.png",
  ], []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    setSlider6(sliders6[0])
    let index = 0
    let timer = setInterval(() => {
      index += 1;
      if (index >= sliders6.length)
        index = 0
      setSlider6(sliders6[index])
    }, 2000);
    return () => clearInterval(timer);
  }, [sliders6])

  return (
  <>
  {/* <AuthLayout> */}
  <HeaderHome />
    <Styles>
      <div className="guide" style={{paddingTop: 0, paddingBottom: 0}}>   
        {/* <section className="cta text-center" style={{paddingTop: 70, height: "100%", paddingBottom: 90}}>
          <div className="container" style={{paddingTop: '20px', paddingBottom: "20px"}}>
            <div className="col-lg-12 my-auto">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-4 my-auto text-center" style={{ paddingTop: '3px', paddingBottom: '0px'}}>    
                    <img src="../assets/img/veev_solution.png" width={'100%'}></img>
                  </div>
                  <div className="col-lg-1 dv-loadmoney">
                  </div>
                  <div className="col-lg-3 dv-loadmoney text-center" style={{ paddingBottom: '0px', marginBottom: '50px' }}>
                    <div className="device-container">
                      <div className="device-mockup iphone6 portrait gold">
                        <div className="device" style={{marginTop: 20, marginBottom: 0}}>
                          <div className="screen">	
                            <div className="slideshow-container">
                              <div className="mySlides">
                                <div className="veevslogan">
                                  <img src={slider} alt="VEEV Checkin" className="img-fluid2" />
                                </div>
                              </div>
                            </div>
                            <div style={{ textAlign: 'center' }}>
                              <span className="dot"></span>
                              <span className="dot"></span>
                              <span className="dot"></span>
                              <span className="dot"></span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-4 my-auto text-center" style={{ paddingTop: '3px', paddingBottom: '0px'}}>    
                    <p className="title0">Sell Out</p>
                    <div style={{paddingTop: '20px', marginLeft: "35px", marginRight: "35px"}}>
                      <Button
                      block
                      type="primary"
                      size="large"
                      onClick={toggleModal || null}>
                      Get Started
                      </Button>
                    </div> 
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12 text-center" style={{padding: 20}}>
              <a href="#howitworks">
              <img src={"../assets/img/512px-chevron_fontawesome.svg.png"} width={"30px"} style={{ paddingTop: '70px', marginLeft: '10px', textAlign: 'center', alignItems: 'center' }} className="img-fluid" />
              </a>
            </div>
          </div>
        </section> */}

        <section className="cta text-center" style={{paddingTop: 50, height: "100%", paddingBottom: 50}}>
          <div className="container" style={{paddingTop: '0px', paddingBottom: "20px"}}>
            <div className="col-lg-12 my-auto">
              <p className="title1">A Checkin Checkout Service</p>  
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-12 my-auto text-center" style={{ paddingTop: '60px', paddingBottom: '0px'}}>    
                    <img src="../assets/img/veev_checkin-out.png" width={'100%'}></img>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12 text-center" style={{ width: '100%', paddingBottom: '5px', paddingTop: '5px', color: '#000000' }}>
              <div className="row">
                <div className="col-lg-4"> 
                  <div style={{ width: '100%', padding: '5px', paddingBottom: '5px', paddingTop: '20px', color: '#000000' }}>  
                  <p className="title4">Checkin</p>
                  <p style={{ width: '100%', color: 'grey' }}>
                    <a href="#checkin">
                    Reservation & Checkin System for Restaurants & Cafes
                    </a>
                  </p>
                  </div>
                </div>
                <div className="col-lg-4"> 
                  <div style={{ width: '100%', padding: '5px', paddingBottom: '5px', paddingTop: '20px', color: '#000000' }}>  
                  <p className="title4">Checkout</p>
                  <p style={{ width: '100%', color: 'grey' }}>
                    <a href="#checkout">
                    Order & Pay Checkout System for Businesses
                    </a>
                  </p>
                  </div>
                </div> 
                <div className="col-lg-4"> 
                  <div style={{ width: '100%', padding: '5px', paddingBottom: '5px', paddingTop: '20px', color: '#000000' }}>  
                  <p className="title4">Solutions</p>
                  <p style={{ width: '100%', color: 'grey' }}>
                    <a href="#crm">
                    PoS, CRM, Sales, Marketing and Promotion System
                    </a>
                  </p>
                  </div>
                </div>
              </div>  
            </div> 
            <div className="col-lg-12 text-center" style={{padding: 10}}>
              <a href="#checkin">
              <img src={"../assets/img/512px-chevron_fontawesome.svg.png"} width={"30px"} style={{ paddingTop: '0px', marginLeft: '10px', textAlign: 'center', alignItems: 'center' }} className="img-fluid" />
              </a>
            </div>
          </div>
        </section>

        <section className="cta text-center" style={{paddingTop: '100px', paddingBottom: '60px' }} id="checkin">
          <div className="container">
            <div className="col-lg-12 my-auto">
              <p className="title1">Reservation & Checkin Service</p>  
              {/* <h5 style={{ width: '100%', paddingBottom: '30px', paddingTop: '10px', paddingLeft: '0px', color: '#000000'}}>
                Single location to manage sales, marketing and operations on Tiktok, Instagram, YouTube, Twitter, online and at Stores.
              </h5> */}
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-12 my-auto text-center" style={{ paddingTop: '60px', paddingBottom: '0px'}}>    
                    <img src="../assets/img/veev_checkin_booking.png" width={'100%'}></img>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="col-lg-12 my-auto">
              <div className="container-fluid">
                <div className="row" style={{marginTop: 0, marginBottom: 0}}>
                  <div className="col-lg-12 text-center" style={{ width: '100%', paddingBottom: '5px', paddingTop: '5px', color: '#000000' }}>
                    <div className="row">
                      <div className="col-lg-6"> 
                        <div style={{ width: '100%', paddingBottom: '5px', paddingTop: '20px', color: '#000000' }}>  
                        <p className="title4">Reservations</p>
                        <p style={{ width: '100%', color: 'grey' }}>Reservations from your website and social media</p>
                        </div>
                      </div> 
                      <div className="col-lg-6"> 
                        <div style={{ width: '100%', paddingBottom: '5px', paddingTop: '20px', color: '#000000' }}> 
                        <p className="title4">Checkin</p>
                        <p style={{ width: '100%', color: 'grey' }}>Easy, fast guest checkin</p>
                        </div>  
                      </div>
                      <div className="col-lg-2 dv-loadmoney text-center" style={{ maxHeight: 'auto' }}>

                      </div>
                    </div>  
                  </div> 
                </div>
              </div>
            </div>
          </div>
          <div className="row" style={{ width: '100%', paddingBottom: '30px', paddingTop: '5px', paddingRight: '20px', color: 'grey' }}>
              <div className="col-lg-5">
              
              </div>
              <div className="col-lg-2">
                <Button
                  block
                  type="primary"
                  size="large"
                  onClick={toggleModal || null}>
                  Get Started
                  </Button>
              </div>
              <div className="col-lg-5">
              
              </div>
            </div>
        </section>

        <section className="cta text-center" style={{paddingTop: '100px', paddingBottom: '60px' }} id="checkout">
          <div className="container">
            <div className="col-lg-12 my-auto">
              <p className="title1">Checkout Service</p>  
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-12 my-auto text-center" style={{ paddingTop: '60px', paddingBottom: '0px'}}>    
                    <img src="../assets/img/veev_checkout_pos.png" width={'100%'}></img>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="col-lg-12 my-auto">
              <div className="container-fluid">
                <div className="row" style={{marginTop: 0, marginBottom: 0}}>
                  <div className="col-lg-12 text-center" style={{ width: '100%', paddingBottom: '5px', paddingTop: '5px', color: '#000000' }}>
                    <div className="row">
                      <div className="col-lg-4"> 
                        <div style={{ width: '100%', paddingBottom: '5px', paddingTop: '20px', color: '#000000' }}>  
                        <p className="title4">Order Management</p>
                        <p style={{ width: '100%', color: 'grey' }}>
                        In-person & online orders processing
                        </p>
                        </div>
                      </div> 
                      <div className="col-lg-4"> 
                        <div style={{ width: '100%', paddingBottom: '5px', paddingTop: '20px', color: '#000000' }}>  
                        <p className="title4">Payment</p>
                        <p style={{ width: '100%', color: 'grey' }}>
                        Online pay, mobile pay and Tap-to-Pay
                        </p>
                        </div>
                      </div> 
                      <div className="col-lg-4"> 
                        <div style={{ width: '100%', paddingBottom: '5px', paddingTop: '20px', color: '#000000' }}>  
                        <p className="title4">Store Operations</p>
                        <p style={{ width: '100%', color: 'grey' }}>
                        Sales tracking & menu management
                        </p>
                        </div>
                      </div> 
                    </div>  
                  </div> 
                </div>
              </div>
            </div>
          </div>
          <div className="row" style={{ width: '100%', paddingBottom: '30px', paddingTop: '5px', paddingRight: '20px', color: 'grey' }}>
              <div className="col-lg-5">
              
              </div>
              <div className="col-lg-2">
                <Button
                  block
                  type="primary"
                  size="large"
                  onClick={toggleModal || null}>
                  Get Started
                  </Button>
              </div>
              <div className="col-lg-5">
              
              </div>
            </div>
        </section>

        <section className="features">
          <div className="container container--main">
            <div className="row h-100">
              <div className="col-lg-12 my-auto">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-lg-4 my-auto text-center" style={{ paddingTop: '75px'}}>
                      <img src="../assets/img/veevqr.png" style={{ marginTop: 10, marginBottom: 20, height: 120 }} alt="VEEV" />
                      <p className="title1">One Click Checkin, Checkout</p>
                      <p className="title3black" style={{ paddingTop: '3px'}}>solution for bars, cafes, events & stores</p>
                    </div>
                    <div className="col-lg-1 dv-loadmoney">
                    </div>
                    <div className="col-lg-3 dv-loadmoney text-center" style={{ paddingBottom: '0px', marginBottom: '0' }}>
                      <div className="device-container">
                        <div className="device-mockup iphone6 portrait gold">
                          <div className="device" style={{marginTop: 20, marginBottom: 0}}>
                            <div className="screen">	
                              <div className="slideshow-container">
                                <div className="mySlides">
                                  <div className="veevslogan">
                                    <img src={slider} alt="VEEV Checkin" className="img-fluid2" />
                                  </div>
                                </div>
                              </div>
                              <div style={{ textAlign: 'center' }}>
                                <span className="dot"></span>
                                <span className="dot"></span>
                                <span className="dot"></span>
                                <span className="dot"></span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4" style={{ textAlign: 'left', display: 'flex', alignItems: 'center' }}>
                      <div className="row">
                        <div style={{ width: '100%', paddingBottom: '25px', paddingTop: '25px', paddingLeft: '50px', color: 'grey' }}>
                        <p className="title3black">Checkin</p>
                        One click signup, scheduling, pay & ID verification for bars, clubs, events & businesses <a href="#checkin" style={{ color: '#cc0000' }}>more >></a> 
                        </div>
                        <div style={{ width: '100%', paddingBottom: '25px', paddingLeft: '50px', color: 'grey' }}> 
                        <p className="title3black">Checkout</p>
                        One click pay solution, eliminating ID and pay fraud in stores & online <a href="#checkout" style={{ color: '#cc0000' }}>more >></a>
                        </div>
                        <div style={{ width: '100%', paddingBottom: '20px', paddingLeft: '50px', color: 'grey' }}> 
                        <p className="title3black">CRM</p>
                        Robust CRM for sales, marketing & promotions <a href="#connect" style={{ color: '#cc0000' }}>more >></a>
                        </div>
                        <div style={{ width: '100%', paddingBottom: '0px', paddingLeft: '50px', color: 'grey' }} className="section-heading text-center submit-button">
                          <a href="/signin">
                            <button className="btn btn-success btn-sm btn-create-account" id="signup_btn" type='signup'>
                            Sign Up
                            </button>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12 text-center" style={{padding: 10}}>
            <a href="#checkin">
            <img src={"../assets/img/512px-chevron_fontawesome.svg.png"} width={"30px"} style={{ paddingTop: '70px', marginLeft: '10px', textAlign: 'center', alignItems: 'center' }} className="img-fluid" />
            </a>
            </div>
          </div>
        </section>

        <section className="text-center download" id="qrlogin">
          <div className="col-lg-12 text-left" style={{width: '100%', paddingTop: '0px', paddingLeft: '40px' }}> 
            <div className="row">
              <div className="col-lg-2" style={{ width: '100%', paddingBottom: '5px', paddingTop: '10px', paddingRight: '20px', color: 'grey' }}>
              <p className="title4">Scan</p>
              <p style={{ width: '100%', color: 'grey' }}>
              - Scan & Order<br></br>
              - Dynamic Menu Management
              </p>
              </div>
              <div className="col-lg-2" style={{ width: '100%', paddingBottom: '5px', paddingTop: '10px', paddingRight: '20px', color: 'grey' }}>
              <p className="title4">Order</p>
              <p style={{ width: '100%', color: 'grey' }}>
              - In-person, Online & Mobile<br></br>
              - Printer Integration
              </p>
              </div>
              <div className="col-lg-2" style={{ width: '100%', paddingBottom: '5px', paddingTop: '10px', paddingRight: '20px', color: 'grey' }}>
              <p className="title4">Checkout</p>
              <p style={{ width: '100%', color: 'grey' }}>
              - Card, Apple & Google Pay<br></br>
              - Integrated POS
              </p>
              </div>
              <div className="col-lg-2" style={{ width: '100%', paddingBottom: '5px', paddingTop: '10px', paddingRight: '20px', color: 'grey' }}>
              <p className="title4">Notify</p>
              <p style={{ width: '100%', color: 'grey' }}>
              - Order completion notificatoin<br></br>
              </p>
              </div>
              <div className="col-lg-2" style={{ width: '100%', paddingBottom: '5px', paddingTop: '10px', paddingRight: '20px', color: 'grey' }}>
              <p className="title4">Review</p>
              <p style={{ width: '100%', color: 'grey' }}>
              - Customer recommendations<br></br>
              </p>
              </div>
              <div className="col-lg-2" style={{ width: '100%', paddingBottom: '5px', paddingTop: '10px', paddingRight: '20px', color: 'grey' }}>
              <p className="title4">Promote</p>
              <p style={{ width: '100%', color: 'grey' }}>
              - In-App promotions<br></br>
              </p>
              </div>
            </div>
            <div className="row" style={{ width: '100%', paddingBottom: '30px', paddingTop: '5px', paddingRight: '20px', color: 'grey' }}>
              <div className="col-lg-5">
              
              </div>
              <div className="col-lg-2">
                <Button
                  block
                  type="primary"
                  size="large"
                  onClick={toggleModal || null}>
                  Get Started
                  </Button>
              </div>
              <div className="col-lg-5">
              
              </div>
            </div>
          </div>    
        </section>

        <section className="cta text-center" style={{paddingTop: '70px', paddingBottom: '50px' }} id="preorder">
          <div className="container">
            <div className="col-lg-12 my-auto text-center">
              <p className="title1" style={{paddingBottom: '30px' }}>Pre Order, Pre Sell, Pre Pay</p>  
            </div>
          </div>
          <div className="container" style={{paddingTop: '20px', paddingBottom: "20px"}}>
            <div className="col-lg-12 my-auto">
              <div className="container-fluid">
                <div className="row"> 
                  <div className="col-lg-3">  
                    <div className="device-mockup iphone6 portrait gold">
                      <div className="device" style={{marginTop: 0, marginBottom: 40}}>
                        <div className="screen">	
                          <div className="slideshow-container">
                            <div className="mySlides">
                              <div className="veevslogan">
                                <img src={slider2} alt="VEEV Checkin" className="img-fluid2" />
                              </div>
                            </div>
                          </div>
                          <div style={{ textAlign: 'center' }}>
                            <span className="dot"></span>
                            <span className="dot"></span>
                            <span className="dot"></span>
                            <span className="dot"></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-5 text-left" style={{width: '100%', paddingTop: '0px', paddingLeft: '40px' }}> 
                    <div style={{ width: '100%', paddingBottom: '5px', paddingTop: '10px', paddingRight: '20px', color: 'grey' }}>
                    <p className="title4">Order Management</p>
                    <p style={{ width: '100%', color: 'grey' }}>
                    - Online and in-person orders processing<br></br>
                    - Printer integration, auto accept & order print<br></br>
                    - Order complete and notification
                    </p>
                    </div>
                    <div style={{ width: '100%', paddingBottom: '5px', paddingTop: '0px', paddingRight: '20px', color: 'grey' }}>
                    <p className="title4">Payment</p>
                    <p style={{ width: '100%', color: 'grey' }}>
                    - Pre-pay and online pay<br></br>
                    - Tap-to-Pay<br></br>
                    - In-person Pay
                    </p>
                    </div>
                    <div style={{ width: '100%', paddingBottom: '5px', paddingTop: '0px', paddingRight: '20px', color: 'grey' }}>
                    <p className="title4">POS</p>
                    <p style={{ width: '100%', color: 'grey' }}>
                    - Vertically integrated POS<br></br>
                    - Sales tracking<br></br>
                    - Menu setup and management<br></br>
                    - Store pause
                    </p>
                    </div>
                  </div>
                  <div className="col-lg-4 text-left" style={{width: '100%', paddingTop: '0px', paddingLeft: '40px' }}> 
                    <div style={{ width: '100%', paddingBottom: '5px', paddingTop: '10px', paddingRight: '20px', color: 'grey' }}>
                    <p className="title4">Pickup / Ship</p>
                    <p style={{ width: '100%', color: 'grey' }}>
                    - Pickup orders<br></br>
                    - Shipping<br></br>
                    - Delivery (coming)
                    </p>
                    </div>
                    <div style={{ width: '100%', paddingBottom: '5px', paddingTop: '0px', paddingRight: '20px', color: 'grey' }}>
                    <p className="title4">Marketing</p>
                    <p style={{ width: '100%', color: 'grey' }}>
                    - SMS<br></br>
                    - Email<br></br>
                    - In-App
                    </p>
                    </div>
                    <div style={{paddingTop: '20px', marginLeft: "0px", marginRight: "35px"}}>
                      <Button
                      block
                      type="primary"
                      size="large"
                      onClick={toggleModal || null}>
                      Sign Me Up
                      </Button>
                    </div> 
                  </div>
                </div>  
              </div>
            </div>
          </div> 
        </section>

        <section className="cta text-center" style={{paddingTop: 70, paddingBottom: 50}} id="checkout">
          <div className="container">
            <div className="col-lg-12 my-auto text-center">
              <p className="title1" style={{paddingBottom: '50px' }}>In-Store Self Checkout</p>  
            </div>
          </div>
          <div className="container">
            <div className="col-lg-12 my-auto">
              <div className="container-fluid">
                <div className="row">   
                  <div className="col-lg-3">  
                    <div className="device-mockup iphone6 portrait gold">
                      <div className="device">
                        <div className="screen">
                        <img src="../assets/img/veev-qr-code.png" className="img-fluid2" alt="" />
                        </div>
                      </div>
                    </div>
                    <div className="section-heading text-center">
                    <p className="title4">Scan</p>
                    </div>
                  </div>
                  <div className="col-lg-3">  
                    <div className="device-mockup iphone6 portrait gold">
                      <div className="device">
                        <div className="screen">
                        <img src="../assets/img/veev_app_order.png" className="img-fluid2" alt="" />
                        </div>
                      </div>
                    </div>
                    <div className="section-heading text-center">
                    <p className="title4">Order</p>
                    </div>
                  </div>  
                  <div className="col-lg-3">  
                    <div className="device-mockup iphone6 portrait gold">
                      <div className="device">
                        <div className="screen">
                        <img src="../assets/img/veev_app_pay.png" className="img-fluid2" alt="" />
                        </div>
                      </div>
                    </div>
                    <div className="section-heading text-center">
                    <p className="title4">Pay</p>
                    </div>
                  </div>
                  <div className="col-lg-3">  
                    <div className="device-mockup iphone6 portrait gold">
                      <div className="device">
                        <div className="screen">
                        <img src="../assets/img/veev_app_receipt.png" className="img-fluid2" alt="" />
                        </div>
                      </div>
                    </div>
                    <div className="section-heading text-center">
                    <p className="title4">Recommend</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <div className="container">
            <div className="row">
              <div className="col-lg-12 my-auto">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-lg-2"></div>
                    <div className="col-lg-8" style={{ paddingBottom: 20, paddingTop: 50 }}>
                      <div className="device-mockup ipad_air_2 landscape silver">
                        <div className="device" style={{marginTop: 0, marginBottom: 30}}>
                          <div className="screen">	
                            <div className="slideshow-container">
                              <div className="mySlides">
                                <div className="veevslogan">
                                  <img src={slider6} className="img-fluid" />
                                </div>
                              </div>
                            </div>
                            <div style={{ textAlign: 'center' }}>
                              <span className="dot"></span>
                              <span className="dot"></span>
                              <span className="dot"></span>
                              <span className="dot"></span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-2"></div>   
                    <div className="col-lg-5">  
                      <div className="section-heading" style={{ paddingTop: '50px' }}>
                      <p className="title1">FREE Point of Sale</p>		
                      </div>
                      <div className="section-heading">
                      VEEV Pay is an easy to use, intuitive POS, loaded with features, designed to serve your business. VEEV POS suports:
                      <br />	
                      <a href="#webpay" className="js-scroll-trigger item-link"><b>In-Store Ordering</b></a><br />
                      <a href="#webpay" className="js-scroll-trigger item-link"><b>Online Ordering</b></a><br />
                      <a href="#webpay" className="js-scroll-trigger item-link"><b>Mobile Ordering</b></a><br />
                      <a href="#webpay" className="js-scroll-trigger item-link"><b>Pick-Ups & Delivery</b></a><br />
                      <a href="#webpay" className="js-scroll-trigger item-link"><b>Customer Check-Ins & Verification</b></a><br />	
                      <a href="#webpay" className="js-scroll-trigger item-link"><b>Credit & Branded Gift Cards</b></a><br />
                      <a href="#webpay" className="js-scroll-trigger item-link"><b>Sales Tracking & CRM</b></a><br />
                      <a href="#webpay" className="js-scroll-trigger item-link"><b>Store Operations, Inventory & Employee Management</b></a><br />
                      <a href="#bank" className="js-scroll-trigger item-link"><b>Banking & Compliance</b></a><br />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 my-auto">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-lg-4"> 
                      <div className="device-mockup ipad_air_2 landscape silver">
                        <div className="device">
                          <div className="screen">
                          <img src="https://staging.veev.app/img/veev_pos3.png" className="img-fluid" alt="" />
                          </div>
                        </div>
                      </div>
                      <div className="section-heading"><br />
                      <h4>Point of Sale</h4>
                      </div>
                      <div className="section-heading">
                      - Process payments instantly and Get paid instantly.<br /><br />
                      - View and get transaction details in real-time<br /><br />
                      - Support for credit and debit transactions     
                      </div>
                    </div> 
                    <div className="col-lg-4"> 
                      <div className="device-mockup ipad_air_2 landscape silver">
                        <div className="device">
                          <div className="screen">
                          <img src="https://staging.veev.app/img/veev_pos4.png" className="img-fluid" alt="" />
                          </div>
                        </div>
                      </div>
                      <div className="section-heading"><br />
                      <h4>Orders</h4>
                      </div>
                      <div className="section-heading">
                      - Eliminate paper receipts by issuing digital receipts<br /><br />
                      - View in-App orders initiated from the VEEV Wallet<br /><br /> 
                      - Search and sort to quickly find what you are looking for 
                      </div>
                    </div>
                    <div className="col-lg-4"> 
                      <div className="device-mockup ipad_air_2 landscape silver">
                        <div className="device">
                          <div className="screen">
                          <img src="https://staging.veev.app/img/veev_sales.png" className="img-fluid" alt="" />
                          </div>
                        </div>
                      </div>
                      <div className="section-heading"><br />
                      <h4>Sales</h4>
                      </div>
                      <div className="section-heading">
                      - Process payments instantly and Get paid instantly<br /><br />
                      - View and get transaction details in real-time<br /><br />
                      - Support for credit and debit transactions	
                      </div>
                    </div>
                    <div className="col-lg-3"> 
                      <div className="device-mockup ipad_air_2 landscape silver">
                        <div className="device">
                          <div className="screen">
                          <img src="https://staging.veev.app/img/veev_pos5.png" className="img-fluid" alt="" />
                          </div>
                        </div>
                      </div>
                      <div className="section-heading"><br />
                      <h4>Marketing</h4>
                      </div>
                      <div className="section-heading">
                      - Online, Offline and Mobile store management<br /><br />
                      - Employee Checkins, Hours & Payroll<br /><br />
                      - Access & Permissions<br /><br />
                      </div>
                    </div> 
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="cta text-center" style={{paddingTop: 70, paddingBottom: 50}} id="checkout">
          <div className="container">
            <div className="col-lg-12 my-auto text-center">
              <p className="title1" style={{paddingBottom: '50px' }}>StoreLink Social App Checkout</p>  
            </div>
          </div>
          <div className="container">
            <div className="col-lg-12 my-auto">
              <div className="container-fluid">
                <div className="row">   
                  <div className="col-lg-3">  
                    <div className="device-mockup iphone6 portrait gold">
                      <div className="device">
                        <div className="screen">
                        <img src="../assets/img/storelink1.png" className="img-fluid2" alt="" />
                        </div>
                      </div>
                    </div>
                    <div className="section-heading text-center">
                    <p className="title4">Click "Edit Profile'</p>
                    </div>
                  </div>
                  <div className="col-lg-3">  
                    <div className="device-mockup iphone6 portrait gold">
                      <div className="device">
                        <div className="screen">
                        <img src="../assets/img/storelink2.png" className="img-fluid2" alt="" />
                        </div>
                      </div>
                    </div>
                    <div className="section-heading text-center">
                    <p className="title4">Click 'Links'</p>
                    </div>
                  </div>  
                  <div className="col-lg-3">  
                    <div className="device-mockup iphone6 portrait gold">
                      <div className="device">
                        <div className="screen">
                        <img src="../assets/img/storelink3.png" className="img-fluid2" alt="" />
                        </div>
                      </div>
                    </div>
                    <div className="section-heading text-center">
                    <p className="title4">Click link icon</p>
                    </div>
                  </div>
                  <div className="col-lg-3">  
                    <div className="device-mockup iphone6 portrait gold">
                      <div className="device">
                        <div className="screen">
                        <img src="../assets/img/storelink4.png" className="img-fluid2" alt="" />
                        </div>
                      </div>
                    </div>
                    <div className="section-heading text-center">
                    <p className="title4">Paste StoreLink</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="cta text-center" style={{paddingTop: '100px', paddingBottom: '60px' }} id="crm">
          <div className="container">
            <div className="col-lg-12 my-auto text-center">
              <p className="title1">Turn Followers Into Customers</p><br></br>
            </div>
          </div>
          <div className="container">
            <div className="col-lg-12 my-auto">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-1 dv-loadmoney text-center" style={{ maxHeight: 'auto' }}>
                    
                  </div>
                  <div className="col-lg-3 dv-loadmoney text-center" style={{ maxHeight: 'auto' }}>
                    <div className="device-container">
                      <div className="device-mockup iphone6 portrait gold">
                        <div className="device" style={{marginTop: 0, marginBottom: 40}}>
                          <div className="screen">	
                            <div className="slideshow-container">
                              <div className="mySlides">
                                <div className="veevslogan">
                                  <img src={slider2} alt="VEEV Checkin" className="img-fluid2" />
                                </div>
                              </div>
                            </div>
                            <div style={{ textAlign: 'center' }}>
                              <span className="dot"></span>
                              <span className="dot"></span>
                              <span className="dot"></span>
                              <span className="dot"></span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-1 dv-loadmoney text-center" style={{ maxHeight: 'auto' }}>

                  </div>
                  <div className="col-lg-7 dv-loadmoney text-center" style={{ maxHeight: 'auto' }}>
                    <div className="device-container">
                      <div className="device-mockup imac imac">
                        <div className="device" style={{marginTop: 0, marginBottom: 40}}>
                          <div className="screen">	
                            <div className="slideshow-container">
                              <div className="mySlides">
                                <div className="veevslogan">
                                  <img src={slider4} className="img-fluid" />
                                </div>
                              </div>
                            </div>
                            <div style={{ textAlign: 'center' }}>
                              <span className="dot"></span>
                              <span className="dot"></span>
                              <span className="dot"></span>
                              <span className="dot"></span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <div className="section-heading text-center submit-button" style={{ paddingTop: '25px', paddingBottom: '30px'}}>
            <a href="/signin">
              <button className="btn btn-success btn-sm btn-create-account" id="signup_btn" type='signup' style={{ margin: '0px'}}>
              Sign Up
              </button>
            </a>
          </div>     
        </section>

        <section className="cta text-center" style={{paddingTop: '70px', paddingBottom: '5s0px' }} id="checkin">
          <div className="container" style={{paddingTop: '20px', paddingBottom: "20px"}}>
            <div className="col-lg-12 my-auto">
              <div className="container-fluid">
                <div className="row"> 
                  <div className="col-lg-3">  
                    <div className="device-mockup iphone6 portrait gold">
                      <div className="device" style={{marginTop: 0, marginBottom: 40}}>
                        <div className="screen">	
                          <div className="slideshow-container">
                            <div className="mySlides">
                              <div className="veevslogan">
                                <img src={slider2} alt="VEEV Checkin" className="img-fluid2" />
                              </div>
                            </div>
                          </div>
                          <div style={{ textAlign: 'center' }}>
                            <span className="dot"></span>
                            <span className="dot"></span>
                            <span className="dot"></span>
                            <span className="dot"></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-9 text-left" style={{width: '100%', paddingTop: '0px', paddingLeft: '40px' }}>
                    <div style={{ width: '100%', paddingBottom: '5px', paddingTop: '10px', paddingRight: '20px', color: 'grey' }}>
                    <h5 style={{ width: '100%', paddingBottom: '50px', paddingTop: '10px', paddingLeft: '0px', color: '#000000'}}>
                    
                    No more creating stores on every social app, uploading products, waiting for approval, setting up payment, remembering passwords and all that headache. Simply create store once and share VEEV StoreLink on all social apps. 
                    </h5>
                    <h className="title">Single System for All Social Apps</h>
                    </div> 
                    <div style={{ width: '100%', paddingTop: '20px', paddingBottom: '0px', paddingRight: '20px', color: 'grey' }}>
                    <p className="title4">One Store Link for on All Social Media</p>
                    <p style={{ width: '100%', color: 'grey' }}>No more creating stores on every social app. Create your store once and use everywhere.</p>
                    </div>
                    <div style={{ width: '100%', paddingBottom: '0px', paddingTop: '10px', paddingRight: '20px', color: 'grey' }}>
                    <p className="title4">One Place to Manage Product Listing on All Social Media</p>
                    <p style={{ width: '100%', color: 'grey' }}>One place for products listings, descriptions, uploading images, pricing, address and hours of operation.</p>
                    </div>
                    <div style={{ width: '100%', paddingBottom: '5px', paddingTop: '10px', paddingRight: '20px', color: 'grey' }}>
                    <p className="title4">One Checkout System for All Social Apps</p>
                    <p style={{ width: '100%', color: 'grey' }}>Create once and use everywhere. No more applying, waiting for approval, setting up payment and all that headache.</p>
                    </div>
                  </div>
                </div>  
              </div>
            </div>
          </div> 
          <div className="section-heading text-center submit-button" style={{ paddingTop: '25px', paddingBottom: '30px'}}>
            <a href="/signin">
              <button className="btn btn-success btn-sm btn-create-account" id="signup_btn" type='signup' style={{ margin: '0px'}}>
              Sign Up
              </button>
            </a>
          </div> 
        </section>

        <section className="cta text-center" style={{paddingTop: '50px', paddingBottom: '60px' }} id="checkin">
          <div className="container">
            <div className="col-lg-12 my-auto">  
              <h className="title">Sell Everywhere With One Link</h>
              <h5 style={{ width: '100%', paddingBottom: '50px', paddingTop: '10px', paddingLeft: '0px', color: '#000000'}}>
              No more creating stores on every social app, uploading products, waiting for approval, setting up payment, remembering passwords and all that headache. Simply create store once and share VEEV StoreLink on all social apps. 
              </h5>
            </div>
          </div>
          <div className="container">
            <div className="col-lg-12 my-auto">
              <div className="container-fluid">
                <div className="row"> 
                  <div className="col-lg-3">  
                    <div className="device-mockup iphone6 portrait gold">
                      <div className="device" style={{marginTop: 0, marginBottom: 40}}>
                        <div className="screen">	
                          <div className="slideshow-container">
                            <div className="mySlides">
                              <div className="veevslogan">
                                <img src={slider2} alt="VEEV Checkin" className="img-fluid2" />
                              </div>
                            </div>
                          </div>
                          <div style={{ textAlign: 'center' }}>
                            <span className="dot"></span>
                            <span className="dot"></span>
                            <span className="dot"></span>
                            <span className="dot"></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-9 text-left" style={{width: '100%', paddingTop: '0px', paddingLeft: '40px' }}>
                    <div style={{ width: '100%', paddingBottom: '5px', paddingTop: '10px', paddingRight: '20px', color: 'grey' }}>
                    <h3>Store Features</h3>
                    </div> 
                    <div style={{ width: '100%', paddingBottom: '5px', paddingTop: '10px', paddingRight: '20px', color: 'grey' }}>
                    <p className="title4">Store Info Page</p>
                    <p style={{ width: '100%', color: 'grey' }}>Store name, logo, address and hours of operation.</p>
                    </div>
                    <div style={{ width: '100%', paddingBottom: '5px', paddingTop: '10px', paddingRight: '20px', color: 'grey' }}>
                    <p className="title4">Product Listing</p>
                    <p style={{ width: '100%', color: 'grey' }}>Menu of product listings, description, add-ons and pricing.</p>                    </div>
                    <div style={{ width: '100%', paddingBottom: '5px', paddingTop: '10px', paddingRight: '20px', color: 'grey' }}>
                    <p className="title4">Checkout</p>
                    <p style={{ width: '100%', color: 'grey' }}>Menu of product listings, description, add-ons and pricing.</p>
                    </div>
                    <div style={{ width: '100%', paddingBottom: '20px', paddingTop: '10px', paddingRight: '20px', color: 'grey' }}>
                    <p className="title4">Recommendations</p>
                    <p style={{ width: '100%', color: 'grey' }}>Real reviews and recommendations from real customers.</p>
                    </div>
                  </div>
                </div>  
              </div>
            </div>
          </div> 
          <div className="section-heading text-center submit-button" style={{ paddingTop: '25px', paddingBottom: '30px'}}>
            <a href="/signin">
              <button className="btn btn-success btn-sm btn-create-account" id="signup_btn" type='signup' style={{ margin: '0px'}}>
              Sign Up
              </button>
            </a>
          </div> 
        </section> 
        
        <section>
          <div className="container">
            <div className="col-lg-12 my-auto">
              <div className="container-fluid">
                <div className="row">   
                  <div className="col-lg-3" style={{paddingTop: 33}}>
                    <div className="device-mockup iphone6 portrait gold">
                      <div className="device">
                        <div className="screen">
                        <img src="../assets/img/store.png" className="img-fluid2" alt="" />
                        </div>
                      </div>
                    </div>
                    <div className="section-heading text-center submit-button" style={{ paddingTop: '25px', paddingBottom: '30px'}}>
                    <a href="/signin">
                      <button className="btn btn-product btn-sm" id="signup_btn" type='signup' style={{ margin: '0px'}}>
                        Store  
                      </button>
                    </a>
                    </div> 
                  </div>
                  <div className="col-lg-2 dv-loadmoney">
                  </div>
                  <div className="col-lg-3 dv-loadmoney" style={{paddingTop: 33}}>
                    <div className="device-mockup iphone6 portrait gold">
                      <div className="device">
                        <div className="screen">
                        <img src="../assets/img/checkout.png" className="img-fluid2" alt="" />
                        </div>
                      </div>
                    </div>
                    <div className="section-heading text-center submit-button" style={{ paddingTop: '25px', paddingBottom: '30px'}}>
                    <a href="/signin">
                      <button className="btn btn-product btn-sm" id="signup_btn" type='signup' style={{ margin: '0px'}}>
                      Checkout
                      </button>
                    </a>
                    </div>
                  </div>
                  <div className="col-lg-1 dv-loadmoney">
                  </div>  
                  <div className="col-lg-3" style={{paddingTop: 33}}>   
                    <div className="device-mockup iphone6 portrait gold">
                      <div className="device">
                        <div className="screen">
                        <img src="../assets/img/recommendations.png" className="img-fluid2" alt="" />
                        </div>
                      </div>
                    </div>
                    <div className="section-heading text-center submit-button" style={{ paddingTop: '25px', paddingBottom: '30px'}}>
                    <a href="/signin">
                      <button className="btn btn-product btn-sm" id="signup_btn" type='signup' style={{ margin: '0px'}}>
                      Marketing
                      </button>
                    </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>    
        </section>

        <section className="features">
          <div className="container container--main">
            <div className="row h-100">
              <div className="col-lg-12 my-auto">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-lg-4 my-auto text-center" style={{ paddingTop: '75px'}}>
                      <img src="../assets/img/veevqr.png" style={{ marginTop: 10, marginBottom: 20, height: 120 }} alt="VEEV" />
                      <p className="title1">One Click Checkin, Checkout</p>
                      <p className="title3black" style={{ paddingTop: '3px'}}>solution for bars, cafes, events & stores</p>
                    </div>
                    <div className="col-lg-1 dv-loadmoney">
                    </div>
                    <div className="col-lg-3 dv-loadmoney text-center" style={{ paddingBottom: '0px', marginBottom: '0' }}>
                      <div className="device-container">
                        <div className="device-mockup iphone6 portrait gold">
                          <div className="device" style={{marginTop: 20, marginBottom: 0}}>
                            <div className="screen">	
                              <div className="slideshow-container">
                                <div className="mySlides">
                                  <div className="veevslogan">
                                    <img src={slider} alt="VEEV Checkin" className="img-fluid2" />
                                  </div>
                                </div>
                              </div>
                              <div style={{ textAlign: 'center' }}>
                                <span className="dot"></span>
                                <span className="dot"></span>
                                <span className="dot"></span>
                                <span className="dot"></span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4" style={{ textAlign: 'left', display: 'flex', alignItems: 'center' }}>
                      <div className="row">
                        <div style={{ width: '100%', paddingBottom: '25px', paddingTop: '25px', paddingLeft: '50px', color: 'grey' }}>
                        <p className="title3black">Checkin</p>
                        One click signup, scheduling, pay & ID verification for bars, clubs, events & businesses <a href="#checkin" style={{ color: '#cc0000' }}>more >></a> 
                        </div>
                        <div style={{ width: '100%', paddingBottom: '25px', paddingLeft: '50px', color: 'grey' }}> 
                        <p className="title3black">Checkout</p>
                        One click pay solution, eliminating ID and pay fraud in stores & online <a href="#checkout" style={{ color: '#cc0000' }}>more >></a>
                        </div>
                        <div style={{ width: '100%', paddingBottom: '20px', paddingLeft: '50px', color: 'grey' }}> 
                        <p className="title3black">CRM</p>
                        Robust CRM for sales, marketing & promotions <a href="#connect" style={{ color: '#cc0000' }}>more >></a>
                        </div>
                        <div style={{ width: '100%', paddingBottom: '0px', paddingLeft: '50px', color: 'grey' }} className="section-heading text-center submit-button">
                          <a href="/signin">
                            <button className="btn btn-success btn-sm btn-create-account" id="signup_btn" type='signup'>
                            Sign Up
                            </button>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12 text-center" style={{padding: 10}}>
            <a href="#checkin">
            <img src={"../assets/img/512px-chevron_fontawesome.svg.png"} width={"30px"} style={{ paddingTop: '70px', marginLeft: '10px', textAlign: 'center', alignItems: 'center' }} className="img-fluid" />
            </a>
            </div>
          </div>
        </section>
        
        <section className="download bg-primary3 text-center" id="events">
          <div>  
            <div className="row">
              <div className="col-lg-6 image"> 
                <img src="https://www.qrc.life/assets/images/ipgarde-qr-code-fidelisez-vos-utilisateurs-communiquez-avec-votre-audience-boostez-vos-ventes-888x592.jpg" alt="VEEV Event Signup" />	
              </div>
              <div className="col-lg-6 content">
                <div className="container">  
                  <div className="section-heading text-center">
                  <p className="title">Checkin Access</p>	
                  </div>
                </div> 
              </div>  
            </div>
          </div>
        </section>

        <section id="stores" className="download bg-primary3 text-center">
          <div>  
            <div className="row">
            <div className="col-lg-6 image">  
                <img src="https://staging.veev.app/img/veevscan.jpeg" alt="VEEV Pay" />	
              </div>
              <div className="col-lg-6 content"> 
                <div className="container">  
                  <div className="section-heading text-center">
                  <p className="title">Store Checkout</p>	
                  </div>
                </div>
              </div> 
            </div>
          </div>
        </section>

        <section className="cta text-center" style={{paddingTop: '100px', paddingBottom: '60px' }} id="crm">
          <div className="container">
            <div className="col-lg-12 my-auto text-center">
              <p className="title1" style={{paddingBottom: '30px' }}>CRM, Marketing & Promotion System</p>  
            </div>
          </div>
          <div className="container">
            <div className="col-lg-12 my-auto">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-6 dv-loadmoney text-center" style={{ maxHeight: 'auto' }}>
                    <div className="device-container">
                      <div className="device-mockup imac imac">
                        <div className="device" style={{marginTop: 0, marginBottom: 40}}>
                          <div className="screen">	
                            <div className="slideshow-container">
                              <div className="mySlides">
                                <div className="veevslogan">
                                  <img src={slider4} className="img-fluid" />
                                </div>
                              </div>
                            </div>
                            <div style={{ textAlign: 'center' }}>
                              <span className="dot"></span>
                              <span className="dot"></span>
                              <span className="dot"></span>
                              <span className="dot"></span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 text-left" style={{width: '100%', paddingTop: 30, paddingLeft: '0px' }}>
                    <div style={{ width: '100%', paddingBottom: '8px', paddingRight: '20px', color: 'grey' }}> 
                    <p className="title4">78% Purchases Influenced by Recommendations</p>
                    <p style={{ width: '100%', color: 'grey' }}>And are refer 50% more people than one time buyers.</p>
                    </div>
                    <div style={{ width: '100%', paddingBottom: '12px', paddingRight: '20px', color: 'grey' }}>
                    <p className="title4">81% Purchase from Retailers Offering Rewards</p>
                    <p style={{ width: '100%', color: 'grey' }}>Which increases customer retention, a 5% retention increases profitability by 75%.</p>
                    </div>
                    <div style={{ width: '100%', paddingBottom: '20px', paddingRight: '20px', color: 'grey' }}> 
                    <p className="title4">67% More Spent by Repeat Customers</p>
                    <p style={{ width: '100%', color: 'grey' }}>And 80% of future profits come from 20% of customers.</p>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-4"> 
                    <div className="device-mockup imac imac">
                      <div className="device">
                        <div className="screen">
                        <img src="../assets/img/veev_sales.png" className="img-fluid" alt="" />
                        </div>
                      </div>
                    </div>
                    <div className="section-heading">
                    <h3>Sales</h3>
                    </div>
                    <div style={{ width: '100%', paddingBottom: '5px', paddingTop: '0px', paddingRight: '20px', color: 'grey' }}>
                    <p style={{ width: '100%', color: 'grey' }}>Sales transactions by customer and product, daily, monthly, by region and many other parameters.</p>
                    </div>
                  </div>
                  <div className="col-lg-4"> 
                    <div className="device-mockup imac imac">
                      <div className="device">
                        <div className="screen">
                        <img src="../assets/img/veev_marketing.png" className="img-fluid" alt="" />
                        </div>
                      </div>
                    </div>
                    <div className="section-heading">
                    <h3>Marketing</h3>
                    </div>
                    <div style={{ width: '100%', paddingBottom: '5px', paddingTop: '0px', paddingRight: '20px', color: 'grey' }}>
                    <p style={{ width: '100%', color: 'grey' }}>Offers & Promotions targeted by customer, by product and other metrics to generate demand.</p>
                    </div>
                  </div>
                  <div className="col-lg-4"> 
                    <div className="device-mockup imac imac">
                      <div className="device">
                        <div className="screen">
                        <img src="../assets/img/veev_event_signups.png" className="img-fluid" alt="" />
                        </div>
                      </div>
                    </div>
                    <div className="section-heading">
                    <h3>CRM</h3> 
                    </div>
                    <div style={{ width: '100%', paddingBottom: '5px', paddingTop: '0px', paddingRight: '20px', color: 'grey' }}>
                    <p style={{ width: '100%', color: 'grey' }}>Dynamic CRM to own your customers in case you get hacked or shut out from social media.</p>
                    </div>
                  </div> 
                </div>
              </div>
            </div>
          </div> 
          <div className="row" style={{ width: '100%', paddingBottom: '30px', paddingTop: '5px', paddingRight: '20px', color: 'grey' }}>
              <div className="col-lg-5">
              
              </div>
              <div className="col-lg-2">
                <Button
                  block
                  type="primary"
                  size="large"
                  onClick={toggleModal || null}>
                  Get Started
                  </Button>
              </div>
              <div className="col-lg-5">
              
              </div>
            </div>    
        </section>

        <section className="cta text-center" style={{paddingTop: '100px', paddingBottom: '60px' }} id="pricing">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 my-auto">
                <div className="container-fluid">
                  <p className="title1">Pricing</p>
                  <p></p>
                </div>
              </div>
            </div>
          </div>
          <div className="container" id="#pricing">
            <Plans/>
          </div>
          <div className="row" style={{ width: '100%', paddingBottom: '30px', paddingTop: '5px', paddingRight: '20px', color: 'grey' }}>
              <div className="col-lg-5">
              
              </div>
              <div className="col-lg-2">
                <Button
                  block
                  type="primary"
                  size="large"
                  onClick={toggleModal || null}>
                  Get Started
                  </Button>
              </div>
              <div className="col-lg-5">
              
              </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-lg-12 my-auto">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-lg-12"> 
                     
                      One system to run your entire operations. VEEV Pay provides your business Mobile Ordering and fullfilment capability for items listed in you POS. Mobile orders initiated from VEEV Wallets are automatically organized into customer’s fulfillment preference, pickup, deliver, or ship. 

                      Supports instant mobile ordering and pay from any smart phone with simple Click & Pay, approve transaction. Payment are processed instantly. Purchase receipt is displayed instantly.<br /><br />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>    
        </section>
      </div>
    </Styles>
  <Footer />
  {/* </AuthLayout> */}
  </>  
  )
}
export default Solutions
