import styled from "styled-components";

export const Styles = styled.div`
  section {
    padding-top: 50px;
    padding-bottom: 0px;
    background-image: "";
    p.title {
      font-weight: bold;
      font-size: 55pt;
      font-weight: 700;
      color: #4d4d4d;
      padding-top: 0px;
      padding-bottom: 0px;
      //height: 125px;
      margin-top: 0px;
      margin-bottom: 0px;
    }
    p.title0 {
      font-weight: 700;
      font-size: 50pt;
      color: #4d4d4d;
      margin-bottom: 0px;
      margin-top: 0px;
      letter-spacing: 6px;
    }
    p.title0white {
      font-weight: 700;
      font-size: 50pt;
      color: #ffffff;
      margin-bottom: 0px;
      margin-top: 0px;
      letter-spacing: 6px;
    }
    p.titlethin {
      font-size: 40pt;
      color: #4d4d4d;
      margin-bottom: 0px;
      margin-top: 0px;
    }
    p.titlethin30 {
      font-size: 30pt;
      color: #4d4d4d;
      margin-bottom: 0px;
      margin-top: 0px;
    }
    p.title1 {
      font-weight: bold;
      font-size: 30pt;
      color: #4d4d4d;
      margin-top: 0px;
      margin-bottom: 0px;
    }
    p.title1white {
      font-weight: bold;
      font-size: 30pt;
      color: #ffffff;
      margin-top: 0px;
      margin-bottom: 0px;
    }
    p.title2 {
      font-weight: bold;
      font-size: 25pt;
      color: #4d4d4d;
      margin-top: 0px;
      margin-bottom: 10px;
    }
    p.title2white {
      font-weight: none;
      font-size: 25pt;
      color: #ffffff;
      margin-top: 50px;
      margin-bottom: 50px;
    }
    p.title3 {
      font-weight: none;
      font-size: 20pt;
      color: #4d4d4d;
      margin-top: 0px;
      margin-bottom: 0px;
    }
    p.title3black {
      font-weight: bold;
      font-size: 20pt;
      color: #4d4d4d;
      margin-top: 0px;
      margin-bottom: 0px;
      padding-bottom: 0px;
    }
    p.title4 {
      font-weight: 700;
      font-size: 15pt;
      color: #4d4d4d;
      margin-top: 10px;
      margin-bottom: 0px;
      padding-bottom: 0px;
    }
    p.title4white {
      font-weight: none;
      font-size: 12pt;
      color: #ffffff;
      margin-top: 10px;
      margin-bottom: 0px;
    }
    .container--main {
      margin-top: 100px;
      @media screen and (min-width: 992px) {
        margin-top: 60px;
      }
    }
    .dv-loadmoney {
      right: 40px;
      @media (max-width: 992px) {
        right: 0px;
      }
    }
    .dv-loadmoney2 {
      right: 40px;
      @media (max-width: 1080px) {
        right: 0px;
      }
    }
    .submit-button {
      padding-bottom: 0px;
      padding-top: 0px;
    }
    .btn-create-account {
      border-radius: 300px;
      font-family: Lato, Helvetica, Arial, sans-serif;
      letter-spacing: 2px;
      text-transform: uppercase;
      margin-right: 45px;
      padding: 0px;
      padding: 0.5rem 1rem;
      font-size: 1.25rem;
      color: #fff !important;
      background-color: #28a745;
      border-color: #28a745;
    }
  }

div.guide {
  section.cta {
    box-shadow: 0px 5px 50px 0px #00000026;
    .title {
      padding-bottom: 0px;
      font-weight: bold;
      font-size: 30pt;
      color: #4d4d4d;
    }
    .subtitle {
      font-weight: bold;
      font-size: 20pt;
      color: #000;
    }
  }
  section.ctafree {
    .title {
      padding-bottom: 30px;
      font-weight: bold;
      font-size: 30pt;
      color: #4d4d4d;
    }
    .subtitle {
      font-weight: bold;
      font-size: 20pt;
      color: #000;
    }
  }
  section.features-gray {
    background: url(), -webkit-linear-gradient(to top, #f6f6f6, #f6f6f6);
    .title {
      font-weight: bold;
      font-size: 30pt;
      color: #4d4d4d;
    }
  }
  section.features-white {
    background: url(), -webkit-linear-gradient(to top, #f6f6f6, #f6f6f6);
    .title {
      font-weight: bold;
      font-size: 20pt;
      color: #000;
    }
  }
  section.download {
    padding: 0px 0px 0px 0px;
    div.image {
      padding: 0px 0px 0px 0px;
      img {
        width: 100%;
        height: auto;
      }
    }
    div.content {
      padding: 0px 0px 0px 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      p.title {
        font-weight: bold;
        font-size: 40pt;
        color: #fff;
      }
      p.subtitle {
        font-weight: bold;
        font-size: 20pt;
        color: #fff;
      }
    }
  }
  img.wallet {
    height: 60px;
  }
}
`;


