import React from 'react';
import Layout from "../../../layouts/dashboard";
import Tab from "../../../layouts/dashboard/components/settings-tab";
import CheckinSidebar from "./sidebar";

const TicketsPage = () => {
  return (
    <Layout>
    <Tab/>  
      <div className="row">
        <div className="col col-lg-3">
          <CheckinSidebar/>
        </div>
        <div className="col col-lg-9">

        </div>
      </div>
    </Layout>
  );
};

export default TicketsPage;
