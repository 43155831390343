import React, {useEffect, useState} from 'react';
import Layout from "../../../layouts/dashboard";
import Tab from "../../../layouts/dashboard/components/settings-tab";
import {Input} from "antd";
import Sidebar from "./sidebar";
import {useSetting} from "../../../provider/setting";
import { get as getMerchantApi } from '../../../api/merchant';

const StoreLink = () => {
  const [setting] = useSetting();
  const [storeUrl, setStoreUrl] = useState()
  const [merchant, setMerchant] = useState({});

  const getMerchantHandle = () => {
    if (!!setting?.auth?.signin_merchant_id) {
      getMerchantApi(setting?.auth?.signin_merchant_id)
        .then((data) => {
          setStoreUrl(window.location.origin+'/stores/' + (data.dba.toLowerCase().replaceAll(" ", "_")))
          setMerchant({
            ...data,
            tax_info: parseInt(parseFloat(data.tax_info || "0") * 100)
          })
        })
        .catch((e) => {
          console.warn(e)
        })
    }
  }

  useEffect(() => {
    if (!!setting?.auth?.signin_merchant_id) {
      getMerchantHandle();
    }
  }, [setting?.auth])

  return (
    <Layout>
    <Tab/>  
      <div className="row">
          <div className="col col-lg-3">
            <Sidebar/>
          </div>
          <div className='col col-lg-9'>
            <h4>StoreLink For Online Stores</h4>
            <p>
            Below is your online StoreLink for Checking Checkout services.
            </p>  
            <div>
              {/* Copy and paste link in browser to view your online store */}
              <p id="storelink url" name="storelink url" label="storelink url">
                <Input
                  style={{margin: 0}}
                  placeholder="StoreLink URL"
                  value={storeUrl}
                  size="large"/>
              </p>  
            </div>   
            <div className="my-auto" style={{paddingTop: 33}}>
              <h4>StoreLink For Social Apps</h4>
              <p>
              Provide Checking Checkout services directly from social apps with StoreLink. Copy and paste StoreLink into your Instagram, Tiktok, Youtube & Twitter page.
              </p> 
              <div className="container-fluid">
                  <div className="row">   
                      <div className="col-lg-3" style={{paddingTop: 10}}>
                      <div className="device-mockup iphone6 portrait gold">
                      <div className="device">
                          <div className="screen">
                          <img src="../../assets/img/storelink1.png" className="img-fluid2" alt="" />
                          </div>
                      </div>
                      </div>
                      <div className="section-heading text-center" style={{paddingTop: 0}}>
                      
                      <p style={{ width: '100%', paddingBottom: '25px', paddingTop: '10px', paddingLeft: '0px', color: 'grey'}}>
                      Click<br></br>'Edit Profile'
                      </p>                   
                      </div>
                      </div>

                      <div className="col-lg-3 dv-loadmoney" style={{paddingTop: 10}}>
                      <div className="device-mockup iphone6 portrait gold">
                          <div className="device">
                              <div className="screen">
                              <img src="../../assets/img/storelink2.png" className="img-fluid2" alt="" />
                              </div>
                          </div>
                      </div>
                      <div className="section-heading text-center" style={{paddingTop: 0}}>
                      
                      <p style={{ width: '100%', paddingBottom: '25px', paddingTop: '10px', paddingLeft: '10px', color: 'grey'}}>
                      Click<br></br>'Links'
                      </p>                   
                      </div>
                      </div>

                      <div className="col-lg-3 dv-loadmoney" style={{paddingTop: 10}}>
                      <div className="device-mockup iphone6 portrait gold">
                          <div className="device">
                              <div className="screen">
                              <img src="../../assets/img/storelink3.png" className="img-fluid2" alt="" />
                              </div>
                          </div>
                      </div>
                      <div className="section-heading text-center" style={{paddingTop: 0}}>
                     
                      <p style={{ width: '100%', paddingBottom: '25px', paddingTop: '10px', paddingLeft: '0px', color: 'grey'}}>
                      Click<br></br>'Links' Icon
                      </p>                   
                      </div>
                      </div>
                  
                      <div className="col-lg-3 dv-loadmoney" style={{paddingTop: 10}}>
                      <div className="device-mockup iphone6 portrait gold">
                          <div className="device">
                              <div className="screen">
                              <img src="../../assets/img/storelink4.png" className="img-fluid2" alt="" />
                              </div>
                          </div>
                      </div>
                      <div className="section-heading text-center" style={{paddingTop: 0}}>
                     
                      <p style={{ width: '100%', paddingBottom: '25px', paddingTop: '10px', paddingLeft: '0px', color: 'grey'}}>
                      Paste StoreLink.<br></br>Click 'Done'
                      </p>                   
                      </div>
                      </div>
                      </div>
                    </div>
                </div>
          </div>
      </div>
    </Layout>
  );
};

export default StoreLink;