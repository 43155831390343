import { createContext, useReducer, useContext } from 'react';

export const CartContext = createContext(null);

// const Item = {
//     item: {id: 1, name: "asdfd", price: "838"},
//     quantity: 12,
//     extras: [],
//     total: function() {
//         return this.item.price * this.quantity
//     }
// }

const itemTotal = function() {
    return this.item.price * this.quantity;
}

const addonTotal = function() {
    return this.extras
        .reduce((accumn, current) => accumn + parseFloat(current.price || 0), 0) * this.quantity;
}

const cartTotal = function() {
    return this.items
        .reduce((accum, current) => accum + current.total() + current.addonTotal(), 0);
}

const initialState = {
    isOpen: false,
    merchantId: "",
    merchantName: "",
    items: [],
    total: function() {
        return this.items.reduce((accum, current) => accum + current.total(), 0);
    }
}

function cartReducer(prevState, action) {
    const state = {...prevState};
    state.total = cartTotal.bind(state)
    switch (action.type) {
      case "SET_STORE":
        return {...state, merchantId: action.merchantId || "", merchantName: action.merchantName || "" };
      case "OPEN":
        return {...state, isOpen: true};
      case "CLOSE":
        return {...state, isOpen: false};
      case "ADD": 
        const item = {
            ...action.item,
        };
        item.total = itemTotal.bind(item);
        item.addonTotal = addonTotal.bind(item);
        return { ...state, items: [...state.items, item]};
      case "REMOVE":
        const items = state.items;
        items.splice(action.index, 1);
        return { ...state, items };
      default: {
        throw new Error(`Unhandled action type: ${action.type}`);
      }
    }
  }
  

export const CartProvider = (props) => {
    initialState.total = cartTotal.bind(initialState)
    const [cart, dispatch] = useReducer(cartReducer, initialState);
    return <CartContext.Provider value={[cart, dispatch]} {...props} />;
};

export const useCart = () => {
    const context = useContext(CartContext);
    if (!context) {
      throw new Error("useCart must be used within a CartProvider");
    }
    return context;
};
