import React, {useEffect, useState} from 'react';
import {Button, Checkbox, Col, Divider, Form, Input, InputNumber, Row, Select, Upload} from "antd";
import {LoadingOutlined, PlusOutlined} from '@ant-design/icons';
import uuid from 'react-uuid';
import Layout from "../../../layouts/dashboard";
import Tab from "../../../layouts/dashboard/components/checkout-tab";
import {useSetting} from "../../../provider/setting";
import {Icon} from "@iconify/react";
import {getFileExtension} from "../../../service/string";
import {uploadFileApi} from "../../../api/api/upload";
import {NotificationManager} from "react-notifications";
import {useNavigate, useSearchParams} from "react-router-dom";
import { getAll as getItemsApi, update as updateItemApi } from '../../../api/item';
import { getAll as getAddonsApi } from '../../../api/extra';
import { getAll as getCategoriesApi } from '../../../api/category';

const StoreItemEditPage = () => {
  const [setting] = useSetting();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [categories, setCategories] = useState([]);
  const [addons, setAddons] = useState([]);
  const [loading, setLoading] = useState(false);
  const [enabled, setEnabled] = useState(false);
  const [tax, setTax] = useState(false);
  const [checkin, setCheckin] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  const [properties, setProperties] = useState([]);
  const [selectedAddons, setSelectedAddons] = useState([]);
  const [item, setItem] = useState({});
  const [selectedCategory, setSelectedCategory] = useState();
  const [saddons, setSAddons] = useState([]);

  const getCategoriesHandle = async () => {
    if (setting?.auth?.signin_merchant_code) {
      getCategoriesApi(setting.auth.signin_merchant_code)
        .then((data) => {
          setCategories(data);
        })
        .catch(e => console.warn(e))
    }
  }
  const getAddonsHandle = async () => {
    if (setting?.auth?.signin_merchant_code) {
      getAddonsApi(setting.auth.signin_merchant_code)
        .then((data) => {
          setAddons(data);
        })
        .catch(e => console.warn(e))
    }
  }

  useEffect(() => {
    const ids = JSON.parse(categories.find((c) => c.id === selectedCategory)?.other || "[]")
    const sodas= []
    addons.map((a) => {
      if(ids.includes(a.id)) {
        sodas.push(a)
      }
    })

    setSAddons(sodas)

  }, [selectedCategory, addons])

  const beforeUpload = async (file) => {
    try {
      setLoading(true);
      const filename = setting?.auth?.signin_merchant_id + '_' + Date.now() + '.' + getFileExtension(file.name)
      uploadFileApi(file, filename, {
        onError: (e) => {
          console.log(e.code);
          console.log(e.message);
          setLoading(false)
        },
        onSuccess: (data) => {
          setImageUrl(data.Location);
          setLoading(false);
        },
        onProgress: (e) => {
          console.log(e)
        }
      })
    } catch (e) {
      console.warn(e)
    }
  };

  const finishHandle = (value) => {
    const payload = {
      name: value.name,
      description: value.description,
      price: value.price,
      picture_url: imageUrl,
      category_id: selectedCategory,
      quantity: value.quantity,
      enabled: enabled,
      sales_tax: tax,
      promotion_active: false,
      promo_price: 0,
      properties: JSON.stringify(properties),
      other: JSON.stringify({
        addons: selectedAddons,
        default_color: value.color,
        default_size: value.size,
      }),
    }
    updateItemApi(setting?.auth?.signin_merchant_code, item.id, payload)
      .then((data) => {
        NotificationManager.success("Successfully created a category!", "Success", 3000)
        navigate('/store/checkout/items')
      })
      .catch(e => {
        console.warn(e)

      })
  }

  useEffect(() => {
    console.log()
    const id = searchParams.get("id");
    if (!!id && setting?.auth?.signin_merchant_code) {
      getAddonsHandle()
      getCategoriesHandle().then(()=>{
        getItemsApi(setting?.auth?.signin_merchant_code)
            .then((data) => {
              data.forEach((i) => {
                if (i.id.toString() === id) {
                  if (!!i.properties) {
                    setProperties(JSON.parse(i.properties))
                    i.properties = JSON.parse(i.properties)
                  }

                   if(!!i.category_id) {
                     setSelectedCategory(i.category_id)
                   }

                  if (!!i.other) {
                    setSelectedAddons(JSON.parse(i.other)?.addons)
                    i.other = JSON.parse(i.other)
                  }

                  setTax(i.sales_tax)
                  setEnabled(i.enabled)
                  setImageUrl(i.picture_url)

                  setItem(i);
                }
              })
            })
            .catch((e) => console.warn(e))
      })

    }
  }, [setting])

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined/> : <PlusOutlined/>}
      <div style={{marginTop: 8}}>Upload</div>
    </div>
  );

  return (
    <Layout>
    <Tab/>  
      <div className="row">
        {/* <div className="col col-lg-3">
          <Sidebar/>
        </div> */}
        <div className="col col-lg-12">
          <h4 className="mb-4">Edit Item</h4>

          <Form
            fields={[
              {
                name: "description",
                value: item.description
              },
              {
                name: "category_id",
                value: item.category_id
              },
              {
                name: "name",
                value: item.name
              },
              {
                name: "price",
                value: item.price
              },
              {
                name: "quantity",
                value: item.quantity
              },
              {
                name: "color",
                value: item.other?.default_color
              },
              {
                name: "size",
                value: item.other?.default_size
              }
            ]}
            onFinish={finishHandle}
            layout="vertical">
            <Row gutter={[12, 0]}>
              <Col span={6}>
                <Form.Item label="Item Image">
                  <Upload
                    name="avatar"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={false}
                    beforeUpload={beforeUpload}>
                    {imageUrl ? <img src={imageUrl} alt="avatar" style={{width: '100%'}}/> : uploadButton}
                  </Upload>
                </Form.Item>
              </Col>
              <Col span={18}>
                <Form.Item
                  name="description"
                  id="description"
                  label="Description">
                  <Input.TextArea
                    showCount
                    maxLength={500}
                    size="large"
                    placeholder="Description"
                    style={{height: 150}}/>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label="Category">
                  <Select
                    onChange={(e) => {
                      setSelectedCategory(e)
                    }}
                    value={selectedCategory}
                    size="large"
                    showSearch
                    placeholder="Select a category"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }
                    options={
                      categories.map((category) => {
                        category.value = category.id;
                        category.label = category.name;
                        return category
                      })
                    }/>
                </Form.Item>
              </Col>
              <Col span={10}>
                <Form.Item
                  rules={[{required: true, message: "Name is required."}]}
                  name="name"
                  label="Name">
                  <Input
                    placeholder="Product Name"
                    size="large"/>
                </Form.Item>
              </Col>
              <Col span={6}>
                  <Form.Item label="Price & Inventory" rules={[{required: true, message: "Price is required."}]}>
                      <Button
                          block
                          type="primary"
                          danger
                          ghost
                          icon={<Icon style={{width: 24, height: 24}} icon="material-symbols:add"/>}
                          onClick={() => {
                              setProperties([...properties, {id: uuid()}])
                          }}
                          size="large">
                          Pricing & Inventory
                      </Button>
                  </Form.Item>
              </Col>

              <Col span={6}>
                <Form.Item
                  name="size"
                  id="size"
                  label="Size">
                  <Input
                    placeholder="Size"
                    size="large"
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="color"
                  id="color"
                  label="Color">
                  <Input
                    placeholder="Color"
                    size="large"
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  id="quantity"
                  name="quantity"
                  label="Inventory">
                  <InputNumber
                    placeholder="Inventory"
                    size="large"
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="price"
                  rules={[{required: true, message: "Price is required."}]}
                  label="Price">
                  <InputNumber
                      step="0.01"
                    // parser={(value) => parseFloat(value.toString() || 0).toFixed(2)}
                    // formatter={(value) => parseFloat(value.toString() || 0).toFixed(2)}
                    prefix="$"
                    suffix="USD"
                    placeholder="Price"
                    size="large"/>
                </Form.Item>
              </Col>
            </Row>

            {
              properties.map((property) => (
                <Row
                  key={property.id}
                  gutter={[12, 0]}>
                  <Col span={6}>
                    <Form.Item>
                      <Input
                        onChange={(e) => {
                          setProperties(properties.map((i) => {
                            if (i.id === property.id) {
                              i.size = e.target.value
                            }
                            return i
                          }))
                        }}
                        value={property.size}
                        placeholder="Size"
                        size="large"/>
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item>
                      <Input
                        onChange={(e) => {
                          setProperties(properties.map((i) => {
                            if (i.id === property.id) {
                              i.color = e.target.value
                            }
                            return i
                          }))
                        }}
                        value={property.color}
                        placeholder="Color"
                        size="large"/>
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item>
                      <InputNumber
                        onChange={(e) => {
                          setProperties(properties.map((i) => {
                            if (i.id === property.id) {
                              i.inventory = e
                            }
                            return i
                          }))
                        }}
                        value={property.inventory}
                        placeholder="Inventory"
                        size="large"/>
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    <Form.Item>
                      <InputNumber
                        onChange={(e) => {
                          setProperties(properties.map((i) => {
                            if (i.id === property.id) {
                              i.price = e
                            }
                            return i
                          }))
                        }}
                        value={property.price}
                        step="0.01"
                        parser={(value) => parseFloat(value.toString() || 0).toFixed(2)}
                        prefix="$"
                        suffix="USD"
                        placeholder="Price"
                        size="large"/>
                    </Form.Item>
                  </Col>
                  <Col span={2}>
                    <Form.Item>
                      <Button
                        block
                        size="large"
                        onClick={() => {
                          setProperties(properties.filter((i) => i.id !== property.id))
                        }}
                        type="primary">
                        <Icon style={{width: 24, height: 24}} icon="material-symbols:close"/>
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              ))
            }
            {/* <Form.Item>
              <Button
                block
                type="primary"
                ghost
                icon={<Icon style={{width: 24, height: 24}} icon="material-symbols:add"/>}
                onClick={() => {
                  setProperties([...properties, {id: uuid()}])
                }}
                size="large">
                Add More Property
              </Button>
            </Form.Item> */}

            <Divider/>

            {
                selectedCategory && (
                    <Form.Item label="Add-Ons">
                      {
                        saddons.map((addon) => (
                            <Checkbox
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    setSelectedAddons([...selectedAddons, addon.id])
                                  } else {
                                    setSelectedAddons(selectedAddons.filter((i) => i !== addon.id))
                                  }
                                }}
                                checked={selectedAddons.includes(addon.id)}
                                key={addon.id}>
                              {addon.name} - ${parseFloat(addon.price || "0").toFixed(2)}
                            </Checkbox>
                        ))
                      }
                    </Form.Item>
                )
            }

            <Divider/>

            <Row gutter={[12, 0]}>
              <Col span={6}>
                <Form.Item
                  name="enabled"
                  id="enabled">
                  <Checkbox
                    checked={enabled}
                    onChange={(e) => {
                      setEnabled(e.target.checked)
                    }}>
                    Enable In App Purchase
                  </Checkbox>
                </Form.Item>
              </Col>

              <Col span={6}>
                <Form.Item
                  name="sales_tax"
                  id="sales_tax">
                  <Checkbox
                    checked={tax}
                    onChange={(e) => {
                      setTax(e.target.checked)
                    }}>
                    Requires Sales Tax
                  </Checkbox>
                </Form.Item>
              </Col>

              <Col span={6}>
                <Form.Item
                  name="sales_tax"
                  id="sales_tax">
                  <Checkbox
                    checked={checkin}
                    onChange={(e) => {
                      setCheckin()
                    }}>
                    Item Requires Checkin
                  </Checkbox>
                </Form.Item>
              </Col>
            </Row>
            
            <Col span={5}>
            <Form.Item style={{marginBottom:'50', marginTop:'0', textAlign:'center'}}>
              <Button 
                block
                type="primary"
                size="large"
                htmlType="submit">
                Update
              </Button>
            </Form.Item>
            </Col>
          </Form>
        </div>
      </div>
    </Layout>
  );
};


export default StoreItemEditPage;
