import React, { useState, useEffect } from "react";
import Header from "../../../components/header/authempty";
import Footer from "../../../components/footer/footerEmptyPage";
import { loadStripe } from '@stripe/stripe-js';
import { EmbeddedCheckoutProvider, EmbeddedCheckout } from "@stripe/react-stripe-js";
  
import CheckoutForm from "../../../components/CheckoutForm";
import { useCart } from "../../../provider/checkout";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

export default function CheckoutPage() {
  const [clientSecret, setClientSecret] = useState(null);
  const [{items, merchantId, merchantName}, _] = useCart();
  const onComplete = function() {
    console.info("checkout complete"); 
  }

  useEffect(() => {
    console.info("initiating checkout...")
    fetch(process.env.REACT_APP_API_URL + "/v1/checkout", {
      method: "POST",
      headers: 
      {
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: {
          merchant_id: merchantId,
          items: items.map((item) => {
            return {id: item.item.id, qty: item.quantity, extras: item.extras}
          })
        }
      })
    })
      .then((res) => res.json())
      .then((data) => {
        setClientSecret(data.client_secret)
      });

  }, []);
  
  return (
    <main id="container" className='store' style={{marginBottom: "100px", alignItems: 'normal'}}>
      <Header/>
        <div id="checkout">
      {clientSecret && (
        <EmbeddedCheckoutProvider
          stripe={stripePromise}
          options={{clientSecret, onComplete}}
        >
          <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>
      )}
        </div>
      <Footer/>  
    </main>
  );
};
