import React, {useState} from 'react'
import styled from "styled-components";
import { NotificationManager } from "react-notifications";
import moment from "moment";
import {
    Button,
    Card,
    DatePicker,
    Form,
    TimePicker,
    Select,
    Typography
} from "antd";
import { getServiceProvidersApi, createProviderBookingApi } from '../../api/api';
import {Styles, ModalStyles, DetailCss, CardModalStyle} from "../../layouts/style/storeDetailStyle"

const { Title } = Typography;
const { Item, useForm } = Form;
const { Option } = Select;

const AppointmentForm = ({ items }) => {
    const [form] = useForm();
    const [loading, setLoading] = useState(false);
    const [providerLoading, setProviderLoading] = useState(false);
    const [providers, setProviders] = useState([]);
    const [selectedProvider, setSelectedProvider] = useState(null);
    
    const [workdays, setWorkdays] = useState([]);
    const [workdaysOfProvider, setWeekdayOfProvider] = useState({});

    const [services, setServices] = useState([]);

    const onServiceSelect = (id) => {
        setProviderLoading(true);
        getServiceProvidersApi(id)
        .then(({ providers, workdays, services }) => {
            setProviders(providers || []);
            form.setFieldValue("provider", "");
            setWorkdays(workdays || []);
            setServices(services);
        })
        .catch(e => console.log(e))
        .finally(() => setProviderLoading(false));
    }
    
    const onDatePick = (d) => {
        const weekdaynames = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
        const weekdayname = weekdaynames[d.weekday()];
        const workdayOfProvider = workdays.find(({ provider }) => provider == selectedProvider) || {};
        setWeekdayOfProvider(workdayOfProvider[weekdayname]);
    }
    
    const disabledDate = (current) => {
        const workday = workdays.find(({provider}) => provider == selectedProvider) || {};
        const { sunday, monday, tuesday, wednesday, thursday, friday, saturday } = workday;
        const disabledWeekdays = [];
        if (!sunday?.active) {
            disabledWeekdays.push(0);
        }
        if (!monday?.active) {
            disabledWeekdays.push(1);
        }
        if (!tuesday?.active) {
            disabledWeekdays.push(2);
        }
        if (!wednesday?.active) {
            disabledWeekdays.push(3);
        }
        if (!thursday?.active) {
            disabledWeekdays.push(4);
        }
        if (!friday?.active) {
            disabledWeekdays.push(5);
        }
        if (!saturday?.active) {
            disabledWeekdays.push(6);
        }
    
        return moment().add(-1, 'days')  >= current 
            || disabledWeekdays.includes(current.day())
    }
    
    const getDisabledHours = () => {
        const { open, close, active } = workdaysOfProvider;
        const disabledHours = [];
        for (let i = 0; i < 24; i++) {
            if (!active) {
                disabledHours.push(i);
            } else if ( i < open || i > close) {
                disabledHours.push(i)
            }
        }
        return disabledHours;
    }

    const onFinish = ({item, date, time, provider}) => {
        const service = services.find((elem) => elem.itemId == item && elem.provider == provider);
        const serviceItem = items.find((elem) => elem.id == item);
        
        const start = date.hour(time.hour()).minute(time.minute());
        const end = start.add(service.duration, "m");
        const booking = {
            title: serviceItem.name,
            provider,
            start: start.toISOString(),
            end: end.toISOString(),
        }
        
        setLoading(true);
        createProviderBookingApi(booking)
        .then(res => {
            NotificationManager.success("You booked the service successfully", "Success", 3000)
        })
        .catch(error => console.log(error))
        .finally(() => setLoading(false));
    }

    return (
    <BookingCard className="bg-booking">
        <DetailCss/>
        <Form layout="vertical" form={form} onFinish={onFinish}>
            <Title
                level={5}
                align="center">
                <h2>Book Appointment</h2>
            </Title>
            <Item
                name="item"
                label="Service"
                rules={[{ required: true }]}
            >
                <Select onChange={onServiceSelect}>
                    {
                        items.map((item, i) => (
                            <Option value={item.id} key={i}>{item.name}</Option>
                        ))
                    }
                </Select>
            </Item>
            <Item
                name="provider"
                label="Provider"
                rules={[{ required: true }]}
            >
                <Select
                    loading={providerLoading}
                    onChange={setSelectedProvider}
                >
                {
                        providers.map((provider, i) => (
                            <Option value={provider.id} key={i}>{provider.name}</Option>
                        ))
                    }
                </Select>
            </Item>
            <Item>
                <Item
                    name="date"
                    label="Date"
                    rules={[{ required: true }]}
                    style={{ display: "inline-block"}}
                >
                    <DatePicker
                        format="YYYY-MM-DD"
                        placeholder="Pick date"
                        disabledDate={disabledDate}
                        onSelect={onDatePick}
                    />
                </Item>
                {" "}
                <Item
                    name="time"
                    label="Time"
                    rules={[{ required: true }]}
                    style={{ display: "inline-block"}}
                >
                    <TimePicker
                        format="HH:mm"
                        placeholder="Pick time"
                        disabledTime={(current) => ({
                            disabledHours: getDisabledHours,
                        })}
                    />
                </Item>
            </Item>
            <Button
                type="primary"
                block
                size="large"
                htmlType="submit"
                loading={loading}
            >
                Book
            </Button>
        </Form>
    </BookingCard>
    );
}

export default AppointmentForm;

const BookingCard = styled(Card)`
    padding: 10px;
    border-radius: 5px!important;
    margin-top: 48px;
`;
