import styled, {createGlobalStyle} from "styled-components";

export const DetailCss = createGlobalStyle`
  .product-popup .ant-modal-content {
    border-radius: 0 !important;
    padding: 0 !important;
  }
`

export const Styles = styled.div`
  .bg-area {
    background: url("/assets/img/badge-bg.png");
    background-size: 150px;
    height: 200px;
    position: relative;
    z-index: 0;
    
    @media(max-width: 600px) {
      height: 150px;
    }
    
    .back-button {
      position: absolute;
      top: 32px;
      left: 24px;
      width: 42px;
      height: 42px;
      padding: 0;
      border-radius: 52px;
      font-size: 24px;
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #cc0000;
      border: 2px solid #ffffff;
      backdrop-filter: blur(4px);
      color: #ffffff;
      
      :hover {
        border: 2px solid #ffffff;
        backdrop-filter: blur(4px);
        color: #ffffff;
      }
      
      :focus {
        outline: none;
      }
    }

    .card-button {
      position: absolute;
      top: 32px;
      left: auto;
      right: 24px;
      
      .ant-scroll-number {
        z-index: 2;
      }
      
      button {
        width: 42px;
        height: 42px;
        padding: 0;
        border-radius: 52px;
        font-size: 24px;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #cc0000;
        border: 2px solid #ffffff;
        backdrop-filter: blur(4px);
        color: #ffffff;

        :hover {
          border: 2px solid #ffffff;
          backdrop-filter: blur(4px);
          color: #ffffff;
        }

        :focus {
          outline: none;
        }
      }
    }
  }


  .bg-badge {
    max-width: 1200px;
    padding: 42px 42px 82px;
    margin: -62px auto 32px;
    background: #ffffff;
    border-radius: 0;
    box-shadow: rgba(17, 17, 26, 0.1) 0 0 16px;
    z-index: 1;
    position: relative;

    @media(max-width: 600px) {
      padding: 32px 20px 62px;
    }
    
    .bg-badge-container {
      display: grid;
      grid-template-columns: 120px auto;
      gap: 24px;
      
      @media(max-width: 600px) {
        grid-template-columns: auto;
      }
    }

    .nav-wrap {
      position: absolute;
      bottom: 0;
      left: 5%;
      width: 90%;
      overflow-x: auto;
    
      .badge-nav {
        align-items: center;
        justify-content: center;
        gap: 24px;
        white-space: nowrap;

        a {
          font-size: 16px;
          font-weight: 700;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          padding: 12px 0;
          height: 50px;
          white-space: nowrap;
          margin: 0 24px;

          :hover {
            text-decoration: none;
          }
        }
      }
    }

    h1 {
      text-align: left;
      margin-top: 4px;
      margin-bottom: 4px;
      font-size: 32px;
    }

    p {
      margin-top: 0;
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 4px;
    }
  }
  
  .detail-container {
    margin-bottom: 132px;
    
    @media(max-width: 600px) {
      margin-bottom: 12px;
    }
  }


  h2 {
    margin-top: 32px;
    font-size: 20px;
    font-weight: 700;
  }
  
  h3 {
    margin-top: 32px;
    font-size: 24px;
    font-weight: 700;
  }

  .ant-card {
    border-radius: 0;
    border: 1px solid #e0e0e0;
    overflow: hidden;

    .ant-card-body {
      padding: 0;

      &>div {
        @media(max-width: 600px) {
          grid-template-columns: auto 100px!important;
        }
      }
      
      .ant-card-meta-detail {
        padding: 24px;

        .ant-card-meta-title {
          font-size: 18px;
          max-width: 100%;
          color: #CC0000;
          white-space: break-spaces;
          line-height: 1;
        }

        .ant-card-meta-description {
          font-size: 16px;
          color: #000000;
        }
      }

      img {
        margin-bottom: -5px;
        @media(max-width: 600px) {
          width: 100px!important;
          height: 100px!important;
        }
      }
    }
  }

  .bg-booking {
    max-width: 1200px;
    // padding: 42px 42px 82px;
    // margin: -62px auto 32px;
    background: #ffffff;
    border-radius: 0;
    box-shadow: rgba(17, 17, 26, 0.1) 0 0 16px;
    z-index: 1;
    position: relative;

    @media(max-width: 600px) {
    // padding: 32px 20px 62px;
    }

`;

export const ModalStyles = styled.div`
  section {
    border-radius: 0;
    width: 800px;
    display: grid;
    grid-template-columns: 350px auto;
    
    @media(max-width: 800px) {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 100%;
      
      &>div>img {
        width: 100% !important;
      }
    }

    @media(max-width: 600px) {
      &>div>img {
        height: 80vw!important;
      }
    }

    .detail-content {
      padding: 12px 12px 44px 32px;
      position: relative;
      overflow-y: auto;
      overflow-x: hidden;
      height: 350px;
      
      .detail-content-wrap {
      }

      .title {
        font-size: 28px;
        font-weight: 700;
        margin-left: 0;
        margin-bottom: 4px;
      }

      .description {
        font-size: 16px;
        padding-left: 4px;
        margin-bottom: 12px;
      }

      .price {
        font-size: 20px;
        padding-left: 4px;
        font-weight: 700;
      }
      
      .addon-label {
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 0;
      }
    }

    .add {
      position: absolute;
      height: 60px;
      width: calc(100% - 350px);
      right: 0;
      bottom: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: 32px;
      padding-right: 12px;
      gap: 24px;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      background: #ffffff;

      @media(max-width: 800px) {
        width: 100%;
        padding-left: 12px;
        left: 0;
      }

      .counters {
        display: grid;
        grid-template-columns: 36px 50px 36px;
        align-items: center;
        border: 1px solid #cc0000;
        padding: 2px 4px;

        p {
          display: flex;
          padding: 0;
          margin: 0;
          align-items: center;
          justify-content: center;
          font-size: 20px;
          font-weight: 700;
        }

        button {
          height: 36px;
          width: 36px;
          padding: 0;
          font-size: 18px;
          border-radius: 0;
          border: none;
          :focus {
            outline: none;
          }
        }
      }

      .price-preview {
        align-items: center;
        display: flex;

        p {
          font-size: 20px;
          font-weight: 700;
          margin-bottom: 0;
          color: #cc0000;
        }
      }

      button {
        height: 42px;
        font-size: 16px;
        font-weight: 700;
        
        :focus {
          outline: none;
        }
      }

      @media(max-width: 600px) {
        gap: 12px;
        .counters {
          grid-template-columns: 32px 32px 32px;
          padding: 2px 4px;

          p {
            font-size: 16px;
          }

          button {
            height: 32px;
            width: 32px;
            font-size: 16px;
          }
        }

        .price-preview {
          p {
            font-size: 16px;
          }
        }

        button {
          height: 38px;
          font-size: 14px;
        }
      }
    }
    
    .addons {
      .ant-checkbox-wrapper {
        width: 50%;
        margin: 0;
        
        @media(max-width: 600px) {
          width: 100%;
        }
      }
    }
  }
`;


export const CardModalStyle = styled.div`
  h3 {
    font-size: 14px;
    font-weight: 600;
    color: #a0a0a0;
    margin-bottom: 12px;
    padding-left: 2px;
    margin-top: 0;
  }  
  
  .card-item {
    display: grid;
    grid-template-columns: 62px auto 62px;
    gap: 12px;
    align-items: top;
    
    p {
      margin-bottom: 0;
      margin-top: 0;
      
      &.card-item-title {
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 4px;
      }
      
      &.card-item-price {
        font-size: 14px;
        color: #3f3f3f;
        font-weight: 600;
      }

      &.card-item-quantity {
        font-size: 14px;
        color: #adacac;
        text-align: right;
      }

      &.card-item-cost {
        font-size: 16px;
        color: #cc0000;
        font-weight: 700;
        text-align: right;
      }
    }
  }
  
  .card-addon {
    display: grid;
    grid-template-columns: auto auto 102px;
    align-items: top;
    margin-bottom: 12px;
    
    p {
      margin-bottom: 0;
      margin-top: 0;
      
      &.card-addon-title {
        font-size: 16px;
        font-weight: 700;
      }

      &.card-addon-quantity {
        font-size: 14px;
        color: #adacac;
        text-align: right;
      }
      
      &.card-addon-cost {
        font-size: 16px;
        color: #cc0000;
        text-align: right;
      }
    }
  }
  
  .total-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    h3 {
      margin-bottom: 0;
    }
  }
  
  .card-total {
    text-align: right;
    font-size: 20px;
    color: #cc0000;
    font-weight: 700;
    margin-bottom: 0;
  }
`