import React, {Fragment, useState} from 'react';
import {Icon} from "@iconify/react";
import DatePicker from "react-datepicker";
import {countries, states, cities} from "country-cities";
import Layout from "../../../layouts/dashboard";
import {useNavigate} from "react-router-dom";
import CheckinSidebar from "./sidebar2";

const weekDays = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
]

const CheckinEditPage = () => {
  const navigate = useNavigate();
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [type, setType] = useState("event");
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [openDays, setOpenDays] = useState([0, 1, 2, 3, 4, 5]);

  const submitHandle = (event) => {
    navigate("/bank/store/identity/check")
    event.preventDefault();
  }

  return (
    <Layout>
      <div className="row">
        <div className="col col-lg-3">
          <CheckinSidebar/>
        </div>
        <div className="col col-lg-9">
          <h4>Create Checkin</h4>
          <form onSubmit={submitHandle}>
            {/* BASIC INFORMATION */}
            <div>
              <div className="row">
                <div className="mb-3 col-6">
                  <label htmlFor="type">Checkin Type</label>
                  <select
                    className="form-control"
                    name="type"
                    onChange={(e) => {
                      setType(e.target.value)
                    }}
                    id="type">
                    <option value="event">Event Page</option>
                    <option value="business">Business Page</option>
                  </select>
                </div>
              </div>

              <div className="mb-3">
                <label className="form-label" htmlFor="name">Name*</label>
                <input
                  style={{marginTop: 0}}
                  className="form-control"
                  type="text"
                  name="name"
                  placeholder="Page Name"
                  id="name"/>
              </div>

              <div className="mb-3 image-upload">
                <label>Image*</label>
                <label className="upload-area" htmlFor="image">
                  <Icon icon="uil:image-upload"/>
                </label>
                <input
                  type="file"
                  name="image"
                  id="image"
                  className="form-control"/>
              </div>

              <div className="mb-3">
                <label className="form-label" htmlFor="name">Description*</label>
                <textarea
                  style={{marginTop: 0}}
                  className="form-control"
                  type="text"
                  name="name"
                  rows={6}
                  placeholder="Description"
                  id="name"/>
              </div>
            </div>

            {/* ADDRESS */}
            <h5 className="mt-5">Checkin Address</h5>
            <div className="row">
              <div className="col col-lg-8 mb-3">
                <label htmlFor="address">Street Address*</label>
                <input
                  style={{marginTop: 0}}
                  className="form-control"
                  type="text"
                  name="address"
                  placeholder="Street address"
                  id="name"/>
              </div>
              <div className="col col-lg-4 mb-3">
                <label htmlFor="address">Suite</label>
                <input
                  style={{marginTop: 0}}
                  className="form-control"
                  type="text"
                  name="suite"
                  placeholder="Suite"
                  id="name"/>
              </div>

              <div className="col col-lg-3 mb-3">
                <label htmlFor="country">Country</label>
                <select
                  className="form-control"
                  name="country"
                  id="country"
                  onChange={(e) => {
                    setCountry(e.target.value)
                  }}
                  placeholder="State">
                  <option value="">Select</option>
                  {
                    countries.all().map((i, index) => (
                      <option key={`state-${index}`} value={i.isoCode}>{i.name}</option>
                    ))
                  }
                </select>
              </div>

              <div className="col col-lg-3 mb-3">
                <label htmlFor="state">State</label>
                <select
                  className="form-control"
                  name="state"
                  id="state"
                  onChange={(e) => {
                    setState(e.target.value)
                  }}
                  placeholder="State">
                  <option value="">Select</option>
                  {
                    states.getByCountry(country).map((i, index) => (
                      <option key={`state-${index}`} value={i.isoCode}>{i.name}</option>
                    ))
                  }
                </select>
              </div>

              <div className="col col-lg-3 mb-3">
                <label htmlFor="city">City</label>
                <select
                  className="form-control"
                  name="city"
                  id="city"
                  placeholder="State">
                  <option value="">Select</option>
                  {
                    cities.getByState(state, country).map((i, index) => (
                      <option key={`state-${index}`} value={i.name}>{i.name}</option>
                    ))
                  }
                </select>
              </div>

              <div className="col col-lg-3 mb-3">
                <label htmlFor="address">ZIP Code</label>
                <input
                  style={{marginTop: 0}}
                  className="form-control"
                  type="text"
                  name="city"
                  placeholder="ZIP Code"
                  id="name"/>
              </div>
            </div>

            {/* TIME DETAILS */}
            {
              type === "event" ? (
                <Fragment>
                  <h5 className="mt-5">Event Date & Time</h5>
                  <div className="row">
                    <div className="col col-lg-4 mb-3">
                      <label htmlFor="startDate">
                        Start Date&nbsp;&nbsp;
                        <i className="fa fa-calendar" aria-hidden="true" id="id_trx_calandar_icon"></i>
                      </label>
                      <DatePicker
                        id="startDate"
                        className="form-control m-0"
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}/>
                    </div>

                    <div className="col col-lg-4 mb-3">
                      <label htmlFor="startTime">Time:</label>
                      <select
                        className="form-control"
                        name="startTime"
                        id="startTime"
                        placeholder="State">
                        {
                          [...Array(12)].map((i, index) => (
                            <option
                              key={`state-${index}`}
                              value={`${index < 9 && "0"}${index + 1}:00`}>
                              {index < 9 && "0"}{index + 1}:00
                            </option>
                          ))
                        }
                      </select>
                    </div>

                    <div className="col col-lg-4 mb-3">
                      <label htmlFor="startAm">AM/PM</label>
                      <select
                        className="form-control"
                        name="startAm"
                        id="startAm"
                        placeholder="State">
                        <option value="am">AM</option>
                        <option value="pm">PM</option>
                      </select>
                    </div>

                    <div className="col col-lg-4 mb-3">
                      <label htmlFor="endDate">
                        End Date&nbsp;&nbsp;
                        <i className="fa fa-calendar" aria-hidden="true" id="id_trx_calandar_icon"></i>
                      </label>
                      <DatePicker
                        id="endDate"
                        name="endDate"
                        className="form-control m-0"
                        selected={endDate}
                        onChange={(date) => setEndDate(date)}/>
                    </div>

                    <div className="col col-lg-4 mb-3">
                      <label htmlFor="endTime">Time:</label>
                      <select
                        className="form-control"
                        name="endTime"
                        id="endTime"
                        placeholder="State">
                        {
                          [...Array(12)].map((i, index) => (
                            <option
                              key={`state-${index}`}
                              value={`${index < 9 && "0"}${index + 1}:00`}>
                              {index < 9 && "0"}{index + 1}:00
                            </option>
                          ))
                        }
                      </select>
                    </div>

                    <div className="col col-lg-4 mb-3">
                      <label htmlFor="endAm">AM/PM</label>
                      <select
                        className="form-control"
                        name="endAm"
                        id="endAm"
                        placeholder="AM/PM">
                        <option value="am">AM</option>
                        <option value="pm">PM</option>
                      </select>
                    </div>
                  </div>
                </Fragment>
              ) : (
                <Fragment>
                  <h5 className="mt-5">Business Hours</h5>
                  <div className="row">
                    <div className="col col-4 mb-1">
                      <label>Week Day</label>
                    </div>
                    <div className="col col-4  mb-1">
                      <label>Open Time:</label>
                    </div>
                    <div className="col col-4  mb-1">
                      <label>Close Time:</label>
                    </div>
                    {
                      [...new Array(7)].map((_, index) => (
                        <Fragment key={`open-${index}`}>
                          <div className="col col-4 mb-3 in-row">
                            <input
                              className="alone"
                              type="checkbox"
                              checked={openDays.includes(index)}
                              onChange={(e) => {
                                e.target.checked ?
                                  setOpenDays([...openDays, index]) :
                                  setOpenDays([...openDays.filter((i) => i !== index)])
                              }}
                              name={`open${index}`}/>
                            <select
                              disabled={!openDays.includes(index)}
                              className="form-control"
                              name={`day${index}`}
                              id={`day${index}`}>
                              <option value={weekDays[index]}>{weekDays[index]}</option>
                            </select>
                          </div>

                          <div className="col col-4 mb-3 in-row in-time">
                            <select
                              disabled={!openDays.includes(index)}
                              className="form-control"
                              name={`openTime${index}`}
                              id={`openTime${index}`}
                              defaultValue="09:00"
                              placeholder="State">
                              {
                                [...Array(12)].map((i, index) => (
                                  <option
                                    key={`state-${index}`}
                                    value={`${index < 9 && "0"}${index + 1}:00`}>
                                    {index < 9 && "0"}{index + 1}:00
                                  </option>
                                ))
                              }
                            </select>
                            <select
                              disabled={!openDays.includes(index)}
                              className="form-control"
                              name={`openAm${index}`}
                              id={`openAm${index}`}
                              defaultValue="AM"
                              placeholder="AM/PM">
                              <option value="am">AM</option>
                              <option value="pm">PM</option>
                            </select>
                          </div>

                          <div className="col col-lg-4 mb-3 in-row in-time">
                            <select
                              disabled={!openDays.includes(index)}
                              className="form-control"
                              name={`closeTime${index}`}
                              id={`closeTime${index}`}
                              defaultValue="05:00"
                              placeholder="State">
                              {
                                [...Array(12)].map((i, index) => (
                                  <option
                                    key={`state-${index}`}
                                    value={`${index < 9 && "0"}${index + 1}:00`}>
                                    {index < 9 && "0"}{index + 1}:00
                                  </option>
                                ))
                              }
                            </select>
                            <select
                              disabled={!openDays.includes(index)}
                              className="form-control"
                              name={`closeAm${index}`}
                              id={`closeAm${index}`}
                              defaultValue="pm"
                              placeholder="AM/PM">
                              <option value="am">AM</option>
                              <option value="pm">PM</option>
                            </select>
                          </div>
                        </Fragment>
                      ))
                    }
                  </div>
                </Fragment>
              )
            }

            {/* SUBMIT */}
            <div className="mt-5">
              <button className="btn btn-submit btn-submit-primary">Create Checkin</button>
            </div>
          </form>
        </div>
      </div>
    </Layout>
  );
};

export default CheckinEditPage;