import React, {useEffect, useState} from 'react';
import Layout from "../../../layouts/dashboard";
import Tab from "../../../layouts/dashboard/components/settings-tab";
import ProfileSidebar from "./sidebar";
import {Button, Col, Form, Row, Select, Switch} from "antd";
import {LoadingOutlined, PlusOutlined} from "@ant-design/icons";
import {useSetting} from "../../../provider/setting";
import {getFileExtension} from "../../../service/string"
import {uploadFileApi} from "../../../api/api/upload";
import { get as getMerchantApi, update as updateMerchantApi } from '../../../api/merchant';

const {Item} = Form;
const {Option} = Select;

const weekDays = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
]

const initDays = [
  {open: false, openHour: "09:00", openSuffix: "AM", closeHour: "05:00", closeSuffix: "PM"},
  {open: false, openHour: "09:00", openSuffix: "AM", closeHour: "05:00", closeSuffix: "PM"},
  {open: false, openHour: "09:00", openSuffix: "AM", closeHour: "05:00", closeSuffix: "PM"},
  {open: false, openHour: "09:00", openSuffix: "AM", closeHour: "05:00", closeSuffix: "PM"},
  {open: false, openHour: "09:00", openSuffix: "AM", closeHour: "05:00", closeSuffix: "PM"},
  {open: false, openHour: "09:00", openSuffix: "AM", closeHour: "05:00", closeSuffix: "PM"},
  {open: false, openHour: "09:00", openSuffix: "AM", closeHour: "05:00", closeSuffix: "PM"},
]

const StoreProfileEditPage = () => {
  const [setting] = useSetting();
  const [openDays, setOpenDays] = useState(initDays);
  const [age, setAge] = useState(false);
  const [tipping, setTipping] = useState(false);
  const [delivery, setDelivery] = useState(false);
  const [booking, setBooking] = useState(false);
  const [notifications, setNotifications] = useState(false);
  const [mobileListing, setMobileListing] = useState(false);
  const [user, setUser] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [merchant, setMerchant] = useState({});
  const [loading, setLoading] = useState(false);

  const getMerchantHandle = () => {
    if (!!setting?.auth?.signin_merchant_id) {
      getMerchantApi(setting?.auth?.signin_merchant_id)
        .then((data) => {
          setMerchant(data)
          setImageUrl(data.logo);
          setAge(Boolean(data.minimumGuestAge));
          setDelivery(Boolean(data.delivery));
          setNotifications(Boolean(data.notifications));
          setTipping(Boolean(data.tipping));
          setMobileListing(Boolean(data.list_in_stores))
          setOpenDays(JSON.parse(data.other)?.openHours || initDays)
        })
        .catch((e) => {
          console.warn(e)
        })
    }
  }

  const beforeUpload = async (file) => {
    try {
      setLoading(true);
      const filename = setting?.auth?.signin_merchant_id + '_' + Date.now() + '.' + getFileExtension(file.name)
      uploadFileApi(file, filename, {
        onError: (e) => {
          console.log(e.code);
          console.log(e.message);
          setLoading(false)
        },
        onSuccess: (data) => {
          setImageUrl(data.Location);
          setLoading(false);
        },
        onProgress: (e) => {
          console.log(e)
        }
      })

    } catch (e) {
      console.warn(e)
    }
  };

  const updateMerchantHandle = async (payload) => {
    setLoading(true)
    console.log(payload, "PAYLOAD");
    try {
      const data = {
        user: {
          merchant_id: setting?.auth?.signin_merchant_id,
          merchant_user_id: setting?.auth?.signin_merchant_user_id,
          merchant: {
            description: payload.description,
            category: payload.category,
            logo: !!imageUrl ? imageUrl: undefined,
            minimumGuestAge: age ? payload.minimumGuestAge : null,
            tipping: tipping,
            list_in_stores: mobileListing,
            other: JSON.stringify({
              openHours: openDays
            })
          }
        },
      };
      const res = await updateMerchantApi(setting?.auth?.signin_merchant_id, {...data})
      console.log("UPDATE", res)
      getMerchantHandle();
    } catch (e) {
      console.log(e)
    }
    setLoading(false)
  }

  useEffect(() => {
    if (!!setting?.auth?.signin_merchant_id) {
      getMerchantHandle();
    }
  }, [setting?.auth])

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined/> : <PlusOutlined/>}
      <div style={{marginTop: 8}}>Upload</div>
    </div>
  );

  return (
    <Layout>
    <Tab/>  
      <div className="row">
        <div className="col col-lg-3">
          <ProfileSidebar/>
        </div>
        <div className="col col-lg-9 pb-5">
          <h4>Activations</h4>
          <Form
            fields={[
              {name: "category", value: merchant.category},
              {name: "dba", value: merchant.dba},
              {name: "description", value: merchant.description},
              {name: "minimumGuestAge", value: merchant.minimumGuestAge || 13}
            ]}
            onFinish={updateMerchantHandle}
            layout="vertical">
            <Row gutter={12}>
              <h5 className="mt-5">Checkout</h5>
              <Col span={24}>
                <div className="card card-body mb-5">
                  <div>
                    <div style={{padding: 10, border: "0px solid #000000", display: "inline-block", borderRadius: 10}}>
                      <div>
                      <h5>Store $29.99 / month</h5>
                        <td width="15%">
                          <Item>
                            <Switch
                              checked={mobileListing}
                              onChange={(checked) => {
                                setMobileListing(checked)
                              }}
                            />&nbsp;&nbsp;Activated
                          </Item>
                        </td>
                        <td width="85%">
                          <Item style={{paddingLeft: '40px', paddingTop: "0px"}}>
                          Includes website, social media StoreLink & Apple iOS PoS System. Credit card fees of 3.00% + $0.30 per transaction. Chargeback fees for $15 apply per chargeback.
                          </Item>
                        </td>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div style={{padding: 10, border: "0px solid #000000", display: "inline-block", borderRadius: 10}}>
                      <div>
                      <h5>Tipping</h5>
                        <td width="15%">
                          <Item>
                            <Switch
                              checked={tipping}
                              onChange={(checked) => {
                                setTipping(checked)
                              }}
                            />&nbsp;&nbsp;Activated
                          </Item>
                        </td>
                        <td width="85%">
                          <Item style={{paddingLeft: '40px', paddingTop: "0px", paddingRight: '175px'}}>
                          Included. Preset rates of none, 10%, 15%, 20% and custom tipping.                                                                  
                          </Item>
                        </td>
                      </div>
                    </div>
                  </div> 
                  <div>
                    <div style={{padding: 10, border: "0px solid #000000", display: "inline-block", borderRadius: 10}}>
                      <div>
                      <h5>Shipping</h5>
                        <td width="15%">
                          <Item>
                            <Switch
                              checked={delivery}
                              onChange={(checked) => {
                                setDelivery(checked)
                              }}
                            />&nbsp;&nbsp;Activated
                          </Item>
                        </td>
                        <td width="85%">
                          <Item style={{paddingLeft: '40px', paddingTop: "0px", paddingRight: '440px'}}>
                          Rates set by selected provider.
                          </Item>
                        </td>
                      </div>
                    </div>
                  </div>     
                </div>  
              </Col>   
              
              <h5 className="mt-5">Checkin</h5>
              <Col span={24}>
                <div className="card card-body mb-5">
                  <div>
                    <div style={{padding: 10, border: "0px solid #000000", display: "inline-block", borderRadius: 10}}>
                      <div>
                      <h5>Booking & Reservations $79.99 / month</h5>
                        <td width="15%">
                        <Item>
                          <Switch
                            checked={booking}
                            onChange={(checked) => {
                              setBooking(checked)
                            }}
                          />&nbsp;&nbsp;Activated
                        </Item>
                        </td>
                        <td width="85%">
                          <Item style={{paddingLeft: '40px', paddingTop: "0px"}}>
                            Included unlimited reviews and 50 notifications and email blasts to 100 email addresses per month.
                          </Item>
                        </td>
                      </div>
                    </div>
                  </div>  
                  <div>
                    <div style={{padding: 10, border: "0px solid #000000", display: "inline-block", borderRadius: 10}}>
                      <div>
                      <h5>Age & ID Verification $99.99 / month</h5>
                        <td width="15%">
                        <Item>
                            <Switch
                              height={22}
                              width={44}
                              checked={age}
                              onChange={(checked) => {
                                setAge(checked)
                              }}/>&nbsp;&nbsp;Activated
                        </Item>  
                        {
                          age && (
                            <Item 
                              name="minimumGuestAge">
                              <Select
                                style={{width: 70}}
                                name="minimumGuestAge"
                                id="minimumGuestAge"
                                defaultValue="13"
                                size="large">
                                {
                                  [...new Array(13)].map((_, index) => (
                                    <Option key={`age-${index}`} value={index + 13}>{index + 13}</Option>
                                  ))
                                }
                              </Select>
                            </Item>
                          )
                        }
                        </td>
                        <td width="85%">
                          <Item style={{paddingLeft: '40px', paddingTop: "0px"}}>
                            Provides identity verification of U.S. residents with valid drivers licenses for upto 1000 checkins a month. Additional checkins can be purchased in increments of $10.
                          </Item>
                        </td>
                      </div>
                    </div>
                  </div>   
                </div>  
              </Col>  
              
              <h5 className="mt-5">CRM</h5>
              <Col span={24}>
                <div className="card card-body mb-5">
                  <div>
                    <div style={{padding: 10, border: "0px solid #000000", display: "inline-block", borderRadius: 10}}>
                      <div>
                      <h5>Customer Management & Reviews $59.99 / month</h5>
                        <td width="15%">
                          <Item>
                            <Switch
                              checked={notifications}
                              onChange={(checked) => {
                                setNotifications(checked)
                              }}
                            />&nbsp;&nbsp;Activated
                          </Item>
                        </td>
                        <td width="85%">
                          <Item style={{paddingLeft: '40px', paddingTop: "0px"}}>
                            Included unlimited reviews and 50 notifications and email blasts to 100 email addresses per month.
                          </Item>
                        </td>
                      </div>
                    </div>
                  </div>  
                  <div>
                    <div style={{padding: 10, border: "0px solid #000000", display: "inline-block", borderRadius: 10}}>
                      <div>
                      <h5>SMS Notifications $10 / month</h5>
                        <td width="15%">
                          <Item>
                            <Switch
                              checked={notifications}
                              onChange={(checked) => {
                                setNotifications(checked)
                              }}
                            />&nbsp;&nbsp;Activated
                          </Item>
                        </td>
                        <td width="85%">
                          <Item style={{paddingLeft: '40px', paddingTop: "0px"}}>
                            Rates of $0.05 per SMS. Minimum subscription charge of $10 will added to your billing. Subsequent minimum charge will be $10.
                          </Item>
                        </td>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div style={{padding: 10, border: "0px solid #000000", display: "inline-block", borderRadius: 10}}>
                      <div>
                      <h5>Email $10 / month</h5>
                        <td width="15%">
                          <Item>
                            <Switch
                              checked={notifications}
                              onChange={(checked) => {
                                setNotifications(checked)
                              }}
                            />&nbsp;&nbsp;Activated
                          </Item>
                        </td>
                        <td width="85%">
                          <Item style={{paddingLeft: '40px', paddingTop: "0px"}}>
                            Rates of $0.05 per email. Minimum subscription charge of $10 will added to your billing. Subsequent minimum charge will be $10.
                          </Item>
                        </td>
                      </div>
                    </div>
                  </div>    
                </div>  
              </Col>      
            </Row>

            <Item style={{marginBottom:'50', textAlign:'center'}}>
              <Button 
                htmlType="submit"
                type="primary"
                loading={loading}
                size="large">
                Save Activiations
              </Button>
            </Item>
          </Form>
        </div>
      </div>
    </Layout>
  );
};

export default StoreProfileEditPage;
