import { apiPost } from "..";

export const loginWithEmail = async (email, auth) => {
  try {
    const data = {
      user: {
        username: email,
        password: auth
      }
    }

    const res = await apiPost("v1/sessions", data)

    return Promise.resolve(res)
  } catch (e) {
    return Promise.reject(e)
  }
}

export const signupApi = async (params) => {
    try {
        const res = await apiPost("/registrations", params)
        return Promise.resolve(res)
    } catch (e) {
        return Promise.reject(e)
    }
}