import React, {useEffect} from 'react'
import Header from "../../../components/header/authhome";
import Footer from "../../../components/footer/footerPage";
import {Styles} from '../../../layouts/style/guideStyle'

const Guide = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Header />
      <Styles>
      <div className="guide">
          <section className="cta text-center" id="" style={{paddingTop: 50, paddingBottom: 50}}>
            <div className="">
              <div className="col-lg-12 my-auto">
                <h className="title">NFT Identity For People, Products, Places</h>
                <h3>Know what data you are sharing & who is profiting from your data.</h3>
                <div className="container-fluid">
                  <img src="https://staging.veev.app/img/veevid1.png" style={{paddingTop: 50}} className="img-fluid" alt="" /> 
                  <div className="row">
                    <div className="col-lg-2"> 
                      <div className="device-mockup iphone6 portrait gold">
                        <div className="device">
                          <div className="screen">
                          <img src="https://staging.veev.app/img/veevid.png" className="img-fluid" alt="" />
                          </div>
                        </div>
                      </div>
                      <div className="section-heading">
                      <h4>One Click NFT Sign +</h4>	
                      Blockchain Security
                      </div>
                    </div>
                    <div className="col-lg-2"> 
                      <div className="device-mockup iphone6 portrait gold">
                        <div className="device">
                          <div className="screen">
                          <img src="https://staging.veev.app/img/veev-qr-code.png" className="img-fluid" alt="" />
                          </div>
                        </div>
                      </div>
                      <div className="section-heading">
                      <h4>One Click NFT Checkin</h4>	
                      No Filling Forms
                      </div>
                    </div>
                    <div className="col-lg-2"> 
                      <div className="device-mockup iphone6 portrait gold">
                        <div className="device">
                          <div className="screen">
                          <img src="https://staging.veev.app/img/veevnft1.png" className="img-fluid" alt="" />
                          </div>
                        </div>
                      </div>
                      <div className="section-heading">
                      <h4>One Click NFT Checkout</h4>
                      No Passwords
                      </div>
                    </div>
                    <div className="col-lg-2"> 
                      <div className="device-mockup iphone6 portrait gold">
                        <div className="device">
                          <div className="screen">
                          <img src="https://staging.veev.app/img/veevid5.png" className="img-fluid" alt="" />
                          </div>
                        </div>
                      </div>
                      <div className="section-heading">
                      <h4>NFT Marketplace</h4>	
                      Text 
                      </div>
                    </div>
                    <div className="col-lg-2">
                      <div className="device-mockup iphone6 portrait gold">
                        <div className="device">
                          <div className="screen">
                          <img src="https://staging.veev.app/img/veev_events_signup.png" className="img-fluid" alt="" />
                          </div>
                        </div>
                      </div>
                      <div className="section-heading">
                      <h4>Crypto & NFT Wallet</h4>	
                      Text
                      </div>
                    </div>
                    <div className="col-lg-2"> 
                      <div className="device-mockup iphone6 portrait gold">
                        <div className="device">
                          <div className="screen">
                          <img src="https://staging.veev.app/img/veev_app_order.png" className="img-fluid" alt="" />
                          </div>
                        </div>
                      </div>
                      <div className="">
                      <h4>NFT Score & Password Manager</h4>	
                      Text
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="cta" id="" style={{paddingTop: 40, paddingBottom: 40}}>
            <div className="container">
              <div className="col-lg-12 my-auto">	
                <div className="container-fluid">
                  <div className="row"> 
                    <div className="col-lg-12 text-center">
                      <p className="title">One Click NFT Sign Up</p>
                      <img src="https://gateway.pinata.cloud/ipfs/QmcYRhXsWnpp6RhyT4K2tyF54W2AebEyJoWy2Tnq7hGgjD/checkinsls1.png" className="img-fluid" style={{paddingTop: 30, paddingBottom: 40}} alt="" />  
                    </div>  
                  </div>
                </div>
              </div>
              <div className="col-lg-12 my-auto">
                <div className="row">   
                  <div className="col-lg-4">   
                    <div className="section-heading text-center">
                    <h3>One Click Sign Ups</h3>
                    When you like, post, checkin or checkout, you earn VEEVcoin. VEEVcoin is a crypto currency like Dogecoin.<a href="https://dogecoin.com/"></a><br /><br />
                    What is posting?<br />
                    Posting is sharing photos on the VEEV app.<br /><br />
                    </div>
                  </div>
                  <div className="col-lg-4">   
                    <div className="section-heading text-center">
                    <h3>One Click Sign Ins</h3>
                    What is liking?<br />
                    Liking is clicking the heart icon next to a post.<br /><br />
                    website, tickets, <br />
                    </div>
                  </div> 
                  <div className="col-lg-4">   
                    <div className="section-heading text-center">
                    <h3>No More Passwords</h3>
                    Checkin is when you scan a VEEV QR to sign up to an event, get into a club, bar or login to a website.<br /><br />
                    What is Checkout?<br />
                    Checkout is when you scan a VEEV QR to pay for drinks, tickets, coffee, whatever!
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="cta text-center" id="" style={{paddingTop: 40, paddingBottom: 40}}>
          {/* <section className="features-gray"> */}
            <div className="container">	
              <div className="row">
                <div className="col-lg-12 my-auto">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-lg-12"> 
                        <p className="title">One Click NFT Checkin</p>
                        <br/>id verification - List events, promote to your group. Watch fans and followers signup. VEEV Checkin make checking in easy breezy, eliminating line-ups and queues. Attendees scan the QR at the event to checkin which is matched with the signup list.<br /><br /> 
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container">
              <div className="col-lg-12 my-auto">
                <div className="container-fluid">
                  <div className="row">   
                    <div className="col-lg-3">  
                      <div className="device-mockup iphone6 portrait gold">
                        <div className="device">
                          <div className="screen">
                          <img src="https://staging.veev.app/img/veev-qr-code.png" className="img-fluid" alt="" />
                          </div>
                        </div>
                      </div>
                      <div className="section-heading text-center">
                      <h3>Scan, bars, event, airports</h3>
                      </div>
                    </div>
                    <div className="col-lg-1">  
                      
                    </div>  
                    <div className="col-lg-8"> 
                      <div className="device-mockup ipad_air_2 landscape silver">
                        <div className="device">
                          <div className="screen">
                            <img src="https://staging.veev.app/img/veev_checkin.png" className="img-fluid" alt="" />
                          </div>
                        </div>
                      </div>
                      <div className="section-heading text-center">
                      <h3>Event Checkin Admin Portal</h3>
                      </div>
                      <div className="section-heading text-center">
                      Reminder customers about appointments, schedule follow-ups, return visits, send promotional offers and more.<br />
                      </div>
                    </div> 
                  </div>
                </div>
              </div>
            </div>
            <div className="container">
              <div className="text-center">
                <a className="badge-link" href="https://itunes.apple.com/WebObjects/MZStore.woa/wa/viewSoftware?id=1380885775&mt=8"><br />
                <img className="wallet" src="https://staging.veev.app/img/app-store-badge.svg" style={{ marginTop: 20, height: 38 }} alt="VEEV Wallet" /></a>
              </div>		
            </div>
          </section>

          <section className="cta" id="" style={{paddingTop: 40, paddingBottom: 40}}>
            <div className="container">
              <div className="col-lg-12 my-auto">	
                <div className="container-fluid">
                  <div className="row"> 
                    <div className="col-lg-12 text-center">
                      <p className="title">One Click NFT Checkout - Online & Mobile</p>
                      <img src="https://staging.veev.app/img/checkout_online.png" className="img-fluid" style={{paddingBottom: 40}} alt="" />  
                    </div>  
                  </div>
                </div>
              </div>
              <div className="col-lg-12 my-auto">
                <div className="row">   
                  <div className="col-lg-12">   
                    <div className="section-heading text-center">
                    <p className="subtitle">Online Checkout</p>
                        Support business that align with your values. support your values. Instant mobile ordering and pay from any smart phone with simple Click & Pay, approve transaction. Payment are processed instantly. Purchase receipt is displayed instantly.
                        <br /><br />
                        Secure, No Signup, No giving credit card, fraud proof<br /><br />
                        Eliminate Signups<br />
                        Groups is tool to connect with your community, engage with them and have a dialogue. The Group feature has community building tools so fans can follow you and a notification system to drop a new song, event or a call to action to followers.<br /><br />   
                        <b>Website Signups</b><br />
                        VEEV Pay provides your business Online Ordering and fullfilment capability for items listed in you POS.<br /><br />
                        <b>Form Filling</b><br />
                        - Eliminate line ups and serve customers faster using Scan & Pay<br /><br /> 
                        <b>Passwordless Login</b><br />
                        - Allows customers to shop, pay for purchases and select delivery options<br /><br />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="download bg-primary3 text-center" id="" style={{paddingTop: 30, paddingBottom: 60}}>
            <div className="container">
              <div className="col-lg-12 my-auto">	
                <div className="container-fluid">
                  <div className="row">   
                    <div className="col-lg-4 content"> 
                      <div className="container">  
                        <div className="section-heading">
                        <p className="title">NFT Score & Password Manager</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-1">
                    </div> 
                    <div className="col-lg-3">  
                      <div className="device-mockup iphone6 portrait gold">
                        <div className="device">
                          <div className="screen">	
                            <img src="https://staging.veev.app/img/veev_event_listings.png" className="img-fluid" alt="" />
                          </div>
                        </div>
                      </div>
                    </div>  
                    <div className="col-lg-4">
                      <b>Website Signups</b><br />
                        VEEV Pay provides your business Online Ordering and fullfilment capability for items listed in you POS.<br /><br />
                        <b>Form Filling</b><br />
                        - Eliminate line ups and serve customers faster using Scan & Pay<br /><br /> 
                        <b>Passwordless Login</b><br />
                        - Allows customers to shop, pay for purchases and select delivery options<br /><br />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="download bg-primary text-center" id="" style={{paddingTop: 30, paddingBottom: 60}}>
            <div className="container">
              <div className="col-lg-12 my-auto">	
                <div className="container-fluid">
                  <div className="row">   
                    <div className="col-lg-4 content"> 
                      <div className="container">  
                        <div className="section-heading">
                        <p className="title">NFT Marketplace</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-1">
                    </div> 
                    <div className="col-lg-3">  
                      <div className="device-mockup iphone6 portrait gold">
                        <div className="device">
                          <div className="screen">	
                            <img src="https://staging.veev.app/img/veev_event_listings.png" className="img-fluid" alt="" />
                          </div>
                        </div>
                      </div>
                    </div>  
                    <div className="col-lg-4">
                    - Turn photos into NFTs & Earn Crypto for Likes
                    - Create NFTs for products, track usage, generate crypto.
                    - get paid, make money 
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="cta text-center" id="" style={{paddingTop: 40, paddingBottom: 40}}>
            <div className="container">
              <div className="col-lg-12 my-auto">
                <div>
                  <p className="title">NFTs For Products. Track Usage, Get Paid, Make Money</p>
                </div>	
                <div className="container-fluid">
                  <div className="row">   
                    <div className="col-lg-3">  
                      <div className="device-mockup iphone6 portrait gold">
                        <div className="device">
                          <div className="screen">
                            <img src="https://staging.veev.app/img/veev_pics.png" className="img-fluid" alt="" />
                          </div>
                        </div>
                      </div>
                      <div className="section-heading text-center">
                      <h3>100 Likes<br />1 VEEV Coin</h3>
                      </div>
                    </div> 
                    <div className="col-lg-3">  
                      <div className="device-mockup iphone6 portrait gold">
                        <div className="device">
                          <div className="screen">
                            <img src="https://staging.veev.app/img/veev_social.png" className="img-fluid" alt="" />
                          </div>
                        </div>
                      </div>
                      <div className="section-heading text-center">
                      <h3>8 Posts<br />1 VEEV Coin</h3>
                      </div>
                    </div>  
                    <div className="col-lg-3">  
                      <div className="device-mockup iphone6 portrait gold">
                        <div className="device">
                          <div className="screen">	
                            <img src="https://staging.veev.app/img/veev_event_listings.png" className="img-fluid" alt="" />
                          </div>
                        </div>
                      </div>
                      <div className="section-heading text-center">
                      <h3>2 Checkins<br />1 VEEV Coin</h3>
                      </div>
                    </div>  
                    <div className="col-lg-3">  
                      <div className="device-mockup iphone6 portrait gold">
                        <div className="device">
                            <div className="screen">
                            <img src="https://staging.veev.app/img/veev_app_order.png" className="img-fluid" alt="" />
                            </div>	  
                          </div>
                        </div>
                      <div className="section-heading text-center">
                      <h3>$10 Checkouts<br />1 VEEV Coin</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="features-gray text-center" style={{paddingTop: 40, paddingBottom: 40}}>
            <div className="container">	
              <div className="row">
                <div className="col-lg-12 my-auto">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-lg-12"> 
                        <p className="title">Get Your NFT</p>

                        <img src="https://staging.veev.app/img/veevqr1.png" style={{ marginTop: 0, height: 170 }} alt="VEEV" />
                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container">
              <div className="text-center">
                <a className="badge-link" href="https://itunes.apple.com/WebObjects/MZStore.woa/wa/viewSoftware?id=1380885775&mt=8"><br />
                <img className="wallet" src="https://staging.veev.app/img/app-store-badge.svg" style={{ marginTop: 20, height: 38 }} alt="VEEV Wallet" /></a>
              </div>		
            </div>
          </section>
        </div>
      </Styles>
    <Footer />
  </>
  )
}
export default Guide
