import React, {useState, useEffect} from "react";
import { 
    useStripe, 
    useElements, 
    PaymentElement, 
    LinkAuthenticationElement,
    AddressElement,
    PaymentRequestButtonElement
} from '@stripe/react-stripe-js';
import { useCart } from "../provider/checkout";

const CheckoutForm = () =>  {
  const stripe = useStripe();
  const elements = useElements();
  const [paymentRequest, setPaymentRequest] = useState(null);
  const [paymentAmount, setPaymentAmount] = useState(null);
  const [{items, merchantId, total, merchantName}, _] = useCart();

    const [clientSecret, setClientSecret] = React.useState(null);

    const [isLoading, setIsLoading] = React.useState(false);
    const [isLoadingPayment, setIsLoadingPayment] = React.useState(false);
    const [message, setMessage] = useState(null);
    const [paymentType, setPaymentType] = React.useState('0');
    const [email, setEmail] = React.useState('');
    const [billing, setBilling] = React.useState('');


  useEffect(() => {
    setIsLoading(true)

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const intent = elements._commonOptions.clientSecret.id;

    fetch(process.env.REACT_APP_API_URL + "/v2/payment_intent", {
      method: "PUT",
      headers: 
      {
        "Accept": "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
      	    payment_intent: intent,
      	    transaction: {
      	    	merchant_id: merchantId,
      	    	items: items.map(({item, quantity, extras}) => ({ id: item.id, qty: quantity, extras, }))
      	    }
        })
    })
      .then((res) => res.json())
      .catch(err => console.error(err))
      .then((data) => {
        setClientSecret(data.clientSecret)
        stripe
          .retrievePaymentIntent(data.clientSecret)
          .then(function(result) {
            if (result.error) {
                console.error("error getting transaction info")
                return
            }
            setPaymentAmount(result.paymentIntent.amount)
        });
      })
      .finally(() => {
        setIsLoading(false)
      })

  }, [stripe]);


  useEffect(() => {
    if (stripe) {
      const pr = stripe.paymentRequest({
        country: 'US',
        currency: 'usd',
        total: {
          label: merchantName,
          amount: paymentAmount,
        },
        requestPayerName: true,
        requestPayerEmail: true,
        disableWallets: ['link']
      });

      pr.canMakePayment().then(result => {
        if (result) {
          setPaymentRequest(pr);
        } else {
            setPaymentType('1')
        }
      });
    }
  }, [paymentAmount]);

  if (paymentRequest) {
    paymentRequest.on('paymentmethod', async (ev) => {
    // Confirm the PaymentIntent without handling potential next actions (yet).
      if (!stripe || !elements) {
        // Stripe.js hasn't yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
      }

      const intent = elements._commonOptions.clientSecret.id;

      setIsLoadingPayment(true)

        const params = {
          email: ev.payerEmail,
          customer_name: ev.payerName,
          address: 'none',
        	payment_intent: intent,
          return_base_url: 'https://www.veev.app',
        	transaction: {
        		merchant_id: merchantId,
        		items: items.map(({item, quantity, extras}) => ({ id: item.id, qty: quantity, extras: extras }))
        	}
        }

        fetch(process.env.REACT_APP_API_URL + "/v2/transactions/guest/create", {
        	method: "POST",
        	headers: 
        	{
        		"Accept": "application/json",
        		"Content-Type": "application/json",
        	},
        	body: JSON.stringify(params)
        })
        .then((res) => res.json())
        .catch((err) => {
          console.error("error", err)
          alert("Something went wrong! Please try again later or contact support.")
         })
        .then(async (data) => {


         let url = new URL(data.return_url)
         let urlParams = new URLSearchParams(url.search)
         const redirect_url = (process.env.NODE_ENV == 'production') ? (window.location.origin + url.pathname  + '?email=' + urlParams.get('email') || '') : url.toString()
        
         const result = await stripe.confirmCardPayment(
            clientSecret,
            {
              payment_method: ev.paymentMethod.id,
      	      return_url: redirect_url
            },
            {handleActions: true}
          );

          let error = result.error
          if (error) {
              ev.complete('fail');
              setMessage(error.message);
          } else {
              ev.complete('success');
              window.location.replace(redirect_url);
          }

          console.info('checkout status', result.paymentIntent.status)
      })
      .catch((err) => {
          console.error("error =>", err)
      })
      .finally(() => {
          setIsLoadingPayment(false)
      })
 
    });
  }

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    const intent = elements._commonOptions.clientSecret.id;

    setIsLoadingPayment(true)

      const params = {
        email: email.email,
        customer_name: billing.name,
        address: billing.address,
      	payment_intent: intent,
	    return_base_url: 'https://www.veev.app',
      	transaction: {
      		merchant_id: merchantId,
      		items: items.map(({item, quantity, extras}) => ({ id: item.id, qty: quantity, extras: extras }))
      	}
      }

      fetch(process.env.REACT_APP_API_URL + "/v2/transactions/guest/create", {
      	method: "POST",
      	headers: 
      	{
      		"Accept": "application/json",
      		"Content-Type": "application/json",
      	},
      	body: JSON.stringify(params)
      })
      .then((res) => res.json())
      .catch((err) => {
        console.error("error", err)
       })
      .then(async (data) => {

       let url = new URL(data.return_url)
       let urlParams = new URLSearchParams(url.search)
       const result = await stripe.confirmPayment({
		  // `Elements` instance that was used to create the Payment Element
		   elements,
		   confirmParams: {
		       return_url: (process.env.NODE_ENV == 'production') ? (window.location.origin + url.pathname  + '?email=' + urlParams.get('email') || '') : url.toString()
		   },
           //redirect: "if_required",
	     });

         let error = result.error;
         if (error) {
            setMessage(error.message);
         }
    })
    .catch((err) => {
        console.error("error =>", err)
    })
    .finally(() => {
        setIsLoadingPayment(false)
    })
 
	      // if (result.error) {
		  // Show error to your customer (for example, payment details incomplete)
		  // console.log(result.error.message);
	      // } else {
		  // Your customer will be redirected to your `return_url`. For some payment
		  // methods like iDEAL, your customer will be redirected to an intermediate
		  // site first to authorize the payment, then redirected to the `return_url`.
	      // }

	  // });
  };

  const paymentElementOptions = {
    layout: "tabs",
    wallets: {
        applePay: 'never',
        googlePay: 'never',
    }
  };

    if (isLoading) {
        return <></>
    }

    return (
    <>
        
	<form id="payment-form" >
    <h1>Checkout</h1>
    <br />
    {(paymentType === '1') ?  
    	<div>
            <LinkAuthenticationElement id="link-authentication-element" onChange={(e) => setEmail(e.value)}/>

            <AddressElement options={{mode: 'billing'}} onChange={(e) => setBilling(e.value)} />

            <PaymentElement id="payment-element" options={paymentElementOptions} style={{marginBottom: '25px'}}/>

            <div >
            <br />
            <h5 style={{fontWeight: '400', fontFamily: "SFProDisplay-Bold, Helvetica, Arial, sans-serif" }}>
                <span style={{float: 'left'}}>Subtotal:</span>
                <span style={{float: 'right'}}>&#36;{total().toFixed(2)}</span>
            </h5>
            <br />

            <h5 style={{fontWeight: '400', fontFamily: "SFProDisplay-Bold, Helvetica, Arial, sans-serif" }}>
                <span style={{float: 'left'}}>Tax:</span>
                <span style={{float: 'right'}}>&#36;{((paymentAmount / 100) - total()).toFixed(2)}</span>
            </h5>
            <br />

            <h5 style={{fontWeight: '600', fontFamily: "SFProDisplay-Bold, Helvetica, Arial, sans-serif" }}>
                <span style={{float: 'left'}}>Total:</span>
                <span style={{float: 'right'}}>&#36;{(paymentAmount / 100).toFixed(2)}</span>
            </h5>
            <br />
            </div>
    
            <button
                    style={{
                    background: '#cc0000',
                    color: '#ffffff',
                    display: 'block',
                    width: '100%',
                    margin: '16px 0px',
                    borderRadius: '3px',
                    padding: '12px 16px',
                    fontWeight: '600'
                    }}
                    disabled={isLoadingPayment || !stripe || !elements}
                    onClick={handleSubmit}
                    id="submit">
                    <span id="button-text">
                    {isLoadingPayment ? <div className="spinner" id="spinner"></div> : "Pay"}
                    </span>
            </button>
            <button
                    style={{
                    color: '#333333',
                    display: 'block',
                    width: '100%',
                    margin: '0px',
                    borderRadius: '3px',
                    fontWeight: '600'
                    }}
                    disabled={isLoadingPayment || !stripe || !elements}
                    onClick={() => setPaymentType('0')}
                    id="payment-back-btn">
                    <span>
                        Back
                    </span>
            </button>
	    </div>

        :
        <>
            <div >
                <br />
                <h5 style={{fontWeight: '400', fontFamily: "SFProDisplay-Bold, Helvetica, Arial, sans-serif" }}>
                    <span style={{float: 'left'}}>Subtotal:</span>
                    <span style={{float: 'right'}}>&#36;{total().toFixed(2)}</span>
                </h5>
                <br />

                <h5 style={{fontWeight: '400', fontFamily: "SFProDisplay-Bold, Helvetica, Arial, sans-serif" }}>
                    <span style={{float: 'left'}}>Tax:</span>
                    <span style={{float: 'right'}}>&#36;{((paymentAmount / 100)-total()).toFixed(2)}</span>
                </h5>
                <br />

                <h5 style={{fontWeight: '600', fontFamily: "SFProDisplay-Bold, Helvetica, Arial, sans-serif" }}>
                    <span style={{float: 'left'}}>Total:</span>
                    <span style={{float: 'right'}}>&#36;{(paymentAmount / 100).toFixed(2)}</span>
                </h5>
                <br />
            </div>       

            <br />

            {paymentRequest && 
                <PaymentRequestButtonElement options={{paymentRequest}} />
            }
            <button
                style={{
                background: '#cc0000',
                color: '#ffffff',
                display: 'block',
                width: '100%',
                margin: '16px 0px',
                borderRadius: '3px',
                padding: '12px 16px',
                fontWeight: '600'
                }}
                disabled={isLoading || !stripe || !elements}
                onClick={() => setPaymentType('1')}
                id="card-payemnt-type">
                <span id="button-text">
                    Credit or Debit Card
                </span>
            </button>
            <button
                style={{
                color: '#333333',
                display: 'block',
                width: '100%',
                margin: '0px',
                borderRadius: '3px',
                fontWeight: '600'
                }}
                disabled={isLoadingPayment || !stripe || !elements}
            >
                <span>
                    Continue Shopping
                </span>
            </button>
        </>
    }
    {message && <div id="payment-message">{message}</div>}
	</form>
    </>
    );
}

export default CheckoutForm;
