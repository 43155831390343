import React, {useState, useEffect, Fragment} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import { NotificationManager } from "react-notifications";
import moment from "moment";
import {
    Avatar,
    Button,
    Card,
    Checkbox,
    Col,
    DatePicker,
    Divider,
    Drawer,
    Form,
    Modal,
    Rate,
    Row,
    TimePicker,
    Select,
    Space,
    Typography
} from "antd";
import Header from "../../../components/header/authstore";
import Footer from "../../../components/footer/footerEmptyPage";
import {getAll as getStores} from "../../../api/stores";
import {useAsync} from "../../../service/utils";
import {Styles, ModalStyles, DetailCss, CardModalStyle} from "../../../layouts/style/storeDetailStyle"
import {isEmpty} from "../../../service/string";
import {MinusOutlined, PlusOutlined} from "@ant-design/icons";
import {Icon} from "@iconify/react";
import { useCart } from '../../../provider/checkout';
import { getAll as getAddonsApi } from '../../../api/extra';
import { getServiceProvidersApi, createProviderBookingApi } from '../../../api/api';
import ReservationCard from '../../../components/store/reservation-card';
// import AppointmentForm from '../../../components/store/appointment-card';
import { StarHalf } from '@material-ui/icons';

const {Meta} = Card;
const { Title } = Typography;
const { Item, useForm } = Form;
const { Option } = Select;

const Detail = () => {
  const {data, status, error, run} = useAsync({
    status: "idle",
  });
  const {name} = useParams();
  const navigate = useNavigate();
  const [cart, dispatch] = useCart();

  const [store, setStore] = useState({})
  const [categories, setCategories] = useState([])
  const [items, setItems] = useState([])
  const [item, setItem] = useState({})
  const [quantity, setQuantity] = useState(1)
  const [orderPrice, setOrderPrice] = useState(0)
  const [modalActive, setModalActive] = useState(false)
  const [addons, setAddons] = useState([])
  const [addonsChecked, setAddonsChecked] = useState([]);

  const handleModalOpen = (id) => {
    if (items.length !== 0) {
      let _item = items.find((i) => i.id == id);
      setItem(_item)
      setQuantity(1)
      setOrderPrice(parseFloat(_item.price))
    }

    setAddonsChecked([])
    setQuantity(1)
    setModalActive(true)
  }
  const handleModalClose = () => {
    setModalActive(false);
    setItem({});
    setAddonsChecked([])
  }
  const addQuantity = () => {
    setQuantity(quantity + 1);
  }
  
  const minusQuantity = () => {
    if (quantity > 0) {
      setQuantity(quantity - 1);
    }
  }

  const addCardHandle = () => {
    dispatch({
        type: "ADD",
        item: {
            item,
            quantity,
            extras: addonsChecked
        }
    })
    setModalActive(false)


    let addonPrice = 0

    addonsChecked.map((i) => {
      addonPrice = addonPrice + parseFloat(i.price)
    })

  }

  useEffect(() => {
    const stores = run(getStores());
  }, [run]);

  useEffect(() => {
    if (status === "resolved") {
      data.forEach((item) => {
        let store_url = item.dba
        store_url = store_url.toLowerCase()
        store_url = store_url.replaceAll(" ", "_")
        store_url = store_url.replaceAll("'", "")
        item.store_url = store_url

        if (item.store_url === name) {
          let _categories = item.categories || []
          _categories = _categories.map((category) => {
            let category_id = category.name
            category_id = category_id.toLowerCase();
            category_id = category_id.replaceAll(" ", "_");
            category_id = category_id.replaceAll("'", "");
            category.category_id = category_id;
            return category
          })
          setCategories(_categories)
          setItems(item.items || [])
          setStore({...item, booking_type: "appointment"})
        }
      })
    } else if (status === "rejected") {
      console.error(error);
    }
  }, [status, name]);

  useEffect(() => {
    if (store.id) {
        dispatch({
            type: "SET_STORE",
            merchantId: store.id,
            merchantName: store.legal_name
        });
        getAddonsApi(store.merchant_code)
          .then((data) => {
            setAddons(data.map((i) => ({...i, key: i.id})));
          })
          .catch((e) => console.warn(e))
    }
  }, [store])

  useEffect(() => {
    let addonPrice = addonsChecked?.reduce((accum, c) => accum + parseFloat(c.price || 0), 0)

    setOrderPrice(quantity * (parseFloat(item.price || "0") + addonPrice))
  }, [addonsChecked, quantity])

  const its = JSON.parse(item.other || "[]")?.addons;

  return (
    <Fragment>
      <DetailCss/>
      <Header/>
        <Styles>
            <Fragment>
              <div className="bg-area"/>
              <div className="bg-badge">
                <div className="bg-badge-container">
                  <Avatar
                    src={store.logo}
                    size={120}
                    style={{borderRadius: 0}}
                    shape="square">
                    A
                  </Avatar>
                  <div>
                    <h1>{store.dba}</h1>
                    <p>
                      {store.address}, {store.city}, {store.state}, {store.zip}
                    </p>
                    <p>
                      <a href={`tel:${store.phone}`}>{store.phone}</a>
                    </p>
                  </div>
                </div>
                <div className="nav-wrap">
                  <div className="badge-nav">
                    {
                      categories.map((category, i) => (
                        <a href={`#${category.name?.toLowerCase()?.replaceAll(" ", "")}`} key={i}>
                          {category.name}
                        </a>
                      ))
                    }
                    <a href="#about">
                      About
                    </a>
                    <a href="#reviews">
                      Reveiws
                    </a>
                    <a href="#book">
                      Reservations
                    </a>
                  </div>
                </div>
              </div>

              <div className="container detail-container">
                <Row gutter={[24, 24]}>
                  <Col span={24} sm={16}>
                    {categories.map((category, i) => (
                    <section key={i} id="store_category">
                      <Title
                          level={4}
                          id={category.name?.toLowerCase()?.replaceAll(" ", "")}
                      >
                          {category.name}
                      </Title>
                      <Row gutter={[16, 16]}>
                      {
                          items
                          .filter(({category_id}) => category_id == category.id)
                          .map((item, j) => (
                              <Col
                                  span={24}
                                  md={12}
                                  xl={12}
                                  key={j}
                              >
                                  <Card
                                      onClick={() => handleModalOpen(item.id)}
                                      hoverable
                                  >
                                      <div
                                      style={{
                                          display: "grid",
                                          gridTemplateColumns: "auto 120px",
                                          gap: 24
                                      }}>
                                      <Meta
                                          style={{ width: "100%" }}
                                          title={item.name}
                                          description={`$${parseFloat(item?.price).toFixed(2)}`}
                                      />
                                      <img
                                          alt="example"
                                          width={120}
                                          height={120}
                                          style={{ objectFit: "cover" }}
                                          src={item.picture_url} />
                                      </div>
                                  </Card>
                              </Col>
                          ))
                      }
                            </Row>
                        </section>
                        ))}
                    </Col>

                    <Col span={24} sm={{ span: 7, offset: 1 }} id="book">
                    {/* {
                      store.booking_type &&
                          <AppointmentForm items={items}/>                 
                    } */}
                    {
                      store.booking_type &&
                          <ReservationCard items={items} merchant_id={store.id}/>                   
                    }
                      <section style={{ paddingTop: 18 }} id="about">
                        <h3> About </h3>
                        <Row gutter={[24, 24]}>
                          <Col>
                            <span>{store.description}</span>
                          </Col>
                          <Col>
                            <Space direction="vertical">
                              <Space>
                                <Icon style={{ fontSize: 20 }} icon="carbon:location" />
                                <span>{store.address}, {store.city}, {store.state}, {store.zip}</span>
                              </Space>
                              <Space>
                                <Icon style={{ fontSize: 20 }} icon="bx:phone" />
                                <span>
                                  <a href={`tel:${store.phone}`}>{store.phone}</a>
                                </span>
                              </Space>
                              <Space style={{ alignItems: "flex-start" }}>
                                <Icon style={{ fontSize: 20 }} icon="fluent-mdl2:business-hours-sign" />
                                <span>
                                  {
                                    JSON.parse(store.other || "[]")?.openHours?.map((open, index) => (
                                      <span key={`open-${index}`}>
                                        <span style={{ display: "inline-block", width: "100px" }}>{open.day}:</span>
                                        {
                                          open.open ? (
                                            <span>{open.openHour} {open.openSuffix} - {open.closeHour} {open.closeSuffix}</span>
                                          ) : (
                                            <span>Closed</span>
                                          )
                                        }<br />
                                      </span>
                                    ))
                                  }
                                </span>
                              </Space>
                            </Space>
                          </Col>
                        </Row>
                      </section>
                  </Col>
                </Row>

                {/* Reveiws will go here */}
                <section style={{paddingTop: 40}} id="reviews">
                  <h3> Reviews - Test Mode</h3>
                  <Row gutter={[24, 24]}>
                    <Col span={24} sm={16}>
                      <section>
                      <Row style={{marginBottom:'20px', marginTop:10}}>  
                        <Col span={3} sm={2}>
                          <Space>
                            <Avatar
                                size={42}
                                marginTop={10}
                                src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png">
                            </Avatar>
                          </Space>
                        </Col>
                        <Col span={21} sm={22}>
                          <Space direction="vertical">
                            <Space>
                              <Rate disabled value={4} style={{color:'#cc0000'}}/>
                            </Space>
                            <Space>
                              <Icon style={{ fontSize: 20 }}/>
                              <span>
                              The food was great. The portion sizes were large, we all had to take it to go. The presentation was beautiful and the service was excelent.
                              </span>
                            </Space>
                          </Space>
                        </Col>
                      </Row>
                      <Row style={{paddingBottom:'20px'}}>   
                        <Col span={3} sm={2}>
                          <Space>
                            <Avatar
                                size={42}
                                marginTop={10}
                                src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png">
                            </Avatar>
                          </Space>
                        </Col>
                        <Col span={21} sm={22}>
                          <Space direction="vertical">
                            <Space>
                              <Rate disabled value={4} style={{color:'#cc0000'}}/>
                            </Space>
                            <Space>
                              <Icon style={{ fontSize: 20 }}/>
                              <span>
                              Pleasantly surprised at how good it was. Highly recommend this place and looking forward my next visit.
                              </span>
                            </Space>
                          </Space>
                        </Col>
                      </Row>
                      </section>
                    </Col>

                    <Col span={24} sm={{ span: 7, offset: 1 }} style={{backgroundColor:'', height:'10px'}}>
                      <section style={{ paddingTop: 0}}>
                      &nbsp;
                      </section>
                    </Col>
                  </Row>
                </section>

                {/* <section style={{ paddingTop: 40 }} id="about">
                  <h3> About </h3>
                  <Row gutter={[24, 24]}>
                    <Col span={24} sm={12}>
                      <span>{store.description}</span>
                    </Col>
                    <Col span={24} sm={12}>
                      <Space direction="vertical">
                        <Space>
                          <Icon style={{ fontSize: 20 }} icon="carbon:location" />
                          <span>{store.address}, {store.city}, {store.state}, {store.zip}</span>
                        </Space>
                        <Space>
                          <Icon style={{ fontSize: 20 }} icon="bx:phone" />
                          <span>
                            <a href={`tel:${store.phone}`}>{store.phone}</a>
                          </span>
                        </Space>
                        <Space style={{ alignItems: "flex-start" }}>
                          <Icon style={{ fontSize: 20 }} icon="fluent-mdl2:business-hours-sign" />
                          <span>
                            {
                              JSON.parse(store.other || "[]")?.openHours?.map((open, index) => (
                                <span key={`open-${index}`}>
                                  <span style={{ display: "inline-block", width: "100px" }}>{open.day}:</span>
                                  {
                                    open.open ? (
                                      <span>{open.openHour} {open.openSuffix} - {open.closeHour} {open.closeSuffix}</span>
                                    ) : (
                                      <span>Closed</span>
                                    )
                                  }<br />
                                </span>
                              ))
                            }
                          </span>
                        </Space>
                      </Space>
                    </Col>
                  </Row>
                </section> */}

              </div>
            </Fragment>
        </Styles>
      <Footer/>

      <Modal
        className="product-popup"
        bodyStyle={{
          borderRadius: 0
        }}
        centered
        width={800}
        footer={false}
        open={modalActive}
        onCancel={handleModalClose}>
        {!isEmpty(item) &&
          <ModalStyles>
            <section>
              <div>
                <img alt="" style={{height: "350px", width: "350px", objectFit: "cover"}}
                     src={item.picture_url}/>
              </div>
              <div className="detail-content">
                <div className="detail-content-wrap">
                  <h2 className="title">{item.name}</h2>
                  <p className="description">
                    {item.description}
                  </p>
                  <p className="price">
                    ${parseFloat(item.price)?.toFixed(2)}
                  </p>
                  <div className="addons">
                    {
                      its?.map((addon, index) => {
                        const adn = addons.find((a) => a.id === addon)
                        return adn?.name ? (
                          <Checkbox
                            onChange={(e) => {
                              if (e.target.checked) {
                                setAddonsChecked([...addonsChecked, adn])
                              } else {
                                setAddonsChecked(addonsChecked.filter((e) => e.id !== addon))
                              }
                            }}
                            checked={Boolean(addonsChecked.find((e) => e.id === addon)?.id)}
                            style={{marginBottom: 6}}
                            key={index}>
                            {adn?.name} - ${parseFloat(adn?.price || "0").toFixed(2)}
                          </Checkbox>
                        ) : (<></>)
                      })
                    }
                  </div>
                  <Divider/>
                </div>
              </div>
              <div className="add">
                <div className="counters">
                  <Button style={{color: "#cc0000"}} onClick={() => {
                    minusQuantity()
                  }}>
                    <MinusOutlined/>
                  </Button>
                  <p>{quantity}</p>
                  <Button style={{color: "#cc0000"}} onClick={() => {
                    addQuantity()
                  }}>
                    <PlusOutlined/>
                  </Button>
                </div>
                <div className="price-preview">
                  <p>${orderPrice.toFixed(2)}</p>
                </div>
                <div>
                  <Button
                    onClick={addCardHandle}
                    style={{
                      padding: "4px 24px",
                      borderRadius: "0px",
                      textTransform: "uppercase"
                    }}
                    type="primary">
                    Add to Cart
                  </Button>
                </div>
              </div>
            </section>
          </ModalStyles>
        }
      </Modal>

      <Drawer
        title="Your Order"
        placement="right"
        onClose={() => dispatch({ type: "CLOSE" })}
        open={cart.isOpen}>
        <CardModalStyle>
          <h3>Items</h3>
          <div>
            {
                cart.items.map(({item, quantity, total, extras, addonTotal}, index) => (
                    <Fragment key={`card-item-${index}`}>
                        <div className="card-item">
                            <Avatar
                                shape="square"
                                size={62}
                                src={item?.picture_url}/>
                            <div>
                                <p className="card-item-title">{item.name}</p>
                                <Space direction="row" align="center">
                                    <Button
                                        type="link"
                                        size="small"
                                        danger
                                        onClick={() => dispatch({ type: "REMOVE", index})}>
                                        Delete
                                    </Button>
                                    <p className="card-item-quantity">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    {quantity} x ${parseFloat(item.price).toFixed(2)}</p>
                                </Space>
                            </div>
                            {/* <p className="card-item-cost">${(total() + addonTotal()).toFixed(2)}</p>  */}
                            <p className="card-item-cost">${(total()).toFixed(2)}</p>       
                        </div>
                        <h3>Add-0ns</h3>
                        {
                            extras.map((addon, j) => (
                                <Fragment key={`addon-${index}-${j}`}>
                                    <div className="card-addon">
                                      <p className="card-addon-title">{addon.name}</p>                                    
                                      <p className="card-addon-quantity">{quantity} x ${parseFloat(addon.price || 0).toFixed(2)}</p> 
                                      <p className="card-addon-cost">${(parseFloat(addon.price || 0) * quantity).toFixed(2)}</p> 
                                    </div>
                                </Fragment>
                            ))
                        }
                        <Divider/>
                    </Fragment>
                ))
            }
          </div>
          <div className="total-wrap">
            <h3>Total</h3>
            <p className="card-total">${cart.total().toFixed(2)}</p>
          </div>
          <Divider/>
          <Button
            onClick={() => navigate(`/teststore/${name}/checkout`)}
            style={{
              borderRadius: 8,
              height: 44,
              fontSize: 16,
              fontWeight: 700,
              textTransform: "uppercase"
            }}
            block
            type="primary"
            size="large">
            Checkout ${cart.total().toFixed(2)}
          </Button>
          <Button
            onClick={() => dispatch({ type: "CLOSE"})}
            style={{
              marginTop: 5,
              border: 'none',
              boxShadow: 'none',
              borderRadius: 8,
              height: 44,
              fontSize: 14,
              fontWeight: 400,
              textTransform: "uppercase",
              color: "#333333"
            }}
            block
          >
            Continue Shopping
          </Button>
        </CardModalStyle>
      </Drawer>
    </Fragment>
  )
}
export default Detail;