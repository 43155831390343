import React, {useEffect, useState} from 'react';
import {Button, Checkbox, Divider, Form, Input, Space} from "antd";
import {useSetting} from "../../provider/setting";
import {setCookie} from "../../service/cookie";
import siteConfig from "../../config/site.config";
import {loginWithEmail} from "../../api/auth";
import ActionCable from "actioncable";
import {NotificationManager} from "react-notifications";
import styled from "styled-components";
import {useNavigate} from "react-router-dom";

const {Item} = Form;

const LoginPopup = (props) => {
    const {setScreen} = props;
    const navigate = useNavigate();
    const [, dispatch] = useSetting()
    const [qrcode, setQrcode] = useState('')

    const handleReceivedAuth = (data) => {
        console.log("Received authentication data." + JSON.stringify(data));
        //update the QR code by using new session_Id.
        const session_id = data.session_id;
        const _qrcode = {
            type: "login",
            version: 1,
            properties: {session_id},
        };
        const qr_src =
            "https://chart.googleapis.com/chart?chl=" +
            JSON.stringify(_qrcode) +
            "&chs=200x200&cht=qr&chld=H 7C0";
        setQrcode(qr_src)
        setCookie("signin_session_id", session_id, siteConfig.expired);
    }

    const handleSubmit = (data) => {
        login(data.email, data.password)
    }

    const login = async (email, pwd) => {
        if (email === "" && email === undefined) {
            return
        }
        if (pwd === "" && pwd === undefined) {
            return
        }
        try {
            const res = await loginWithEmail(email, pwd)
            console.log("logging in ...", res);
            setCookie("veev_current_user_username", res?.handle, siteConfig.expired);
            setCookie("current_ident_token", res.ident_token, siteConfig.expired);
            setCookie("current_user_email", res.email, siteConfig.expired);
            navigate(`/user/${res?.handle}`)
            // navigate(`/store/checkout/transactions`)
        } catch (e) {
            console.warn(e);
        }
    }

    useEffect(() => {
        const cable = ActionCable.createConsumer(siteConfig.webSocketUrl);
        const _authChanel = cable.subscriptions.create(
            {
                channel: 'SessionChannel'
            },
            {
                connected: () => {
                    console.log('Connected authentication_request')
                    _authChanel.send({
                        type: "authentication_request",
                        session_id: true,
                    });
                },
                received: data => {
                    const session_id = data.session_id
                    handleReceivedAuth(data)
                    cable.subscriptions.create(
                        {
                            channel: "MerchantChannel",
                            session_id,
                        },
                        {
                            received: (data) => {
                                // `data` will contain a `merchant_select_response` message.
                                console.log("Received merchant data." + JSON.stringify(data));
                                /* Do whatever with merchant data here. : save sessionid and merchant code., user_id on the session */
                                if (data.type === "merchant_select_response") {
                                    setCookie("signin_session_id", session_id, siteConfig.expired);
                                    setCookie("signin_merchant_code", data.merchant_information.merchant_code, siteConfig.expired);
                                    setCookie("signin_merchant_id", data.selected_merchant_id, siteConfig.expired);
                                    setCookie("signin_merchant_user_id", data.merchant_user_id, siteConfig.expired);
                                    setCookie("signin_current_user_id", data.current_user_id, siteConfig.expired);
                                    setCookie("signin_merchant_name", data.merchant_information.legal_name, siteConfig.expired);
                                    setCookie("current_ident_token", data.current_user.ident_token, siteConfig.expired);
                                    setCookie("current_user_email", data.current_user.email, siteConfig.expired);
                                    const selected_merchant_address =
                                        data.merchant_information.street +
                                        " " +
                                        data.merchant_information.city +
                                        " " +
                                        data.merchant_information.state +
                                        " " +
                                        data.merchant_information.zip;
                                    setCookie("selected_merchant_address", selected_merchant_address, siteConfig.expired);
                                    setCookie("current_merchant_wallet_address", data.merchant_information.address, siteConfig.expired);
                                    setCookie("selected_merchant_bool_cannabis_merchant", data.merchant_information.cannabis_merchant === 'true', siteConfig.expired);
                                    const auth = {
                                        signin_session_id: session_id,
                                        signin_merchant_code: data.merchant_information.merchant_code,
                                        signin_merchant_id: data.selected_merchant_id,
                                        //signin_merchant_user_id : data.merchant_information.wallet.user_id,
                                        signin_merchant_user_id: data.merchant_wallet_user_id,
                                        signin_current_user_id: data.current_user_id,
                                        signin_merchant_name: data.merchant_information.legal_name,
                                        current_ident_token: data.current_user.ident_token,
                                        current_user_email: data.current_user.email,
                                        selected_merchant_address: selected_merchant_address,
                                        current_merchant_wallet_address: data.merchant_information.wallet_address,
                                        selected_merchant_bool_cannabis_merchant: data.merchant_information.cannabis_merchant === 'true',
                                    };
                                    dispatch({type: 'SET', settingName: 'auth', settingData: auth})
                                    NotificationManager.success('VEEV ID Authenticated', 'Success', 3000);
                                }
                            },
                        }
                    );
                    cable.subscriptions.create(
                        {
                            channel: "SessionChannel",
                            session_id,
                        },
                        {
                            received: (data) => {
                                // `data` will contain a `merchant_select_response` message.
                                console.log("Received Session Channel." + JSON.stringify(data));
                                /* Do whatever with merchant data here. : save sessionid and merchant code., user_id on the session */

                                if (data.type === "authentication_linking") {
                                    // get the current user info.
                                    const current_user = data.current_user;
                                    console.log("current user info" + current_user);

                                    //set the current user info on the local storage: session_id, user_id,
                                    if (session_id !== undefined) {
                                        setCookie("veev_current_user_username", current_user.handle, siteConfig.expired);
                                        setCookie("veev_session_id", session_id, siteConfig.expired);
                                        setCookie("veev_current_user_id", current_user.id, siteConfig.expired);
                                        setCookie("veev_current_user_email", current_user.email, siteConfig.expired);
                                        setCookie("veev_current_user_ident_token", current_user.ident_token, siteConfig.expired);
                                        const auth = {
                                            signin_session_id: session_id,
                                            signin_current_user_id: current_user.id,
                                            current_user_email: current_user.email,
                                            current_ident_token: current_user.ident_token,
                                        }
                                        dispatch({type: 'SET', settingName: 'auth', settingData: auth})
                                    }
                                }

                                if (data.type === "merchant_create_init") {
                                    /* go to the sign up page. */
                                    console.log("create new merchant button: " + JSON.stringify(data));
                                    if (session_id !== undefined) {
                                    }
                                }
                            },
                        }
                    );
                }
            }
        )
    }, [])

    return (
        <CustomForm
            onFinish={handleSubmit}
            layout="vertical">
            <Item
                name="email"
                label="Email">
                <Input placeholder="Email Address" size="large"/>
            </Item>
            <Item
                name="password"
                label="Password">
                <Input.Password placeholder="Password" size="large"/>
            </Item>
            <Space
                style={{
                    width: "100%",
                    alignItems: "center",
                    justifyContent: "space-between"
                }}>
                <Item>
                    <Checkbox>Stay Signed In</Checkbox>
                </Item>
                <button className="forget-button">
                    Forget your password?
                </button>
            </Space>
            <Item>
                <Button
                    htmlType="submit"
                    type="primary"
                    block
                    size="large">
                    Sign In
                </Button>
            </Item>
            <Item style={{height: "35px", padding: "0px", marginBottom: "0px"}}>
                <Divider>
                    Signin with VEEV App
                </Divider>
            </Item>
            <div style={{textAlign: "center"}}>
                {
                    qrcode !== '' && <img id='barcode' width={150} src={qrcode} alt="generated QR Code"/>
                }
            </div>
            <div style={{textAlign: "center", marginBottom: "-20px"}}>
                <a
                    target="_blank"
                    href="https://apps.apple.com/co/app/veev-mobile/id1508649527?l=en">
                    <img
                        className="wallet"
                        width={120}
                        src="/assets/img/appstore.png"
                        // src={AppStoreLogo}
                        alt="VEEV App"
                    />
                </a>
            </div>
            <Item style={{height: "35px", padding: "0px", marginBottom: "0px"}}>
                <Divider>

                </Divider>
            </Item>
            <Item>
                <Button
                    onClick={() => {
                        setScreen("signup")
                    }}
                    style={{
                        border: "2px solid #e74c3c",
                        color: "#e74c3c",
                    }}
                    type="default"
                    block
                    size="large">
                    Create Account
                </Button>
            </Item>
            <p className="notify-text" style={{color: "darkgrey"}}>
                A one click secure signin/up for events, appointments, shipping, ordering and paying.
            </p>
        </CustomForm>
    );
};

const CustomForm = styled(Form)`

  .ant-form-item-label {
    display: block;
    padding-bottom: 0;
    padding-left: 6px;

    label {
      font-weight: 600;
    }
  }

  .ant-form-item {
    margin-top: 8px;
    margin-bottom: 8px;

    .ant-form-item-control-input-content {
      height: 50px;
      display: flex;
      align-items: center;

      span {
        height: 100%;

        .ant-checkbox-inner {
          height: 24px;
          width: 24px;
          display: flex;
          align-items: center;
          justify-content: center;

          &:after {
            top: 44%;
            inset-inline-start: 20.5%;
            width: 8.71429px;
            height: 16.1429px;
            border-width: 3px;
          }
        }
      }

      .ant-checkbox-wrapper {
        span {
          font-size: 16px;
          line-height: 1.8;
          font-weight: 400;
        }
      }
    }

    input {
      margin: 0;
      font-weight: 500;
      font-size: 16px;
      height: 100%;
    }
  }

  .ant-btn {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 14px;
    font-weight: 700;

    span {
      line-height: 1;
      display: block;
      font-size: 18px;
      text-transform: uppercase;
    }

    :focus {
      outline: none;
    }
  }

  .forget-button {
    font-size: 14px;
    padding: 0;
    color: #e74c3c;
    font-weight: 600;
    text-decoration: underline;
    margin-bottom: 6px;

    :focus {
      outline: none;
    }
  }

  .notify-text {
    text-align: center;
    font-size: 16px;
    margin-top: 20px;
    margin-bottom: -10px;
  }
`

export default LoginPopup;