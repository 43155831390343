import React, {Fragment, useEffect, useState} from 'react';
import Layout from "../../../layouts/dashboard";
import Tab from "../../../layouts/dashboard/components/checkin-tab";
import Sidebar from "./sidebar";
import {Avatar, Button, Checkbox, Col, Form, List, Modal, Row, Select, Skeleton, Space, Switch} from "antd";
import {Icon} from "@iconify/react";
import {useSetting} from "../../../provider/setting";
import {getMerchantApi} from "../../../api/api";

const { Item } = Form;
const { Option } = Select;

const SchedulingPage = () => {
  const [setting] = useSetting()
  const [merchant, setMerchant] = useState({});
  const [initLoading, setInitLoading] = useState(false);
  const [user, setUser] = useState({});
  const [hours, setHours] = useState(false);
  const [services, setServices] = useState(false);

  const hoursHandle = (u) => {
    setHours(true)
    setUser(u)
  }

  const servicesHandle = (u) => {
    setServices(true)
    setUser(u)
  }

  useEffect(() => {
    console.log(setting)

    getMerchantApi(setting?.auth?.signin_merchant_id)
      .then(({data}) => {
        setMerchant(data)
      })
      .catch((e) => {
        console.warn(e)
      })
  }, [setting?.auth])

  return (
    <Layout>
    <Tab/>  
      <div className="row">
        {/* <div className="col col-lg-3">
          <Sidebar/>
        </div> */}
        <div className="col col-lg-12">
          <h4>Floor Plan</h4>

          <p style={{fontsize: 40, textAlign: "center"}}>Coming</p> 
          <img src="../../assets/img/floorplan.png" width="100%"></img>
          
        </div>
        
      </div>
    </Layout>
  );
};

export default SchedulingPage;
