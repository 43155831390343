import React, {Fragment, useEffect, useState} from 'react';
import Layout from "../../../layouts/dashboard";
import Tab from "../../../layouts/dashboard/components/checkout-tab";
import {useNavigate} from "react-router-dom";
import {useSetting} from "../../../provider/setting";
import {deleteItemApi} from "../../../api/api";
import {Avatar, AutoComplete, Button, Col, Input, Row, Modal, Table} from "antd";
import {Icon} from "@iconify/react";
import {NotificationManager} from "react-notifications";
import { getAll } from '../../../api/item';
import { getAll as getAllCategories } from '../../../api/category';

const StoreItemsPage = () => {
  const { Search } = Input;
  const [setting] = useSetting();
  const navigate = useNavigate();
  const [modal, contextHolder] = Modal.useModal();
  const [items, setItems] = useState([]);
  const [keyworkd, setKeyword] = useState('')
  const [categories, setCategories] = useState([]);

  const getHandle = () => {
    if(setting?.auth?.signin_merchant_code) {
      getAll(setting?.auth?.signin_merchant_code)
        .then((data) => {
          setItems(data.map((i) => ({...i, key: i.id })));
        })
        .catch((e)=>{console.error(e, "ITEMS")})
    }
  }

  const deleteHandle = (item) => {
    modal.confirm({
      title: <h5>Delete Item!</h5>,
      icon: <Icon style={{width: 60, height: 60, color: "#cc0000"}} icon="ep:warn-triangle-filled" />,
      content: <p>Are you sure to delete <b>{item.name}</b> item?</p>,
      okText: 'Delete',
      cancelText: 'Cancel',
      okType: "danger",
      onOk: () => {
        console.log(item)
        deleteItemApi(setting?.auth?.signin_merchant_code, item.id)
          .then(() => {
            getHandle();
            NotificationManager.success("Successfully deleted!", "Success", 3000)
          })
          .catch((e)=>{
            console.warn(e);
            NotificationManager.error("An error was created. try again later!", "Error", 3000)
          })
      },
    });
  }

  const getCategoriesHandle = async () => {
    if (setting?.auth?.signin_merchant_code) {
        getAllCategories(setting.auth.signin_merchant_code)
            .then((data) => {
                setCategories(data);
            })
            .catch(e => console.warn(e))
    }
}

  const columns = [
    {
      title: "Items",
      width: '105',
      key: "id",
      render: (i) => {
        return (
          <Avatar size={100} shape="square" src={i.picture_url}/>
        )
      }
    },
    {
      title: "",
      // width: '65%',
      align: "left",
      key: "name",
      render: (i) => {
        return (
          <Fragment>
            <h4>{i.name}</h4>
            <p>{i.description}</p>
          </Fragment>
        )
      }
    },
    // {
    //   title: "Category", 
    //   width: 100,
    //   dataIndex: "category_id",
    //   key: "category"
    // },
    {
      title: 'Category',
      dataIndex: 'id',
      key: 'id',
      align: "left",
      width: 100,
      render: (i) => {
        let z = ""
        categories.map((category) => {
            if(JSON.parse(category.other || "[]").includes(i)) {
              z = category.name
            }
        })
        return z
      }
  },
    {
      title: "Price",
      width: 100,
      dataIndex: "price",
      key: "price",
      render: (i) => (`$${parseFloat(i).toFixed(2)}`)
    },
    {
      title: '',
      width: 100,
      align: "center",
      render: (i) => {
        return (
          <Fragment>
            <Button
              onClick={()=>{navigate(`/store/checkout/items/edit/?id=${i.id}`)}}
              className="icon-button"
              style={{marginRight: 6}}
              type="link" >
              <Icon color="#006000" icon="mdi:circle-edit-outline"/>
            </Button>
            <Button
              onClick={()=>{deleteHandle(i)}}
              className="icon-button"
              type="link"
              danger>
              <Icon icon="material-symbols:delete-outline-sharp"/>
            </Button>
          </Fragment>
        )
      }
    },
  ]

  useEffect(()=>{
    getHandle()
    getCategoriesHandle();
  }, [setting?.auth?.signin_merchant_code])

  return (
    <Layout>
    <Tab/>  
      <div className="row">
        {/* <div className="col col-lg-3">
          <Sidebar/>
        </div> */}
        <div className="col col-lg-12">
          <h4>Items</h4>
          <Row gutter={12} className="mb-4">
            <Col span={14}>
              <Search
                placeholder='Search Items'
                size="large"
                allowClear
                onSearch={setKeyword}/>
            </Col>
            <Col span={5}>
              
            </Col>
            <Col span={5}>
              <Button 
                block
                type="primary"
                size="large"
                onClick={()=>{navigate('/store/checkout/items/create')}}>
                + New Item
              </Button>
            </Col>
          </Row>
          <Table
            dataSource={items}
            columns={columns}
            pagination={{
              pageSize: 5
            }}
          />
        </div>
      </div>
      {contextHolder}
    </Layout>
  );
};

export default StoreItemsPage;
