import React, {useState} from 'react'
import styled from "styled-components";
import { NotificationManager } from "react-notifications";
import moment from "moment";
import dayjs from "dayjs";
import {
    Button,
    Card,
    DatePicker,
    Form,
    TimePicker,
    Select,
    Typography
} from "antd";
import { getServiceProvidersApi, createProviderBookingApi } from '../../api/api'; 
import TimeSlotGrid from '../TimeSlotGrid';


const { Title } = Typography;
const { Item, useForm } = Form;
const { Option } = Select;

const ReservationForm = ({merchant_id}) => {
    const [form] = useForm();
    const [loading, setLoading] = useState(false);
    const [selectedDate, setSelectedDate] = useState(dayjs().format('MM/DD/YYYY'));
    const [bookingSize, setBookingSize] = useState(2);

    const onFinish = () => {
    }

    return (
    <BookingCard className="bg-booking">
        <Form layout="vertical" form={form} onFinish={onFinish}>
        <Title
                level={5}
                align="center">
                <h2>Make Reservation</h2>
            </Title>
            <Item
                name="seats"
                label="Party Size"
                rules={[{ required: true }]}
            >
                <Select defaultValue={2} onChange={(e) => setBookingSize(e)}>
                    {
                       [...new Array(10)].map((_, index) => (
                        <Option value={index + 1} key={index}>
                            {index + 1} People
                        </Option>
                       ))
                    }
                </Select>
            </Item>
            <Item>
                <Item
                    name="date"
                    label="Date"
                    rules={[{ required: true }]}
                    style={{ display: "inline-block"}}
                >
                    <DatePicker
                            allowClear={false}
                            format="MM/DD/YYYY"
                            defaultValue={dayjs()}
                            onChange={(date, dateStr) => setSelectedDate(dateStr)}
                            disabledDate={current => {
                                const date = new Date();
                                date.setDate(date.getDate() - 1);
                                return current && current.valueOf() < date;
                            }}
                        />
                </Item>
            </Item>
            <Item>
                <TimeSlotGrid date={selectedDate} size={bookingSize} merchant_id={merchant_id} />
            </Item>
        </Form>
    </BookingCard>
    );
}

export default ReservationForm;

const BookingCard = styled(Card)`
    padding: 10px;
    border-radius: 5px!important;
    margin-top: 48px;
`;
