import React, {useEffect, useState} from 'react';
import { Button, Form, Input, Select } from 'antd';
import Header from "../../../components/header/authempty";
import Footer from "../../../components/footer/footerEmptyPage";
import Countdown from "../../../components/Countdown";

const { Option } = Select;

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

/* eslint-disable no-template-curly-in-string */
const validateMessages = {
    required: '${label} is required!',
    types: {
      email: '${label} is not a valid email!',
      number: '${label} is not a valid number!',
    },
  };

const ReservationForm = ({ submit }) => {
    const prefixSelector = (
        <Form.Item name="prefix" noStyle>
            <Select style={{ width: 70 }}>
                <Option value="1">+1</Option>
            </Select>
        </Form.Item>
    );

    return (
        <Form
            {...layout}
            name="nest-messages"
            onFinish={submit}
            style={{ maxWidth: 600 }}
            validateMessages={validateMessages}
            initialValues={{ prefix: '1' }}
        >
            <Form.Item name={['user', 'name']} label="Name" rules={[{ required: true }]}>
                <Input />
            </Form.Item>
            <Form.Item name={['user', 'email']} label="Email" rules={[{ required: true, type: 'email' }]}>
                <Input />
            </Form.Item>
            <Form.Item
                name={['user', 'phone']}
                label="Phone Number"
                rules={[{
                    required: true,
                    message: 'Please input a valid phone number!',
                    pattern: new RegExp(/^(\+\d{1,2}\s*)?\(?\d{3}\)?[\s.-]*\d{3}[\s.-]*\d{4}$/g)
                }]}
            >
                <Input addonBefore={prefixSelector} style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
                <Button type="primary" htmlType="submit">
                    Submit
                </Button>
            </Form.Item>
        </Form>
    )
}

const ReservationPage = () => {
    const params = new URLSearchParams(window.location.search);
    const slotHash = params.get('slotHash');
    const expireAt = params.get('expire_at');
    const date = params.get('date');

    useEffect(() => {
    }, [])

    const submit = async (values) => {
        const data = {
            slot_hash: slotHash,
            data: {...values.user, phone_prefix: values.prefix}
        }

        const response = await fetch(
            `https://veev-dev.herokuapp.com/api/reservations`, {
                method: "POST",
                headers: 
                {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data)
            }
        );

        if (response.ok) {
            console.log("success")
            window.location.href = `/reservation/success?name=${values.user.name}&date=${date}`
        }
    }

    return (
    <main id="container" className='store' style={{marginBottom: "100px", alignItems: 'normal'}}>
      <Header/>
        <article>
          <h1>Reservation</h1>
          <div>
            <span>This slot will be held for <Countdown deadline={ new Date(expireAt) }/></span>
          </div>
          <ReservationForm submit={submit} />
        </article>
      <Footer/>  
    </main>
    ); 
}

export default ReservationPage;
