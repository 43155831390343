import React, {useEffect, useState} from 'react';
import {useNavigate, useSearchParams} from "react-router-dom";
import styled from "styled-components";
import UserLayout from "../../layouts/user";
// import UserLayout from "../../layouts/dashboard";
import {Button, Col, Modal, Row, Typography} from "antd";

const { Title } = Typography;

const UserPage = () => {

  const navigate = useNavigate();
  const [searchParam, ] = useSearchParams();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    console.log(searchParam.get('new'));
    if (searchParam.get('new')) {
        setOpen(true);
    }
  }, []);

    return (
        <UserLayout>
          <Modal
              onCancel={()=>setOpen(false)}
              width={400}
              title={
                  <img
                      style={{width: 80, height: "auto", marginBottom: "3px"}}
                      alt="VEEV Logo"
                      src="/assets/img/veev_logo_red.png"
                      width={139}
                      height={41}
                  />
              }
              footer={false}
              centered
              open={open}>
              <MerchantDialog>
                <Title level={1} align="center">Do you want to Create Business Account</Title>
                <Row gutter={20}>
                    <Col span={12} align='center'>
                        <XLargeButton onClick={() => setOpen(false)}>No</XLargeButton>
                    </Col>
                    <Col span={12} align='center'>
                        <XLargeButton type="primary" onClick={() => navigate('/merchant/register')}>Yes</XLargeButton>
                    </Col>
                </Row>
              </MerchantDialog>
          </Modal>
          <p style={{fontsize: 40, marginTop: 100, textAlign: "center"}}>To access your Business Account click the profile image and select Business Account.</p>
          <img src="../../assets/img/helpbusinessaccount.png" style={{display: 'block', marginLeft: 'auto',
  marginRight: 'auto' }} width="23%"></img> 
          {/* <img src="../../assets/img/simpledesign.png" width="100%"></img> */}
        </UserLayout>
    );
};

const MerchantDialog = styled('div')`
    padding: 15px;
    min-height: 500px;
    display: flex;
    align-items: center;
    flex-direction: column;
    
    gap: 100px;
`;

const XLargeButton = styled(Button)`
    width: 100px;
    height: 80px;
    font-size: 35px;
    font-weight: bold;
`;

export default UserPage;