import React, {useState} from 'react';
import {Avatar, Button, Form, Input, Modal, Dropdown, Space} from "antd";
import {toast} from 'react-toastify';
import styled from "styled-components";
import {Styles} from '../../layouts/style/headerHomeStyle';
import {Link, useNavigate} from "react-router-dom";
import {Icon} from "@iconify/react";
import {getCookie, setCookie} from "../../service/cookie";
import {getUserMerchants, selectMerchant} from "../../api/merchant";
import {getProfile, updateProfile} from '../../api/auth';
import AuthPopup from "../auth/auth";

const { Item, useForm } = Form;

const AuthHeader = (props, initialKeyword) => {
    const navigate = useNavigate();
    const [profileModal, toggleProfileModal] = useState(false);
    const [form] = useForm();
    const username = getCookie("veev_current_user_username");
    const {Search} = Input;
    const {directory} = props;
    const isAuth = getCookie("current_ident_token");

    const [loading, setLoading] = useState(false);
    const [avatar, setAvatar] = useState('');

    const logoutHandle = () => {
        setCookie("signin_session_id", '', 0);
        setCookie("signin_merchant_code", '', 0);
        setCookie("signin_merchant_id", '', 0);
        setCookie("signin_merchant_user_id", '', 0);
        setCookie("signin_current_user_id", '', 0);
        setCookie("signin_merchant_name", '', 0);
        setCookie("current_ident_token", '', 0);
        setCookie("current_user_email", '', 0);
        setCookie("selected_merchant_address", '', 0);
        setCookie("current_merchant_wallet_address", '', 0);
        setCookie("selected_merchant_bool_cannabis_merchant", '', 0);
        setCookie("veev_session_id", '', 0);
        setCookie("veev_current_user_id", '', 0);
        setCookie("veev_current_user_email", '', 0);
        setCookie("veev_current_user_ident_token", '', 0);
        navigate("/");
    }

  const onSearch = async (keyword) => {
    navigate(`/search/${keyword}`);
  }

    const handleSelectBusiness = async () => {
        try {
            const current_ident_token = getCookie('current_ident_token');
            const sessionId = getCookie('signin_session_id');
            const merchants = await getUserMerchants(current_ident_token);
            if(merchants.length > 0) {
                selectMerchant(sessionId, merchants[0].id)
                    .then((res) => console.log(res))
                    .catch(error => console.log(error))
                navigate("/store/checkout/transactions")
            } else {
                navigate("/merchant/register")
            }
        } catch (e) {
            console.warn(e)
        }
    }

    const onAvatarPick = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onloadend = () => {
            setAvatar(reader.result);
        }
        reader.readAsDataURL(file);
    }

    const finishHandle = (values) => {
        setLoading(true);
    
        const data = {
          phone: values.phone,
          first_name: values.firstname,
          last_name: values.lastname,
          username: values.username,
          email: values.email
        };
    
        updateProfile(values.id, data)
          .then(res => {
            toast(res.message, {
              position: "top-left",
              type: "success"
            });
          })
          .catch(error => {
            toast(error.message, {
              position: "top-left",
              type: "error"
            });
          })
          .finally(() => {
            setLoading(false);
            toggleProfileModal(false);
          });
    };    

    const openProfileModal = async () => {
        getProfile(username)
        .then(({ profiles }) => {
            const profile = profiles[0];
            setAvatar(profile.picture_url);
            form.setFieldsValue({
                id: profile.id,
                phone: profile.phone,
                firstname: profile.first_name,
                lastname: profile.last_name,
                username: profile.handle
            });
            toggleProfileModal(true);
        })
        .catch(error => console.error(error))
    };

    const menuItems = [
        // {
        //     key: '1',
        //     label: (
        //         <Link to={`/user/${username}`}>
        //             <Space>
        //                 <Icon
        //                     style={{fontSize: 24, fontWeight: 700}}
        //                     icon="ph:user"/>
        //                 <h5 style={{fontSize: 16, fontWeight: 700}}>
        //                     User Account
        //                 </h5>
        //             </Space>
        //         </Link>
        //     ),
        // },
        // {
        //     key: '2',
        //     label: (
        //         <a onClick={openProfileModal} >
        //             <Space>
        //                 <Icon
        //                     style={{fontSize: 24, fontWeight: 700}}
        //                     icon="carbon:settings"/>
        //                 <h5 style={{fontSize: 16, fontWeight: 700}}>
        //                     Profile Update
        //                 </h5>
        //             </Space>
        //         </a>
        //     ),
        // },
        {
            key: '3',
            label: (
                <a onClick={handleSelectBusiness} >
                    <Space>
                        <Icon
                            style={{fontSize: 24, fontWeight: 700}}
                            icon="ic:outline-local-grocery-store"/>
                        <h5 style={{fontSize: 16, fontWeight: 700}}>
                            Business Account
                        </h5>
                    </Space>
                </a>
            ),
        },
        {
            key: '4',
            label: (
                <a onClick={logoutHandle}>
                    <Space>
                        <Icon
                            style={{fontSize: 24, fontWeight: 700}}
                            icon="teenyicons:logout-outline"/>
                        <h5 style={{fontSize: 16, fontWeight: 700}}>
                            Logout
                        </h5>
                    </Space>
                </a>
            ),
        },
    ];

    return (
      // <Styles>
      //       <nav className="navbar navbar-expand-lg navbar-light fixed-top header" style={{zIndex: 999}} id="mainNav">
      //           <div className="container">
      //               <Link
      //                   className="navbar-brand js-scroll-trigger"
      //                   to="#">
      //                   <img
      //                       src="/assets/img/veev_logo_red.png"
      //                       height="25"
      //                       width="*"
      //                       alt='logo-red'
      //                   />
      //               </Link>

      //               {/* mobile */}
      //               <div className="navbar-toggler navbar-light" id="navbarResponsive">
      //                   <Search
      //                       style={{width: '100%', height: 36, padding: 0}}
      //                       placeholder="Search reviews for restaurants, hair, beauty spas, etc"
      //                       allowClear
      //                       size="large"
      //                       onSearch={onSearch}
      //                   />  
      //                   <ul>
      //                       <li className="nav-item">
      //                           {
      //                               isAuth ? (
      //                                   <Dropdown menu={{items: menuItems}} placement="bottomRight">
      //                                       <Avatar
      //                                           size={42}
      //                                           src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png">
      //                                       </Avatar>
      //                                   </Dropdown>
      //                               ) : (
      //                                  <AuthPopup />
      //                               )
      //                           }
      //                       </li>
      //                   </ul>
      //               </div>

      //               {/* web */}
      //               <div className="collapse navbar-collapse" id="navbarResponsive"> 
      //                   <Search
      //                       style={{width: 500, height: 36, padding: 0}}
      //                       placeholder="Search reviews for restaurants, hair, beauty spas, etc"
      //                       allowClear
      //                       size="large"
      //                       onSearch={onSearch}
      //                   />  
      //                   <ul className="navbar-nav ml-auto" style={{height: '40px'}}>
      //                       <li>
      //                           <Link className={`nav-link ${directory === 'app' ? 'active' : ''}`} to="/app"
      //                                 id="">App</Link>
      //                       </li>
      //                       <li className="nav-item">
      //                           {
      //                               isAuth ? (
      //                                   <Dropdown menu={{items: menuItems}} placement="bottomRight">
      //                                       <Avatar
      //                                           size={42}
      //                                           src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png">
      //                                       </Avatar>
      //                                   </Dropdown>
      //                               ) : (
      //                                  <AuthPopup />
      //                               )
      //                           }
      //                       </li>
      //                   </ul>
      //               </div>
      //           </div>
      //       </nav>
      //   </Styles>

        <AuthHeaderWrap>
            <div className="container">
                <div className="header-wrap">
                    <h1 className="logo">
                        <Link className="navbar-brand js-scroll-trigger" to="#">
                            <img src="/assets/img/veev_logo_red.png"                                                               height="23" width="*"/></Link>
                    </h1>
                      <Search
                            style={{width: 500, height: 36, padding: 0}}
                            placeholder="Search reviews for restaurants, hair, beauty spas, etc"
                            allowClear
                            size="large"
                            onSearch={onSearch}
                        />  
                    <nav>
                        <ul>
                            <li><Link to="/app">App</Link></li>
                            <Dropdown menu={{items: menuItems}} placement="bottomRight">
                                <Avatar
                                    size={42}
                                    src="">
                                    <Icon style={{marginTop: 8}} width={24} height={24} icon="fa:user"/>
                                </Avatar>
                            </Dropdown>
                        </ul>
                    </nav>
                </div>
            </div>
            <Modal
                title={
                <p style={{textAlign: "center"}}>Account Profile</p>
                }
                open={profileModal}
                footer={null}
                centered
                width={400}
                onCancel={()=>toggleProfileModal(false)}
            >
                <FormWrap>
                    <CustomForm
                        form={form}
                        onFinish={finishHandle}
                        layout="vertical">
                        <div className="upload-row">
                            <label className="upload_label" htmlFor="file">
                                <Avatar
                                    src={avatar}
                                    size={120}>
                                    <Icon width={70} height={70} icon="fa:user"/>
                                </Avatar>
                                <input id="file" name="avatar" type="file" onChange={onAvatarPick}/>
                            </label>
                        </div>
                        <Item
                        hidden
                        name="id"
                        >
                        <Input hidden />
                        </Item>
                        <Item
                            name="phone"
                            label="Phone Number">
                            <Input placeholder="Enter Phone Number" size="large"/>
                        </Item>

                        <Item
                            name="email"
                            label="Email">
                            <Input placeholder="@ Your Email Address" size="large"/>
                        </Item>

                        <Item
                            name="firstname"
                            label="First Name">
                            <Input placeholder="@ Your Firstname" size="large"/>
                        </Item>

                        <Item
                            name="lastname"
                            label="Last Name">
                            <Input placeholder="@ Your Lastname" size="large"/>
                        </Item>

                        <Item
                            name="username"
                            label="User Name">
                            <Input placeholder="@ Your Username" size="large"/>
                        </Item>

                        <Item>
                            <Button
                                htmlType="submit"
                                type="primary"
                                block
                                loading={loading}
                                size="large">
                                Update Profile
                            </Button>
                        </Item>
                    </CustomForm>
                </FormWrap>
            </Modal>
        </AuthHeaderWrap>
    );
};

export const AuthHeaderWrap = styled.div`
  background-color: #ffffff;
  height: 62px;
  position: sticky;
  top: 0;

  .container {
    height: 100%;

    .header-wrap {
      display: flex;
      align-items: center;
      height: 100%;
      justify-content: space-between;

      h1 {
        line-height: 1;
        margin-bottom: 0;
        margin-top: 0;
        color: #cc0000;
        font-size: 1.5rem;
        font-weight: 200;
        letter-spacing: 1px;
        font-family: Catamaran, Helvetica, Arial, sans-serif;

        a {
          color: #cc0000;
          text-decoration: none;
        }
      }

      nav {
        ul {
          display: flex;
          align-items: center;
          margin-bottom: 0;
          grid-gap: 42px;

          li {
            list-style: none;
            margin-bottom: 0;
            line-height: 1;

            a {
              font-size: 1rem;
              line-height: 1.5;
              color: #000000;
              text-decoration: none;
              font-family: Lato, Helvetica, Arial, sans-serif;
              letter-spacing: 2px;
              touch-action: manipulation;
            }
          }
        }
      }
    }
  }
`;

const FormWrap = styled.div`
  padding-top: 20px;
  max-width: 430px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 20px;

  .ant-avatar-string {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      margin-top: 22px;
    }
  }

  .upload-row {
    text-align: center;
  }

  .upload_label {
    display: inline-block;
    margin-bottom: 24px;
    margin-left: auto;
    margin-right: auto;

    input {
      display: none;
    }
  }

  .ant-input {
    height: 50px;
  }
`

const CustomForm = styled(Form)`
  .ant-form-item-label {
    display: block;
    padding-bottom: 0;
    padding-left: 6px;

    label {
      font-weight: 600;
    }
  }

  .checkbox-wrap {
    padding-top: 12px;
    margin-bottom: 12px;
    display: flex;
    flex-direction: column;
    gap: 12px;

    span {
      height: 100%;

      .ant-checkbox-inner {
        height: 24px;
        width: 24px;
        display: flex;
        align-items: center;
        justify-content: center;

        &:after {
          top: 44%;
          inset-inline-start: 20.5%;
          width: 8.71429px;
          height: 16.1429px;
          border-width: 3px;
        }
      }
    }

    .ant-checkbox-wrapper {
      span {
        font-size: 16px;
        line-height: 1.8;
        font-weight: 400;
      }
    }
  }

  .ant-form-item {
    margin-top: 8px;
    margin-bottom: 8px;

    .ant-form-item-control-input-content {
      height: 50px;
      display: flex;
      align-items: center;

      span {
        height: 100%;

        .ant-checkbox-inner {
          height: 24px;
          width: 24px;
          display: flex;
          align-items: center;
          justify-content: center;

          &:after {
            top: 44%;
            inset-inline-start: 20.5%;
            width: 8.71429px;
            height: 16.1429px;
            border-width: 3px;
          }
        }
      }

      .ant-checkbox-wrapper {
        span {
          font-size: 16px;
          line-height: 1.8;
          font-weight: 400;
        }
      }
    }

    input {
      margin: 0;
      font-weight: 500;
      font-size: 16px;
      height: 100%;
    }
  }

  .ant-btn {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 14px;
    font-weight: 700;
    margin-top: 50px;
    
    span {
      line-height: 1;
      display: block;
      font-size: 18px;
      text-transform: uppercase;
    }

    :focus {
      outline: none;
    }
  }

  .forget-button {
    font-size: 14px;
    padding: 0;
    color: #e74c3c;
    font-weight: 600;
    text-decoration: underline;
    margin-bottom: 6px;

    :focus {
      outline: none;
    }
  }

  .notify-text {
    text-align: center;
    font-size: 16px;
    margin-top: 20px;
    margin-bottom: -10px;
  }
`;

export default AuthHeader;
