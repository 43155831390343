import React, { useEffect, useState } from 'react'
import { NotificationManager } from 'react-notifications';
import Layout from "../../../../layouts/dashboard";
import CRMTab from "../../../../layouts/dashboard/components/crm-tab";
import ProfileSidebar from "./sidebar";
import { useAsync } from "../../../../service/utils";
import { useSetting } from "../../../../provider/setting";
import { sendAnnouncement, getAllWithMerchantId as getGroups } from "../../../../api/groups";

const Notification = () => {
  const { data, status, error, run } = useAsync({
    status: "idle",
  });
  const [setting] = useSetting()
  const [notification, setNotification] = useState('')
  const [groupId, setGroupId] = useState('')
  const [asyncState, setAsyncState] = useState('')
  
  const handleSubmit = () => {
    run(sendAnnouncement({ group_id: groupId, msg: notification, user_id: setting?.auth?.signin_current_user_id }))
    setAsyncState('sendNotification')
  }

  useEffect(() => {
    if (setting?.auth) {
      run(getGroups(setting?.auth?.signin_merchant_id));
      setAsyncState('getGroup')
    }
  }, [run, setting?.auth])
  useEffect(() => {
    if (status === 'resolved') {
      if (asyncState === 'getGroup') {
        setGroupId(data?.group?.id)
      }
      if (asyncState === 'sendNotification') {
        NotificationManager.success('Send successfully', 'Success', 3000)
      }
    }
    else if (status === 'rejected') {
      console.log(error)
    }
  }, [status])
  return (
    <>
      <Layout>
      <CRMTab/>  
      <div className="row">
        <div className="col col-lg-2">
          <ProfileSidebar/>
        </div>
        <div className='col col-lg-10'>
        <h4>Send Notifications</h4>
        <div className="row" id="id_sec_group_announce">
            <div className="col-md-12 form-group">
              <fieldset>
                <label htmlFor="" className="ak-is-overlaid cursor">Notifications will be sent to all members following this group.</label>
                <input type="text" placeholder="Enter Notification Message" maxLength="255" className="form-control mo-userfield-input ak-has-overlay zip-format" required="" value={notification} onChange={(e) => setNotification(e.target.value)} />
              </fieldset>
            </div>
            <div className="col-md-10"></div>
            <div className="col-md-2 section-heading text-center save-button">
              <button type="button" className="btn2 btn2-outline js-scroll-trigger form-control" onClick={handleSubmit}> Send </button>
            </div>
          </div>
        </div>
      </div>
    </Layout>
    </>
  );
}
export default Notification
