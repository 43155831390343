import React, { useEffect, useState} from 'react';
import {
  Avatar,
  Button,
  Checkbox,
  Col,
  Input,
  Form,
  InputNumber,
  List,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Typography,
  Switch,
  Upload,
} from "antd";
import { duration } from "moment";
import Layout from "../../../layouts/dashboard";
import Tab from "../../../layouts/dashboard/components/checkin-tab";
import {useSetting} from "../../../provider/setting";
import { getBase64 } from "../../../service/string"
import {
    createProviderApi,
    getProvidersApi,
    getProviderApi,
    upsertProviderWorkdayApi,
    updateProviderApi,
    getProviderServicesApi,
    upsertProviderServicesApi,
} from '../../../api/api';
import { getAll } from '../../../api/item';

import { getCookie } from '../../../service/cookie';

const { Item, useForm, useWatch } = Form;
const { Option } = Select;
const { Title } = Typography;
const { Search } = Input;

const defaultDailyOpenSchedule = { open: 9, close: 17, active: false};
const defaultWeekOpenSchedule = {
    monday: defaultDailyOpenSchedule,
    tuesday: defaultDailyOpenSchedule,
    wednesday: defaultDailyOpenSchedule,
    thursday: defaultDailyOpenSchedule,
    friday: defaultDailyOpenSchedule,
    saturday: defaultDailyOpenSchedule,
    sunday: defaultDailyOpenSchedule,
}

const SchedulingPage = () => {
  const [setting] = useSetting()
  const [loading, setLoading] = useState(false);

  const [keyworkd, setKeyword] = useState('')
  const [providers, setProviders] = useState([]);
  const [providerOpen, setProviderOpen] = useState(false);
  const [providerActiveLoading, setProviderActiveLoading] = useState(null);
  
  const [openWorkdayPopup, setOpenWorkdayPopup] = useState(false);
  const [workday, setWorkday] = useState(null);
  const [workdayLoading, setWorkdayLoading] = useState(null);
  
  const [services, setServices] = useState([]);
  const [openServicesPopup, setOpenServicesPopup] = useState(null);
  const [servicesLoading, setServicesLoading] = useState(null);
  
  const [items, setItems] = useState([]);
  const [type, setType] = useState("table");

  const onCreateProvider = ({ avatar, type, name, active, file }) => {
    // setLoading(true);
    // createProviderApi({ avatar, type, name, active })
    // .then(({ provider }) => {
    //     setProviders([...providers, provider]);
    //     setProviderOpen(false);
    // })
    // .finally(() => {
    //     setLoading(false)
    // })
  };

  const toggleWorkdayModal = () => {
    setOpenWorkdayPopup(!openWorkdayPopup);
  }

    const openWorkdayModal = (id) => {

        setWorkdayLoading(id);
        if (id === null) {
            setWorkday({ type: "table", provider: id });
        } else {
            const selectedService = providers.find((service) => {
                return service.id === id
            })
            setWorkday({ name: selectedService.name, capacity: selectedService.capacity, duration: selectedService.duration, hours: selectedService.hours, type: "table", provider: id });
        }
        toggleWorkdayModal();
        setWorkdayLoading(null);
    }

  const onSaveWorkdaySave = (data) => {
    setWorkdayLoading(true);


      const email = getCookie("current_user_email");
      const token = getCookie("current_ident_token");

      if (email === undefined || token === undefined) { return }


      let hours = Object.fromEntries(Object.entries(data).filter(([key, _data]) => {
          return ((_data !== undefined && _data !== null) && (typeof _data === 'object') && _data.active)
      }))
      let serialized_hours = Object.fromEntries(Object.entries(hours).map(([key, _data]) => {
            let opendate = new Date();
            opendate.setHours(_data.open)
            opendate.setMinutes(0);
            let closedate = new Date();
            closedate.setHours(_data.close)
            closedate.setMinutes(0);
            return [key, { 
                open: opendate.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }),
                close: closedate.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }), 
            }]
      }))


      const tzInfo = Intl.DateTimeFormat().resolvedOptions().timeZone;
      let params = {
          merchant_id: setting?.auth?.signin_merchant_id,
          name: data.name,
          capacity: data.capacity,
          duration: data.duration,
          hours: serialized_hours,
          timezone: tzInfo
        }


      if (data.id !== null && data.id !== undefined) {
          fetch(process.env.REACT_APP_API_URL + `/reservation_providers/${data.id}`, {
              method: 'PUT',
              headers: {
                  "Accept": "application/json",
                  "Content-Type": "application/json",
                  "X-User-Email": email,
                  "X-User-Token": token
              },
              body: JSON.stringify(params)
          })
          .catch((e) => console.error(e))
          .then((data) => {
              console.log(data)
          })
              .then((res) => res.json())
              .catch((e) => console.error(e))
              .then((data) => {
                  console.log(data)
                  fetchServices(setting?.auth?.signin_merchant_id)
              })
              .finally(() => {
                  setWorkdayLoading(null);
                  toggleWorkdayModal();
              })
          return
      }

      fetch(process.env.REACT_APP_API_URL + `/reservation_providers/${data.id}`, {
        method: 'POST',
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "X-User-Email": email,
            "X-User-Token": token
        },
        body: JSON.stringify(params)
      })
          .then((res) => res.json())
          .catch((e) => console.error(e))
          .then((data) => {
              console.log(data)
              fetchServices(setting?.auth?.signin_merchant_id)
          })
          .finally(() => {
              setWorkdayLoading(null);
              toggleWorkdayModal();
          })
  }

  const toggleServicesModal = () => {
    setOpenServicesPopup(!openServicesPopup);
    setServices([]);
  }

  const openServiceModal = (provider) => {
    setServicesLoading(provider.id);
    getProviderServicesApi(provider.id)
    .then(({services}) => {
        const result = items.map((item) => {
            const service = services.find((service) => service.itemId == item.id);
            if (service) {
                return {...item, active: true, deposit: service.deposit, duration: service.duration}
            }
            return item;
        });

        setServices(result);
        setOpenServicesPopup(provider.id);
    })
    .catch(e => console.log(e))
    .finally(() => setServicesLoading(null))
  }

  const activeHandle = (item, active) => {
    const data = { ...item, active};

    const email = getCookie("current_user_email");
    const token = getCookie("current_ident_token");

    fetch(process.env.REACT_APP_API_URL + `/reservation_providers/${data.id}`, {
        method: 'PUT',
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "X-User-Email": email,
            "X-User-Token": token
        },
        body: JSON.stringify(data) 
    })
    .catch(e => console.log(e))
    .then((res) => res.json())
    .catch(e => console.log(e))
    .then((data) => {
        console.log(data)
        fetchServices(setting?.auth?.signin_merchant_id)
    })
    .finally(() => setProviderActiveLoading(null));
  }

  const onSaveServices = (selectedServices) => {
    const svcs = selectedServices.map(({ id, deposit, duration, active }) => 
        ({itemId: id, providerId: openServicesPopup, deposit: deposit || 0, duration, active }));
    setServicesLoading(true);
    upsertProviderServicesApi({ providerId: openServicesPopup, services: svcs })
    .then((res) => {
        setOpenServicesPopup(null);
    })
    .catch(e => console.log(e))
    .finally(() => {
        setServicesLoading(false);
        
    });
  }

  useEffect(() => {
    if(setting?.auth?.signin_merchant_code) {
        getAll(setting?.auth?.signin_merchant_code)
          .then((data) => {
            setItems(data.map((i) => ({...i, key: i.id })));
          })
          .catch((e)=>{console.error(e, "ITEMS")})
      }
  }, [setting?.auth])

  const fetchServices = (mid) => {
      const merchant_id = mid;
        const email = getCookie("current_user_email");
        const token = getCookie("current_ident_token");

        fetch(process.env.REACT_APP_API_URL + `/reservation_providers?merchant_id=${merchant_id}`, {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "X-User-Email": email,
                "X-User-Token": token
            }
        })
        .then((res) => res.json())
        .catch((e) => console.error(e))
        .then((data) => {
            const services = data.services || [];
            const norm_services = services.map((service) => {
                return {
                    id: service.id,
                    name: service.name,
                    active: service.enabled || false,
                    duration: service.duration || "",
                    capacity: service.capacity || "",
                    hours: service.business_hours || {}
                }
            })
            setProviders(norm_services);
        })
        .finally(() => setLoading(false));
  }

  useEffect(() => {
    setLoading(true);

    if (setting?.auth?.signin_merchant_id) {
        fetchServices(setting?.auth?.signin_merchant_id)
    }
  }, [setting?.auth?.signin_merchant_id])
console.log(type)
  return (
    <Layout>
        <Tab/>  
        <Row gutter={10}>
            <Col lg={16}>
                {/* <Title level={4}>Setup</Title> */}

                <Row gutter={12} className="mb-4">
                    <Col span={21}>
                        <Search
                            placeholder='Search'
                            size="large"
                            allowClear
                            onSearch={setKeyword}/>
                    </Col>
                </Row>
                <List
                    loading={loading}
                    itemLayout="horizontal"
                    dataSource={providers}
                    renderItem={(item) => (
                    <List.Item actions={[
                        <Space direction="vertical">
                            <Typography>Active</Typography>
                            <Switch
                                onChange={(checked)=>{activeHandle(item, checked)}}
                                checked={item.active}
                                loading={providerActiveLoading == item.id}
                            />
                        </Space>
                    ]}>
                        <>
                            <List.Item.Meta
                                avatar={<Avatar size={100} shape="square" src={item.avatar}>{item.name}</Avatar>}
                                title={<Title level={3}>{item.name}</Title>}
                                description={
                                    item.type == "table" ? `${item.seats} seats`: item.name
                                }
                            />
                            <Space direction="vertical">
                                <Button
                                    onClick={()=>openWorkdayModal(item.id)}
                                    disabled={!item.active}
                                    ghost
                                    block
                                    type="primary"
                                    loading={workdayLoading == item.id}
                                >
                                    Edit
                                </Button>
                                {
                                    item.type == "person" &&
                                    <Button
                                        onClick={() => openServiceModal(item)}
                                        disabled={!item.active}
                                        ghost
                                        block
                                        type="primary"
                                        loading={servicesLoading == item.id}
                                    >
                                        Services
                                    </Button>
                                }
                            </Space>
                        </>
                    </List.Item>
                    )}
                />
            </Col>
            <Col lg={7} offset={1} style={{textAlign: 'center', backgroundColor: '#eeeeee', borderRadius: '10px'}}>
                <Title level={4}>Checkin Setup</Title>
                <Radio.Group onChange={({target}) => setType(target.value)} defaultValue={type}>
                    <Space direction="horizontal">
                        {/* <Radio value="person">Appointments</Radio> */}
                        {/* <Radio value="table">Reservations</Radio> */}
                    </Space>
                    <Space direction="horizontal" style={{paddingTop: '20px', paddingBottom: '20px'}}>
                        <Button 
                            block
                            type="primary"
                            size="large"
                            onClick={() => toggleWorkdayModal()}>
                            Add Table
                        </Button>  
                    </Space>
                </Radio.Group>
                {/* {
                    type === "person" ?
                        <AppointmentForm items={[]} />
                        : <ReservationForm />
                } */}
                <p style={{padding: '10px'}}>To activate click Settings > Activations > Checkins > Activate Bookings.
                </p>
            </Col>
        </Row>
        <WorkdayPopup
            open={openWorkdayPopup}
            onClose={toggleWorkdayModal}
            data={workday}
            onSave={onSaveWorkdaySave}
            loading={workdayLoading}
          />
        <ProductPopup
          open={!!openServicesPopup}
          onClose={() => toggleServicesModal(null)}
          loading={servicesLoading}
          services={services}
          onSave={onSaveServices}
        />
        <ProviderCreate
          open={providerOpen}
          onClose={() => setProviderOpen(false)}
          onSave={onCreateProvider}
          loading={loading}
          type={type}
         />
    </Layout>
  );
};

const WorkdayPopup = ({ open, onClose, loading, data, onSave }) => {
    const defaultData = data || {hours: {}}

    let hours = {}
    try {
        hours = Object.fromEntries(Object.entries(defaultData.hours).map(([key, _data]) => {
            let open = _data.open.split(" ")[1].toUpperCase() === "PM" && (parseInt(_data.open) < 12)
                ? (parseInt(_data.open) + 12)
                : (parseInt(_data.open));
            let close = _data.close.split(" ")[1].toUpperCase() === "PM" && (parseInt(_data.close) < 12)
                ? (parseInt(_data.close) + 12)
                : (parseInt(_data.close));
            return [key, { open, close, active: true }];
        }))
    } catch(e) {
        console.info(null)
    }

    delete defaultData.hours;

    const workday = {
        ...defaultWeekOpenSchedule,
        ...defaultData,
        ...hours,
    }

    delete workday.duration;
    delete workday.capacity;
    delete workday.id;
    delete workday.provider;
    delete workday.type;

    const onFinish = (values) => {
        console.log(values)

        onSave({ ...values, id: data ? data.id : null });
    }
    
    return (
    <Modal
        centered
        width={600}
        onCancel={onClose}
        title={<Title level={4}>Service Info</Title>}
        open={open}
        confirmLoading={loading}
        destroyOnClose={true}
        footer={null}
    >
        <Form
            initialValues={workday}
            onFinish={onFinish}
        >
            <Space direction="vertical">
                    {
                        <>
                        <Item
                            label="Name: "
                            name="name"
                            rules={[{ required: true }]}
                    >
                                <Input placeholder="Table" defaultValue={defaultData.table}/>
                        </Item>


                            <Item
                                label="Seats"
                                name="capacity"
                                rules={[
                                    { required: true }
                                ]}
                            >
                                <InputNumber placeholder="2" defaultValue={defaultData.capacity}/>
                            </Item>
                            <Item
                                label="Duration (mins)"
                                name="duration"
                                rules={[
                                    { required: true }
                                ]}
                            >
                                <InputNumber placeholder="60" defaultValue={defaultData.duration}/>
                            </Item>
                        </>
                    }
                </Space>
                <h3>WorkDays & Hours</h3>
            <Row
                gutter={12}
                style={{ marginBottom: "10px"}}
            >
                <Col span={data?.type === "table" ? 4 : 5} offset={5}>
                    <Title level={5} align="center">Status</Title>
                </Col>
                <Col span={data?.type === "table" ? 5 : 7}>
                    <Title level={5} align="center">Open Time</Title>
                </Col>
                <Col span={data?.type === "table" ? 5 : 7}>
                    <Title level={5} align="center">Close Time</Title>
                </Col>

            </Row>
            {
                Object.keys(workday).map((key, index) => (
                <Row
                    gutter={12}
                    key={index}
                >
                    <Col span={5}>
                        <Title
                            style={{ textTransform: "capitalize"}}
                            level={5}
                        >
                            {key} :
                        </Title>
                    </Col>
                    <Col span={data?.type === "table" ? 4 : 5}>
                        <Item
                            name={[key, "active"]}
                            valuePropName="checked"
                            style={{ textAlign: "center" }}
                        >
                            <Checkbox checked/>
                        </Item>
                    </Col>
                    <Col span={data?.type === "table" ? 5 : 7}>
                        <Item name={[key, "open"]}>
                            <Select defaultValue={workday[key].open}>
                                {
                                    [...Array(24)].map((i, index) => (
                                        <Option
                                            key={index}
                                            value={index}>
                                            {index < 10 && "0"}{index}:00
                                        </Option>
                                    ))
                                }
                            </Select>
                        </Item>
                    </Col>
                    <Col span={data?.type === "table" ? 5 : 7}>
                        <Item name={[key, "close"]}>
                            <Select defaultValue={workday[key].close}>
                                {
                                    [...Array(24)].map((i, index) => (
                                        <Option
                                            key={index}
                                            value={index}>
                                            {index < 10 && "0"}{index}:00
                                        </Option>
                                    ))
                                }
                            </Select>
                        </Item>
                    </Col>
                </Row>
                ))
            }
            <Space
                style={{
                    width: "100%",
                    justifyContent: "end"
                }}
            >
                <Button loading={loading}>Cancel</Button>
                {" "}
                <Button
                    type='primary'
                    htmlType="submit"
                    loading={loading}
                >
                    OK
                </Button>
            </Space>
            <Space>
                    <Row
                        gutter={12}
                        style={{ marginTop: "10px", marginBottom: "10px" }}
                    >
                        <i>Note: Updates will not affect previously made reservations</i>
                    </Row>
            </Space>
        </Form>
    </Modal>
    )
}

const ProductPopup = ({ open, onClose, loading, services, onSave }) => {
    const onFinish = (values) => {
        onSave(values.services);
    }
    return (
    <Modal
        width={1000}
        height={600}
        centered
        onCancel={onClose}
        title={<Title level={4}>Appointment Services</Title>}
        open={open}
        confirmLoading={loading}
        footer={null}
        destroyOnClose={true}
    >
        <Form
            initialValues={{services}}
            onFinish={onFinish}
            preserve={false}
        >
            <Row
                align="middle"
                gutter={[32, 12]}
            >
                <Col span={12}>
                    <Title level={5}>
                        Products / Services for Sale
                    </Title>
                </Col>
                <Col span={4}>
                    <Title level={5} align="center">
                        Active
                    </Title>
                </Col>
                <Col span={4}>
                    <Title level={5} align="center">
                        Deposit
                    </Title>
                </Col>
                <Col span={4}>
                    <Title level={5} align="center">
                        Duration
                    </Title>
                </Col>
            </Row>
            <Form.List name="services">
                {
                    (svcs) => (
                        <>
                        {
                            svcs.map((item, index) => (
                            <Row
                                key={index}
                                align="middle"
                                gutter={[32, 12]}
                                style={{ paddingBottom: 20}}
                            >
                                <Col span={12}>
                                    <Space size={24}>
                                        <Avatar
                                            size={80}
                                            shape="square"
                                            src={services[item.name].picture_url}
                                        />
                                        <div>
                                            <Title level={5}>{services[item.name].name}</Title>
                                            <Typography>{services[item.name].description}</Typography>
                                        </div>
                                    </Space>
                                    <Item
                                        hidden={true}
                                        name={[index, "id"]}
                                    >
                                        <Input />
                                    </Item>
                                </Col>
                                <Col span={4}>
                                    <Item
                                        name={[index, "active"]}
                                        valuePropName="checked"
                                        style={{ textAlign: "center" }}
                                    >
                                        <Checkbox />
                                    </Item>
                                </Col>
                                <Col span={4}>
                                    <Item name={[index, "deposit"]}>
                                        <InputNumber
                                            style={{width: "100%"}}
                                            addonAfter="%"
                                            min={0}
                                        />
                                    </Item>
                                </Col>
                                <Col span={4}>
                                    <Item name={[index, "duration"]}>
                                        <Select>
                                            {
                                                [...Array(10)].map((i, index) => {
                                                    const a = duration((index + 1) * 30, "minutes");
                                                    const hours = a.hours();
                                                    const minutes = a.minutes();
                                                    return (
                                                    <Option
                                                        key={index}
                                                        value={a.asMinutes()}
                                                    >
                                                        0{hours}:{minutes==0 && "0"}{minutes}
                                                    </Option>
                                                    );
                                                })
                                            }
                                        </Select>
                                    </Item>
                                </Col>
                            </Row>
                            )) 
                        }
                        </>
                    )
                }
            </Form.List>
            <Space
                style={{
                    width: "100%",
                    justifyContent: "end"
                }}
            >
                <Button loading={loading}>Cancel</Button>
                {" "}
                <Button
                    type='primary'
                    htmlType="submit"
                    loading={loading}
                >
                    OK
                </Button>
            </Space>
        </Form>
    </Modal>
    )
}

const ProviderCreate = ({ open, onClose, onSave, loading, type }) => {
    const [form] = useForm();
    const [image, setImage] = useState("");


  const onFinish = (values) => {
    onSave({ ...values, type, active: true, avatar: image });
  }

  const onDestroy = () => {
    form.resetFields();
    setImage("");
  }

  return (
    <Modal
        title="Select Booking Type"
        open={open}
        centered
        onCancel={onClose}
        afterClose={onDestroy}
        onOk={() => form.submit()}
        confirmLoading={loading}
        okText="Add"
        width={400}
    >
        <Form
            form={form}
            onFinish={onFinish}
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
            initialValues={{ type: "person" }}
        >
            <Space
                align="space-between"
                size="large"
                style={{ width: "100%" }}
            >
                <Space direction="vertical">
                    {
                        type == "person" &&
                        <Item
                            label="Name: "
                            name="name"
                            rules={[{ required: true }]}
                    >
                        <Input placeholder="Name of Provider"/>
                        </Item>
                    }
                    {
                        type == "table" &&
                        <>
                            <Item
                                label="Table #"
                                name="name"
                                rules={[
                                    { required: true }
                                ]}
                            >
                                <Input placeholder="Table #"/>
                            </Item>
                            <Item
                                label="Seats"
                                name="seats"
                                rules={[
                                    { required: true }
                                ]}
                            >
                                <InputNumber />
                            </Item>
                        </>
                    }
                </Space>
                {
                    type === "person" &&
                    <Item
                        name="avatar"
                        rules={[{ required: true }]}
                    >
                        <Upload
                            listType="picture-card"
                            className="avatar-uploader w-25"
                            showUploadList={false}
                            beforeUpload={(file) => {
                                getBase64(file, (url) => {
                                    setImage(url);
                                });
                                return false;
                            }}
                            >
                                {
                                    image 
                                    ? <img src={image} alt="avatar" width="100%" height="100%" /> 
                                    : <div className='p-2'>Upload Image</div>
                                }
                        </Upload>
                    </Item>
                }
            </Space>
        </Form>
    </Modal>
  );
};

export default SchedulingPage;
