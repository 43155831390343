export default {
  apiUrl: process.env.REACT_APP_API_URL + '/' || "https://veev-dev.herokuapp.com/api/",
  webSocketUrl: process.env.REACT_APP_WS_URL || "wss://veev-dev.herokuapp.com/ws",
  baseUrl: "https://staging.veev.app", // REMOVE
  // apiUrl: "https://veev-production.herokuapp.com/api/",
  // webSocketUrl: "",
  // baseUrl: "https://www.veev.app",
  checkoutUrl: "https://checkout.veev.app", // REMOVE
  expired: 100,
  s3Config: {
    bucketName: "veev-prod-bucket",
    region: "us-west-1",
    accessKeyId: "AKIA2ZTBBGXYDE7CBGO7",
    secretAccessKey: "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",
  },
  stripeClient: process.env.REACT_APP_STRIPE_KEY || 'pk_test_CukwWAFm3eN4t1fok5eaclzR'
};
