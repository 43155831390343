import { apiGetToken, apiPostToken, apiPutToken, apiDeleteToken } from "./index";

async function create(merchantCode, data) {
  try {
    const res = await apiPostToken(`merchants/${merchantCode}/items`, data);
    return Promise.resolve(res);
  } catch (error) {
    return Promise.reject(error);
  }
}

async function update(merchantCode, id, data) {
  try {
    const res = await apiPutToken(`merchants/${merchantCode}/items/${id}`, data);
    return Promise.resolve(res);
  } catch (error) {
    return Promise.reject(error);
  }
}

async function remove(merchantCode, id) {
  try {
    const res = await apiDeleteToken(`merchants/${merchantCode}/items/${id}`,
      {
        merchant_id: merchantCode,
        id: id,
      }
    );
    return Promise.resolve(res);
  } catch (error) {
    return Promise.reject(error);
  }
}

async function getAll(merchantCode) {
  try {
    const res = await apiGetToken(`merchants/${merchantCode}/items`);
    return Promise.resolve(res)
  } catch(error) {
    return Promise.reject(error)
  }
}

export {
  create,
  update,
  remove,
  getAll
}
