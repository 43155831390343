import React, {useEffect} from "react";
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import {NotificationManager} from "react-notifications";
import ActionCable from "actioncable";
import Home from "../pages/publicweb/staticpages/home.js";
import Links from "../pages/publicweb/staticpages/links.js";
// import App from "../pages/publicweb/staticpages/app.js";
import Business from "../pages/publicweb/staticpages/business.js";
import Download from "../pages/publicweb/staticpages/download.js";
import FoodTrucks from "../pages/publicweb/staticpages/foodtrucks.js";
import Restaurants from "../pages/publicweb/staticpages/restaurants.js";
import Guide from "../pages/publicweb/staticpages/guide.js";
import Solutions from "../pages/publicweb/staticpages/solutions.js";
import Checkout from "../pages/publicweb/staticpages/solutions2.js";
import Checkin from "../pages/publicweb/staticpages/solutions3.js";
import User from "../pages/publicweb/staticpages/user.js";
import Login from "../components/auth/login";
import Register from "../components/auth/register_original";
import Logout from '../components/auth/logout';
import About from "../pages/publicweb/staticpages/about.js";
import Privacy from "../pages/publicweb/staticpages/privacy.js";
import Coin from "../pages/publicweb/staticpages/coin.js";
import Support from "../pages/publicweb/staticpages/support.js";
import Terms from "../pages/publicweb/staticpages/terms.js";
import Search from "../pages/publicweb/search/index";
import StoreDetail from "../pages/publicweb/stores/detail";
import StoreRes from "../pages/publicweb/book/index.js";
import StoreApp from "../pages/publicweb/testapp/index.js";
import StoreCheckoutPage from "../pages/publicweb/stores/checkout";
import Events from "../pages/not-used/events/index";
import NFTS from "../pages/not-used/nft/index";
import NFTTest from "../pages/not-used/nft/nfttest";
import NFTDetail from '../pages/not-used/nft/detail'
import {getCookie, setCookie} from "../service/cookie";
import {useSetting} from "../provider/setting";
import siteConfig from '../config/site.config.js';
import {AuthRoute, PrivateRoute} from './middleware';
import OnboardingPage from "../pages/onboarding";

import StoreBusinessPage from "../pages/dashboard/settings/store.js";
import StoreIphonesettingsPage from "../pages/dashboard/settings/iphonesettings.js";
import StoreLegalPage from "../pages/dashboard/settings/legal.js";
import StoreActivationPage from "../pages/dashboard/settings/activations.js";
import StoreItemsPage from "../pages/dashboard/checkout/items.js";
import StoreItemCreatePage from "../pages/dashboard/checkout/items-create.js";
import StoreItemEditPage from "../pages/dashboard/checkout/items-edit.js";
import StoreCategoriesPage from "../pages/dashboard/checkout/categories.js";
import StoreCategoryCreatePage from "../pages/dashboard/checkout/category-create.js";
import StoreCategoryEditPage from "../pages/dashboard/checkout/category-edit.js";
import StoreAddonsPage from "../pages/dashboard/checkout/addons.js";
import StoreAddonCreatePage from "../pages/dashboard/checkout/addons-create.js";
import StoreAddonEditPage from "../pages/dashboard/checkout/addons-edit.js";

import Page1 from "../pages/not-used/checkin_for_identity/checkin.js";
import Page2 from "../pages/not-used/checkin_for_identity/create.js";
import Page3 from "../pages/not-used/checkin_for_identity/edit.js";
// import Page4 from "../pages/dashboard/store/checkin/landingpage.js";

import BookingPage from "../pages/dashboard/checkin/bookings.js";
import BookingSetupPage from "../pages/dashboard/checkin/setupreservations.js";
// import BookingSetupReservationPage from "../pages/dashboard/checkin/setup_reservation.js";
import FloorPlanPage from "../pages/dashboard/checkin/floorplan.js";
import RSVPPage from "../pages/dashboard/checkin/rsvp.js";
import EventsPage from "../pages/dashboard/checkin/events.js";
import EventsCreatePage from "../pages/dashboard/checkin/events-edit.js";
import EventsEditPage from "../pages/dashboard/checkin/events-edit.js";
import TicketsPage from "../pages/dashboard/checkin/tickets.js";
import TicketsCreatePage from "../pages/dashboard/checkin/tickets-create.js";
import TicketsEditPage from "../pages/dashboard/checkin/tickets-edit.js";

import UserPage from "../pages/users/index"
import StoreLinksPage from "../pages/dashboard/settings/storelink.js";
import StoreQRPage from "../pages/dashboard/settings/storeqr.js";
import Transactions from "../pages/dashboard/checkout/transactions.js";
import CreateDonate from "../pages/dashboard/donate/create.js";
import ViewDonate from "../pages/dashboard/donate/index.js";
import DonateCheckout from "../pages/dashboard/donate/checkout.js";
import Followers from "../pages/dashboard/crm/followers/index.js";
import CRM from "../pages/dashboard/crm/activity/index.js";
import Email from "../pages/dashboard/crm/email/index.js";
import Notification from "../pages/dashboard/crm/notifications/index.js";
import OrderConfirmation from "../pages/order/confirmation";
import SignupPage from "../pages/signup";
import ReservationPage from "../pages/publicweb/book/reservation.js";
import ReservationConfirmationPage from "../pages/publicweb/book/confirmation.js";

const AppRoutes = () => {
  const [, dispatch] = useSetting()

  useEffect(() => {
    const auth = {
      signin_session_id: getCookie("signin_session_id"),
      signin_merchant_code: getCookie("signin_merchant_code"),
      signin_merchant_id: getCookie("signin_merchant_id"),
      signin_merchant_user_id: getCookie("signin_merchant_user_id"),
      signin_current_user_id: getCookie("signin_current_user_id"),
      signin_merchant_name: getCookie("signin_merchant_name"),
      current_ident_token: getCookie("current_ident_token"),
      current_user_email: getCookie("current_user_email"),
      selected_merchant_address: getCookie("selected_merchant_address"),
      current_merchant_wallet_address: getCookie("current_merchant_wallet_address"),
      selected_merchant_bool_cannabis_merchant: getCookie("selected_merchant_bool_cannabis_merchant"),
    };
    dispatch({type: 'SET', settingName: 'auth', settingData: auth})
    const selectNetwork = getCookie("sel_network");
    if (selectNetwork) {
      dispatch({type: 'SET', settingName: 'sel_network', settingData: selectNetwork})
    } else {
      dispatch({type: "SET", settingName: "sel_network", settingData: "live"});
      setCookie("sel_network", "live", siteConfig.expired);
    }
  }, [])

  useEffect(() => {
    const cable = ActionCable.createConsumer(siteConfig.webSocketUrl);
    const _authChanel = cable.subscriptions.create(
        {
            channel: 'SessionChannel'
        },
        {
            connected: () => {
                console.log('Connected authentication_request')
                _authChanel.send({
                    type: "authentication_request",
                    session_id: true,
                });
            },
            received: data => {
                const session_id = data.session_id
                setCookie("signin_session_id", session_id, siteConfig.expired);;
                cable.subscriptions.create(
                    {
                        channel: "MerchantChannel",
                        session_id,
                    },
                    {
                        received: (data) => {
                            // `data` will contain a `merchant_select_response` message.
                            console.log("Received merchant data." + JSON.stringify(data));
                            /* Do whatever with merchant data here. : save sessionid and merchant code., user_id on the session */
                            if (data.type === "merchant_select_response") {
                                setCookie("signin_session_id", session_id, siteConfig.expired);
                                setCookie("signin_merchant_code", data.merchant_information.merchant_code, siteConfig.expired);
                                setCookie("signin_merchant_id", data.selected_merchant_id, siteConfig.expired);
                                setCookie("signin_merchant_user_id", data.merchant_user_id, siteConfig.expired);
                                setCookie("signin_current_user_id", data.current_user_id, siteConfig.expired);
                                setCookie("signin_merchant_name", data.merchant_information.legal_name, siteConfig.expired);
                                setCookie("current_ident_token", data.current_user.ident_token, siteConfig.expired);
                                setCookie("current_user_email", data.current_user.email, siteConfig.expired);
                                const selected_merchant_address =
                                    data.merchant_information.street +
                                    " " +
                                    data.merchant_information.city +
                                    " " +
                                    data.merchant_information.state +
                                    " " +
                                    data.merchant_information.zip;
                                setCookie("selected_merchant_address", selected_merchant_address, siteConfig.expired);
                                setCookie("current_merchant_wallet_address", data.merchant_information.address, siteConfig.expired);
                                setCookie("selected_merchant_bool_cannabis_merchant", data.merchant_information.cannabis_merchant === 'true', siteConfig.expired);
                                const auth = {
                                    signin_session_id: session_id,
                                    signin_merchant_code: data.merchant_information.merchant_code,
                                    signin_merchant_id: data.selected_merchant_id,
                                    //signin_merchant_user_id : data.merchant_information.wallet.user_id,
                                    signin_merchant_user_id: data.merchant_wallet_user_id,
                                    signin_current_user_id: data.current_user_id,
                                    signin_merchant_name: data.merchant_information.legal_name,
                                    current_ident_token: data.current_user.ident_token,
                                    current_user_email: data.current_user.email,
                                    selected_merchant_address: selected_merchant_address,
                                    current_merchant_wallet_address: data.merchant_information.wallet_address,
                                    selected_merchant_bool_cannabis_merchant: data.merchant_information.cannabis_merchant === 'true',
                                };
                                dispatch({type: 'SET', settingName: 'auth', settingData: auth})
                                NotificationManager.success('VEEV ID Authenticated', 'Success', 3000);
                            }
                        },
                    }
                );
                cable.subscriptions.create(
                    {
                        channel: "SessionChannel",
                        session_id,
                    },
                    {
                        received: (data) => {
                            // `data` will contain a `merchant_select_response` message.
                            console.log("Received Session Channel." + JSON.stringify(data));
                            /* Do whatever with merchant data here. : save sessionid and merchant code., user_id on the session */

                            if (data.type === "authentication_linking") {
                                // get the current user info.
                                const current_user = data.current_user;
                                console.log("current user info" + current_user);

                                //set the current user info on the local storage: session_id, user_id,
                                if (session_id !== undefined) {
                                    setCookie("veev_current_user_username", current_user.handle, siteConfig.expired);
                                    setCookie("veev_session_id", session_id, siteConfig.expired);
                                    setCookie("veev_current_user_id", current_user.id, siteConfig.expired);
                                    setCookie("veev_current_user_email", current_user.email, siteConfig.expired);
                                    setCookie("veev_current_user_ident_token", current_user.ident_token, siteConfig.expired);
                                    const auth = {
                                        signin_session_id: session_id,
                                        signin_current_user_id: current_user.id,
                                        current_user_email: current_user.email,
                                        current_ident_token: current_user.ident_token,
                                    }
                                    dispatch({type: 'SET', settingName: 'auth', settingData: auth})
                                }
                            }

                            if (data.type === "merchant_create_init") {
                                /* go to the sign up page. */
                                console.log("create new merchant button: " + JSON.stringify(data));
                                if (session_id !== undefined) {
                                }
                            }
                        },
                    }
                );
            }
        }
    )
  }, [])

  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Home/>}/>
        <Route path="/user/:username" element={<UserPage/>}/>
        <Route path="/signin" element={<AuthRoute><Login/></AuthRoute>}/>
        <Route path="/signup" element={<AuthRoute><SignupPage/></AuthRoute>}/>
        <Route path="/merchant/register" element={<Register/>}/>
        <Route path="/signout" element={<PrivateRoute><Logout/></PrivateRoute>}/>
        <Route path="/checkin" element={<Checkin/>}></Route>
        <Route path="/checkout" element={<Checkout/>}></Route>
        <Route path="/links" element={<Links/>}></Route>
        {/* <Route path="/app" element={<App/>}></Route> */}
        <Route path="/guide" element={<Guide/>}></Route>
        <Route exact path="/about/user" element={<User/>}></Route>
        <Route path="/business" element={<Business/>}></Route>
        <Route path="/download" element={<Download/>}></Route>
        <Route path="/foodtrucks" element={<FoodTrucks/>}></Route>
        <Route path="/restaurants" element={<Restaurants/>}></Route>
        <Route path="/solutions" element={<Solutions/>}></Route>
        <Route path="/solutions2" element={<Checkout/>}></Route>
        <Route path="/solutions3" element={<Checkin/>}></Route>
        <Route path="/about/" element={<About/>}></Route>
        <Route path="/privacy" element={<Privacy/>}></Route>
        <Route path="/coin" element={<Coin/>}></Route>
        <Route path="/support" element={<Support/>}></Route>
        <Route path="/terms" element={<Terms/>}></Route>
        <Route exact path="/search/:term?" element={<Search/>}></Route>
        <Route path="/stores/:name" element={<StoreDetail/>}></Route>
        <Route path="/book/:name" element={<StoreRes/>}></Route>
        <Route path="/testapp/:name" element={<StoreApp/>}></Route>
        <Route path="/stores/:name/checkout" element={<StoreCheckoutPage/>}></Route>
        <Route path="/events" element={<Events/>}></Route>
        <Route exact path="/nfts" element={<NFTS/>}></Route>
        <Route exact path="/directory/nfttest" element={<NFTTest/>}></Route>
        <Route exact path="/directory/nfts/:id" element={<NFTDetail/>}></Route>
        <Route exact path="/orders/:id/confirmation" element={<OrderConfirmation/>}></Route>

        {/* STORE Checkout */}
        <Route exact path="/store/checkout/transactions" element={<PrivateRoute><Transactions/></PrivateRoute>}></Route> 
        <Route path="/store/checkout/items" element={<PrivateRoute><StoreItemsPage/></PrivateRoute>}/>
        <Route path="/store/checkout/items/create" element={<PrivateRoute><StoreItemCreatePage/></PrivateRoute>}/>
        <Route path="/store/checkout/items/edit" element={<PrivateRoute><StoreItemEditPage/></PrivateRoute>}/>
        <Route path="/store/checkout/categories" element={<PrivateRoute><StoreCategoriesPage/></PrivateRoute>}/>
        <Route path="/store/checkout/categories/create" element={<PrivateRoute><StoreCategoryCreatePage/></PrivateRoute>}/>
        <Route path="/store/checkout/categories/edit" element={<PrivateRoute><StoreCategoryEditPage/></PrivateRoute>}/>
        <Route path="/store/checkout/addons" element={<PrivateRoute><StoreAddonsPage/></PrivateRoute>}/>
        <Route path="/store/checkout/addons/create" element={<PrivateRoute><StoreAddonCreatePage/></PrivateRoute>}/>
        <Route path="/store/checkout/addons/edit" element={<PrivateRoute><StoreAddonEditPage/></PrivateRoute>}/>
       
        {/* Identity Checkin */}
        <Route path="/store/checkin/checkin" element={<PrivateRoute><Page1/></PrivateRoute>}/>
        <Route path="/store/checkin/create" element={<PrivateRoute><Page2/></PrivateRoute>}/>
        <Route path="/store/checkin/edit" element={<PrivateRoute><Page3/></PrivateRoute>}/>
        {/* <Route path="/store/checkin/landingpage" element={<PrivateRoute><Page4/></PrivateRoute>}/> */}

        {/* STORE Checkin */}
        <Route path="/store/checkin/bookings" element={<PrivateRoute><BookingPage/></PrivateRoute>}/>
        <Route path="/store/checkin/floorplan" element={<PrivateRoute><FloorPlanPage/></PrivateRoute>}/>
        <Route path="/store/checkin/setup" element={<PrivateRoute><BookingSetupPage/></PrivateRoute>}/>
        {/* <Route path="/store/checkin/setup_reservation" element={<PrivateRoute><BookingSetupReservationPage/></PrivateRoute>}/> */}
        <Route path="/store/checkin/rsvp" element={<PrivateRoute><RSVPPage/></PrivateRoute>}/>
        <Route path="/store/checkin/events" element={<PrivateRoute><EventsPage/></PrivateRoute>}/>
        <Route path="/store/checkin/events/create" element={<PrivateRoute><EventsCreatePage/></PrivateRoute>}/>
        <Route path="/store/checkin/events/edit" element={<PrivateRoute><EventsEditPage/></PrivateRoute>}/>
        <Route path="/store/checkin/tickets" element={<PrivateRoute><TicketsPage/></PrivateRoute>}/>
        <Route path="/store/checkin/tickets/create" element={<PrivateRoute><TicketsCreatePage/></PrivateRoute>}/>
        <Route path="/store/checkin/tickets/edit" element={<PrivateRoute><TicketsEditPage/></PrivateRoute>}/>

        {/* STORE CRM */}
        <Route exact path="/store/crm/followers" element={<PrivateRoute><Followers/></PrivateRoute>}></Route>
        <Route exact path="/store/crm/activity" element={<PrivateRoute><CRM/></PrivateRoute>}/>
        <Route exact path="/store/crm/email" element={<PrivateRoute><Email/></PrivateRoute>}/>
        <Route exact path="/store/crm/notification" element={<PrivateRoute><Notification/></PrivateRoute>}/>

        {/* STORE Settings */}
        <Route path="/store/settings/store" element={<PrivateRoute><StoreBusinessPage/></PrivateRoute>}/>
        <Route path="/store/settings/iphonesettings" element={<PrivateRoute><StoreIphonesettingsPage/></PrivateRoute>}/>
        <Route path="/store/settings/legal" element={<PrivateRoute><StoreLegalPage/></PrivateRoute>}/>
        <Route path="/store/settings/activations" element={<PrivateRoute><StoreActivationPage/></PrivateRoute>}/>
        <Route path="/store/settings/storelink" element={<PrivateRoute><StoreLinksPage/></PrivateRoute>}/>
        <Route path="/store/settings/storeQR" element={<PrivateRoute><StoreQRPage/></PrivateRoute>}/>

        {/* STORE Donate */}
        <Route exact path="/store/donate/create" element={<PrivateRoute><CreateDonate/></PrivateRoute>}/>
        <Route exact path="/store/donate/" element={<PrivateRoute><ViewDonate/></PrivateRoute>}/>
        <Route exact path="/store/donate/checkout" element={<PrivateRoute><DonateCheckout/></PrivateRoute>}/>

        <Route exact path="/merchant/onboarding" element={<PrivateRoute><OnboardingPage /></PrivateRoute>}/>

        <Route exact path="/reservation/details" element={<ReservationPage/>}></Route>
        <Route exact path="/reservation/success" element={<ReservationConfirmationPage/>}></Route>
      </Routes>
    </Router>
  );
};
export default AppRoutes;
