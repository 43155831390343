import React, {useEffect, useState} from 'react'
import { Modal } from "antd"
import LoginPopup from "./login-popup";
import SignupPopup from "./signup-popup";

const AuthPopup = () => {
    const [screen, setScreen] = useState("login");
    const [open, setOpen] = useState(false);

    useEffect(() => {
        setScreen("login")
    }, []);

    return (
        <>
            <a className="nav-link js-scroll-trigger" onClick={()=>{setOpen(true)}}>Sign In</a>
            <Modal
                onCancel={()=>{setOpen(false); setScreen("login")}}
                width={400}
                title={
                    <img
                        style={{width: 80, height: "auto", marginBottom: "3px"}}
                        alt="VEEV Logo"
                        src="/assets/img/veev_logo_red.png"
                        width={139}
                        height={41}
                    />
                }
                footer={false}
                centered
                open={open}>
                {{
                    "login": <LoginPopup setScreen={setScreen}/>,
                    "signup": <SignupPopup setScreen={setScreen}/>
                }[screen]}
            </Modal>
        </>
    )
}

export default AuthPopup