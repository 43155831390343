import React, { useState } from "react";
import { Card, Layout, Form, Input, Button, Checkbox, Space } from "antd";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { signupApi } from "../../api/api/auth";

const {Item} = Form;

const SignupPage = () => {
    const navigate = useNavigate();

    const [agree, setAgree] = useState(false);
    const [createMerchant, setCreateMerchant] = useState(false);

    const handleFinish = async (values)=> {
        const payload = {
            user: {
                email: values.email,
                handle: values.username,
                password: values.password,
                profile: {
                    first_name: values.firstname,
                    last_name: values.lastname,
                    phone: values.phone
                }
            }
        }

        try {
            // const {data} = await signupApi(payload);
            // console.log(data);

            if (createMerchant) {
                navigate("/merchant/register")
            } else {
                navigate("/user")
            }
        } catch(e)
        {
            console.warn(e)
        }
        
    }
    return (
        <SignUpWrap>
            <Card>
                <Form 
                    onFinish={handleFinish}
                    layout="vertical">  
                    <h1>
                    <img src="/../assets/img/veev_logo_red.png" height="25" justify-content="center" width="*" />
                    <br></br>
                    <br></br>  
                    Create User Account</h1>
                    <Item name="phone">
                        <Input size="large" placeholder="Phone Number"/>
                    </Item>
                    <Item name="email">
                        <Input size="large" placeholder="Email"/>
                    </Item>
                    <Item name="firstname">
                        <Input size="large" placeholder="First Name"/>
                    </Item>
                    <Item name="lastname">
                        <Input size="large" placeholder="Last Name"/>
                    </Item>
                    <Item name="username">
                        <Input size="large" placeholder="User Name"/>
                    </Item>
                    <Item name="password">
                        <Input.Password
                            size="large"
                            placeholder="Password"/>
                    </Item>

                    <Space 
                        style={{marginBottom: 24}}
                        direction="vertical">
                        <Checkbox 
                            checked={createMerchant}
                            onChange={(e)=>{setCreateMerchant(e.target.checked)}}>
                            Create Business account
                        </Checkbox>
                    
                        <Checkbox
                            checked={agree}
                            onChange={(e)=>{setAgree(e.target.checked)}}
                        >
                            I agree to <a href="terms" target="_blank">terms</a> of use.
                        </Checkbox>
                    </Space>
               

                    <Item style={{textAlign: "center"}}>
                        <Button 
                        size="large"
                            type="primary"
                            htmlType="submit">
                            Sign Up
                        </Button>
                    </Item>
                </Form>
            </Card>
           
        </SignUpWrap>
    )
}

const SignUpWrap = styled.div`
    background: #ffffff;
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;

    h1 {
        font-size: 24px;
        /* text-transform: uppercase; */
        color: #000000;
    }

    .ant-card {
        border: none;
        .ant-card-body {
            width: 439px;
        }
    }

    .ant-form{
        .ant-input {
            margin: 0;
        
        }
    }
`

export default SignupPage