import React, {Fragment} from 'react';
import AuthHeader from "../../components/header/authdashboard";
import Breadcrumb from "./components/breadcrumbs";
import styled from "styled-components";
import Footer from '../../components/footer/footerEmptyPage';

const DashboardLayout = ({children}) => {
  return (
    <Fragment>
      <AuthHeader/>
      <Breadcrumb/>
      {/* <StoreTopTab/> */}
      <DashboardLayoutMain>
        <div className="container">
          {children}
        </div>
      </DashboardLayoutMain>
      <Footer/>
    </Fragment>
  );
};

export const DashboardLayoutMain = styled.main`
  .ant-input {margin: 0!important;}
  div.ant-upload.ant-upload-select{
    width: 100% !important;
    padding: 1px;
    height: 150px!important;
    img {
      border-radius: 8px;
      max-width: 100%;
      max-height: 100%;
      height: 100%;
      width: 100%;
      
      object-fit: cover;
    }
  }
  .incheck {
    font-size: 12px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
    font-weight: 700;
    color: #ffffff;
    
    height: 100%;
  }
  .side-nav-wrap{
    padding-right: 42px;
    .side-nav{
      background-color: var(--bg-light-color);
      padding: 20px 30px;

      h5 {
        margin-bottom: 24px;
        margin-top: 24px;
      }

      nav {
        display: flex;
        flex-direction: column;
        grid-gap: 18px;
        margin-bottom: 24px;
        li {
          line-height: 1;
          list-style: none;
          a {
            font-size: 18px;
            line-height: 1.5;
            text-decoration: none;
            font-weight: 400;
            color:  #5e5e5e;
            
            :hover, &.active {
              color: #cc0000;
            }
          }
        }
      }
    }
  }
  
  input[type="checkbox"].alone {
    width: 20px;
    height: 20px;
    margin: 8px 0;
    cursor: pointer;
  }

  .in-row{
    display: flex;
    grid-gap: 8px;
    align-items: center;
  }

  .in-time {
    display: grid;
    grid-template-columns: 3fr 1.8fr;
  }
  
  .ant-input-number, .ant-input-number-affix-wrapper{
    width: 100%;
  }

  .image-upload {
    input {
      display: none;
    }
    .upload-area {
      display: flex;
      height: 150px;
      width: 150px;
      background-color: #fefefe;
      border: 1px dashed #ccc;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      margin-bottom: 24px;
      border-radius: 6px;

      svg {
        width: 40px;
        height: 40px;
        color: #e77c7c;
      }
    }
  }

  .card-body {
    border-radius: 16px;
  }

  .gap{
    display: flex;
    grid-gap: 16px;
  }
  
  .col {
    margin-bottom: 32px;

    select.form-control, input.form-control {
      height: 42px;
      margin-top: 0;
    }

    label {
      font-size: 12px;
      line-height: 1;
      margin-bottom: 4px;
      font-weight: 500;
      color: #888888;
      padding-left: 8px;
      &.checkbox-label {
        display: flex;
        grid-gap: 8px;
        align-items: center;
        font-size: 14px;
        color: #000000;
        cursor: pointer;
        input[type="checkbox"] {
          width: 15px;
          height: 15px;
        }
      }
    }

    .ant-form-item-label{
      padding-bottom: 0;
    }
    
    .image-upload {
      input {
        display: none;
      }

      .upload-area {
        display: flex;
        height: 150px;
        width: 150px;
        background-color: #fefefe;
        border: 1px dashed #ccc;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin-bottom: 24px;
        border-radius: 6px;

        svg {
          width: 40px;
          height: 40px;
          color: #cc0000;
        }
      }
    }

    .btn-submit {
      padding: 16px 42px;
      text-transform: uppercase;
      letter-spacing: 1px;
      margin: 0;
      border-radius: 60px;
      font-size: 16px;
      background-color: transparent;
      border: 1px solid #cc0000;
      color: #cc0000;
      font-weight: 600;
      cursor: pointer;
      &.btn-submit-primary{
        background-color: #cc0000;
        color: #FFFFFF;
      }
      :hover {
        background-color: #cc0000;
        color: #ffffff;
      }
    }
  }
  
  .form-control {
    border-radius: 6px;
    :focus{
      border-color: #818182;
      outline: none;
      box-shadow: none;
    }
  }
  
  .icon-button{
    padding: 0;
    min-height: 0;
    height: auto;
    font-size: 24px;
    line-height: 1;
  }
  
  .ant-collapse-item{
    .ant-collapse-header{
      display: flex;
      align-items: center;
      .ant-collapse-header-text {
        font-size: 18px;
        line-height: 1;
        font-weight: 600;
      }
    }
  }
  
`

export default DashboardLayout;
