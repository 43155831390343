import React, { useEffect } from 'react'
import Header from "../../../components/header/authhome";
import Footer from '../../../components/footer/footerPage'
// import { Styles } from '../../style/aboutStyle'
import { Styles } from '../../../layouts/style/homeStyle'

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  return (
    <>
      <Header />
      <Styles>
      <div className="guide" id="howitworks" style={{paddingTop: 0, paddingBottom: 0}}>   
        {/* <section className="cta text-center" style={{paddingTop: 100, paddingBottom: 50}}>
          <div className="container">
            <div className="col-lg-12 my-auto">  
              <h className="title">Make It One Click</h>
              <p>Pay, ID Verification, checkin, signup, scheduling, contact sharing without giving your personal data.</p>
            </div>
          </div>
          <div>
            <img src="/assets/img/veev_web3_identity.png" style={{paddingTop: 20, width: '100%'}} className="img-fluid" alt="" />
          </div>  
          <div className="container" style={{ paddingTop: '0px', paddingBottom: '40px'}}>
            <Plans></Plans>

                <div className="row">   
                  <div className="col-lg-3" style={{paddingTop: 0}}>
                    
                    <div className="section-heading text-center" style={{paddingTop: 20}}>
                        <div style={{ width: '100%', paddingBottom: '25px', paddingTop: '0px', color: 'grey' }}> 
                        <p className="title4">ID Verification</p>
                        One click age & ID Verificatio at bars, clubs & events.
                        </div>  
                    </div>
                  </div>
                  
                  <div className="col-lg-3" style={{paddingTop: 0}}>
                    
                    <div className="section-heading text-center" style={{paddingTop: 20}}>
                      <div style={{ width: '100%', paddingBottom: '25px', paddingTop: '0px', color: 'grey' }}> 
                        <p className="title4">Sign Up</p>
                        One click QR signup for events, promotions & more.
                        </div>  
                    </div>
                  </div>
 
                  <div className="col-lg-3" style={{paddingTop: 0}}>   
                    
                    <div className="section-heading text-center" style={{paddingTop: 0}}>
                      <div style={{ width: '100%', paddingBottom: '25px', paddingTop: '20px', color: 'grey' }}> 
                        <p className="title4">Scheduling</p>
                        One click appointment scheduling & RSVP. 
                      </div>  
                    </div>
                  </div>

                  <div className="col-lg-3" style={{paddingTop: 0}}>
                    
                    <div className="section-heading text-center" style={{paddingTop: 20}}>
                      <div style={{ width: '100%', paddingBottom: '25px', paddingTop: '0px', color: 'grey' }}> 
                        <p className="title4">Pay</p>
                        One click pay at stores, online, send & receive money.
                        </div>  
                    </div>
                  </div>
                </div>

                <div className="row">

                  <div className="col-lg-1 dv-loadmoney">
                  </div>

                  <div className="col-lg-3" style={{paddingTop: 0}}>
                    
                    <div className="section-heading text-center" style={{paddingTop: 20}}>
                        <div style={{ width: '100%', paddingBottom: '25px', paddingTop: '0px', color: 'grey' }}> 
                        <p className="title4">Contacts & Links</p>
                        Share contact info and all your links in one click. 
                        </div>  
                    </div>
                  </div>
                  
                  <div className="col-lg-1 dv-loadmoney">
                  </div>

                  <div className="col-lg-3 dv-loadmoney" style={{paddingTop: 0}}>
                    
                    <div className="section-heading text-center" style={{paddingTop: 20}}>
                      <div style={{ width: '100%', paddingBottom: '25px', paddingTop: '0px', color: 'grey' }}> 
                        <p className="title4">Recommendations</p>
                        Recommendations of stores, events, clubs cool & hot spots.
                        </div>  
                    </div>
                  </div>
                  
                  <div className="col-lg-3" style={{paddingTop: 0}}>   
                    
                    <div className="section-heading text-center" style={{paddingTop: 0}}>
                      <div style={{ width: '100%', paddingBottom: '25px', paddingTop: '20px', color: 'grey' }}> 
                        <p className="title4">Social</p>
                        Social space to share recommendations.
                      </div>  
                    </div>
                  </div>
                
                  <div className="col-lg-1 dv-loadmoney">
                  </div>  

                </div>
              
          </div>
          <div className="text-center">
            <a className="badge-link" style={{ textAlign: 'center', display: 'block' }} href="https://apps.apple.com/app/veev-app/id6444875499">
            <img className="wallet" src="https://developer.apple.com/news/images/download-on-the-app-store-badge.png" style={{ marginTop: 30, height: 38 }} alt="VEEV Wallet" /></a>
          </div>	
        </section> */}
        
        <section className="cta" id="connect">
          <div className="container">	
            <div className="row">
              <div className="col-lg-12 my-auto">
                <div className="container-fluid">
                <p className="titlethin text-center">Link Your Cards</p>
                <p className="title0 text-center">Get Recommendations</p>  
                {/* <p>Manage ID verification, access & payments for bars, events and venues.</p>  */} 
                </div>
              </div>
            </div>
          </div>
          <div class="sticky-container" style={{width: '100%'}}>
            <div class="app-demo no-upper-margin" style={{width: '918px'}}>
              <div class="phone_column" style={{width: '200px'}}>
                <div style={{maxHeight: 'auto', paddingTop: 50}}>
                  <div className="device-mockup iphone6 portrait gold">
                    <div className="device">
                      <div className="screen">
                      <img src="../assets/img/veev_id.png" className="img-fluid2" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
                
                <div style={{maxHeight: 'auto', paddingTop: 50}}>
                  <div className="device-mockup iphone6 portrait gold">
                    <div className="device">
                      <div className="screen">
                      <img src="../assets/img/veev_checkinevents.png" className="img-fluid2" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
                
                <div style={{maxHeight: 'auto', paddingTop: 50, paddingBottom: 50}}>
                  <div className="device-mockup iphone6 portrait gold">
                    <div className="device">
                      <div className="screen">
                      <img src="../assets/img/veev_contactid.png" className="img-fluid2" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
              <div class="text_column" style={{width: '100%'}}>
                <div class="text_holder" style={{width: '500px'}}>
                  <div class="text_content" style={{width: '500px'}}>
                    <div  >
                      <p className="title3black">Link Credit, ID, Contact Cards</p>
                      <p style={{ width: '100%', color: 'grey' }}>all payment, signups, rsvps on social apps, websites & stores in one place</p>
                      <br></br>    
                    </div>
                    <div  >
                      <p className="title3black">One Click Checkout, Checkin, Signup</p>
                      <p style={{ width: '100%', color: 'grey' }}>no more entering name, address, billing, shipping over and over on multiple apps</p>
                      <br></br> 
                    </div>
                    <div>
                      <p className="title3black">Get Recommendations</p>
                      <p style={{ width: '100%', color: 'grey' }}>protect personal, financial, identity & payment data from fraud, theft and resale</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>        
      </div>
      </Styles>
      <Footer/>
        
        {/* <div className="about">
          <section class="features">
            <div class="col-lg-12 text-center">
              <div class="container">
                <div class="row">
                  <div class="col-lg-2"> 
                    <div class="nav-item">
                      <a class="nav-link js-scroll-trigger" href="#events">BlockChain Wallet</a>
                    </div>
                  </div>
                  <div class="col-lg-2"> 
                    <div class="section-heading">
                      <a href="#checkin" class="js-scroll-trigger">Secure Keys</a>
                    </div>
                  </div>
                  <div class="col-lg-2"> 
                    <div class="section-heading">
                      <a href="#stores" class="js-scroll-trigger">Don't Sell My PI</a>
                    </div>
                  </div>
                  <div class="col-lg-2"> 
                    <div class="section-heading">
                      <a href="#stores" class="js-scroll-trigger">Earn Crypto</a>
                    </div>
                  </div>
                  <div class="col-lg-2"> 
                    <div class="section-heading">
                      <a href="/solutions" class="js-scroll-trigger">Solutions</a>
                    </div>
                  </div>
                  <div class="col-lg-2"> 
                    <div class="section-heading">
                      <a href="#qrconnect" class="js-scroll-trigger">About Us</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section class="container">
            <div>
              <div class="section-heading">
                <h3>About Us</h3>
                VEEV is a NFT checkin, checkout app for stores, events, websites and more.
              </div>
            </div>   
            <div class="container">
              <div class="row h-100">
                <div class="col-lg-12">
                  <img src="https://staging.veev.app/client/img/veevid.png" class="img-fluid" alt="" />
                </div>
              </div>
            </div>  
          </section>
                
          <section class="download bg-primary3 text-center" id="crypto">
            <div>  
              <div class="row">
                <div class="col-lg-6 image"> 
                  <img src="https://staging.veev.app/client/img/veevid2.png" alt="Earn Crypto" />	
                </div>
                <div class="col-lg-6 content">
                  <div class="container">  
                    <div class="section-heading text-center">
                    <p class="title">Earn Crypto</p>
                    </div>
                  </div> 
                </div>  
              </div>
            </div>
          </section>

          <section class="cta text-left" id="marketing">
            <div class="container">
              <div class="row">
                <div class="col-lg-12 my-auto">
                  <div class="container-fluid">
                    <div class="row">
                      <div class="col-lg-12"> 
                      <p class="title">Earn Crypto</p>	
                      Earn Crypto everytime you use VEEV to<br /><br />
                      VEEV is NFT identity on the blockchain that earns you crypto everytime you use it. Every user get a unique identity when with the VEEV app.<br /><br />
                      <br /><br /><br />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
              
          <section class="team text-center" id="team">
            <div class="container">  
                <div class="section-heading text-center">
                <br /><br />
                    <h3>Team</h3>
                    <div class="w3-container w3-padding-10 w3-center">
                        <div class="w3-third item">
                          <div class="advisors daya teamimage" id="daya">
                          </div>
                          <h3>Daya Baran</h3>
                          <h1>Founder & CEO</h1>
                          <h12>Daya is the founder & CEO of VEEV. Previously, he was the founder of StockChats a social network and messaging app for the investment community. He is a FinTech executive with over 20 years, generating $100 million for a $1 billion fund in 12 months. He is a Top 10 analyst on Apple Inc. He built the world’s first web based on-demand music streaming service.
                          </h12>
                        </div>
                        <div class="w3-third item">
                          <div class="advisors phil teamimage" id="phil">
                          </div>
                          <h3>Darren Lam</h3>
                          <h1>Co-Founder & Software Architect</h1>
                          <h12>Darren is a Software Engineer and with strong competencies in Data Structures and System Design, and versed in a variety of programming languages, including but not limited to Ruby, PHP, and Swift.
                          </h12>                   
                        </div>
                        <div class="w3-third item">
                          <div class="advisors dare teamimage" id="dare">
                          </div>
                          <h3>Mitchel Baker</h3>
                          <h1>Software Developer</h1>
                          <h12>Mitchel is a developer and with strong competencies in web and mobile development.
                          </h12>                
                        </div>
                    </div>
                    <br /><br />
                    <h3>Advisors</h3>
                    <div class="w3-container w3-padding-10 w3-center">
                        <div class="w3-third item">
                          <div class="advisors sunny teamimage" id="sunny">
                          </div>
                          <h3></h3>
                          <h1></h1>
                          <h12>
                          </h12>                
                        </div>
                    </div>
                </div>
            </div>   
          </section>

          <section class="contact bg-primary2" id="connect">
            <div class="container">  
                <div class="section-heading text-center">
                <br /><br />
                  <h2>Connect</h2>
                <div class="" style={{ padding: '0px 0px 0px 0px' }}>
                    <ul class="list-inline list-social">
                        <li class="list-inline-item social-medium">
                        <a href="https://medium.com/veev-coin">
                          <i class="fa fa-medium"></i>
                        </a>
                        </li>
                        <li class="list-inline-item social-twitter">
                        <a href="https://twitter.com/veevcoin">
                          <i class="fa fa-twitter"></i>
                        </a>
                        </li>
                        <li class="list-inline-item social-slack">
                        <a href="https://www.t.me/veevcoin">
                            <i class="fa fa-telegram"></i>
                        </a>
                        </li>
                        <li class="list-inline-item social-google-plus">
                        <a href="https://www.youtube.com/watch?v=O8HpjL2LlWY">
                          <i class="fa fa-youtube"></i>
                        </a>
                        </li>
                    </ul> 
                  </div>
                </div>
                <div class="container">  
                    <div class="section-heading text-center">
                    <br /><br />
                    <h12><b>VEEV Inc</b><br />
                    95 Third Street, San Francisco, CA USA 94117</h12>   
                    </div>
                </div>   
            </div>   
          </section> 
        </div>
      </Styles>
      <Footer /> */}
    </>  
  )
}
export default About
