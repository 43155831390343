import React, {useEffect, useState} from 'react';
import Layout from "../../../layouts/dashboard";
import Tab from "../../../layouts/dashboard/components/settings-tab";
import {Input} from "antd";
import Sidebar from "./sidebar";
import {useSetting} from "../../../provider/setting";
import { QRCode } from 'react-qrcode-logo';
import { get as getMerchantApi } from '../../../api/merchant';

const StoreQr = () => {
  const [setting] = useSetting();
  const [storeUrl, setStoreUrl] = useState()
  const [merchant, setMerchant] = useState({});

  const getMerchantHandle = () => {
    if (!!setting?.auth?.signin_merchant_id) {
      getMerchantApi(setting?.auth?.signin_merchant_id)
        .then((data) => {
          setStoreUrl(window.location.origin+'/stores/' + (data.dba.toLowerCase().replaceAll(" ", "_")))
          setMerchant({
            ...data,
            tax_info: parseInt(parseFloat(data.tax_info || "0") * 100)
          })
        })
        .catch((e) => {
          console.warn(e)
        })
    }
  }

  useEffect(() => {
    if (!!setting?.auth?.signin_merchant_id) {
      getMerchantHandle();
    }
  }, [setting?.auth])

  return (
    <Layout>
    <Tab/>  
      <div className="row">
        <div className="col col-lg-3">
          <Sidebar/>
        </div>
        <div className='col col-lg-9'>
        <h4>StoreQR</h4>
          <p>
          Below is your StoreQR for Checking Checkout services.
          </p>  
          <div className="card card-body mb-5">
            <div style={{width: 50}}>
              <div style={{padding: 10, paddingBottom: 0, border: "10px solid #000000", display: "inline-block", borderRadius: 10, textAlign: "center"}}>
              <QRCode 
              logoWidth={50}
              logoHeight={50}
              logoImage="/favicon.ico"
              value={storeUrl}/><div style={{marginTop: -12}}>{merchant?.dba||""}</div>
              </div>
            </div>  
          </div>
          
          {/* <h4>Access Portal</h4>
          <div className="card card-body mb-5">
            <div style={{display: "flex", gridGap: 32, alignItems: "center"}}>
              <div style={{width: 200, height: 200, background: "#f0f0f0"}}>
                {
                  accessQrCode && (
                    <img
                      id='client-barcode'
                      width={200}
                      height={200}
                      src={accessQrCode}
                      alt="generated QR Code" />
                  )
                }
              </div>
              <button onClick={generateAccess} className="btn btn-primary mt-3">
                Generate New Access Code
              </button>
            </div>
          </div> */}
        </div>
      </div>
    </Layout>
  );
};

export default StoreQr;