import React, { useEffect, useState } from "react";
import {HashLink as Link} from 'react-router-hash-link';
import {Avatar, Badge, Button, Dropdown, Input,  Space} from "antd";
import {Icon} from "@iconify/react";
import {Styles} from '../../layouts/style/headerHomeStyle';
import {getCookie, setCookie} from "../../service/cookie";
import {useNavigate} from "react-router-dom";
import AuthPopup from "../auth/auth";
import {ShoppingCartOutlined} from "@ant-design/icons";
import { useCart } from "../../provider/checkout";
import { get as getMerchantApi } from '../../api/merchant';
import { useSetting } from "../../provider/setting";

const HeaderSearch = (props) => {
    const { initialKeyword } = props;
    const {Search} = Input;
    const isAuth = getCookie("current_ident_token");
    const navigate = useNavigate();
    const username = getCookie("veev_current_user_username");
    const [cart, dispatch] = useCart();
    const [setting] = useSetting();
    const [logo, setLogo] = useState("");

    const getMerchantHandle = () => {
        if (!!setting?.auth?.signin_merchant_id) {
          getMerchantApi(setting?.auth?.signin_merchant_id)
              .then((data) => {
                setLogo(data.logo)
              })
              .catch((e) => {
                console.warn(e)
              })
        }
    }

    const logoutHandle = () => {
        setCookie("signin_session_id", '', 0);
        setCookie("signin_merchant_code", '', 0);
        setCookie("signin_merchant_id", '', 0);
        setCookie("signin_merchant_user_id", '', 0);
        setCookie("signin_current_user_id", '', 0);
        setCookie("signin_merchant_name", '', 0);
        setCookie("current_ident_token", '', 0);
        setCookie("current_user_email", '', 0);
        setCookie("selected_merchant_address", '', 0);
        setCookie("current_merchant_wallet_address", '', 0);
        setCookie("selected_merchant_bool_cannabis_merchant", '', 0);
        setCookie("veev_session_id", '', 0);
        setCookie("veev_current_user_id", '', 0);
        setCookie("veev_current_user_email", '', 0);
        setCookie("veev_current_user_ident_token", '', 0);
        navigate("/");
    }

    const onSearch = (keyword) => {
        navigate(`/search/${keyword}`)
    }

  const menuItems = [
        // {
        //     key: '1',
        //     label: (
        //         <Link to={`/user/${username}`}>
        //             <Space>
        //                 <Icon
        //                     style={{fontSize: 24, fontWeight: 700}}
        //                     icon="ph:user"/>
        //                 <h5 style={{fontSize: 16, fontWeight: 700}}>
        //                     User Account
        //                 </h5>
        //             </Space>
        //         </Link>
        //     ),
        // },
        {
            key: '2',
            label: (
                <Link to="/store/checkout/transactions">
                    <Space>
                        <Icon
                            style={{fontSize: 24, fontWeight: 700}}
                            icon="ic:outline-local-grocery-store"/>
                        <h5 style={{fontSize: 16, fontWeight: 700}}>
                            Business Account
                        </h5>
                    </Space>
                </Link>
            ),
        },
        {
            key: '3',
            label: (
                <a onClick={logoutHandle}>
                    <Space>
                        <Icon
                            style={{fontSize: 24, fontWeight: 700}}
                            icon="teenyicons:logout-outline"/>
                        <h5 style={{fontSize: 16, fontWeight: 700}}>
                            Logout
                        </h5>
                    </Space>
                </a>
            ),
        },
   ];

    useEffect(() => {
        getMerchantHandle()
    }, [setting]);

  return (
        <Styles>
            <nav className="navbar navbar-expand-lg navbar-light fixed-top header" style={{zIndex: 999}} id="mainNav">
                <div className="container">
                    <Link
                        className="navbar-brand js-scroll-trigger"
                        to="/">
                        <img
                            src="/assets/img/veev_logo_black.png"
                            height="25"
                            width="*"
                            alt='logo-black'
                        />
                    </Link>

                    {/* mobile */}
                    <div className="navbar-toggler navbar-light" id="navbarResponsive"> 
                        {/* <Search
                            defaultValue={initialKeyword || ""}
                            style={{width: '200px', height: 36, padding: 0, paddingRight: 10}}
                            placeholder="Search reviews for restaurants, hair, beauty spas, etc"
                            allowClear
                            size="large"
                            onSearch={onSearch || null}
                        />    */}
                        
                            <Badge
                                offset={[-5, 5]}
                                dot={false}
                                count={cart.items.length}>
                                <Button
                                    onClick={() => dispatch({ type: "OPEN"})}
                                    style={{
                                        background: "rgb(204, 0, 0)",
                                        border: "solid 2px white",
                                        width: "42px",
                                        height: "42px",
                                        borderRadius: "50%",
                                        padding: 0,
                                        marginRight: 10,
                                        marginLeft: 10,
                                        fontSize: "20px",
                                        color: "white"
                                    }}
                                >
                                    <ShoppingCartOutlined/>
                                </Button>
                            </Badge>
                            {/* {
                                isAuth ? (
                                    <Dropdown menu={{items: menuItems}} placement="bottomRight">
                                        <Avatar
                                            size={42}
                                            src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png" style={{marginBottom: '9px'}}>
                                        </Avatar>
                                    </Dropdown>
                                ) : (
                                <AuthPopup />
                                )
                            } */}
                    </div>

                    {/* web */}
                    <div className="collapse navbar-collapse" id="navbarResponsive"> 
                        <Search
                            defaultValue={initialKeyword || ""}
                            style={{width: 500, height: 36, padding: 0}}
                            placeholder="Search reviews for restaurants, hair, beauty spas, etc"
                            allowClear
                            size="large"
                            onSearch={onSearch || null}
                        />   
                        <ul className="navbar-nav ml-auto" style={{height: '40px'}}>
                            <li>
                            <Badge
                                offset={[-5, 5]}
                                dot={false}
                                count={cart.items.length}>
                                <Button
                                    onClick={() => dispatch({ type: "OPEN"})}
                                    style={{
                                        background: "rgb(204, 0, 0)",
                                        border: "solid 2px white",
                                        width: "42px",
                                        height: "42px",
                                        borderRadius: "50%",
                                        padding: 0,
                                        fontSize: "20px",
                                        color: "white"
                                    }}
                                >
                                    <ShoppingCartOutlined/>
                                </Button>
                            </Badge>
                            </li>
                            
                            <li className="nav-item">
                                {
                                    isAuth ? (
                                        <Dropdown menu={{items: menuItems}} placement="bottomRight">
                                            <Avatar
                                                size={42}
                                                src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png">
                                            </Avatar>
                                        </Dropdown>
                                    ) : (
                                       <AuthPopup />
                                    )
                                }
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </Styles>
    )
}
export default HeaderSearch;
