import React, {Fragment, useEffect, useState} from 'react'
import {Link, useParams } from 'react-router-dom'
import Header from "../../../components/header/authsearch";
import Footer from "../../../components/footer/footerPage";
import {getAll as getStores} from '../../../api/stores'
import {useAsync} from '../../../service/utils'
import {Styles} from '../../../layouts/style/storesStyle'
import {Avatar, Rate, Space, Row, Col, Button, Input} from "antd";
import {FilterOutlined} from "@ant-design/icons";
import {Icon} from "@iconify/react";
import {getCookie} from "../../../service/cookie";

const Stores = () => {
  const { term } = useParams();
  const isAuth = getCookie("current_ident_token");
  const {data, status, error, run} = useAsync({
    status: "idle",
  });
  const [stores, setStores] = useState([]);
  const [keyword, setKeyword] = useState(term || "");

  const onSearch = async (keyword) => {
    setKeyword(keyword)
  }
  useEffect(() => {
    run(getStores())
  }, [run])
  
  useEffect(() => {
    if (status === 'resolved') {
      const sts = data.map((item) => {
        let store_url = item.dba
        store_url = store_url.toLowerCase()
        store_url = store_url.replaceAll(" ", "_")
        store_url = store_url.replaceAll("'", "")
        item.store_url = store_url
        return item
      })
      setStores(sts)
    } else if (status === 'rejected') {
      console.log(error)
    }
  }, [status])

  return (
    <Fragment>
      <Header onSearch={onSearch} initialKeyword={term}/>
      <Styles>
        <div className="container">
          <Row
            style={{marginBottom: 24}}
            justify="space-between"
            align="middle"
            gutter={[12, 12]}>
            <Col>
              <Button
                style={{width: 36, height: 36, padding: 0, marginLeft: 7, marginRight: 20, fontSize: 30}}
                size="large">
                <Icon icon="lucide:coffee"/>
              </Button>
              <Button
                style={{width: 36, height: 36, padding: 0, marginRight: 20, fontSize: 30}}
                size="large">
                <Icon icon="ph:hamburger"/>
              </Button>
              <Button
                style={{width: 34, height: 36, padding: 0, marginRight: 20, fontSize: 30}}
                size="large">
                <Icon icon="lucide:martini"/>
              </Button>
              <Button
                style={{width: 36, height: 36, padding: 0, marginRight: 20, fontSize: 30}}
                size="large">
                <Icon icon="iconoir:yoga"/>
              </Button>
              <Button
                style={{width: 36, height: 36, padding: 0, marginRight: 20, fontSize: 30}}
                size="large">
                <Icon icon="tabler:shirt"/>
              </Button>
              {/* <Button
                style={{width: 36, height: 36, padding: 0, marginRight: 20, fontSize: 18}}
                size="large">
                <FilterOutlined/>
              </Button> */}
            </Col>
          </Row>
            
          <Row gutter={[24, 24]}>
            <Col span={24} xl={17}>
              {
                stores.filter((e) => e?.dba?.toLowerCase().includes(keyword.toLowerCase())).map((store, index) => (
                  <Link
                    to={`/stores/${store.store_url}`}
                    key={index}
                    className="card">
                    <div className="card-body">
                      <Space size={24}>
                        <Avatar
                          style={{fontSize: 42, borderRadius: 0}}
                          shape="square"
                          size={132}
                          src={store.logo}>
                          {store.legal_name?.[0]}
                        </Avatar>
                        <div>
                          <h4>{store.dba}</h4>
                          <Rate disabled value={4} style={{color:'#cc0000'}}/>
                          <p>{store.description?.substring(0, 30)}...</p>
                          <p>{store.city}, {store.state}, {store.zip}</p>
                        </div>
                      </Space>
                    </div>
                  </Link>
                ))
              }
            </Col>
            <Col span={0} xl={7}>
              {/*<img className="ad-image" alt="" src="/assets/img/ads1.jpg"/>*/}
              {/*<img className="ad-image" alt="" src="/assets/img/ads2.jpg"/>*/}
            </Col>
          </Row>
        </div>
      </Styles>
      <Footer/>
    </Fragment>
  )
}
export default Stores