import React from 'react';
import {Link, useLocation} from "react-router-dom";

const CheckinSidebar = () => {
  const location = useLocation()

  return (
    <div className="side-nav-wrap">
      <div className="side-nav">
        <nav>
          <li>
            <Link
              className={location.pathname === "/store/checkin/bookings" ? "active" : ""}
              to="/store/checkin/bookings">
              Bookings
            </Link>
          </li>
          <li>
            <Link
              className={location.pathname === "/store/checkin/providers" ? "active" : ""}
              to="/store/checkin/providers">
              Providers
            </Link>
          </li>  
          <li>
            <Link
              className={location.pathname === "/store/checkin/floorplan" ? "active" : ""}
              to="/store/checkin/floorplan">
              Floor Plan
            </Link>
          </li>
          {/* <li>
            <Link
              className={location.pathname === "/store/events" ? "active" : ""}
              to="/store/events">
              Events
            </Link>
          </li>
          
          {/* <li>
            <Link
              className={location.pathname === "/store/identity" ? "active" : ""}
              to="/store/identity">
              ID Verification
            </Link>
          </li> */}
        </nav>
      </div>
    </div>
  );
};

export default CheckinSidebar;
