import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Link, useNavigate} from "react-router-dom";
import {getCookie, setCookie} from "../../service/cookie";
import {useSetting} from "../../provider/setting";
import {Avatar, Dropdown, Input, Space} from "antd";
import {Icon} from "@iconify/react";
import { get as getMerchantApi } from '../../api/merchant';

const AuthHeader = (props) => {
  const username = getCookie("veev_current_user_username");
  const navigate = useNavigate();
  const [setting] = useSetting();
  const [logo, setLogo] = useState("");
  const {initialKeyword } = props;
  const {Search} = Input;

  const logoutHandle = () => {
    setCookie("signin_session_id", '', 0);
    setCookie("signin_merchant_code", '', 0);
    setCookie("signin_merchant_id", '', 0);
    setCookie("signin_merchant_user_id", '', 0);
    setCookie("signin_current_user_id", '', 0);
    setCookie("signin_merchant_name", '', 0);
    setCookie("current_ident_token", '', 0);
    setCookie("current_user_email", '', 0);
    setCookie("selected_merchant_address", '', 0);
    setCookie("current_merchant_wallet_address", '', 0);
    setCookie("selected_merchant_bool_cannabis_merchant", '', 0);
    setCookie("veev_session_id", '', 0);
    setCookie("veev_current_user_id", '', 0);
    setCookie("veev_current_user_email", '', 0);
    setCookie("veev_current_user_ident_token", '', 0);
    navigate("/");
  }

  const getMerchantHandle = () => {
    if (!!setting?.auth?.signin_merchant_id) {
      getMerchantApi(setting?.auth?.signin_merchant_id)
          .then((data) => {
            setLogo(data.logo)
          })
          .catch((e) => {
            console.warn(e)
          })
    }
  }

  const onSearch = (keyword) => {
    navigate(`/search/${keyword}`)
}

  const menuItems = [
    // {
    //   key: '1',
    //   label: (
    //       <Link to={`/user/${username}`}>
    //         <Space>
    //           <Icon
    //               style={{fontSize: 24, fontWeight: 700}}
    //               icon="ph:user"/>
    //           <h5 style={{fontSize: 16, fontWeight: 700}}>
    //             User Account
    //           </h5>
    //         </Space>
    //       </Link>
    //   ),
    // },
    {
      key: '3',
      label: (
          <a onClick={logoutHandle}>
            <Space>
              <Icon
                  style={{fontSize: 24, fontWeight: 700}}
                  icon="teenyicons:logout-outline"/>
              <h5 style={{fontSize: 16, fontWeight: 700}}>
                Logout
              </h5>
            </Space>
          </a>
      ),
    },
  ];

  useEffect(() => {
    getMerchantHandle()
  }, []);

  return (
    <AuthHeaderWrap>
      <div className="container">
        <div className="header-wrap">
          <h1 className="logo">
            <Link className="navbar-brand js-scroll-trigger" to="/store/checkout/transactions"><img src="/assets/img/veev_logo_white.png" height="23" width="*" /></Link>
          </h1>
          <Search
              defaultValue={initialKeyword || ""}
              style={{width: 500, height: 36, padding: 0}}
              placeholder="Search reviews for restaurants, hair, beauty spas, etc"
              allowClear
              size="large"
              onSearch={onSearch || null}
          />  
          <nav>
            <ul>
              <li><Link to="/store/checkout/transactions">Store</Link></li>
              <li><Link to="/store/checkin/bookings">Reservations</Link></li>
              <li><Link to="/store/crm/activity">CRM</Link></li>
              <li><Link to="/store/settings/store">Settings</Link></li>
              <Dropdown menu={{items: menuItems}} placement="bottomRight">
                <Avatar
                    size={42}
                    src={logo}>
                </Avatar>
              </Dropdown>
            </ul>
          </nav>

        </div>
      </div>
    </AuthHeaderWrap>
  );
};

export const AuthHeaderWrap = styled.div`
  background-color: #cc0000;
  z-index: 1030;
  height: 62px;
  position: sticky;
  top: 0;
  
  .container {
    height: 100%;
    .header-wrap {
      display: flex;
      align-items: center;
      height: 100%;
      justify-content: space-between;
      
      h1{
        line-height: 1;
        margin-bottom: 0;
        margin-top: 0;
        color: #ffffff;
        font-size: 1.5rem;
        font-weight: 200;
        letter-spacing: 1px;
        font-family: Catamaran,Helvetica,Arial,sans-serif;
        a {
          color: #ffffff;
          text-decoration: none;
        }
      }
      
      nav {
        ul {
          display: flex;
          align-items: center;
          margin-bottom: 0;
          grid-gap: 42px;
          li {
            list-style: none;
            margin-bottom: 0;
            line-height: 1;
            a {
              font-size: 1rem;
              line-height: 1.5;
              color: #ffffff;
              text-decoration: none;
              font-family: Lato,Helvetica,Arial,sans-serif;
              letter-spacing: 2px;
              touch-action: manipulation;
            }
          }
        }
      }
    }
  }
`

export default AuthHeader;
