import React, {useEffect, useMemo, useState} from 'react';
//import AuthLayout from '../../layouts/landing/';
import HeaderHome from "../../../components/header/authhome";
import Footer from "../../../components/footer/footerPage";
import {HashLink as Link} from 'react-router-hash-link';
import {Avatar, Button, Dropdown, Space} from "antd";
import {Icon} from "@iconify/react";
import {Styles} from '../../../layouts/style/homeStyle';
import Plans from './plans'
import {useNavigate} from 'react-router-dom'

const Business = (props, toggleModal) => {
    /// Login popup materials
    const navigate = useNavigate();
    const navigateTo =() => {navigate('https://auth.veev.app')}
    const [open, setOpen] = useState(false);

  const [slider, setSlider] = useState('')
  const sliders = useMemo(() => [
    "../assets/img/recommendations.png",
    "../assets/img/store.png",
    "../assets/img/pos_store.PNG",
    "../assets/img/pos_sales.png",
  ], []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    setSlider(sliders[0])
    let index = 0
    let timer = setInterval(() => {
      index += 1;
      if (index >= sliders.length)
        index = 0
      setSlider(sliders[index])
    }, 2000);
    return () => clearInterval(timer);
  }, [sliders])

  const [slider2, setSlider2] = useState('')
  const sliders2 = useMemo(() => [
    // "../assets/img/veev_connect.png",
    "../assets/img/recommendations.png",
    "../assets/img/store.png",
    "../assets/img/veevreviews.png",
    // "../assets/img/veevcheckin.png",    
    // "../assets/img/veevstore.png",
  ], []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    setSlider2(sliders2[0])
    let index = 0
    let timer = setInterval(() => {
      index += 1;
      if (index >= sliders2.length)
        index = 0
      setSlider2(sliders2[index])
    }, 2000);
    return () => clearInterval(timer);
  }, [sliders2])

  const [slider3, setSlider3] = useState('')
  const sliders3 = useMemo(() => [
    "../assets/img/veev_connect.png",
    "../assets/img/checkout.png",
    "../assets/img/mobilepos1.png",
    "../assets/img/mobilepos2.png",
  ], []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    setSlider3(sliders3[0])
    let index = 0
    let timer = setInterval(() => {
      index += 1;
      if (index >= sliders3.length)
        index = 0
      setSlider3(sliders3[index])
    }, 2000);
    return () => clearInterval(timer);
  }, [sliders3])

  const [slider4, setSlider4] = useState('')
  const sliders4 = useMemo(() => [
    "../assets/img/veev_sales.png",
    "../assets/img/veev_event_signups.png",
    "../assets/img/veev_marketing.png",
  ], []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    setSlider4(sliders4[0])
    let index = 0
    let timer = setInterval(() => {
      index += 1;
      if (index >= sliders4.length)
        index = 0
      setSlider4(sliders4[index])
    }, 2000);
    return () => clearInterval(timer);
  }, [sliders4])

  const [slider5, setSlider5] = useState('')
  const sliders5 = useMemo(() => [
    "../assets/img/veev_checkin.png",
    "../assets/img/veev_event_signups.png",
    "../assets/img/veev_marketing.png",
  ], []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    setSlider5(sliders5[0])
    let index = 0
    let timer = setInterval(() => {
      index += 1;
      if (index >= sliders5.length)
        index = 0
      setSlider5(sliders5[index])
    }, 2000);
    return () => clearInterval(timer);
  }, [sliders5])

  const [slider6, setSlider6] = useState('')
  const sliders6 = useMemo(() => [
    "../assets/img/pos2.png",
    "../assets/img/pos3.png",
    "../assets/img/pos4.png",
    "../assets/img/veev_sales.png",
    "../assets/img/pos5.png",
  ], []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    setSlider6(sliders6[0])
    let index = 0
    let timer = setInterval(() => {
      index += 1;
      if (index >= sliders6.length)
        index = 0
      setSlider6(sliders6[index])
    }, 2000);
    return () => clearInterval(timer);
  }, [sliders6])

  return (
  <>
  {/* <AuthLayout> */}
  <HeaderHome />
    <Styles>
      <div className="guide" style={{paddingTop: 0, paddingBottom: 0}}>   
        <section className="cta text-center" style={{paddingTop: 70, height: "100%", paddingBottom: 90}}>
          <div className="container" style={{paddingTop: '20px', paddingBottom: "20px"}}>
            <div className="col-lg-12 my-auto">
              <div className="container-fluid">
                <div className="row">
                <div className="col-lg-3 dv-loadmoney text-center" style={{ paddingBottom: '0px', marginBottom: '50px' }}>
                    <div className="device-container">
                      <div className="device-mockup iphone6 portrait gold">
                        <div className="device" style={{marginTop: 20, marginBottom: 0}}>
                          <div className="screen">	
                            <div className="slideshow-container">
                              <div className="mySlides">
                                <div className="veevslogan">
                                  <img src={slider} alt="VEEV Checkin" className="img-fluid2" />
                                </div>
                              </div>
                            </div>
                            <div style={{ textAlign: 'center' }}>
                              <span className="dot"></span>
                              <span className="dot"></span>
                              <span className="dot"></span>
                              <span className="dot"></span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-1 dv-loadmoney">
                  </div>
                  {/* <div className="col-lg-4 my-auto text-center" style={{ paddingTop: '3px', paddingBottom: '0px'}}>    
                    <img src="../assets/img/veev_solution.png" width={'100%'}></img>
                  </div> */}
                  <div className="col-lg-8 my-auto text-center" style={{ paddingTop: '3px', paddingBottom: '0px'}}>    
                    <p className="title0">Increase Sales</p>
                    <p className="titlethin30">With Automated Rewards Program Powered By</p>
                    <p className="title0">AI</p>

                    <p style={{width: '100px', paddingTop: '20px', marginLeft: "auto", marginRight: "auto"}}>
                      <Button
                      block
                      type="primary"
                      size="large"
                      onClick={() => {navigateTo()}}>
                      Sign Up
                      </Button>
                    </p> 
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12 text-center" style={{padding: 20}}>
              <a href="#howitworks">
              <img src={"../assets/img/512px-chevron_fontawesome.svg.png"} width={"30px"} style={{ paddingTop: '70px', marginLeft: '10px', textAlign: 'center', alignItems: 'center' }} className="img-fluid" />
              </a>
            </div>
          </div>
        </section>

        <section className="cta text-center" style={{paddingTop: 70, height: "100%", paddingBottom: 90}}>
          <div className="container" style={{paddingTop: '20px', paddingBottom: "20px"}}>
            <div className="col-lg-12 my-auto">
              <div className="container-fluid">
                <div className="row">
                <div className="col-lg-3 dv-loadmoney text-center" style={{ paddingBottom: '0px', marginBottom: '50px' }}>
                    <div className="device-container">
                      <div className="device-mockup iphone6 portrait gold">
                        <div className="device" style={{marginTop: 20, marginBottom: 0}}>
                          <div className="screen">	
                            <div className="slideshow-container">
                              <div className="mySlides">
                                <div className="veevslogan">
                                  <img src={slider} alt="VEEV Checkin" className="img-fluid2" />
                                </div>
                              </div>
                            </div>
                            <div style={{ textAlign: 'center' }}>
                              <span className="dot"></span>
                              <span className="dot"></span>
                              <span className="dot"></span>
                              <span className="dot"></span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-1 dv-loadmoney">
                  </div>
                  {/* <div className="col-lg-4 my-auto text-center" style={{ paddingTop: '3px', paddingBottom: '0px'}}>    
                    <img src="../assets/img/veev_solution.png" width={'100%'}></img>
                  </div> */}
                  <div className="col-lg-8 my-auto text-center" style={{ paddingTop: '3px', paddingBottom: '0px'}}>    
                    <div className="text-left" style={{width: '100%', paddingTop: 30, paddingLeft: '0px' }}>
                      <div style={{ width: '100%', paddingBottom: '12px', paddingRight: '20px', color: 'grey' }}>
                      <p className="title4">81% Purchase from Retailers Offering Rewards</p>
                      <p style={{ width: '100%', color: 'grey' }}>Which increases customer retention, a 5% retention increases profitability by 75%.</p>
                      </div>
                      <div style={{ width: '100%', paddingBottom: '8px', paddingRight: '20px', color: 'grey' }}> 
                      <p className="title4">78% Customers Earning Rewards Recommend Products</p>
                      <p style={{ width: '100%', color: 'grey' }}>And are refer 50% more people than one time buyers.</p>
                      </div>
                      <div style={{ width: '100%', paddingBottom: '20px', paddingRight: '20px', color: 'grey' }}> 
                      <p className="title4">67% More Spent by Customers Getting Rewards</p>
                      <p style={{ width: '100%', color: 'grey' }}>And 80% of future profits come from 20% of customers.</p>
                      </div>
                    </div>
                    
                    <p style={{width: '100px', paddingTop: '20px', marginLeft: "auto", marginRight: "auto"}}>
                      <Button
                      block
                      type="primary"
                      size="large"
                      onClick={() => {navigateTo()}}>
                      Sign Up
                      </Button>
                    </p> 
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12 text-center" style={{padding: 20}}>
              <a href="#howitworks">
              <img src={"../assets/img/512px-chevron_fontawesome.svg.png"} width={"30px"} style={{ paddingTop: '70px', marginLeft: '10px', textAlign: 'center', alignItems: 'center' }} className="img-fluid" />
              </a>
            </div>
          </div>
        </section>

        <section className="cta text-center" style={{paddingTop: '100px', paddingBottom: '60px' }} id="crm">
          <div className="container">
            <div className="col-lg-12 my-auto text-center">
              <p className="title1" style={{paddingBottom: '30px' }}>CRM, Marketing & Promotion System</p>  
            </div>
          </div>
          <div className="container">
            <div className="col-lg-12 my-auto">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-6 dv-loadmoney text-center" style={{ maxHeight: 'auto' }}>
                    <div className="device-container">
                      <div className="device-mockup imac imac">
                        <div className="device" style={{marginTop: 0, marginBottom: 40}}>
                          <div className="screen">	
                            <div className="slideshow-container">
                              <div className="mySlides">
                                <div className="veevslogan">
                                  <img src={slider4} className="img-fluid" />
                                </div>
                              </div>
                            </div>
                            <div style={{ textAlign: 'center' }}>
                              <span className="dot"></span>
                              <span className="dot"></span>
                              <span className="dot"></span>
                              <span className="dot"></span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 text-left" style={{width: '100%', paddingTop: 5, paddingLeft: '0px' }}>
                    <div style={{ width: '100%', paddingBottom: '20px', paddingRight: '20px', color: 'grey' }}> 
                    <p className="title4">CRM</p>
                    <p style={{ width: '100%', color: 'grey' }}>CRM of customers, their rewards earnings, engage and build brand value.</p>
                    </div>
                    <div style={{ width: '100%', paddingBottom: '12px', paddingRight: '20px', color: 'grey' }}>
                    <p className="title4">Marketing & Promotions</p>
                    <p style={{ width: '100%', color: 'grey' }}>Marketing and promotions to customers to generate demand.</p>
                    </div>
                    <div style={{ width: '100%', paddingBottom: '8px', paddingRight: '20px', color: 'grey' }}> 
                    <p className="title4">Sales</p>
                    <p style={{ width: '100%', color: 'grey' }}>Increase sales with incentives to customers and prospects.</p>
                    </div>
                    <p style={{width: '100px', paddingTop: '20px', marginLeft: "auto", marginRight: "auto"}}>
                      <Button
                      block
                      type="primary"
                      size="large"
                      onClick={() => {navigateTo()}}>
                      Sign Up
                      </Button>
                    </p> 
                  </div>
                </div>
              </div>
            </div>
          </div>   
        </section>

        <section className="cta text-center" style={{paddingTop: 70, height: "100%", paddingBottom: 90}}>
          <div className="container" style={{paddingTop: '20px', paddingBottom: "20px"}}>
            <div className="col-lg-12 my-auto">
              <div className="container-fluid">
                <div className="row">
                <div className="col-lg-3 dv-loadmoney text-center" style={{ paddingBottom: '0px', marginBottom: '50px' }}>
                    <div className="device-container">
                      <div className="device-mockup iphone6 portrait gold">
                        <div className="device" style={{marginTop: 20, marginBottom: 0}}>
                          <div className="screen">	
                            <div className="slideshow-container">
                              <div className="mySlides">
                                <div className="veevslogan">
                                  <img src={slider} alt="VEEV Checkin" className="img-fluid2" />
                                </div>
                              </div>
                            </div>
                            <div style={{ textAlign: 'center' }}>
                              <span className="dot"></span>
                              <span className="dot"></span>
                              <span className="dot"></span>
                              <span className="dot"></span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-1 dv-loadmoney">
                  </div>
                  {/* <div className="col-lg-4 my-auto text-center" style={{ paddingTop: '3px', paddingBottom: '0px'}}>    
                    <img src="../assets/img/veev_solution.png" width={'100%'}></img>
                  </div> */}
                  <div className="col-lg-8 my-auto text-center" style={{ paddingTop: '3px', paddingBottom: '0px'}}>    
                    
                    <p className="titlethin30">Introductory Price</p>
                    <p className="title0">$49</p>
                    
                    <p style={{width: '100px', paddingTop: '20px', marginLeft: "auto", marginRight: "auto"}}>
                      <Button
                      block
                      type="primary"
                      size="large"
                      onClick={() => {navigateTo()}}>
                      Sign Up
                      </Button>
                    </p> 
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <section className="cta text-center" style={{paddingTop: '100px', paddingBottom: '60px' }} id="pricing">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 my-auto">
                <div className="container-fluid">
                  <p className="title1">Pricing</p>
                  <p></p>
                </div>
              </div>
            </div>
          </div>
          <div className="container" id="#pricing">
            <Plans/>
          </div>
          <div className="row" style={{ width: '100%', paddingBottom: '30px', paddingTop: '5px', paddingRight: '20px', color: 'grey' }}>
              <div className="col-lg-5">
              
              </div>
              <div className="col-lg-2">
                <Button
                  block
                  type="primary"
                  size="large"
                  onClick={toggleModal || null}>
                  Get Started
                  </Button>
              </div>
              <div className="col-lg-5">
              
              </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-lg-12 my-auto">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-lg-12"> 
                     
                      One system to run your entire operations. VEEV Pay provides your business Mobile Ordering and fullfilment capability for items listed in you POS. Mobile orders initiated from VEEV Wallets are automatically organized into customer’s fulfillment preference, pickup, deliver, or ship. 

                      Supports instant mobile ordering and pay from any smart phone with simple Click & Pay, approve transaction. Payment are processed instantly. Purchase receipt is displayed instantly.<br /><br />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>    
        </section> */}
      </div>
    </Styles>
  <Footer />
  {/* </AuthLayout> */}
  </>  
  )
}
export default Business