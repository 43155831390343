import Routes from './router/index'
import "react-notifications/lib/notifications.css";
import {NotificationContainer} from "react-notifications";
import {ConfigProvider} from "antd";
import {SettingProvider} from './provider/setting'
import { CartProvider } from './provider/checkout';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import GTM from './components/GTM';

function App() {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#cc0000',
        },
      }}>
      <GTM />
      <NotificationContainer/>
      <ToastContainer/>
      <SettingProvider>
        <CartProvider>
          <Routes/>
        </CartProvider>
      </SettingProvider>
    </ConfigProvider>
  );
}

export default App;
