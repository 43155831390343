import styled from "styled-components";

export const Styles = styled.div`
  /* .stores {
     section {
       .store_container {
         text-decoration: none;
         #store_a {
           margin: 10px 0px;
             .store_logo_wrapper {
               min-height: 210px;
               max-height: 210px;
               background-size: auto 210px;
               background-repeat: no-repeat;
               background-position: center center;
               border: solid 1px #cecece;
               border-radius: 5px;
             }
           .store_name_address {
             .firstChild {
             }
             .secondChild {
               min-width: 50px;
             }
           }
         }
       }
     }
   }*/

  .container {
    padding-top: 30px;
    position: relative;
    min-height: 86vh;

    .card {
      margin-bottom: 24px;
      border: none;
      border-radius: 0px;
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

      :hover {
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      }

      h4 {
        font-weight: 700;
        font-size: 24px;
      }

      p {
        margin-top: 12px;
        margin-bottom: 0;
        font-size: 14px;
        line-height: 1.2;
      }
      
      @media(max-width: 600px) {
        .ant-avatar {
          width: 100px!important;
          height: 100px!important;
          
          .ant-avatar-string {
            line-height: 100px!important;
          }
        }
      }
    }
  }

  .ant-input-group {
    input {
      margin-top: 0;
    }
  }

  .ad-image {
    max-width: 100%;
    margin-bottom: 12px;
    border-radius: 12px;
  }
`;

export const ModalStyles = styled.div`
  div.modal-image {
    min-height: 300px;
    background-color: #cfcfcf;

    img {
      width: 100%;
    }
  }
`;