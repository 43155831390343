import React, {useState, useEffect} from 'react'
import { getRandomLowString } from "../../../service/string";

const QrBox = (props) => {
  const { donation, next } = props
  const [qrSrc, setQrSrc] = useState('')
  const [isMonthly, setIsMonthly] = useState(false)
  const [isAddFee, setIsAddFee] = useState(false)

  const handleClickCredit = () => {
    next()
  }
  const initQr = () => {
    const receiptUuid = getRandomLowString(8) + "-" + getRandomLowString(4) + "-" + getRandomLowString(4) + "-" + getRandomLowString(4) + "-" + getRandomLowString(12)
    const data = [
      "veev-tx",
      donation.legal_name,
      `${donation.street}, ${donation.city}, ${donation.state}, ${donation.zip}`,
      receiptUuid,
      donation.merchant_code,
      donation?.wallet?.address,
      true,
      '0.00',
      0,
      parseFloat(donation.tax_info / 10)
    ]
    const data_str = data.join("|||");
    const qr_src =
      "https://chart.googleapis.com/chart?cht=qr&chs=200x200&chld=H7C0&chl=" +
      data_str;
    setQrSrc(qr_src)
  }

  useEffect(() => {
    initQr()
  }, [])
  return (
    <>
      <div className='row items'>
        <div className='col-3 p-3'>
          <div className='item'>
            <div className='price'>$0.05</div>
            <div className='currency'>USD</div>
          </div>
        </div>
        <div className='col-3 p-3'>
          <div className='item'>
            <div className='price'>$0.05</div>
            <div className='currency'>USD</div>
          </div>
        </div>
        <div className='col-3 p-3'>
          <div className='item'>
            <div className='price'>$0.05</div>
            <div className='currency'>USD</div>
          </div>
        </div>
        <div className='col-3 p-3'>
          <div className='item other'>
            <div className='price'>Other</div>
          </div>
        </div>
      </div>
      <div className="form-group mt-3">
        <fieldset>
          <input type="checkbox" checked={isMonthly} onChange={(e) => setIsMonthly(e.target.checked)} /> Make this a monthly donation
        </fieldset>
      </div>
      <div className="form-group">
        <fieldset>
          <input type="checkbox" checked={isAddFee} onChange={(e) => setIsAddFee(e.target.checked)} /> I'd like to add to my donation to help offset the cost of processing fees
        </fieldset>
      </div>
      <section id="checkout_header">
        <section id="qr_image_parent">
          <img id="qr_image" src={ qrSrc } />
        </section>
        {/* <h4 className="qrcheckout_description"> Or continue from the web </h4> */}
        <section className="cancel_submit_order">
          <span id="pay_with_credit_card"> <a onClick={handleClickCredit}> Pay with Credit Card </a> </span>
          {/* <label htmlFor="submit_user_pickup_info" type="button" id="submit_user_pickup_label"> Web Pay </label> */}
        </section>
      </section>
    </>
  )
}
export default QrBox
