import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import HeaderHome from "../../../components/header/authhome";
import FooterHome from "../../../components/footer/footerPage";
import { Styles } from '../../../layouts/style/homeStyle'

const Checkout = () => {
  const [slider, setSlider] = useState('')
  const sliders = useMemo(() => [
    // "../assets/img/checkin_connect.png",
    "../assets/img/veev_contactid2.png",
    "../assets/img/veev_contactid3.png",
    "../assets/img/veev_social1.png",
    "../assets/img/veev_social2.png",
  ], []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    setSlider(sliders[0])
    let index = 0
    let timer = setInterval(() => {
      index += 1;
      if (index >= sliders.length)
        index = 0
      setSlider(sliders[index])
    }, 2000);
    return () => clearInterval(timer);
  }, [sliders])

  const [slider2, setSlider2] = useState('')
  const sliders2 = useMemo(() => [
    "../assets/img/veev_social1.png",
    "../assets/img/veev_social2.png",
  ], []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    setSlider2(sliders2[0])
    let index = 0
    let timer = setInterval(() => {
      index += 1;
      if (index >= sliders2.length)
        index = 0
      setSlider2(sliders2[index])
    }, 2000);
    return () => clearInterval(timer);
  }, [sliders2])

  return (
    <>
    <HeaderHome />
    <Styles>
      <div className="guide">
        <section className="features">
          <div className="container container--main">
            <div className="row h-100">
              <div className="col-lg-12 my-auto">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-lg-4 my-auto text-center" style={{ paddingTop: '75px'}}>
                      <img src="../assets/img/veevqr.png" style={{ marginTop: 10, marginBottom: 20, height: 120 }} alt="VEEV" />
                      <p className="title1">Scan &</p>
                      <p className="title">Connect</p>
                      <div className="section-heading text-center submit-button">
                          <a className="badge-link" style={{ textAlign: 'center', display: 'block' }} href="https://apps.apple.com/app/veev-app/id6444875499">
                          <img src="../assets/img/appstore-apple-light.svg" style={{ marginTop: 20, height: 38 }} alt="VEEV" />
                          </a>
                      </div> 
                    </div>
                    <div className="col-lg-1 dv-loadmoney">
                    </div>
                    <div className="col-lg-3 dv-loadmoney text-center" style={{ paddingBottom: '0px', marginBottom: '0' }}>
                      <div className="device-container">
                        <div className="device-mockup iphone6 portrait gold">
                          <div className="device" style={{marginTop: 20, marginBottom: 0}}>
                            <div className="screen">	
                              <div className="slideshow-container">
                                <div className="mySlides">
                                  <div className="veevslogan">
                                    <img src={slider} alt="VEEV Checkin" className="img-fluid2" />
                                  </div>
                                </div>
                              </div>
                              <div style={{ textAlign: 'center' }}>
                                <span className="dot"></span>
                                <span className="dot"></span>
                                <span className="dot"></span>
                                <span className="dot"></span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4" style={{ textAlign: 'left', display: 'flex', alignItems: 'center' }}>
                      <div className="row">
                        <div style={{ width: '100%', paddingBottom: '25px', paddingTop: '25px', paddingLeft: '50px', color: 'grey' }}>
                        <p className="title3black">Links, vCard, Contacts</p>
                        One Link with all you links {/* issuance and redemption */} system for bars, clubs, events & access controlled venues <a href="#checkin" style={{ color: '#cc0000' }}>more >></a> 
                        </div>
                        <div style={{ width: '100%', paddingBottom: '25px', paddingTop: '0px', paddingLeft: '50px', color: 'grey' }}>
                        <p className="title3black">Social </p>
                        One click signup, pay, ID verification and ticketing {/* issuance and redemption */} system for bars, clubs, events & access controlled venues <a href="#checkin" style={{ color: '#cc0000' }}>more >></a> 
                        </div>
                        <div style={{ width: '100%', paddingBottom: '0px', paddingLeft: '50px', color: 'grey' }}> 
                        <p className="title3black">Recommendations</p>
                        One Link with all you links. marketers, businesses, salespeople & influencers <a href="#connect" style={{ color: '#cc0000' }}>more >></a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12 text-center" style={{padding: 10}}>
            <a href="#checkin">
            <img src={"../assets/img/512px-chevron_fontawesome.svg.png"} width={"30px"} style={{ paddingTop: '70px', marginLeft: '10px', textAlign: 'center', alignItems: 'center' }} className="img-fluid" />
            </a>
            </div>
          </div>
        </section>

        <section className="cta text-center" style={{paddingTop: '100px', paddingBottom: '60px' }} id="checkin">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 my-auto">
                <div className="container-fluid">
                  <p className="title1">Links, Contacts, Social</p><br></br>
                  <p className="title3black text-center">One Click Contact Sharing</p><br></br>    
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="col-lg-12 my-auto">
              <div className="container-fluid">
                <div className="row">   
                  <div className="col-lg-3" style={{paddingTop: 33}}>
                    <div className="device-mockup iphone6 portrait gold">
                      <div className="device">
                        <div className="screen">
                        <img src="../assets/img/veev_contactid2.png" className="img-fluid2" alt="" />
                        </div>
                      </div>
                    </div>
                    <div className="section-heading text-center" style={{paddingTop: 20}}>
                      <div style={{ width: '100%', paddingBottom: '25px', paddingTop: '0px', color: 'grey' }}> 
                        <p className="title4">Links</p>
                        text here
                        </div>  
                    </div>
                  </div>
                  <div className="col-lg-2 dv-loadmoney">
                  </div>
                  <div className="col-lg-3 dv-loadmoney" style={{paddingTop: 33}}>
                    <div className="device-mockup iphone6 portrait gold">
                      <div className="device">
                        <div className="screen">
                        <img src="../assets/img/veev_contactid3.png" className="img-fluid2" alt="" />
                        </div>
                      </div>
                    </div>
                    <div className="section-heading text-center" style={{paddingTop: 20}}>
                      <div style={{ width: '100%', paddingBottom: '25px', paddingTop: '0px', color: 'grey' }}> 
                        <p className="title4">Contact Share</p>
                        text here
                        </div>  
                    </div>
                  </div>
                  <div className="col-lg-1 dv-loadmoney">
                  </div>  
                  <div className="col-lg-3" style={{paddingTop: 33}}>   
                    <div className="device-container">
                      <div className="device-mockup iphone6 portrait gold">
                        <div className="device" style={{marginTop: 0, marginBottom: 0}}>
                          <div className="screen">	
                            <div className="slideshow-container">
                              <div className="mySlides2">
                                <div className="veevslogan">
                                  <img src={slider2} alt="VEEV Checkin" className="img-fluid2" />
                                </div>
                              </div>
                            </div>
                            <div style={{ textAlign: 'center' }}>
                              <span className="dot"></span>
                              <span className="dot"></span>
                              <span className="dot"></span>
                              <span className="dot"></span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="section-heading text-center" style={{paddingTop: 20}}>
                      <div style={{ width: '100%', paddingBottom: '25px', paddingTop: '0px', color: 'grey' }}> 
                        <p className="title4">Social & Recommendations</p>
                        text here
                        </div>  
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="section-heading text-center submit-button" style={{ paddingTop: '35px', paddingBottom: '30px'}}>
            <a className="badge-link" style={{ textAlign: 'center', display: 'block' }} href="https://apps.apple.com/app/veev-app/id6444875499">
            <img src="../assets/img/appstore-apple-light.svg" style={{ marginTop: 20, height: 38 }} alt="VEEV" />
            </a>
          </div>    
        </section>
        
        <section className="cta text-center" style={{paddingTop: '100px', paddingBottom: '50px' }} id="connect">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 my-auto">
                <div className="container-fluid">
                  <p className="title1">Connect</p>
                  <p className="title3black text-center">One Click Contact Sharing</p><br></br><br></br>      
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="col-lg-12 my-auto">
              <div className="container-fluid">
                <div className="row">   
                  <div className="col-lg-3">  
                    <div className="device-mockup iphone6 portrait gold">
                      <div className="device">
                        <div className="screen">
                          <img src="../assets/img/checkin_connect.png" className="img-fluid2" alt="" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-9 text-left" style={{width: '100%', paddingTop: 0, paddingLeft: '50px' }}>
                    <div style={{ width: '100%', paddingBottom: '25px', paddingTop: '25px', paddingRight: '20px', color: 'grey' }}>
                    <p className="title4">Connect</p>
                    Connect is a digital business card with community engagement and notification tools to keep your community informed.  
                    </div>
                    <div style={{ width: '100%', paddingBottom: '25px', paddingRight: '20px', color: 'grey' }}> 
                    <p className="title4">Contact Sharing</p>
                    QR based contact sharing tool to share contact information in one click.
                    </div>
                    <div style={{ width: '100%', paddingBottom: '25px', paddingRight: '20px', color: 'grey' }}> 
                    <p className="title4">Community Building</p>
                    A robust community building system that lets you build community even without you physically being there.
                    </div>
                    <div style={{ width: '100%', paddingBottom: '25px', paddingRight: '20px', color: 'grey' }}> 
                    <p className="title4">Notifications</p>
                    Send notifications, call to action, alerts and updates to your community. 
                    </div>
                  </div>
                </div>  
              </div>
            </div>
          </div>
          <div className="section-heading text-center submit-button" style={{ paddingTop: '25px'}}>
            <a href="/signin">
              <button className="btn btn-success btn-sm btn-create-account" id="signup_btn" type='signup'>
              Sign Up
              </button>
            </a>
          </div>  
        </section>
      </div>
    </Styles>
    <FooterHome />
  </>  
  )
}
export default Checkout
