import React, { useState, useEffect } from "react";
import dayjs from "dayjs";

import {
  DayPilot,
  DayPilotCalendar,
  DayPilotNavigator,
} from "@daypilot/daypilot-lite-react";

const CalendarSchedule = ({ columns, events }) => {
  const barColor = "#CC0000";

  const [datePickerHidden, setDatePickerHidden] = useState(false);
  const [config, setConfig] = useState({
    viewType: "Resources",
    columns: [],
    events: [],
  });

  const [startDate, setStartDate] = useState((new Date()).toISOString());

  useEffect(() => {
    setConfig({
      ...config,
      columns: columns,
      events: events.map((event) => ({
        id: event.id,
        text: `${event.title} \n${dayjs(event.start).format('h:mm a')} - ${dayjs(event.end).format('h:mm a')}`,
        start: dayjs(event.start).format("YYYY-MM-DDTHH:mm:ss"),
        end: dayjs(event.end).format("YYYY-MM-DDTHH:mm:ss"),
        barColor: barColor,
        resource: event.resourceId,
      })),
    });
  }, [columns, events]);

  useEffect(() => {
    window
    .matchMedia("(min-width: 768px)")
    .addEventListener('change', e => setDatePickerHidden( !e.matches ));
  }, []);

  const previousDay = () => {
    setStartDate(dayjs(startDate).subtract(1, 'day').toISOString())
  };

  const nextDay = () => {
    setStartDate(dayjs(startDate).add(1, "day").toISOString());
  };

  const handleEventClick = async (args) => {
    console.log(args.e.text());
  };

  const handleEventMove = async (args) => {
    console.log(args)
  }

  return (
    <div>
      <div
        style={{
          backgroundColor: "#F9F9F9",
          borderRadius: "8px",
          padding: "10px 20px",
          boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
          display: "flex",
          alignItems: "center",
          justifyContent: "start",
          flexWrap: "wrap",
          marginBottom: "20px",
          border: "1px solid #E0E0E0",
        }}
      >
        <span style={{ margin: "0px 10px" }}>
          Day: {dayjs(startDate).format("MMMM D, YYYY").toString()}
        </span>
        <button onClick={(e) => previousDay()}>Previous</button>
        <button onClick={(e) => nextDay()}>Next</button>
      </div>
      <div style={{ display: "flex" }}>
        {!datePickerHidden && (
          <div>
            <DayPilotNavigator
              selectMode={"Day"}
              showMonths={1}
              selectionDay={startDate}
              startDate={startDate}
              onTimeRangeSelected={(args) => {
                const date = startDate.value || startDate;
                if (!date.match(args.day.value)) {
                  setStartDate(args.day);
                }
              }}
            />
          </div>
        )}
        <div style={{ flexGrow: 1 }}>
          <DayPilotCalendar
            {...config}
            startDate={startDate}
            onEventClick={handleEventClick}
            onEventMove={handleEventMove}
            eventMoveHandling={"Disabled"}
            onEventResize={() => {}}
            allowEventOverlap={"false"}
          />
        </div>
      </div>
      <br />
      <br />
    </div>
  );
};

export default CalendarSchedule;
