import React, {useEffect, useState} from 'react';
import { Button, Flex } from 'antd';

const TimeSlotGrid = ({ date, size, merchant_id }) => {
    const [timeSlots, setTimeSlots] = useState([]);

    useEffect(() => {
        const fetchTimeSlot = async () => {
            const datetime = new Date(`${date}`)
            const response = await fetch(process.env.REACT_APP_API_URL + `/reservations/available?merchant_id=${merchant_id}&date=${datetime}&size=${size}`);
            const data = await response.json();
            setTimeSlots(data.data || []);
        }
        fetchTimeSlot();
    }, [date, size, merchant_id]);

    const selectTimeSlot = async (e) => {
        const timeslot = e.target.textContent;
        const datetime = new Date(`${date} ${timeslot}`)

        const response = await fetch(process.env.REACT_APP_API_URL + `/reservations/available?merchant_id=${merchant_id}&date=${datetime}&size=${size}`, {method: "POST"});
        const data = await response.json();

        window.location.href = `/reservation/details?size=${size}&slotHash=${data.slot_hash}&expire_at=${data.expire_time}&date=${datetime.getTime()}`
    }

    return (
        <article>
            <h3 style={{ fontWeight: "200", fontSize: "medium" }} data-test="select-time-header">Select a time:</h3>
            <Flex wrap="wrap" gap="small">
                {timeSlots.length === 0 
                    ? <div> No times available for {date} </div>
                    : timeSlots.map(timeSlot =>
                        <Button
                            key={timeSlot}
                            type="primary"
                            onClick={selectTimeSlot}
                        >
                            {timeSlot}
                        </Button>
                    )
                }
            </Flex>
        </article>
    )
}

export default TimeSlotGrid;
