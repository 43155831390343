import React, {useEffect, useMemo, useState} from "react";
import {Input} from 'antd';
import {useNavigate} from "react-router-dom";
import {Avatar, Button, Dropdown, Rate, Space} from "antd";
import HeaderHome from "../../../components/header/authhome";
import FooterHome from "../../../components/footer/footerPage";
import {Styles} from '../../../layouts/style/homeStyle'

const Home = (props, toggleModal) => {
    const {Search} = Input;
    const navigate = useNavigate();
    
    const [slider, setSlider] = useState('')
    const sliders = useMemo(() => [
        "../assets/img/veev_1.png",
        "../assets/img/veevcash3.png",
        "../assets/img/veev_checkout.png",
        "../assets/img/veev_3.png",
    ], []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    
    useEffect(() => {
        setSlider(sliders[0])
        let index = 0
        let timer = setInterval(() => {
            index += 1;
            if (index >= sliders.length)
                index = 0
            setSlider(sliders[index])
        }, 2000);
        return () => clearInterval(timer);
    }, [sliders])

  const [slider2, setSlider2] = useState('')
  const sliders2 = useMemo(() => [
    "../assets/img/veevcash1.png",
    "../assets/img/veevcash2.png",
    "../assets/img/veevcash3.png",
  ], []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    setSlider2(sliders2[0])
    let index = 0
    let timer = setInterval(() => {
      index += 1;
      if (index >= sliders2.length)
        index = 0
      setSlider2(sliders2[index])
    }, 2000);
    return () => clearInterval(timer);
  }, [sliders2])

  const [slider3, setSlider3] = useState('')
  const sliders3 = useMemo(() => [
    "../assets/img/veevcash4.png",
  ], []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    setSlider3(sliders3[0])
    let index = 0
    let timer = setInterval(() => {
      index += 1;
      if (index >= sliders3.length)
        index = 0
      setSlider3(sliders3[index])
    }, 2000);
    return () => clearInterval(timer);
  }, [sliders3])

  const [slider4, setSlider4] = useState('')
  const sliders4 = useMemo(() => [
    "../assets/img/veev_3.png",
  ], []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    setSlider4(sliders4[0])
    let index = 0
    let timer = setInterval(() => {
      index += 1;
      if (index >= sliders4.length)
        index = 0
      setSlider4(sliders4[index])
    }, 2000);
    return () => clearInterval(timer);
  }, [sliders4])

//   ------

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const onSearch = (keyword) => {
        navigate(`/search/${keyword}`)
    }

    return (
        <>
        <HeaderHome/>
            <Styles>
                <div className="guide" id="home" style={{paddingTop: 0, paddingBottom: 0}}>   
                    <section className="cta text-center" style={{paddingTop: 70, height: "100%", paddingBottom: 90}} id="">
                        <div className="container" style={{paddingTop: '20px', paddingBottom: "20px"}}>
                            <div className="col-lg-12 my-auto">
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-lg-4 my-auto text-center" style={{ paddingTop: '3px', paddingBottom: '10px'}}>
                                            <img className="wallet" style={{textAlign: 'center', display: 'block'}}
                                                src="../../assets/img/veevcoin.png"
                                                style={{marginTop: 0, marginBottom: 10, height: 80}} alt="VEEV Cash"/>    
                                            {/* <p className="titlethin30" style={{ paddingTop: '-5px', paddingBottom: '0px'}}>Earn</p>     */}
                                            <p className="title0">VEEV<sup style={{textDecoration: 'none'}}>&reg;</sup> Rewards</p>
                                            {/* <p className="titlethin30" style={{ paddingTop: '-5px', paddingBottom: '0px'}}>Everywhere</p> */}
                                        </div>
                                        <div className="col-lg-1 dv-loadmoney">
                                        </div>
                                        <div className="col-lg-3 dv-loadmoney text-center" style={{ paddingBottom: '0px', marginBottom: '50px' }}>
                                            <div className="device-container">
                                                <div className="device-mockup iphone6 portrait gold">
                                                    <div className="device" style={{marginTop: 20, marginBottom: 0}}>
                                                        <div className="screen">	
                                                            <div className="slideshow-container">
                                                                <div className="mySlides">
                                                                    <div className="veevslogan">
                                                                    <img src={slider} alt="VEEV Checkin" className="img-fluid2" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div style={{ textAlign: 'center' }}>
                                                            <span className="dot"></span>
                                                            <span className="dot"></span>
                                                            <span className="dot"></span>
                                                            <span className="dot"></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 my-auto text-center" style={{ paddingTop: '3px', paddingBottom: '0px'}}>    
                                        {/* <p className="title3">Easy, Simple, Fast</p> */}
                                        {/* <img className="wallet" style={{textAlign: 'center', display: 'block'}}
                                                src="../../assets/img/veevcoin.png"
                                                style={{marginTop: 0, marginBottom: 10, height: 80}} alt="VEEV Cash"/> */}
                                            <p className="title3">Earn VEEV<sup style={{textDecoration: 'none'}}>&reg;</sup> Cash Rewards for purchases at cafes, restaurants, stores, etc.</p>
                                            <br/>Download App<br/>
                                            <a className="badge-link"
                                            href="https://apps.apple.com/us/app/veev-earn-rewards/id6478800230">
                                            <img className="wallet" style={{textAlign: 'center', display: 'block'}}
                                            src="https://developer.apple.com/news/images/download-on-the-app-store-badge.png"
                                            style={{marginTop: 5, height: 38, marginRight: 5}} alt="VEEV App"/></a>

                                            <a className="badge-link"
                                            href="https://play.google.com/store/apps/details?id=com.veevinc.veev&pli=1">
                                            <img className="wallet" style={{textAlign: 'center', display: 'block'}}
                                            src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                                            style={{marginTop: 5, height: 52, marginLeft: 5}} alt="VEEV App"/></a>
                                        </div>
                                    </div> 
                                </div>
                            </div>
                            <div className="col-lg-12 text-center" style={{padding: 20}}>
                            <a href="#rewards">
                            <img src={"../assets/img/512px-chevron_fontawesome.svg.png"} width={"30px"} style={{ paddingTop: '30px', marginLeft: '10px', textAlign: 'center', alignItems: 'center' }} className="img-fluid" />
                            </a>
                            </div>
                        </div>
                    </section>

                    <section className="cta text-center" style={{paddingTop: 70, height: "100%", paddingBottom: 90}} id="oneapp">
                        <div className="container" style={{paddingTop: '20px', paddingBottom: "20px"}}>
                            <div className="col-lg-12 my-auto">
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-lg-6 my-auto text-center" style={{ paddingTop: '3px', paddingBottom: '0px'}}>    
                                            {/* <p className="title0">All Your Rewards In One Place</p> */}
                                            <p className="title0">No More Carrying</p>
                                            <p className="titlethin30">Rewards Cards</p>
                                        </div>
                                        <div className="col-lg-6 my-auto text-center" style={{ paddingTop: '3px', paddingBottom: '0px'}}>    
                                            
                                            <img className="wallet" style={{textAlign: 'center', display: 'block'}}
                                            src="https://media.istockphoto.com/id/485640212/photo/stack-of-discount-cards-isolated-on-white-background-with-shadows.jpg?s=612x612&w=0&k=20&c=8HYrhmjwiu2KSXpUR93525t4cwuAqBvrdFERZ6FSJtY="
                                            style={{marginTop: 5, height: '100%', marginRight: 50}} alt="VEEV App"/>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12 text-center" style={{padding: 20}}>
                            <a href="#getstarted">
                            <img src={"../assets/img/512px-chevron_fontawesome.svg.png"} width={"30px"} style={{ paddingTop: '30px', marginLeft: '10px', textAlign: 'center', alignItems: 'center' }} className="img-fluid" />
                            </a>
                            </div>
                        </div>
                    </section>

                    <section className="cta text-center" style={{paddingTop: 70, height: "100%", paddingBottom: 50}} id="rewards">
                        <div className="container" style={{paddingTop: '20px', paddingBottom: "20px"}}>
                            <div className="col-lg-12 my-auto">
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-lg-4 my-auto text-center" style={{ paddingTop: '3px', paddingBottom: '10px'}}>    
                                            <p className="title0">One App</p>
                                            <p className="titlethin30" style={{ paddingTop: '-5px', paddingBottom: '0px'}}>For All Rewards</p>
                                        </div>
                                        <div className="col-lg-1 dv-loadmoney">
                                        </div>
                                        <div className="col-lg-3 dv-loadmoney text-center" style={{ paddingBottom: '0px', marginBottom: '50px' }}>
                                            <div className="device-container">
                                                <div className="device-mockup iphone6 portrait gold">
                                                    <div className="device" style={{marginTop: 20, marginBottom: 0}}>
                                                        <div className="screen">	
                                                            <div className="slideshow-container">
                                                                <div className="mySlides">
                                                                    <div className="veevslogan">
                                                                    <img src={slider2} alt="VEEV Checkin" className="img-fluid2" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div style={{ textAlign: 'center' }}>
                                                            <span className="dot"></span>
                                                            <span className="dot"></span>
                                                            <span className="dot"></span>
                                                            <span className="dot"></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 my-auto text-center" style={{ paddingTop: '3px', paddingBottom: '0px'}}>
                                            {/* <p className="title0">100% FREE</p> */}
                                            <p className="title3">Manage and store all your rewards in one place.</p>
                                            <br/>Download App<br/>
                                            <a className="badge-link"
                                            href="https://apps.apple.com/us/app/veev-earn-rewards/id6478800230">
                                            <img className="wallet" style={{textAlign: 'center', display: 'block'}}
                                            src="https://developer.apple.com/news/images/download-on-the-app-store-badge.png"
                                            style={{marginTop: 5, height: 38, marginRight: 5}} alt="VEEV App"/></a>

                                            <a className="badge-link"
                                            href="https://play.google.com/store/apps/details?id=com.veevinc.veev&pli=1">
                                            <img className="wallet" style={{textAlign: 'center', display: 'block'}}
                                            src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                                            style={{marginTop: 5, height: 52, marginLeft: 5}} alt="VEEV App"/></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12 my-auto">
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-lg-12 text-left" style={{width: '100%', paddingTop: '0px', paddingLeft: '40px' }}>
                                            <div style={{ width: '100%', paddingTop: '20px', paddingBottom: '0px', paddingRight: '20px', color: 'grey' }}>
                                            <p className="title4">What is VEEV Cash Rewards</p>
                                            <p style={{ width: '100%', color: 'grey' }}>VEEV Cash Rewards is a universal rewards currency on the blockchain.</p>
                                            </div>
                                            {/* <div style={{ width: '100%', paddingTop: '20px', paddingBottom: '0px', paddingRight: '20px', color: 'grey' }}>
                                            <p className="title4">What Is VEEV Cash</p>
                                            <p style={{ width: '100%', color: 'grey' }}>VEEV's rewards points are called VEEV Cash. VEEV Cash is a rewards points system on the blockchain.</p>
                                            </div> */}
                                            <div style={{ width: '100%', paddingTop: '20px', paddingBottom: '0px', paddingRight: '20px', color: 'grey' }}>
                                            <p className="title4">What Does That Mean</p>
                                            <p style={{ width: '100%', color: 'grey' }}>That means it can be redeem at all participating merchants and stores. ALL your rewards will be in one app. You no longer have to carry around hundreds of rewards cards with tiny balances, that you can only be redeem at a specific store.</p>
                                            </div>
                                            <div style={{ width: '100%', paddingBottom: '0px', paddingTop: '10px', paddingRight: '20px', color: 'grey' }}>
                                            <p className="title4">How Is It Different</p>
                                            <p style={{ width: '100%', color: 'grey' }}>It is different because you earn it when you pay at any merchant and you can redeem it at any purchase at any merchant.</p>
                                            </div>
                                            <div style={{ width: '100%', paddingBottom: '0px', paddingTop: '10px', paddingRight: '20px', color: 'grey' }}>
                                            <p className="title4">How Much Will I Earn</p>
                                            <p style={{ width: '100%', color: 'grey' }}>You will earn 1% and upto 5% at participating merchants. For every $1 spent you will earn 1 VEEV Cash or if you spend $100 at participating merchants you can earn upto 500 VEEV Cash.</p>
                                            </div>
                                            <div style={{ width: '100%', paddingBottom: '0px', paddingTop: '10px', paddingRight: '20px', color: 'grey' }}>
                                            <p className="title4">How Do I Redeem VEEV Cash</p>
                                            <p style={{ width: '100%', color: 'grey' }}>100 VEEV Cash can redeemed for $1 at participating merchants.</p>
                                            </div>
                                            <div style={{ width: '100%', paddingBottom: '0px', paddingTop: '10px', paddingRight: '20px', color: 'grey' }}>
                                            <p className="title4">Is There A Limit To How Much I Can Redeem</p>
                                            <p style={{ width: '100%', color: 'grey' }}>There is no limit.</p>
                                            </div>
                                            <div style={{ width: '100%', paddingBottom: '5px', paddingTop: '10px', paddingRight: '20px', color: 'grey' }} id="cashback">
                                            <p className="title4">Is It Free</p>
                                            <p style={{ width: '100%', color: 'grey' }}>Yes. Its 100% FREE!</p>
                                            </div>
                                            {/* <p className="title text-center" style={{paddingBottom: "5px", paddingTop: "20px"}}>Cash Back</p>
                                            <div style={{ width: '100%', paddingBottom: '0px', paddingTop: '10px', paddingRight: '20px', color: 'grey' }}>
                                            <p className="title4">How Does Cash Back Work</p>
                                            <p style={{ width: '100%', color: 'grey' }}>To get cash back a minimum of $25 is required in VEEV Cash. Cash back is subject to Terms & Conditions.</p>
                                            </div> */}
                                        </div>
                                    </div>  
                                </div>
                            </div>
                            {/* <div className="col-lg-12 text-center" style={{padding: 20}}>
                            <a href="#oneapp">
                            <img src={"../assets/img/512px-chevron_fontawesome.svg.png"} width={"30px"} style={{ paddingTop: '30px', marginLeft: '10px', textAlign: 'center', alignItems: 'center' }} className="img-fluid" />
                            </a>
                            </div> */}
                        </div>
                    </section>

                    {/* <section className="cta text-center" style={{paddingTop: 70, height: "100%", paddingBottom: 50}} id="recommendations">
                        <div className="container" style={{paddingTop: '20px', paddingBottom: "20px"}}>
                            <div className="col-lg-12 my-auto">
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-lg-4 my-auto text-center" style={{ paddingTop: '3px', paddingBottom: '10px'}}>    
                                            <p className="title0">AI</p>
                                            <p className="titlethin30" style={{ paddingTop: '-5px', paddingBottom: '0px'}}>Recommendations</p>
                                        </div>
                                        <div className="col-lg-1 dv-loadmoney">
                                        </div>
                                        <div className="col-lg-3 dv-loadmoney text-center" style={{ paddingBottom: '0px', marginBottom: '50px' }}>
                                            <div className="device-container">
                                                <div className="device-mockup iphone6 portrait gold">
                                                    <div className="device" style={{marginTop: 20, marginBottom: 0}}>
                                                        <div className="screen">	
                                                            <div className="slideshow-container">
                                                                <div className="mySlides">
                                                                    <div className="veevslogan">
                                                                    <img src={slider4} alt="VEEV Checkin" className="img-fluid2" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div style={{ textAlign: 'center' }}>
                                                            <span className="dot"></span>
                                                            <span className="dot"></span>
                                                            <span className="dot"></span>
                                                            <span className="dot"></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 my-auto text-center" style={{ paddingTop: '3px', paddingBottom: '0px'}}>
                                            <p className="title0">100% FREE</p>
                                            <p className="title3">Discover new experiences personalized to your tastes.</p>
                                            <br/>Download App<br/>
                                            <a className="badge-link"
                                            href="https://apps.apple.com/us/app/veev-earn-rewards/id6478800230">
                                            <img className="wallet" style={{textAlign: 'center', display: 'block'}}
                                            src="https://developer.apple.com/news/images/download-on-the-app-store-badge.png"
                                            style={{marginTop: 5, height: 38, marginRight: 5}} alt="VEEV App"/></a>

                                            <a className="badge-link"
                                            href="https://play.google.com/store/apps/details?id=com.veevinc.veev&pli=1">
                                            <img className="wallet" style={{textAlign: 'center', display: 'block'}}
                                            src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                                            style={{marginTop: 5, height: 52, marginLeft: 5}} alt="VEEV App"/></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12 my-auto">
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-lg-12 text-left" style={{width: '100%', paddingTop: '0px', paddingLeft: '40px' }}>
                                            <div style={{ width: '100%', paddingTop: '20px', paddingBottom: '0px', paddingRight: '20px', color: 'grey' }}>
                                            <p className="title4">What is VEEV Cash Rewards</p>
                                            <p style={{ width: '100%', color: 'grey' }}>VEEV Cash Rewards is a universal rewards currency on the blockchain.</p>
                                            </div>
                                            <div style={{ width: '100%', paddingTop: '20px', paddingBottom: '0px', paddingRight: '20px', color: 'grey' }}>
                                            <p className="title4">What Does That Mean</p>
                                            <p style={{ width: '100%', color: 'grey' }}>That means it can be redeem at all participating merchants and stores. ALL your rewards will be in one app. You no longer have to carry around hundreds of rewards cards with tiny balances, that you can only be redeem at a specific store.</p>
                                            </div>
                                            <div style={{ width: '100%', paddingBottom: '0px', paddingTop: '10px', paddingRight: '20px', color: 'grey' }}>
                                            <p className="title4">How Is It Different</p>
                                            <p style={{ width: '100%', color: 'grey' }}>It is different because you earn it when you pay at any merchant and you can redeem it at any purchase at any merchant.</p>
                                            </div>
                                            <div style={{ width: '100%', paddingBottom: '0px', paddingTop: '10px', paddingRight: '20px', color: 'grey' }}>
                                            <p className="title4">How Much Will I Earn</p>
                                            <p style={{ width: '100%', color: 'grey' }}>You will earn 1% and upto 5% at participating merchants. For every $1 spent you will earn 1 VEEV Cash or if you spend $100 at participating merchants you can earn upto 500 VEEV Cash.</p>
                                            </div>
                                            <div style={{ width: '100%', paddingBottom: '0px', paddingTop: '10px', paddingRight: '20px', color: 'grey' }}>
                                            <p className="title4">How Do I Redeem VEEV Cash</p>
                                            <p style={{ width: '100%', color: 'grey' }}>100 VEEV Cash can redeemed for $1 at participating merchants.</p>
                                            </div>
                                            <div style={{ width: '100%', paddingBottom: '0px', paddingTop: '10px', paddingRight: '20px', color: 'grey' }}>
                                            <p className="title4">Is There A Limit To How Much I Can Redeem</p>
                                            <p style={{ width: '100%', color: 'grey' }}>There is no limit.</p>
                                            </div>
                                            <div style={{ width: '100%', paddingBottom: '5px', paddingTop: '10px', paddingRight: '20px', color: 'grey' }} id="cashback">
                                            <p className="title4">Is It Free</p>
                                            <p style={{ width: '100%', color: 'grey' }}>Yes. Its 100% FREE!</p>
                                            </div>
                                        </div>
                                    </div>  
                                </div>
                            </div>
                            <div className="col-lg-12 text-center" style={{padding: 20}}>
                            <a href="#oneapp">
                            <img src={"../assets/img/512px-chevron_fontawesome.svg.png"} width={"30px"} style={{ paddingTop: '30px', marginLeft: '10px', textAlign: 'center', alignItems: 'center' }} className="img-fluid" />
                            </a>
                            </div>
                        </div>
                    </section> */}
                    
                    {/* <section className="cta text-center" style={{paddingTop: '70', paddingBottom: '60px' }} id="checkin">
                        <div className="container">
                            <div className="col-lg-12 my-auto">
                                <p className="title1 text-center">How It Works</p>
                                <div className="container-fluid">
                                    <div className="row">   
                                        <div className="col-lg-3" style={{paddingTop: 33}}>
                                        <div className="device-mockup iphone6 portrait gold">
                                        <div className="device">
                                            <div className="screen">
                                            <img src="../assets/img/recommendations.png" className="img-fluid2" alt="" />
                                            </div>
                                        </div>
                                        </div>
                                        <div className="section-heading text-center" style={{paddingTop: 0}}>
                                        <h3 style={{ width: '100%', paddingBottom: '0px', paddingTop: '20px', paddingLeft: '0px', color: '#000', fontWeight: '500'}}>Reviews & Recommendations</h3>
                                        <p style={{ width: '100%', paddingBottom: '25px', paddingTop: '0px', paddingLeft: '0px', color: 'grey'}}>
                                        manage all payment, bookings & rsvp across all social apps from one place 
                                        </p>                   
                                        </div>
                                        <div className="text-center">
                                        <a className="badge-link" style={{ textAlign: 'center', display: 'block' }} href="https://apps.apple.com/app/veev-app/id6444875499">
                                        <img className="wallet" src="https://developer.apple.com/news/images/download-on-the-app-store-badge.png" style={{ marginBottom: 40, height: 38 }} alt="VEEV App" /></a>
                                        </div>	
                                    </div>
                                    <div className="col-lg-2 dv-loadmoney">
                                    </div>
                                    <div className="col-lg-3 dv-loadmoney" style={{paddingTop: 33}}>
                                        <div className="device-mockup iphone6 portrait gold">
                                            <div className="device">
                                                <div className="screen">
                                                <img src="../assets/img/store.png" className="img-fluid2" alt="" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="section-heading text-center" style={{paddingTop: 0}}>
                                        <h3 style={{ width: '100%', paddingBottom: '0px', paddingTop: '20px', paddingLeft: '0px', color: '#000', fontWeight: '500'}}>Appointments, Reservations</h3>
                                        <p style={{ width: '100%', paddingBottom: '25px', paddingTop: '0px', paddingLeft: '0px', color: 'grey'}}>
                                        no more entering billing & shipping details over and over on multiple apps
                                        </p>                   
                                        </div>
                                        <div className="text-center">
                                        <a className="badge-link" style={{ textAlign: 'center', display: 'block' }} href="https://apps.apple.com/app/veev-app/id6444875499">
                                        <img className="wallet" src="https://developer.apple.com/news/images/download-on-the-app-store-badge.png" style={{ marginBottom: 40, height: 38 }} alt="VEEV App" /></a>
                                        </div>	
                                    </div>
                                    <div className="col-lg-1 dv-loadmoney">
                                    </div>  
                                    <div className="col-lg-3" style={{paddingTop: 33}}>   
                                        <div className="device-mockup iphone6 portrait gold">
                                            <div className="device">
                                                <div className="screen">
                                                <img src="../assets/img/veev_checkout.png" className="img-fluid2" alt="" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="section-heading text-center" style={{paddingTop: 0}}>
                                            <h3 style={{ width: '100%', paddingBottom: '0px', paddingTop: '20px', paddingLeft: '0px', color: '#000', fontWeight: '500'}}>Order, Pay & Receipting</h3>
                                            <p style={{ width: '100%', paddingBottom: '25px', paddingTop: '0px', paddingLeft: '0px', color: 'grey'}}>
                                                protect personal, financial, transaction & identity data from fraud, theft and resale
                                            </p>                   
                                            </div>
                                            <div className="text-center">
                                            <a className="badge-link" style={{ textAlign: 'center', display: 'block' }} href="https://apps.apple.com/app/veev-app/id6444875499">
                                            <img className="wallet" src="https://developer.apple.com/news/images/download-on-the-app-store-badge.png" style={{ marginBottom: 40, height: 38 }} alt="VEEV App" /></a>
                                            </div>	
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section> */}
        
                </div>
            </Styles>
        <FooterHome/>
        </>
    );
};

export default Home;