import React, { useEffect, useState, Fragment } from 'react'
import ActionCable from "actioncable";
import Plot from "react-plotly.js";
import Layout from '../../../layouts/dashboard';
import Tab from '../../../layouts/dashboard/components/checkout-tab';
import Sidebar from "./sidebar";
import { getCookie} from '../../../service/cookie'
// import { formatYmd } from '../../../service/string'
import { useAsync } from '../../../service/utils'
import { getItemsAll, getCrmTrxItemList } from '../../../api/bank'
import siteConfig from '../../../config/site.config';
import { NotificationManager } from 'react-notifications';
import { useSetting } from '../../../provider/setting'

const Transactions = () => {
  const { data, status, error, run } = useAsync({status: "idle"});
  const [setting] = useSetting()
  const [merchantName, setMerchantName] = useState('')
  const [itemsParse, setItemsParse] = useState([])
  const [transactions, setTransactions] = useState([])
  const [isLoad, setIsLoad] = useState(false)
  const [padInfo, setPadInfo] = useState({})
  const [totalVEEV, setTotalVEEV] = useState(0)
  const [totalETH, setTotalETH] = useState(0)
  const [cummulativeTotals, setCummulativeTotals] = useState([])
  const [cummulativeTotalsEth, setCummulativeTotalsEth] = useState([])
  const [topProducts, setTopProducts] = useState([])
  // const [initDuration, setInitDuration] = useState('Aug 30,2021 - Nov 28,2021 23:59:59')
  const [asyncState, setAsyncState] = useState('')
  
  const init = () => {
    const _merchantName = getCookie("signin_merchant_name");
    setMerchantName(_merchantName)
    const dateTo = new Date()
    dateTo.setHours(23);
    dateTo.setMinutes(59);
    dateTo.setSeconds(59);
    const dateFrom = new Date()
    dateFrom.setDate(dateFrom.getDate() - 90)
    // let _initDuration = formatYmd(dateFrom) + " - " + formatYmd(dateTo)
  }
  const changeTransactions = (transactions) => {
    let _transactions = transactions.filter((item) => item.merchant_id === parseInt(setting?.auth?.signin_merchant_user_id))
    _transactions = _transactions.sort((a, b) => {
      let comparison = 0;
      if (new Date(a.created_at) > new Date(b.created_at)) {
        comparison = -1;
      } else if (new Date(a.created_at) < new Date(b.created_at)) {
        comparison = 1;
      }
      return comparison;
    })
    let dayTotalsEth = {}
    let dayTotals = {}
    let _topProducts = {}
    let _totalVEEV = 0
    let _totalETH = 0
    _transactions = _transactions.map((transaction) => {
      const datetime = new Date(transaction.created_at);
      const date = datetime.toDateString().substr(4);
      transaction.date = date
      transaction.time = datetime.toTimeString().substr(0, 8);
      let receipt_items = Array();
      try {
        receipt_items = JSON.parse(transaction.receipt || '');
        transaction.receipt_items = receipt_items;
      } catch (err) {
        transaction.receipt_items = [];
        console.error("receipt emtpy!");
      }
      transaction.isPad = false;
      const amount = Number(transaction.amount).toFixed(2);
      let eth_amount = "N/A";
      let eth_price = "N/A";
      if (
        setting?.auth?.signin_merchant_name == "VEEV Inc" ||
        Number(setting?.auth?.signin_merchant_user_id) == Number(transaction.merchant_id)
      ) {
        if (transaction.pay_type === "Buy" && transaction.merchant_name !== "Wallet Transfer") {
          //
          eth_amount = transaction.merchant_info.split("|||")[1];
          eth_price = +amount / +eth_amount;
        }
        if (transaction.pay_type === "Buy" && transaction.merchant_name === "VEEV Coin") {
          //
          if (!Number.isNaN(+eth_amount)) {
            _totalETH += +eth_amount;
            if (!dayTotalsEth[date]) {
              dayTotalsEth[date] = [0, 0];
            }
            dayTotalsEth[date][0] += +amount;
            dayTotalsEth[date][1] += +eth_amount;
          }
        }
      }
      if (!dayTotals[date]) {
        dayTotals[date] = [0];
      }
      dayTotals[date][0] += +amount;
      _totalVEEV += +amount;
      receipt_items.forEach((item, key) => {
        const property = Object.keys(item)[0];
        const quantity = item[property];
        if (itemsParse[property] != undefined) {
          if (!_topProducts[property]) {
            _topProducts[property] = {
              name: itemsParse[property].name,
              amount: quantity * Number(itemsParse[key].price),
            };
          } else {
            let amount = Number(quantity) + Number(_topProducts[property].amount);
            _topProducts[property].amount = amount;
          }
        }
      });
      return transaction;
    });
    setTransactions(_transactions)
    setTotalETH(_totalETH)
    setTotalVEEV(_totalVEEV)
    let topProductsList = [];
    for (let key in _topProducts) {
      let item = _topProducts[key];
      item.id = key;
      topProductsList.push(item);
    }
    let topProductsSortList = topProductsList.sort(
      (a, b) => Number(b.amount) - Number(a.amount)
    );
    setTopProducts(topProductsSortList)
    const cummulative_totals = Object.keys(dayTotals)
      .reduce((collection, current_day) => {
        const days_until_now = Object.entries(dayTotals).filter(
          ([other_day, x]) => new Date(other_day) <= new Date(current_day)
        );
        const sum = [0];
        days_until_now.forEach((day) => {
          sum[0] += day[1][0];
        });
        collection.push([current_day, sum]);
        return collection;
      }, [])
      .sort(([a, v], [b, w]) => new Date(a) - new Date(b));
    const cummulative_totals_eth = Object.keys(dayTotalsEth)
      .reduce((collection, current_day) => {
        const days_until_now = Object.entries(dayTotalsEth).filter(
          ([other_day, x]) => new Date(other_day) <= new Date(current_day)
        );
        const sum = [0, 0];
        days_until_now.forEach((day) => {
          sum[0] += day[1][0];
          sum[1] += day[1][1];
        });
        collection.push([current_day, sum]);
        return collection;
      }, [])
      .sort(([a, v], [b, w]) => new Date(a) - new Date(b));
    setCummulativeTotals(cummulative_totals)
    setCummulativeTotalsEth(cummulative_totals_eth)
  }
  const addPad = async (transaction) => {
    const res = await getCrmTrxItemList({
      sel_trx_id: transaction.id
    })
    let item_list = res.data
    let amount = 0
    item_list = item_list.map((item) => {
      let itemPrice = parseFloat(item.price) * parseInt(item.quantity);
      amount += itemPrice;
      item.amount = itemPrice;
      return item
    })
    console.log("amount =>", item_list)
    const trxinfo = res.trxinfo
    const datetime = new Date(trxinfo.created_at);
    const date = datetime.toDateString().substr(4);
    trxinfo.date = date;
    trxinfo.time = datetime.toTimeString().substr(0, 5);
    let tax = parseFloat(trxinfo.tax) * 1000000000000000000;
    let tip = parseFloat(trxinfo.tip) * 1000000000000000000;
    amount += tax + tip;
    setPadInfo({
      item_list: item_list,
      trxinfo: trxinfo,
      amount: amount,
      tax: tax,
      tip: tip,
    })
    let _transactions = transactions.map((item) => {
      if (item.id === transaction.id)
        item.isPad = true
      else
        item.isPad = false
      return item
    })
    setTransactions(_transactions)
  }
  const removePad = (index) => {
    let _transactions = [...transactions]
    _transactions[index].isPad = false
    setTransactions(_transactions)
  }

  useEffect(() => {
    init();
    run(getItemsAll());
    setAsyncState("getItemsAll");
    setIsLoad(false)
  }, [run])
  useEffect(() => {
    const session_id = getCookie("signin_session_id");
    if (setting?.sel_network && !isLoad) {
      const cable = ActionCable.createConsumer(siteConfig.webSocketUrl);
      const transactions_subscription = cable.subscriptions.create(
        {
          channel: "TransactionChannel",
          session_id: session_id,
        },
        {
          connected: () => {
            console.log('Connected transaction_channel_request')
            if (!isLoad) {
              transactions_subscription.send({
                type: "transaction_list_request",
                network: setting?.sel_network,
                //merchant_code: getCookie("signin_merchant_code");
              });
              setIsLoad(true)
            }
          },
          received: (data) => {
            if (data.success === true && setting?.sel_network === "live") {
              if (data?.transactions?.length !== 0) {
                if (data.type === "transaction_list_response") {
                  changeTransactions(data.transactions);
                  /* main dashboard show part.*/
                } else if (data.type === "test_transaction_list_response") {
                  changeTransactions(data.transactions);
                }
              }
            } else {
              console.log("error", data);
              // NotificationManager.warning(data.reason, "Warning", 3000);
            }
          },
        }
      );
    }
  }, [setting?.sel_network, isLoad]);
  useEffect(() => {
    if (status === 'resolved') {
      if (asyncState === 'getItemsAll') {
        setItemsParse(data.items)
      }
    }
    else if (status === 'rejected') {
      console.log(error)
    }
  }, [status])
  return (
    <>
    <Layout>
    <Tab/>
      <div className="row">
        {/* <div className="col col-lg-3">
          <Sidebar/>
        </div> */}
        <div className='col col-lg-12'>
        <div className="section-heading text-center">
          <div>
            <div className="row">
              <div className="col-md-12 text-center">
                <span className="title">Transaction </span>
                <span
                  className="total-sales"
                  id="id_span_veev_total_sales">
                  Total ${totalVEEV.toFixed(2)}
                </span>
                <br />
                <span className="description">
                  For above selected date range
                </span>
              </div>
            </div>
            <div id="veev-chart">
              <Plot
                data={[
                  {
                    x: cummulativeTotals.map((a) => a[0]),
                    y: cummulativeTotals.map((a) => a[1]).map((a) => a[0]),
                    type: "bar",
                    autosize: 'true',
                    marker: { color: "RGB(232,195,215)" },
                  },
                  { type: "bar"},
                ]}
                layout={{}}
                config={{ displayModeBar: false }}/>
            </div>
          </div>
          <div>
            <table className="transactions" id="transactions">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Time</th>
                  <th>From</th>
                  <th>Total</th>
                  <th>Username</th>
                  <th>#</th>
                </tr>
              </thead>
              <tbody>
                {transactions.length === 0 ? (
                  <tr>
                    <td colSpan="5" className="text-center">
                      Transaction is empty.
                    </td>
                  </tr>
                ) : (
                  transactions.map((transaction, index) => (
                    <Fragment key={index}>
                      <tr>
                        <td
                          style={{ cursor: "pointer" }}
                          onClick={() => addPad(transaction)}>
                          {transaction.date}
                        </td>
                        <td>{transaction.time}</td>
                        {/* <td>{transaction.merchant_name}</td> */}
                        <td>{transaction.pay_type} {transaction.shipping_type}</td>
                        <td>
                          {transaction.token_symbol}{"$"}
                          {Number(transaction.amount).toFixed(2)}
                        </td>
                        <td>{transaction.first_name}</td>
                        <td>{transactions.length - index}</td>
                      </tr>
                      <tr>
                        <td colSpan="5" name="dv_sel_trx">
                          {transaction.isPad && (
                            <div class="dv-sel-trx-items">
                              <div class="close" onClick={() => removePad(index)}>
                                <i class="icon-remove"></i>
                              </div>
                              <p class="title">
                                {transaction.merchant_name}
                              </p>
                              <p class="shipping-address">
                                {transaction.merchant_info}
                              </p>
                              <p class="receipt">
                                Receipt #{" "}
                                {padInfo?.trxinfo?.receipt_id}
                              </p>
                              <div class="row">
                                <div class="col-md-1"></div>
                                <div class="col-md-10">
                                  <div class="row border-bottom">
                                    <div class="col-md-6 text-left">
                                      {padInfo?.trxinfo?.date}
                                    </div>
                                    <div class="col-md-6 text-right">
                                      {padInfo?.trxinfo?.time}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-md-1"></div>
                                <div class="col-md-10">
                                  <table
                                    style={{ width: "100%" }}
                                    id="id_tbl_crm_trx_item_list">
                                    <tbody>
                                      {padInfo?.item_list?.map(
                                        (item, index) => (
                                          <tr key={index}>
                                            <td colSpan="4" class="text-left">
                                            {item?.quantity}&nbsp;{item?.name}&nbsp;@&nbsp;${item?.price}
                                            
                                            {item?.extras?.trim().replace(/[;]*$/, '').split(";").map(e => <span><br/>{'+ '+e.split('-')[0]}</span>)}
                                                
                                            </td>
                                            <td class="text-right">
                                              ${" "}
                                              {item?.amount.toFixed(2)}
                                              {item?.extras?.trim().replace(/[;]*$/, '').split(";").map(e => <span><br/>{"$ "+e.split('-')[1]}</span>)}
                                            </td>
                                          </tr>
                                        )
                                      )}
                                      <tr>
                                        <td colSpan="3" class="text-left">Tax</td>
                                        <td></td>
                                        <td class="text-right">
                                          ${" "}
                                          {padInfo?.tax.toFixed(2)}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td colSpan="3" class="text-left">Tip</td>
                                        <td></td>
                                        <td class="text-right">
                                          ${" "}
                                          {padInfo?.tip.toFixed(2)}
                                        </td>
                                      </tr>
                                      <tr class="total">
                                        <td colSpan="3" class="text-left">
                                          TOTAL
                                        </td>
                                        <td></td>
                                        <td class="text-right">
                                          ${" "}
                                          {Number(transaction.amount).toFixed(2)}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          )}
                        </td>
                      </tr>
                    </Fragment>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
        </div>
      </div>
    </Layout>
    </>
  );
}
export default Transactions
