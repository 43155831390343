import React, { useState } from 'react'
import { Dialog } from '@material-ui/core'
import Header from "../../../components/header/authhome";
import Footer from '../../../components/footer/footerPage'
import {NFTdata} from '../../../data/nfts'
import { Styles, ModalStyles } from "../../../layouts/style/nftsStyle";
// import siteConfig from '../../../config/site.config'
import Nav from '../../../pages/not-used/nav'

const NFT = () => {
  const [nft, setNft] = useState({})
  const [modalActive, setModalActive] = useState(false);
  const nfts = NFTdata

  const handleModalClose = () => {
    setModalActive(false);
  };
  const handleModalOpen = (index) => {
    if (nfts.length !== 0) {
      let _nft = nfts[index];
      
      setNft(_nft);
    }
    setModalActive(true);
  };

  return (
    <>
      <Header />
      <Styles>
        <div className="nfts">
          <section className="container">
            <div className="tab-part">
              <Nav directory="nfts" />
              <div className="selected-merchant-name">
                NFTs
                <span className="selected-nav-top-active"> &gt; All </span>
              </div>
            </div>
            <div className="row h-100">
              <div className="col-md-12">
                <div className="devsite-section-nav">
                  <div className="row mt-sm-3">
                    <div className="col-md-12">
                      <p href="#" className="trx-left-nav active"></p>
                    </div>
                  </div>
                </div>
                <div className="">
                  {/* Main Transactions part begin. */}
                  <div className="devsite-section-article">
                    <ul className="row">
                      {nfts.map((nft, index) => (
                        <div className="col-lx-4 col-lg-4 col-sm-12" key={index} onClick={() => handleModalOpen(index)}>
                          <li id="store_a" className="store_container">
                            <section className="store_logo_wrapper nft-image" style={{backgroundImage: `url(${nft.image})`}}>
                              {/* <img className="store_logo" src={nft.image} /> */}
                            </section>
                            <section className="store_name_address" style={{justifyContent: 'space-around'}}>
                              <section className="firstChild">
                                <h2> {nft.name} </h2>
                              </section>
                              <section>
                                {/* <div>
                                  {nft.price} VEEVcoin
                                </div> */}
                              </section>
                            </section>
                          </li>
                        </div>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <Dialog
            open={modalActive} 
            onClose={handleModalClose} 
            aria-labelledby="form-dialog-title"
          >
            <ModalStyles>
              <section>
                <div className="modal-image">
                  <img src={nft.image} />
                </div>
                <section className="text-center content">		
                  <div className="title">{nft.name}</div>
                  <div className="subtitle">Price</div>
                  <div className="description">
                    {nft.price} VEEVcoin
                  </div>
                </section>
              </section>
            </ModalStyles>
          </Dialog>
        </div>
      </Styles>
      <Footer />
    </>  
  )
}
export default NFT
