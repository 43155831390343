import React from 'react';
import {Button} from "antd";
import styled from "styled-components";
import Header from "../../../components/header/authempty";
import Footer from "../../../components/footer/footerEmptyPage";

const ReservationConfirmationPage = () => {
    const params = new URLSearchParams(window.location.search);
    const name = params.get('name');
    const date = parseInt(params.get('date'));

    return (
        <div>
            <Header />
            <Section>
                <div class="container">
                    <HeaderSection>
                        <p><h3 style={{fontSize: '36px', fontWeight: 'bold'}}>
                            Thank you for your reservation!
                            <br />
                        </h3></p>
                        <p>
                            <span style={{fontWeight: '400'}}>
                                Name: {name}
                            </span>
                        </p>
                        <p>
                            <span style={{ fontWeight: '400' }}>
                                Date: {(new Date(date)).toLocaleString()}
                            </span>
                        </p>
                    </HeaderSection>
                    <div class="box alt">
                        <Row>
                            <SectionCol class="col-4 col-6-medium col-12-xsmall">
                                <h3>Reservations Made Easy</h3>
                                <p>Find your favorite places and book a reservation in a few minutes.</p>
                            </SectionCol>
                            <SectionCol class="col-4 col-6-medium col-12-xsmall">
                                <h3>Customer-First Checkout</h3>
                                <p>Make shopping easier with VEEV seamless checkout, in-person or online.</p>
                            </SectionCol>
                            <SectionCol class="col-4 col-6-medium col-12-xsmall">
                                <h3>Earn Rewards</h3>
                                <p>Download our App and earn 100 VEEV Coin</p>
                            </SectionCol>
                        </Row>
                    </div>
                    <div className="row" style={{ width: '100%', paddingBottom: '30px', paddingTop: '5px', paddingRight: '20px', color: 'grey' }}>
                        <div className="col-lg-5">
                        
                        </div>
                        <div className="col-lg-2">
                            <a href="/app">
                            <Button
                            block
                            type="primary"
                            width="100px"
                            size="large"
                            >
                            Explore
                            </Button>
                            </a>
                        </div>
                        <div className="col-lg-5">
                        
                        </div>
                    </div>
                </div>
            </Section>
            <Footer />
        </div>
    )
}

const Section = styled.section`
  padding: 4.5em 0 2.5em 0;
  text-align: center;
  marginTop: '15px';
`;

const HeaderSection = styled.header`
  margin: 0 0 4em 0;
  position: relative;
  text-align: center;

  &:after {
    background: #cc0000;
    content: "";
    display: inline-block;
    height: 0.2em;
    max-width: 20em;
    width: 75%;
  }
`;

const SectionCol = styled.section`
  display: 'block';
  text-align: center;
  width: 33.33333%;
  @media (max-width: 480px) {
      width: 100% !important;
      flex: none !important;
      max-width: none !important;
  }
`
const Row = styled.div`
    display: flex;
	flex-wrap: wrap;
	box-sizing: border-box;
	align-items: stretch;
    margin-bottom: 2em;
    padding: 1.5em;
`

export default ReservationConfirmationPage;