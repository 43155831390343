import React from "react";
import {HashLink as Link} from 'react-router-hash-link';
import {Avatar, Button, Dropdown, Input, Space} from "antd";
import {Icon} from "@iconify/react";
import {Styles} from '../../layouts/style/headerHomeStyle';
import {getCookie, setCookie} from "../../service/cookie";
import {useNavigate} from "react-router-dom";
import AuthPopup from "../auth/auth";

const HeaderSearch = (props, toggleModal) => {
    const { onSearch, initialKeyword } = props;
    const {Search} = Input;
    // const {directory} = props;
    const isAuth = getCookie("current_ident_token");
    const navigate = useNavigate();
    const username = getCookie("veev_current_user_username");

    const logoutHandle = () => {
        setCookie("signin_session_id", '', 0);
        setCookie("signin_merchant_code", '', 0);
        setCookie("signin_merchant_id", '', 0);
        setCookie("signin_merchant_user_id", '', 0);
        setCookie("signin_current_user_id", '', 0);
        setCookie("signin_merchant_name", '', 0);
        setCookie("current_ident_token", '', 0);
        setCookie("current_user_email", '', 0);
        setCookie("selected_merchant_address", '', 0);
        setCookie("current_merchant_wallet_address", '', 0);
        setCookie("selected_merchant_bool_cannabis_merchant", '', 0);
        setCookie("veev_session_id", '', 0);
        setCookie("veev_current_user_id", '', 0);
        setCookie("veev_current_user_email", '', 0);
        setCookie("veev_current_user_ident_token", '', 0);
        // navigate("${keyword}");
        navigate('/test/${keyword}');
    }

  const menuItems = [
    // {
    //     key: '1',
    //     label: (
    //         <Link to={`/user/${username}`}>
    //             <Space>
    //                 <Icon
    //                     style={{fontSize: 24, fontWeight: 700}}
    //                     icon="ph:user"/>
    //                 <h5 style={{fontSize: 16, fontWeight: 700}}>
    //                     User Account
    //                 </h5>
    //             </Space>
    //         </Link>
    //     ),
    // },
    {
        key: '2',
        label: (
            <Link to="/store/checkout/transactions">
                <Space>
                    <Icon
                        style={{fontSize: 24, fontWeight: 700}}
                        icon="ic:outline-local-grocery-store"/>
                    <h5 style={{fontSize: 16, fontWeight: 700}}>
                        Business Account
                    </h5>
                </Space>
            </Link>
        ),
    },
    {
        key: '3',
        label: (
            <a onClick={logoutHandle}>
                <Space>
                    <Icon
                        style={{fontSize: 24, fontWeight: 700}}
                        icon="teenyicons:logout-outline"/>
                    <h5 style={{fontSize: 16, fontWeight: 700}}>
                        Logout
                    </h5>
                </Space>
            </a>
        ),
    },
];

  return (
        <Styles>
            <nav className="navbar navbar-expand-lg navbar-light fixed-top header" style={{zIndex: 999}} id="mainNav">
                <div className="container">
                    <Link
                        className="navbar-brand js-scroll-trigger"
                        to="/">
                        <img
                            src="/assets/img/veev_logo_black.png"
                            height="25"
                            width="*"
                            alt='logo-black'
                        />
                    </Link>
                    <div className="collapse navbar-collapse" id="navbarResponsive"> 
                        <Search
                            defaultValue={initialKeyword || ""}
                            style={{width: 500, height: 36, padding: 0}}
                            placeholder="Search reviews for restaurants, hair, beauty spas, etc"
                            allowClear
                            size="large"
                            onSearch={onSearch || null}
                        />  
                        <ul className="navbar-nav ml-auto" style={{height: '40px'}}>
                            {/* <li>
                                <Link className={`nav-link ${directory === 'app' ? 'active' : ''}`} to="/app"
                                      id="">App</Link>
                            </li> */}
                            {
                                !isAuth && (
                                <ul className="navbar-nav ml-auto" style={{height: '40px'}}>
                                {/* <li>
                                <Link className={`nav-link ${directory === 'app' ? 'active' : ''}`} to="/app"
                                      id="">App</Link>
                                </li> */}
                                
                                </ul>
                                )
                            }
                            <li className="nav-item">
                                {
                                    isAuth ? (
                                        <Dropdown menu={{items: menuItems}} placement="bottomRight">
                                            <Avatar
                                                size={42}
                                                src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png">
                                            </Avatar>
                                        </Dropdown>
                                    ) : (
                                       <AuthPopup />
                                    )
                                }
                            </li>
                        </ul>
                    </div>
                
                    <div className="navbar-toggler navbar-light" id="navbarResponsive"> 
                        <Search
                            defaultValue={initialKeyword || ""}
                            style={{width: '100%', height: 36, padding: 0}}
                            placeholder="Search reviews for restaurants, hair, beauty spas, etc"
                            allowClear
                            size="large"
                            onSearch={onSearch || null}
                        />  
                        {/* {
                            isAuth ? (
                                <Dropdown menu={{items: menuItems}} placement="bottomRight">
                                    <Avatar
                                        size={42}
                                        src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png" style={{marginLeft: '19px'}}>
                                    </Avatar>
                                </Dropdown>
                            ) : (
                                <AuthPopup />
                            )
                        } */}   
                    </div>
                </div>
            </nav>
        </Styles>
    )
}
export default HeaderSearch;
