import React from 'react';
import {Link, useLocation} from "react-router-dom";

const Sidebar = () => {
  const location = useLocation()

  return (
    <div className="side-nav-wrap">
      <div className="side-nav">
        <nav>
          <li>
            <Link
              className={location.pathname === "/store/crm/email" ? "active" : ""}
              to="/store/crm/email">
              Compose
            </Link>
          </li>
          {/* <li>
            <Link
              className={location.pathname === "/store/crm/" ? "active" : ""}
              to="/store/crm/">
              <b>Mailing Lists</b>
            </Link>
          </li>
          <li>
            <Link
              className={location.pathname === "/store/crm/" ? "active" : ""}
              to="/store/crm/email">
              Booking Confirmations
            </Link>
          </li>
          <li>  
            <Link
              className={location.pathname === "/store/crm/" ? "active" : ""}
              to="/store/crm/email">
              Marketing
            </Link>
          </li> */}
        </nav>
      </div>
    </div>
  );
};

export default Sidebar;
