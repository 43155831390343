import React, { useState } from 'react'
import Header from "../../../components/header/authhome";
import Footer from '../../../components/footer/footerPage'
import {NFTdata} from '../../../data/nfts'
import { Styles } from "../../../layouts/style/nftStyle";
import Board from './board'

const NFTs = () => {
  const nfts = NFTdata

  return (
    <>
      <Header />
      <Styles>
        <div className="nfts row">
          <div className="sidebar-filter col-xl-2 col-lg-3 col-12">
            <div className="main-filters">

            </div>
          </div>
          <div className="main-container col-xl-10 col-lg-9 col-12">
            <div className="row">
              {nfts.map((nft, index) => (
                <div className="col-xl-2 col-lg-3 col-md-4 col-12 board-item" key={index}>
                  <Board nft={nft} index={index} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </Styles>
      <Footer />
    </>
  );
}
export default NFTs
