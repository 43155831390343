import { apiGet, apiDeleteToken, apiPostToken, apiPutToken } from "./index";

async function create(merchantCode, data) {
  try {
    const res = await apiPostToken(`merchants/${merchantCode}/menu_extras`, data);
    return Promise.resolve(res);
  } catch (error) {
    return Promise.reject(error);
  }
}

async function update(merchantCode, id, data) {
  try {
    const res = await apiPutToken(`merchants/${merchantCode}/menu_extras/${id}`, data);
    return Promise.resolve(res);
  } catch (error) {
    return Promise.reject(error);
  }
}

async function remove(merchantCode, id) {
  try {
    const res = await apiDeleteToken(`merchants/${merchantCode}/menu_extras/${id}`,
      {
        merchant_id: merchantCode,
        id: id
      }
    );
    return Promise.resolve(res);
  } catch (error) {
    return Promise.reject(error);
  }
}

async function getAll(merchantCode) {
  try {
    const res = await apiGet(`merchants/${merchantCode}/menu_extras`);
    return Promise.resolve(res)
  } catch(error) {
    return Promise.reject(error)
  }
}

export {
  create,
  update,
  remove,
  getAll,
}
