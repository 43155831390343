import React, {Fragment, useState} from 'react';
import {Icon} from "@iconify/react";
import DatePicker from "react-datepicker";
import {countries, states, cities} from "country-cities";
import Layout from "../../../layouts/dashboard";
import {useNavigate} from "react-router-dom";
import CheckinSidebar from "./sidebar2";
// import Switch from "react-switch";

const weekDays = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
]

const CheckinCreatePage = () => {
  const navigate = useNavigate();
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [type, setType] = useState("event");
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [member, setMember] = useState(false);
  const [memberIpt, setMemberIpt] = useState("");
  const [members, setMembers] = useState([]);
  const [openDays, setOpenDays] = useState([false, false, false, false, false, false, false]);

  const submitHandle = (event) => {
    navigate("/bank/store/identity/check")
    event.preventDefault();
  }

  return (
    <Layout>
      <div className="row">
        <div className="col col-lg-3">
          <CheckinSidebar/>
        </div>
        <div className="col col-lg-9">
          <h4>Create Checkin</h4>
          <form onSubmit={submitHandle}>
            {/* BASIC INFORMATION */}
            <div>
              <div className="row">
                <div className="mb-3 col-6">
                  <label htmlFor="type">Checkin Type</label>
                  <select
                    className="form-control"
                    name="type"
                    onChange={(e) => {
                      setType(e.target.value)
                    }}
                    id="type">
                    <option value="event">Event Page</option>
                    <option value="business">Business Page</option>
                  </select>
                </div>
              </div>

              <div className="mb-3">
                <label className="form-label" htmlFor="name">Name*</label>
                <input
                  style={{marginTop: 0}}
                  className="form-control"
                  type="text"
                  name="name"
                  placeholder="Page Name"
                  id="name"/>
              </div>

              <div className="mb-3 image-upload">
                <label>Image*</label>
                <label className="upload-area" htmlFor="image">
                  <Icon icon="uil:image-upload"/>
                </label>
                <input
                  type="file"
                  name="image"
                  id="image"
                  className="form-control"/>
              </div>

              <div className="mb-3">
                <label className="form-label" htmlFor="name">Description*</label>
                <textarea
                  style={{marginTop: 0}}
                  className="form-control"
                  type="text"
                  name="name"
                  rows={6}
                  placeholder="Description"
                  id="name"/>
              </div>
            </div>

            {/* ADDRESS */}
            <h5 className="mt-5">Checkin Address</h5>
            <div className="row">
              <div className="col col-lg-8 mb-3">
                <label htmlFor="address">Street Address*</label>
                <input
                  style={{marginTop: 0}}
                  className="form-control"
                  type="text"
                  name="address"
                  placeholder="Street address"
                  id="name"/>
              </div>
              <div className="col col-lg-4 mb-3">
                <label htmlFor="address">Suite</label>
                <input
                  style={{marginTop: 0}}
                  className="form-control"
                  type="text"
                  name="suite"
                  placeholder="Suite"
                  id="name"/>
              </div>

              <div className="col col-lg-3 mb-3">
                <label htmlFor="country">Country</label>
                <select
                  className="form-control"
                  name="country"
                  id="country"
                  onChange={(e) => {
                    setCountry(e.target.value)
                  }}
                  placeholder="State">
                  <option value="">Select</option>
                  {
                    countries.all().map((i, index) => (
                      <option key={`state-${index}`} value={i.isoCode}>{i.name}</option>
                    ))
                  }
                </select>
              </div>

              <div className="col col-lg-3 mb-3">
                <label htmlFor="state">State</label>
                <select
                  className="form-control"
                  name="state"
                  id="state"
                  onChange={(e) => {
                    setState(e.target.value)
                  }}
                  placeholder="State">
                  <option value="">Select</option>
                  {
                    states.getByCountry(country).map((i, index) => (
                      <option key={`state-${index}`} value={i.isoCode}>{i.name}</option>
                    ))
                  }
                </select>
              </div>

              <div className="col col-lg-3 mb-3">
                <label htmlFor="city">City</label>
                <select
                  className="form-control"
                  name="city"
                  id="city"
                  placeholder="State">
                  <option value="">Select</option>
                  {
                    cities.getByState(state, country).map((i, index) => (
                      <option key={`state-${index}`} value={i.name}>{i.name}</option>
                    ))
                  }
                </select>
              </div>

              <div className="col col-lg-3 mb-3">
                <label htmlFor="address">ZIP Code</label>
                <input
                  style={{marginTop: 0}}
                  className="form-control"
                  type="text"
                  name="city"
                  placeholder="ZIP Code"
                  id="name"/>
              </div>
            </div>

            {/* TIME DETAILS */}
            {
              type === "event" ? (
                <Fragment>
                  <h5 className="mt-5">Event Date & Time</h5>
                  <div className="row">
                    <div className="col col-lg-4 mb-3">
                      <label htmlFor="startDate">
                        Start Date&nbsp;&nbsp;
                        <i className="fa fa-calendar" aria-hidden="true" id="id_trx_calandar_icon"></i>
                      </label>
                      <DatePicker
                        id="startDate"
                        className="form-control m-0"
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}/>
                    </div>

                    <div className="col col-lg-4 mb-3">
                      <label htmlFor="startTime">Time:</label>
                      <select
                        className="form-control"
                        name="startTime"
                        id="startTime"
                        placeholder="State">
                        {
                          [...Array(12)].map((i, index) => (
                            <option
                              key={`state-${index}`}
                              value={`${index < 9 && "0"}${index + 1}:00`}>
                              {index < 9 && "0"}{index + 1}:00
                            </option>
                          ))
                        }
                      </select>
                    </div>

                    <div className="col col-lg-4 mb-3">
                      <label htmlFor="startAm">AM/PM</label>
                      <select
                        className="form-control"
                        name="startAm"
                        id="startAm"
                        placeholder="State">
                        <option value="am">AM</option>
                        <option value="pm">PM</option>
                      </select>
                    </div>

                    <div className="col col-lg-4 mb-3">
                      <label htmlFor="endDate">
                        End Date&nbsp;&nbsp;
                        <i className="fa fa-calendar" aria-hidden="true" id="id_trx_calandar_icon"></i>
                      </label>
                      <DatePicker
                        id="endDate"
                        name="endDate"
                        className="form-control m-0"
                        selected={endDate}
                        onChange={(date) => setEndDate(date)}/>
                    </div>

                    <div className="col col-lg-4 mb-3">
                      <label htmlFor="endTime">Time:</label>
                      <select
                        className="form-control"
                        name="endTime"
                        id="endTime"
                        placeholder="State">
                        {
                          [...Array(12)].map((i, index) => (
                            <option
                              key={`state-${index}`}
                              value={`${index < 9 && "0"}${index + 1}:00`}>
                              {index < 9 && "0"}{index + 1}:00
                            </option>
                          ))
                        }
                      </select>
                    </div>

                    <div className="col col-lg-4 mb-3">
                      <label htmlFor="endAm">AM/PM</label>
                      <select
                        className="form-control"
                        name="endAm"
                        id="endAm"
                        placeholder="AM/PM">
                        <option value="am">AM</option>
                        <option value="pm">PM</option>
                      </select>
                    </div>
                  </div>
                </Fragment>
              ) : (
                <Fragment>
                  <h5 className="mt-5">Business Hours</h5>
                  <div className="row">
                    <div className="col col-4 mb-1">
                      <label>Week Day</label>
                    </div>
                    <div className="col col-4  mb-1">
                      <label>Open Time:</label>
                    </div>
                    <div className="col col-4  mb-1">
                      <label>Close Time:</label>
                    </div>
                    {
                      [...new Array(7)].map((_, index) => (
                        <Fragment key={`open-${index}`}>
                          <div className="col col-4 mb-3 in-row">
                            {/* <Switch
                              height={22}
                              width={44}
                              checked={openDays[index]}
                              onChange={(checked) => {
                                const od = openDays
                                od[index] = checked
                                setOpenDays([...od])
                              }}
                            /> */}
                            <select
                              disabled={!openDays[index]}
                              className="form-control"
                              name={`day${index}`}
                              id={`day${index}`}>
                              <option value={weekDays[index]}>{weekDays[index]}</option>
                            </select>
                          </div>

                          <div className="col col-4 mb-3 in-row in-time">
                            <select
                              disabled={!openDays[index]}
                              className="form-control"
                              name={`openTime${index}`}
                              id={`openTime${index}`}
                              defaultValue="09:00"
                              placeholder="State">
                              {
                                [...Array(12)].map((i, index) => (
                                  <option
                                    key={`state-${index}`}
                                    value={`${index < 9 && "0"}${index + 1}:00`}>
                                    {index < 9 && "0"}{index + 1}:00
                                  </option>
                                ))
                              }
                            </select>
                            <select
                              disabled={!openDays[index]}
                              className="form-control"
                              name={`openAm${index}`}
                              id={`openAm${index}`}
                              defaultValue="AM"
                              placeholder="AM/PM">
                              <option value="am">AM</option>
                              <option value="pm">PM</option>
                            </select>
                          </div>

                          <div className="col col-lg-4 mb-3 in-row in-time">
                            <select
                              disabled={!openDays[index]}
                              className="form-control"
                              name={`closeTime${index}`}
                              id={`closeTime${index}`}
                              defaultValue="05:00"
                              placeholder="State">
                              {
                                [...Array(12)].map((i, index) => (
                                  <option
                                    key={`state-${index}`}
                                    value={`${index < 9 && "0"}${index + 1}:00`}>
                                    {index < 9 && "0"}{index + 1}:00
                                  </option>
                                ))
                              }
                            </select>
                            <select
                              disabled={!openDays[index]}
                              className="form-control"
                              name={`closeAm${index}`}
                              id={`closeAm${index}`}
                              defaultValue="pm"
                              placeholder="AM/PM">
                              <option value="am">AM</option>
                              <option value="pm">PM</option>
                            </select>
                          </div>
                        </Fragment>
                      ))
                    }
                  </div>
                </Fragment>
              )
            }

            {/* MEMBER */}
            <h5 className="mt-5">Invite Members</h5>
            <div className="row">
              <div className="col col-lg-12">
                {/* <Switch
                  checked={member}
                  width={44}
                  height={22}
                  onChange={(checked)=>{setMember(checked)}}/> */}
              </div>
              {
                member && (
                  <div className="col-lg-12">
                    <div className="row">
                      {
                        members.map((mb, index)=>(
                          <Fragment key={`member-${index}`}>
                            <div className="col col-lg-9">
                              <input value={mb} type="text" disabled className="form-control"/>
                            </div>
                            <div className="col col-lg-3">
                              <button
                                type="button"
                                onClick={()=>{setMembers(members.filter((i)=>i!==mb))}}
                                style={{width: "100%", color: "#cc0000", borderColor: "#cc0000"}}
                                className="btn">
                                Remove Member
                              </button>
                            </div>
                          </Fragment>
                        ))
                      }
                      <div className="col col-lg-9">
                        <input
                          className="form-control"
                          placeholder="VEEV userId or Email. Example: @veev-tester"
                          value={memberIpt}
                          onChange={(e)=>{setMemberIpt(e.target.value)}}
                          type="text"/>
                      </div>
                      <div className="col col-lg-3">
                        <button
                          type="button"
                          disabled={!memberIpt}
                          onClick={()=>{
                            memberIpt!=="" && setMembers([...members, memberIpt]);
                            setMemberIpt("")
                          }}
                          style={{width: "100%", backgroundColor: "#cc0000", borderColor: "#cc0000"}}
                          className="btn btn-primary">
                          Add Member
                        </button>
                      </div>
                    </div>
                  </div>
                )
              }
            </div>

            {/* SUBMIT */}
            <div className="mt-5">
              <button className="btn btn-submit btn-submit-primary">Create Checkin</button>
            </div>
          </form>
        </div>
      </div>
    </Layout>
  );
};

export default CheckinCreatePage;
