import React, { Fragment, useEffect, useState } from "react";
import Layout from "../../../layouts/dashboard";
import Tab from "../../../layouts/dashboard/components/checkout-tab";
import { useNavigate } from "react-router-dom";
import { useSetting } from "../../../provider/setting";
import { Button, Collapse, Col, Input, Space, Row, Modal, Table } from "antd";
import { Icon } from "@iconify/react";
import { NotificationManager } from "react-notifications";
import {
  getAll,
  update as updateCategory,
  remove as removeCategory,
} from "../../../api/category";
import {
  getAll as getAllExtras,
  create,
  remove,
  update,
} from "../../../api/extra";
import AddOnForm from "./addon-form";

const { Panel } = Collapse;
const { Search } = Input;

const StoreCategoriesPage = () => {
  const navigate = useNavigate();
  const [setting] = useSetting();
  const [modal, contextHolder] = Modal.useModal();
  const [categories, setCategories] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [extras, setExtras] = useState([]);

  const [open, setOpen] = useState(false);

  const showModal = (value) => {
    if (value) {
      setId(value.id);
      setName(value.name);
      setPrice(value.price);
    } else {
      setId(0);
      setName("");
      setPrice(0.0);
    }
    setOpen(true);
  };

  const [category, setCategory] = useState();
  const [name, setName] = useState("");
  const [price, setPrice] = useState(0.0);
  const [id, setId] = useState(0);

  useEffect(() => {
    if (id !== 0) {
      categories.forEach((c) => {
        if (JSON.parse(c.other || "[]").includes(id)) {
          setCategory(c.id);
        }
      });
    }
  }, [id]);

  const handleSave = () => {
    if (id) {
      update(setting?.auth?.signin_merchant_code, id, { category, name, price })
        .then((data) => {
          if (category) {
            const cat = categories.find((c) => c.id === category);
            let other = JSON.parse(cat.other || "[]");
            other.push(data.id);
            updateCategory(setting?.auth?.signin_merchant_code, category, {
              other: JSON.stringify(other),
            })
              .then((data) => {
                getHandle();
              })
              .catch((e) => {
                console.warn(e);
              });
          }

          NotificationManager.success(
            "Successfully edited an addon!",
            "Success",
            3000
          );
          setOpen(false);
        })
        .catch((e) => {
          console.warn(e);
          NotificationManager.error(e.response?.data?.message, "Error", 3000);
          setOpen(false);
        });
    } else {
      create(setting?.auth?.signin_merchant_code, { category, name, price })
        .then((data) => {
          if (category) {
            const cat = categories.find((c) => c.id === category);
            let other = JSON.parse(cat.other || "[]");
            other.push(data.id);
            updateCategory(setting?.auth?.signin_merchant_code, category, {
              other: JSON.stringify(other),
            })
              .then((data) => {
                getHandle();
              })
              .catch((e) => {
                console.warn(e);
              });
          }
          NotificationManager.success(
            "Successfully created an addon!",
            "Success",
            3000
          );
          setOpen(false);
        })
        .catch((e) => {
          console.warn(e);
          NotificationManager.error(e.response?.data?.message, "Error", 3000);
          setOpen(false);
        });
    }
  };

  const handleCancel = () => {
    setOpen(false);
    setId(0);
    setName("");
    setPrice(0.0);
  };

  const getHandle = async () => {
    if (setting?.auth?.signin_merchant_code) {
      getAll(setting.auth.signin_merchant_code)
        .then((data) => {
          setCategories(data);
        })
        .catch((e) => console.warn(e));
    }
  };

  const getExtraHandle = async () => {
    if (setting?.auth?.signin_merchant_code) {
      getAllExtras(setting?.auth?.signin_merchant_code)
        .then((data) => {
          setExtras(data.map((i) => ({ ...i, key: i.id })));
        })
        .catch((e) => console.warn(e));
    }
  };

  const deleteHandle = (category) => {
    modal.confirm({
      title: <h5>Delete Category!</h5>,
      icon: (
        <Icon
          style={{ width: 60, height: 60, color: "#cc0000" }}
          icon="ep:warn-triangle-filled"
        />
      ),
      content: (
        <p>
          Are you sure to delete <b>{category.name}</b> category?
        </p>
      ),
      okText: "Delete",
      cancelText: "Cancel",
      okType: "danger",
      onOk: () => {
        removeCategory(setting?.auth?.signin_merchant_code, category.id)
          .then(() => {
            getHandle();
            NotificationManager.success(
              "Successfully deleted!",
              "Success",
              3000
            );
          })
          .catch((e) => {
            console.warn(e);
            NotificationManager.error(
              "An error was created. try again later!",
              "Error",
              3000
            );
          });
      },
    });
  };

  const deleteExtraHandle = (extra) => {
    modal.confirm({
      title: <h5>Delete Extra!</h5>,
      icon: (
        <Icon
          style={{ width: 60, height: 60, color: "#cc0000" }}
          icon="ep:warn-triangle-filled"
        />
      ),
      content: (
        <p>
          Are you sure to delete <b>{extra.name}</b> Extra?
        </p>
      ),
      okText: "Delete",
      cancelText: "Cancel",
      okType: "danger",
      onOk: () => {
        remove(setting?.auth?.signin_merchant_code, extra.id)
          .then(() => {
            getExtraHandle();
            getHandle();
            NotificationManager.success(
              "Successfully deleted!",
              "Success",
              3000
            );
          })
          .catch((e) => {
            console.warn(e);
            NotificationManager.error(
              "An error was created. try again later!",
              "Error",
              3000
            );
          });
      },
    });
  };

  const getExtrasHandle = async () => {
    if (setting?.auth?.signin_merchant_code) {
      getAllExtras(setting?.auth?.signin_merchant_code)
        .then((data) => {
          let _extras = {};
          categories.forEach((cat) => {
            const _items = data.filter((i) => cat.other.includes(i.id));
            _extras = {
              [cat.name]: _items.map((i) => ({ ...i, key: i.id })),
              ..._extras,
            };
          });
          setExtras(_extras);
        })
        .catch((e) => console.warn(e));
    }
  };

  const columns = [
    {
      title: "Extras",
      width: "80%",
      render: (i) => {
        return (
          <Fragment>
            <h4>{i.name}</h4>
          </Fragment>
        );
      },
    },
    {
      title: "Price",
      width: 100,
      dataIndex: "price",
      key: "price",
      render: (i) => "$" + parseFloat(i).toFixed(2),
    },
    {
      title: "",
      width: 100,
      align: "center",
      render: (i) => {
        return (
          <Fragment>
            <Button
              onClick={() => showModal(i)}
              className="icon-button"
              style={{ marginRight: 6 }}
              type="link"
            >
              <Icon color="#006000" icon="mdi:circle-edit-outline" />
            </Button>
            <Button
              onClick={() => {
                deleteExtraHandle(i);
              }}
              className="icon-button"
              type="link"
              danger
            >
              <Icon icon="material-symbols:delete-outline-sharp" />
            </Button>
          </Fragment>
        );
      },
    },
  ];

  useEffect(() => {
    getHandle();
    getExtraHandle();
  }, [setting]);

  useEffect(() => {
    getExtrasHandle();
  }, [categories]);

  return (
    <>
      <Layout>
        <Tab />
        <div className="row">
          <div className="col col-lg-12">
            <h4>Categories</h4>
            <Row gutter={12} className="mb-4">
              <Col span={14}>
                <Search
                  placeholder="Search Items"
                  size="large"
                  allowClear
                  onSearch={setKeyword}
                />
              </Col>
              <Col span={5}></Col>
              <Col span={5}>
                <Button
                  block
                  type="primary"
                  size="large"
                  onClick={() => {
                    navigate("/store/checkout/categories/create");
                  }}
                >
                  + New Category
                </Button>
              </Col>
            </Row>
            <Space direction="vertical" style={{ width: "100%" }}>
              {categories.map((category, index) => (
                <Collapse key={`category-collapse-${index}`}>
                  <Panel
                    extra={[
                      <Button
                        key={`edit-${index}`}
                        onClick={() => {
                          navigate(
                            `/store/checkout/categories/edit/?id=${category.id}`
                          );
                        }}
                        className="icon-button"
                        style={{ marginRight: 6 }}
                        type="link"
                      >
                        <Icon color="#006000" icon="mdi:circle-edit-outline" />
                      </Button>,
                      <Button
                        key={`delete-${index}`}
                        onClick={() => {
                          deleteHandle(category);
                        }}
                        className="icon-button"
                        type="link"
                        danger
                      >
                        <Icon icon="material-symbols:delete-outline-sharp" />
                      </Button>,
                    ]}
                    header={category.name}
                    key={`category-${index}`}
                  >
                    <p>{category.description}</p>

                    <Row justify="center" className="mb-4">
                      <Col span={4}>
                        <Button
                          block
                          type="primary"
                          size="large"
                          onClick={() => {
                            setCategory(category.id);
                            showModal();
                          }}
                        >
                          + Add Extras
                        </Button>
                      </Col>
                    </Row>
                    <Table
                      dataSource={extras[category.name]}
                      columns={columns}
                    />
                  </Panel>
                </Collapse>
              ))}
            </Space>
          </div>
        </div>
        {contextHolder}
      </Layout>
      <Modal
        open={open}
        title={id ? "Edit Extra" : "Add Extra"}
        onOk={handleSave}
        onCancel={handleCancel}
        destroyOnClose={true}
      >
        <AddOnForm
          categories={categories}
          category={category}
          name={name}
          setName={setName}
          price={price}
          setPrice={setPrice}
        />
      </Modal>
    </>
  );
};

export default StoreCategoriesPage;