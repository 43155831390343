import React, { useState } from 'react'
import { Button, Col, DatePicker, Form, Input, Row, Select, Steps, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import { NotificationManager } from "react-notifications";
import { toast } from 'react-toastify';
import {cities, countries, states} from "country-cities";
import Header from "../header/authempty";
import { setCookie, getCookie } from '../../service/cookie';
import siteConfig from '../../config/site.config';
import { Styles } from '../../layouts/style/registerStyle';
import { toTitlecase } from "../../service/string";
import { create, generateWallet } from '../../api/merchant';

const { Item } = Form;
const { Title } = Typography;
const { Option } = Select;
const { useForm } = Form;

const Register = () => {
  const navigate = useNavigate();
  const [contactForm] = useForm();
  const [data, setData] = useState({ contactInfo: {
    country: 'US',
    state: 'AL',
    city: 'Abbeville'
  }});
  const [step, setStep] = useState(0);
  
  const [stateList, setStates] = useState(states.getByCountry('US'));
  const [cityList, setCities] = useState(cities.getByState('AL', 'US'));

  const onBusinessInfo = (values) => {
    setData({ ...data, businessInfo: values });
    setStep(1);
  };

  const onContactInfo = (values) => {
    setData({ ...data, contactInfo: values });
    setStep(2);
  };

  const onTaxInfo = (values) => {
    setData({ ...data, taxInfo: values });
    setStep(3);
  };

  const onBankInfo = async (values) => {
    const result = { ...data, bankInfo: values };
    setData(result);
    const { businessInfo, contactInfo, taxInfo, bankInfo } = result;

    try {
      const session_id = getCookie("signin_session_id");
      const ident_token = getCookie("current_ident_token");
      const user_email = getCookie("current_user_email");
      const rand_num = Math.floor(Math.random() * 9000) + 1000;
      const handle = businessInfo.legalName.replace(/\s/g, "").toLowerCase() + rand_num
      if (!session_id)
        return
      const res = await create({
        user: {
          email: businessInfo.email,
          password: "passofmerchantaccount",
          handle,
          session_id: session_id,
          merchant: {
            legal_name: toTitlecase(businessInfo.legalName),
            first_name: toTitlecase(contactInfo.firstname),
            last_name: toTitlecase(contactInfo.lastname),
            business_email: businessInfo.email,
            phone: businessInfo.phone,
            address: contactInfo.address,
            suite: contactInfo.suite,
            city: contactInfo.city,
            state: contactInfo.state,
            zip: contactInfo.zipcode,
            category: "coffee shop",
            tax_info: "9.0",
            ssn: bankInfo.last4SSN,
            ein: taxInfo.taxId,
            state_incorporated: taxInfo.stateIncorporated,
            tipping: "true",
            credit_name: toTitlecase(businessInfo.legalName) + Date.now(),
            dba: toTitlecase(businessInfo.dba)
          }
        },
      }, user_email, ident_token);
      if (res.success === false) {
        NotificationManager.warning("This merchant is invalid. Please try again", "Warning", 3000);
        return
      }
      if (res.type === 'merchant_created_response') {
        const current_user = res.current_user;
        const merchant_information = res.merchant_information;
        const selected_merchant_address = merchant_information.address + ' ' + merchant_information.city + ' ' + merchant_information.state + ' ' + merchant_information.zip;

        setCookie('signin_session_id', session_id, siteConfig.expired);
        setCookie('signin_merchant_code', merchant_information.merchant_code, siteConfig.expired);
        setCookie('signin_merchant_id', merchant_information.id, siteConfig.expired);
        setCookie('signin_merchant_user_id', merchant_information.user_id, siteConfig.expired);
        setCookie("signin_current_user_id", current_user.id, siteConfig.expired);
        setCookie('signin_merchant_name', merchant_information.legal_name, siteConfig.expired);
        setCookie('current_ident_token', current_user.ident_token, siteConfig.expired);
        setCookie("current_user_email", current_user.email, siteConfig.expired);
        setCookie('selected_merchant_address', selected_merchant_address, siteConfig.expired);

        const resWallet = await generateWallet({
          user: {
            user_id: merchant_information.user_id
          }
        })
        if (resWallet.success === false) {
          NotificationManager.warning("This merchant is invalid. Please try again", "Warning", 3000)

          return
        }
        else {
          const wallet = resWallet.wallet
          setCookie("current_merchant_wallet_address", wallet.address, siteConfig.expired);
          // navigate("/store/transactions/checkout")
          navigate("/store/settings/store")
        }
      }
      else {
        NotificationManager.warning("Invalid. Please try again later.", "Warning", 3000);
      }
    } catch (e) {

      toast(e.message, {
        position: "bottom-left",
        type: "error"
      })
    }
  }

  return (
    <>
      <Header />
      <Styles>
        <section className='signup'>
          <div className='container'>
            <Steps
              labelPlacement='vertical'
              current={step}
              items={[
                { title: 'Business Information' },
                { title: 'Contact Information' },
                { title: 'Tax Information' },
                { title: 'Banking Information' }
              ]}
            />
            <div style={{ padding: 20  }}>
              <p>
                Complete all <strong>required</strong> fields in the form
                below to setup Stores, Event Listings and Groups. After
                setup you can start accepting orders, selling tickets and
                processing payments.&nbsp;
              </p>
              {
                step === 0 &&
                <Form layout='vertical' onFinish={onBusinessInfo} initialValues={data.businessInfo}>
                  <Title level={3}>Business Information</Title>
                  <Row gutter={20}>
                    <Col span={12}>
                      <Item
                        label='Legal Name'
                        name='legalName'
                        rules={[
                          { required: true }
                        ]}
                      >
                        <Input />
                      </Item>
                    </Col>
                    <Col span={12}>
                      <Item
                        label='DBA'
                        name='dba'
                        rules={[
                          { required: true}
                        ]}
                      >
                        <Input />
                      </Item>
                    </Col>
                    <Col span={12}>
                      <Item
                        label='Business Email'
                        name='email'
                        rules={[
                          { required: true },
                          { type: 'email' }
                        ]}
                      >
                        <Input />
                      </Item>
                    </Col>
                    <Col span={12}>
                      <Item
                        label='Phone'
                        name='phone'
                        rules={[
                          { required: true },
                          { pattern: /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/, message: 'Invalid phone number' }
                        ]}
                      >
                        <Input />
                      </Item>
                    </Col>
                    <Col span={12} offset={12} style={{ textAlign: 'end'}}>
                      <Item>
                        <Button type='primary' htmlType='submit'>Next</Button>
                      </Item>
                    </Col>
                  </Row>
                </Form>
              }
              {
                step === 1 &&
                <Form
                  form={contactForm}
                  layout='vertical'
                  onFinish={onContactInfo}
                  initialValues={data.contactInfo}
                >
                  <Title level={3}>Contact Information</Title>
                  <Row gutter={20}>
                    <Col span={12}>
                      <Item
                        label='Owner Firstname'
                        name='firstname'
                        rules={[{ required: true }]}
                      >
                        <Input />
                      </Item>
                    </Col>
                    <Col span={12}>
                      <Item
                        label='Owner Lastname'
                        name='lastname'
                        rules={[{ required: true}]}
                      >
                        <Input />
                      </Item>
                    </Col>
                    <Col span={16}>
                      <Item
                        label='Address'
                        name='address'
                        rules={[{ required: true }]}
                      >
                        <Input />
                      </Item>
                    </Col>
                    <Col span={8}>
                      <Item label='Suite' name='suite'>
                        <Input />
                      </Item>
                    </Col>
                    <Col span={6}>
                      <Item label='Country' name='country' rules={[{ required: true }]}>
                        <Select
                          showSearch
                          placeholder='Select Country'
                          onChange={(country) => {
                            setStates(states.getByCountry(country));
                            contactForm.setFieldValue('state', states.getByCountry(country)[0].isoCode);
                          }}
                        >
                          {
                            countries.all().map((country, i) => <Option value={country.isoCode} key={i}>{country.name}</Option>)
                          }
                        </Select>
                      </Item>
                    </Col>
                    <Col span={6}>
                      <Item label='State' name='state' rules={[{ required: true }]}>
                        <Select
                          showSearch
                          placeholder='Select State'
                          onChange={(state) => {
                            const country = contactForm.getFieldValue('country');
                            setCities(cities.getByState(state, country));

                            contactForm.setFieldValue('city', cities.getByState(state, country)[0]?.name);
                          }}
                        >
                          {
                            stateList.map((state, i) => <Option value={state.isoCode} key={i}>{state.name}</Option>)
                          }
                        </Select>
                      </Item>
                    </Col>
                    <Col span={6}>
                      <Item label='City' name='city' rules={[{ required: true }]}>
                        <Select showSearch placeholder='Select City'>
                          {
                            cityList.map((city, i) => <Option value={city.name} key={i}>{city.name}</Option>)
                          }
                        </Select>
                      </Item>
                    </Col>
                    <Col span={6}>
                      <Item
                        label='Zipcode'
                        name='zipcode'
                        rules={[{ required: true }, { pattern: /^\d{5}(?:[-\s]\d{4})?$/, message: 'Invalid Zipcode' }]}>
                        <Input />
                      </Item>
                    </Col>
                    <Col span={12}>
                      <Item>
                        <Button type='primary' onClick={() => setStep(0)}>Prev</Button>
                      </Item>
                    </Col>
                    <Col span={12} style={{ textAlign: 'end'}}>
                      <Item>
                        <Button type='primary' htmlType='submit'>Next</Button>
                      </Item>
                    </Col>
                  </Row>
                </Form>
              }
              {
                step === 2 && 
                <Form
                  layout='vertical'
                  onFinish={onTaxInfo}
                  initialValues={data.taxInfo}
                >
                  <Title level={3}>Tax Information</Title>
                  <Row gutter={20}>
                    <Col span={8}>
                      <Item
                        label='Tax ID / EIN'
                        name='taxId'
                        rules={[{ required: true }]}
                      >
                        <Input />
                      </Item>
                    </Col>
                    <Col span={8}>
                      <Item
                        label='Tax Rate (%)'
                        name='taxRate'
                        rules={[{ required: true}]}
                      >
                        <Input />
                      </Item>
                    </Col>
                    <Col span={8}>
                      <Item
                        label='State Incorporated'
                        name='stateIncorporated'
                        rules={[{ required: true }]}
                      >
                        <Input />
                      </Item>
                    </Col>
                    <Col span={12}>
                      <Item>
                        <Button type='primary' onClick={() => setStep(1)}>Prev</Button>
                      </Item>
                    </Col>
                    <Col span={12} style={{ textAlign: 'end'}}>
                      <Item>
                        <Button type='primary' htmlType='submit'>Next</Button>
                      </Item>
                    </Col>
                  </Row>
                </Form>
              }
              {
                step === 3 && 
                <Form
                  layout='vertical'
                  onFinish={onBankInfo}
                  initialValues={data.bankInfo}
                >
                  <Title level={3}>Banking Information</Title>
                  <Row gutter={20}>
                    <Col span={12}>
                      <Item
                        label='Account Number'
                        name='accountNumber'
                        rules={[{ required: true }]}
                      >
                        <Input />
                      </Item>
                    </Col>
                    <Col span={12}>
                      <Item
                        label='Routing Number'
                        name='routingNumber'
                        rules={[{ required: true}]}
                      >
                        <Input />
                      </Item>
                    </Col>
                    <Col span={12}>
                      <Item
                        label='Last 4 SSN'
                        name='last4SSN'
                        rules={[{ required: true}]}
                      >
                        <Input />
                      </Item>
                    </Col>
                    <Col span={12}>
                      <Item
                        label='Date of Birth'
                        name='birthday'
                        rules={[{ required: true}]}
                      >
                        <DatePicker style={{ width: '100%' }} />
                      </Item>
                    </Col>
                    <Col span={12}>
                      <Item>
                        <Button type='primary' onClick={() => setStep(2)}>Prev</Button>
                      </Item>
                    </Col>
                    <Col span={12} style={{ textAlign: 'end'}}>
                      <Item>
                        <Button type='primary' htmlType='submit'>Finish</Button>
                      </Item>
                    </Col>
                  </Row>
                </Form>
              }
            </div>
          </div>
        </section>
      </Styles>
    </>
  );
};

export default Register