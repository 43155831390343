import React from 'react';
import {Link, useLocation} from "react-router-dom";

const ProfileSidebar = () => {
  const location = useLocation()

  return (
    <div className="side-nav-wrap">
      <div className="side-nav">
        <nav>
          <li>
            <Link
              className={location.pathname === "/store/settings/store" ? "active" : ""}
              to="/store/settings/store">
              Store
            </Link>
            {/* <Link
              className={location.pathname === "/bank/store/profile/edit" ? "active" : ""}
              to="/bank/store/profile/edit">
              Profile
            </Link> */}
          </li>
          <li>
          <Link
              className={location.pathname === "/store/settings/legal" ? "active" : ""}
              to="/store/settings/legal">
              Legal
            </Link>
          </li>
          <li>
            <Link
              className={location.pathname.includes("activations") ? "active" : ""}
              to="/store/settings/activations">
              Activations
            </Link>
          </li>
          <li>
            <Link
              className={location.pathname.includes("storelink") ? "active" : ""}
              to="/store/settings/storelink">
              StoreLink
            </Link>
          </li>
          <li>
            <Link
              className={location.pathname.includes("storeQR") ? "active" : ""}
              to="/store/settings/storeQR">
              StoreQR
            </Link>
          </li>
        </nav>
      </div>
    </div>
  );
};

export default ProfileSidebar;
