const getRandomString = (length) => {
  var randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var result = '';
  for ( var i = 0; i < length; i++ ) {
    result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
  }
  return result;
}

const getRandomLowString = (length) => {
  var randomChars = 'abcdefghijklmnopqrstuvwxyz0123456789';
  var result = '';
  for ( var i = 0; i < length; i++ ) {
    result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
  }
  return result;
}

const toTitlecase = (string) => {
  return string.toLowerCase().split(' ').map((word) => {
    return (word.charAt(0).toUpperCase() + word.slice(1))
  }).join(' ')
}

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const getFileExtension = (filename) => {
  var re = /(?:\.([^.]+))?$/
  var ext = re.exec(filename)[1]
  return ext
}

const isEmail = (email) => {
  return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(email);
}

function isEmpty(obj) {
  for (var prop in obj) {
    if (obj.hasOwnProperty(prop)) return false;
  }
  return true;
}

const string2Json = (string) => {
  let res = null
  if (string !== '') {
    res = JSON.parse(string)
  }
  return res
}

const isNumeric = (str) => {
  return !isNaN(str)
}

const formatYmd = (date) => {
  return date.toISOString().slice(0, 10)
}

const formatMdy = (date) => {
  var dd = String(date.getDate()).padStart(2, "0");
  var mm = String(date.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = date.getFullYear();

  return mm + "/" + dd + "/" + yyyy;
}

const getMonth = (month) => {
  const mlist = [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ];
  return mlist[month-1]
}

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
};

const times = [
  "1:00",
  "2:00",
  "3:00",
  "4:00",
  "5:00",
  "6:00",
  "7:00",
  "8:00",
  "9:00",
  "10:00",
  "11:00",
  "12:00",
];

const states = [
  { val: "", name: "State" },
  { val: "AL", name: "Alabama" },
  { val: "AK", name: "Alaska" },
  { val: "AS", name: "American Samoa" },
  { val: "AZ", name: "Arizona" },
  { val: "AR", name: "Arkansas" },
  { val: "CA", name: "California" },
  { val: "CO", name: "Colorado" },
  { val: "CT", name: "Connecticut" },
  { val: "DE", name: "Delaware" },
  { val: "FL", name: "Florida" },
  { val: "GA", name: "Georgia" },
  { val: "GU", name: "Guam" },
  { val: "HI", name: "Hawaii" },
  { val: "ID", name: "Idaho" },
  { val: "IL", name: "Illinois" },
  { val: "IN", name: "Indiana" },
  { val: "IA", name: "Iowa" },
  { val: "KS", name: "Kansas" },
  { val: "KY", name: "Kentucky" },
  { val: "LA", name: "Louisiana" },
  { val: "ME", name: "Maine" },
  { val: "MD", name: "Maryland" },
  { val: "MA", name: "Massachusetts" },
  { val: "MI", name: "Michigan" },
  { val: "MN", name: "Minnesota" },
  { val: "MS", name: "Mississippi" },
  { val: "MO", name: "Missouri" },
  { val: "MT", name: "Montana" },
  { val: "NE", name: "Nebraska" },
  { val: "NV", name: "Nevada" },
  { val: "NH", name: "New Hampshire" },
  { val: "NJ", name: "New Jersey" },
  { val: "NM", name: "New Mexico" },
  { val: "NY", name: "New York" },
  { val: "NC", name: "North Carolina" },
  { val: "ND", name: "North Dakota" },
  { val: "MP", name: "Northern Marianas Islands" },
  { val: "OH", name: "Ohio" },
  { val: "OK", name: "Oklahoma" },
  { val: "OR", name: "Oregon" },
  { val: "PA", name: "Pennsylvania" },
  { val: "PR", name: "Puerto Rico" },
  { val: "RI", name: "Rhode Island" },
  { val: "SC", name: "South Carolina" },
  { val: "SD", name: "South Dakota" },
  { val: "TN", name: "Tennessee" },
  { val: "TX", name: "Texas" },
  { val: "UT", name: "Utah" },
  { val: "VT", name: "Vermont" },
  { val: "VI", name: "Virgin Islands" },
  { val: "VA", name: "Virginia" },
  { val: "WA", name: "Washington" },
  { val: "DC", name: "Washington, D.C." },
  { val: "WV", name: "West Virginia" },
  { val: "WI", name: "Wisconsin" },
  { val: "WY", name: "Wyoming" },
];

const countries = [
  "United States",
  "Afghanistan",
  "Aland Islands",
  "Albania",
  "Algeria",
  "American Samoa",
  "Andorra",
  "Angola",
  "Anguilla",
  "Antarctica",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Aruba",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivia",
  "Bonaire, Saint Eustatius and Saba ",
  "Bosnia and Herzegovina",
  "Botswana",
  "Bouvet Island",
  "Brazil",
  "British Indian Ocean Territory",
  "British Virgin Islands",
  "Brunei",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Cape Verde",
  "Cayman Islands",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Christmas Island",
  "Cocos Islands",
  "Colombia",
  "Comoros",
  "Cook Islands",
  "Costa Rica",
  "Croatia",
  "Cuba",
  "Curacao",
  "Cyprus",
  "Czech Republic",
  "Democratic Republic of the Congo",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "East Timor",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Ethiopia",
  "Falkland Islands",
  "Faroe Islands",
  "Fiji",
  "Finland",
  "France",
  "French Polynesia",
  "French Southern Territories",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Gibraltar",
  "Greece",
  "Greenland",
  "Grenada",
  "Guadeloupe",
  "Guam",
  "Guatemala",
  "Guernsey",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "French Guiana",
  "Haiti",
  "Heard Island and McDonald Islands",
  "Honduras",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran",
  "Iraq",
  "Ireland",
  "Isle of Man",
  "Israel",
  "Italy",
  "Ivory Coast",
  "Jamaica",
  "Japan",
  "Jersey",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Kosovo",
  "Kuwait",
  "Kyrgyzstan",
  "Laos",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macao",
  "Macedonia",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Martinique",
  "Mauritania",
  "Mauritius",
  "Mayotte",
  "Mexico",
  "Micronesia",
  "Moldova",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Montserrat",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "New Caledonia",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "Niue",
  "Norfolk Island",
  "North Korea",
  "Northern Mariana Islands",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Palestinian Territory",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Pitcairn",
  "Poland",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "Republic of the Congo",
  "Reunion",
  "Romania",
  "Russia",
  "Rwanda",
  "Saint Barthélemy",
  "Saint Helena",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Martin",
  "Saint Vincent and the Grenadines",
  "Saint Pierre and Miquelon",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Sint Maarten",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Georgia and the South Sandwich Islands",
  "South Korea",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Svalbard and Jan Mayen",
  "Swaziland",
  "Sweden",
  "Switzerland",
  "Syria",
  "Taiwan",
  "Tajikistan",
  "Tanzania",
  "Thailand",
  "Togo",
  "Tokelau",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Turks and Caicos Islands",
  "Tuvalu",
  "U.S. Virgin Islands",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "United States",
  "United States Minor Outlying Islands",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Vatican",
  "Venezuela",
  "Vietnam",
  "Wallis and Futuna",
  "Western Sahara",
  "Yemen",
  "Zambia",
  "Zimbabwe",
];

export {
  getRandomString,
  getRandomLowString,
  getFileExtension,
  getBase64,
  toTitlecase,
  capitalizeFirstLetter,
  isEmail,
  isNumeric,
  isEmpty,
  string2Json,
  formatYmd,
  formatMdy,
  getMonth,
  times,
  states,
  countries,
};