import React from 'react'
import {HashLink as Link} from 'react-router-hash-link';
import {Avatar, Button, Dropdown, Space} from "antd";
import {Icon} from "@iconify/react";
import {Styles} from '../../layouts/style/headerHomeStyle';
import AuthPopup from "../auth/auth";
import {getCookie, setCookie} from "../../service/cookie";
import {useNavigate} from "react-router-dom";

const HeaderHome = (props, toggleModal) => {
    const {directory} = props;
    const isAuth = getCookie("current_ident_token");
    const navigate = useNavigate();
    const username = getCookie("veev_current_user_username");

    const logoutHandle = () => {
        setCookie("signin_session_id", '', 0);
        setCookie("signin_merchant_code", '', 0);
        setCookie("signin_merchant_id", '', 0);
        setCookie("signin_merchant_user_id", '', 0);
        setCookie("signin_current_user_id", '', 0);
        setCookie("signin_merchant_name", '', 0);
        setCookie("current_ident_token", '', 0);
        setCookie("current_user_email", '', 0);
        setCookie("selected_merchant_address", '', 0);
        setCookie("current_merchant_wallet_address", '', 0);
        setCookie("selected_merchant_bool_cannabis_merchant", '', 0);
        setCookie("veev_session_id", '', 0);
        setCookie("veev_current_user_id", '', 0);
        setCookie("veev_current_user_email", '', 0);
        setCookie("veev_current_user_ident_token", '', 0);
        navigate("/");
    }

    const menuItems = [
        // {
        //     key: '1',
        //     label: (
        //         <Link to={`/user/${username}`}>
        //             <Space>
        //                 <Icon
        //                     style={{fontSize: 24, fontWeight: 700}}
        //                     icon="ph:user"/>
        //                 <h5 style={{fontSize: 16, fontWeight: 700}}>
        //                     User Account
        //                 </h5>
        //             </Space>
        //         </Link>
        //     ),
        // },
        {
            key: '2',
            label: (
                <Link to="/store/checkout/transactions">
                    <Space>
                        <Icon
                            style={{fontSize: 24, fontWeight: 700}}
                            icon="ic:outline-local-grocery-store"/>
                        <h5 style={{fontSize: 16, fontWeight: 700}}>
                            Business Account
                        </h5>
                    </Space>
                </Link>
            ),
        },
        {
            key: '3',
            label: (
                <a onClick={logoutHandle}>
                    <Space>
                        <Icon
                            style={{fontSize: 24, fontWeight: 700}}
                            icon="teenyicons:logout-outline"/>
                        <h5 style={{fontSize: 16, fontWeight: 700}}>
                            Logout
                        </h5>
                    </Space>
                </a>
            ),
        },
    ];

    return (
        <Styles>
            <nav className="navbar navbar-expand-lg navbar-light fixed-top header" style={{zIndex: 999}} id="mainNav">
                <div className="container">
                    <Link
                        className="navbar-brand js-scroll-trigger"
                        to="/#">
                        <img
                            src="/assets/img/veev_logo_black.png"
                            height="25"
                            width="*"
                            alt='logo-black'
                        />
                    </Link>

                    {/* mobile */}
                    <div className="navbar-toggler navbar-light" id="navbarResponsive">
                        <ul>
                            <li className="nav-item">
                                {
                                    isAuth ? (
                                        <Dropdown menu={{items: menuItems}} placement="bottomRight">
                                            <Avatar
                                                size={42}
                                                src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png">
                                            </Avatar>
                                        </Dropdown>
                                    ) : (
                                       <AuthPopup />
                                    )
                                }
                            </li>
                        </ul>
                    </div>

                    {/* web */}
                    <div className="collapse navbar-collapse" id="navbarResponsive">
                        <ul className="navbar-nav ml-auto" style={{height: '40px'}}>
                            <li>
                                <Link className={`nav-link ${directory === 'app' ? 'active' : ''}`} to="/#rewards"
                                id="">Rewards</Link>
                            </li>
                            <li>
                                <Link className={`nav-link ${directory === 'app' ? 'active' : ''}`} to="/business"
                                id="">Business</Link>
                            </li>
                            <li>
                                <Link className={`nav-link ${directory === 'app' ? 'active' : ''}`} to="https://auth.veev.app"
                                id="">Sign Up</Link>
                            </li>
                            {/* <li>
                                <Link className={`nav-link ${directory === 'app' ? 'active' : ''}`} to="/#cashback"
                                id="">AI</Link>
                            </li> */}
                            {/* <li>
                                <Link className={`nav-link ${directory === 'app' ? 'active' : ''}`} to="/#recommendations"
                                id="">Recommendations</Link>
                            </li> */}
                            {/* <li>
                                <Link className={`nav-link ${directory === 'app' ? 'active' : ''}`} to="/coin"
                                id="">VEEV Coin</Link>
                            </li>  */}
                            {/* {
                            !isAuth && (
                            <li>
                                <Link className={`nav-link ${directory === 'solutions' ? 'active' : ''}`} to="/business" id="">Business</Link>
                                <div className="dropdown-content">
                                    <div className="drop-wrap">
                                        <ul style={{width: '20%', marginLeft: 90, marginRight: 30}}>
                                            <li style={{marginLeft: 0, marginRight: 0, paddingRight: 50}}>
                                                <h3 style={{fontSize: 25}}>Easy, Fast, Simple</h3>
                                                <h4 style={{paddingBottom: '0px', marginBottom: '-3px'}}>Checkin<br></br>Checkout<br></br>Service</h4>
                                                <h4 style={{paddingBottom: '0px', marginBottom: '5px'}}></h4>
                                                for reservations, ordering, buying, paying at restaurants, cafes, bars and stores
                                                <h3>Seamless Booking & Ordering</h3>
                                                <p></p>
                                                <li style={{marginLeft: 0, marginRight: 0, paddingRight: 50}}>
                                                <Button
                                                    block
                                                    type="primary"
                                                    size="large"
                                                    onClick={toggleModal || null}>
                                                      Signup  
                                                </Button>
                                                </li>
                                            </li>
                                        </ul>
                                        <ul style={{width: '17%', marginRight: 10}}>
                                            <li><h3 style={{fontSize: 25}}>CRM</h3>
                                            Engage With Customers
                                            </li>
                                            <li><Link to="/business/#crm"><Icon
                                                style={{marginRight: 9, fontSize: 25}} 
                                                icon="octicon:star-16"/> Reviews</Link></li>
                                            <li><Link to="/business/#checkin"><Icon
                                                style={{marginRight: 9, fontSize: 25}}
                                                icon="ic:outline-local-offer"/> Offers</Link></li>      
                                            <li><Link to="/business/#checkin"><Icon
                                                style={{marginRight: 9, fontSize: 25}}
                                                icon="lucide:megaphone"/> Promotions</Link></li>    
                                            <li><Link to="/business/#crm"><Icon
                                                style={{marginRight: 9, fontSize: 25, paddingBottom: 0}}
                                                icon="ic:outline-email"/> Marketing</Link></li>
                                             <li style={{marginLeft: 0, marginRight: 0, paddingRight: 50}}>
                                             <h4 style={{paddingTop: '-5px', marginBottom: '15px'}}>$49</h4>
                                                <Button
                                                    block
                                                    type="primary"
                                                    size="large"
                                                    onClick={toggleModal || null}>
                                                      Sign Up  
                                                </Button>
                                                </li>   
                                            <li><Link to="/business/#checkin"><Icon
                                                style={{marginRight: 9, fontSize: 25}}
                                                icon="ep:calendar"/> Reservations</Link></li>
                                            <li><Link to="/business/#checkin"><Icon
                                                style={{marginRight: 9, fontSize: 25}}
                                                icon="la:id-card"/> Checkin</Link></li>
                                            <li><Link to="/storelink/#marketplace"><Icon
                                                style={{marginLeft: 5, marginRight: 9, fontSize: 25}}
                                                icon="bi:pass"/> Ticketing</Link></li>
                                            <li><Link to="/storelink/#marketplace"><Icon
                                                style={{marginLeft: 5, marginRight: 9, fontSize: 25}}
                                                icon="fluent-mdl2:waitlist-confirm"/> Waitlist</Link></li>
                                            <li><Link to="/storelink/#marketplace"><Icon
                                                style={{marginLeft: 5, marginRight: 9, fontSize: 25}}
                                                icon="carbon:security"/> ID Verification</Link></li>
                                        </ul>
                                        <ul style={{width: '15%', marginRight: 30}}>
                                            <li><h3 style={{fontSize: 25}}>Store</h3>
                                            Mobile Order, Pay, Pickup
                                            </li>
                                            <li><Link to="/business/#checkout"><Icon
                                                style={{marginRight: 9, fontSize: 25}}
                                                icon="ic:outline-local-grocery-store"/> Order</Link></li>
                                            <li><Link to="/business/#checkout"><Icon
                                                style={{marginRight: 9, fontSize: 25}}
                                                icon="quill:creditcard"/> Pay</Link></li> 
                                            <li><Link to="/business/#checkout"><Icon
                                                style={{marginRight: 9, fontSize: 25}}
                                                icon="ph:basket"/> Pickup/Ship</Link></li>
                                            <li><Link to="/business/#checkout"><Icon
                                                style={{marginRight: 9, fontSize: 25}}
                                                icon="ri:computer-line"/> POS</Link></li>
                                            <li style={{marginLeft: 0, marginRight: 0, paddingRight: 50}}>
                                             <h4 style={{paddingTop: '-5px', marginBottom: '15px'}}>$29</h4>
                                                <Button
                                                    block
                                                    type="primary"
                                                    size="large"
                                                    onClick={toggleModal || null}>
                                                      Sign Up  
                                                </Button>
                                            </li>         
                                        </ul>
                                        <ul style={{width: '15%', marginRight: 30}}>
                                            <li><h3 style={{fontSize: 25}}>Solutions</h3></li>
                                            <li><Link to="/business/#crm"><Icon
                                                style={{marginRight: 9, fontSize: 25}}
                                                icon="fluent-emoji-high-contrast:mobile-phone"/> POS</Link></li>
                                            <li><Link to="/business/#crm"><Icon
                                                style={{marginLeft: 0, marginRight: 9, fontSize: 25}}
                                                icon="ep:data-line"/> CRM</Link></li>
                                            <li><Link to="/business/#crm"><Icon
                                                style={{marginRight: 9, fontSize: 25}}
                                                icon="ic:outline-email"/> Email/SMS</Link></li>       
                                            <li><Link to="/business/#crm"><Icon
                                                style={{marginRight: 9, fontSize: 25}} 
                                                icon="octicon:star-16"/> Reviews</Link></li>
                                        </ul>
                                        <ul style={{width: '15%', marginRight: 30}}>
                                            <li><h3 style={{fontSize: 25}}>Industry</h3></li>
                                            <li><Link to="/foodtrucks"><Icon
                                                style={{marginRight: 9, fontSize: 25}}
                                                icon="material-symbols:local-shipping-outline"/> FoodTrucks</Link></li>
                                            <li><Link to="/foodtrucks"><Icon
                                                style={{marginRight: 9, marginBottom: 3, fontSize: 25}}
                                                icon="ph:martini"/> Cafes / Bars</Link></li>      
                                            <li><Link to="/restaurants"><Icon
                                                style={{marginRight: 9, marginBottom: 3, fontSize: 25}}
                                                icon="codicon:coffee"/> Restaurants</Link></li>
                                        </ul>
                                        <ul style={{width: '7%', marginRight: 90}}>
                                            <li style={{marginLeft: 0, marginRight: 0, paddingRight: 50}}>
                                                <li style={{marginLeft: 0, marginRight: 0, paddingRight: 50}}>
                                                <Button
                                                    block
                                                    type="primary"
                                                    size="large"
                                                    onClick={toggleModal || null}>
                                                    Add Your Business
                                                </Button>
                                                </li>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </li>
                            )
                          } */}

                          {/* popout sign in */}
                            {/* <li className="nav-item">
                                {
                                    isAuth ? (
                                        <Dropdown menu={{items: menuItems}} placement="bottomRight">
                                            <Avatar
                                                size={42}
                                                src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png">
                                            </Avatar>
                                        </Dropdown>
                                    ) : (
                                       <AuthPopup />
                                    )
                                }
                            </li> */}
                        </ul>
                    </div>
                </div>
            </nav>
        </Styles>
    )
}
export default HeaderHome;
