import React from "react";
import { Helmet } from "react-helmet";

const GTM = () => {
  return (
    <div>
      <Helmet>
        <script key="gtm_src" id="gtm_def">
          {`
            <!-- Google Tag Manager -->
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src= 'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f); })(window,document,'script','dataLayer','GTM-MJCCDPD6');
            <!-- End Google Tag Manager -->
        `}
        </script>
      </Helmet>
      <Helmet>
        <noscript key="gtmframe" id="gtm_iframe_def">
          {`
        <!-- Google Tag Manager (noscript) -->
        <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-MJCCDPD6" height="0" width="0" style="display:none;visibility:hidden"></iframe>
        <!-- End Google Tag Manager (noscript) -->
        `}
        </noscript>
      </Helmet>
    </div>
  );
};

export default GTM;

