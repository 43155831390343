import React, { useEffect, useState } from 'react'
import { NotificationManager } from 'react-notifications';
import Layout from "../../../layouts/dashboard";
import Tab from "../../../layouts/dashboard/components/settings-tab";
import { useAsync } from "../../../service/utils";
import { useSetting } from "../../../provider/setting";
import { upload } from "../../../api/file";
import { getFileExtension, formatYmd, times } from "../../../service/string";
// import Nav from "../nav";
// import Sidebar from './sidebar';

const boxNumbers = [1, 2, 3, 4, 5, 6, 7]
const Create = () => {
  const { data, status, error, run } = useAsync({
    status: "idle",
  });
  const [setting] = useSetting()
  const [category, setCategory] = useState('Donation')
  const [logo, setLogo] = useState('https://s3.amazonaws.com/veev-prod/uploaded_event_image/184_1572124316.png')
  const [boxNumber, setBoxNumber] = useState(boxNumbers[0])
  const [isListing, setIsListing] = useState(true)
  const [backLink, setBackLink] = useState('')
  const [asyncState, setAsyncState] = useState('')

  const uploadImage = (e) => {
    const file = e.target.files[0]
    const filename = setting?.auth?.signin_merchant_id + '_' + formatYmd(new Date()) + '.' + getFileExtension(file.name);
    run(upload(file, filename))
    setAsyncState('fileUpload')
  }
  const handleSave = () => {

  }

  useEffect(() => {
    if (status === 'resolved') {
      if (asyncState === 'fileUpload') {
        setLogo(data.location);
      }
    }
    else if (status === 'rejected') {
      console.log(error)
    }
  }, [status])
  return (
    <>
      <Layout>
      <Tab/>  
      {/* <Styles> */}
        <section className="bank-section bank-events">
          <div className="container tab-part">
            {/* <Nav active="event" /> */}
            <div className="selected-merchant-name">
              {setting?.auth?.signin_merchant_name}
              <span className="selected-nav-top"> &gt; Donation &gt; </span>
              <span className="selected-nav-top-active">Create</span>
            </div>
          </div>
          <div className="features tab-section" id="id_sec_veev_id_access">
            <div className="container h-100">
              <div className="row h-100">
                <div className="col-md-12">
                  {/* <Sidebar active="create" /> */}
                  {/* CREATE DONATE */}
                  <div className="row devsite-section-article" id="id_dv_contact_info">
                    <div className="col-md-12">
                      <fieldset id="">
                        <legend>Create Donation</legend>
                      </fieldset>
                    </div>
                    <div className="col-md-12 form-group">
                      {/* DONATE TITLE */}
                      <fieldset>
                        <div className="row">
                          <div className="col-md-4 col-xs-12 form-group">
                            <label htmlFor="id_event_start_date" className="ak-is-overlaid cursor">Category<span className="ak-required-flag">*</span></label>
                            <input id="id_event_title" type="text" className="form-control mo-userfield-input ak-has-overlay m-0" value={category} onChange={(e) => setCategory(category)} />
                          </div>
                          <div className="col-md-4 col-xs-12 form-group">
                            <div>
                              <label htmlFor="id_event_start_time" className="ak-is-overlaid cursor">Number of Boxes<span className="ak-required-flag">*</span></label>
                            </div>
                            <div>
                              <select style={{ width: '100%' }} id="id_event_start_date" className="event_times form-control mo-userfield-input ak-has-overlay" value={boxNumber} onChange={(e) => setBoxNumber(e.target.value)}>
                                {boxNumbers.map((item, index) => (
                                  <option value={item} key={index}>{item}</option>
                                ))}
                              </select>
                            </div>
                          </div>
                          <div className="col-md-4 col-xs-12 form-group">
                            <div>
                              <label htmlFor="id_event_ticket_quantity" className="ak-is-overlaid cursor">Mobile Store Listing*<span></span></label>
                            </div>
                            <div>
                              <select className="am" id="id_start_time_postfix" value={isListing} onChange={(e) => setIsListing(e.target.value)}>
                                <option value={true}>Yes</option>
                                <option value={false}>No</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </fieldset>
                    </div>
                    <div className="col-md-12 form-group">
                      {/* EVENT TITLE */}
                      <fieldset>
                        <label htmlFor="id_event_title" className="ak-is-overlaid cursor">Back to website<span className="ak-required-flag">*</span></label>
                        <input id="id_event_title" type="text" className="form-control mo-userfield-input ak-has-overlay m-0" value={backLink} onChange={(e) => setBackLink(e.target.value)} />
                      </fieldset>
                    </div>
                    <div className="col-md-12">  
                      <fieldset>
                        <label htmlFor="id_event_img">Mobile Logo</label>
                      </fieldset>
                    </div>
                    <div className="col-md-12 form-group">
                      <fieldset>
                        <div className="col-md-12 form-group imgUp">
                          <label className="">
                            <div className="imagePreview" id="eventImagePreview" style={{ objectFit : 'fill', backgroundImage : `url(${logo})`}}></div>
                            <input type="file" className="uploadFile img" id="id_upload_file" onChange={uploadImage} />
                          </label>
                        </div>
                      </fieldset>
                    </div>
                    {/* TODO: - MOVE IMG FILES TO CORRECT PATH */}
                    <div className="col-md-4"></div>
                    <div className="col-md-4  section-heading text-center" style={{ paddingBottom: 20 }}>
                      <button type="button" className="btn2 btn2-outline js-scroll-trigger bank-account-btn form-control" id="id_btn_save_event" onClick={handleSave}> Save Changes </button>
                    </div>
                    <div className="col-md-4"></div>
                  </div>
                   {/* END DONATE EVENTS */}
                </div>
              </div>
            </div>
          </div>
        </section>
      {/* </Styles> */}
      </Layout>
    </>
  );
}
export default Create
