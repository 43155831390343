import React, { useEffect, useState } from 'react'
import ActionCable from "actioncable";
import Layout from "../../../../layouts/dashboard";
import CRMTab from "../../../../layouts/dashboard/components/crm-tab";
import Sidebar from "./sidebar";
import {Avatar, AutoComplete, Button, Col, Input, Row, Modal, Table} from "antd";
import { useAsync } from '../../../../service/utils';
import { getCrmTrxItemList } from "../../../../api/bank";
import siteConfig from "../../../../config/site.config";
import { getCookie } from "../../../../service/cookie";
import { useSetting } from '../../../../provider/setting';

const Crm = () => {
  const { data, status, error, run } = useAsync({ status: "idle",});
  const [setting] = useSetting()
  const [users, setUsers] = useState([])
  const [isLoad, setIsLoad] = useState(false)
  const [padInfo, setPadInfo] = useState({})
  const { Search } = Input;
  const [keyworkd, setKeyword] = useState('')
  
  const init = () => {
    const dateTo = new Date()
    dateTo.setHours(23);
    dateTo.setMinutes(59);
    dateTo.setSeconds(59);
    const dateFrom = new Date()
    dateFrom.setDate(dateFrom.getDate() - 90)
    // let _initDuration = formatYmd(dateFrom) + " - " + formatYmd(dateTo)
  }
  const changeUsers = (transactions) => {
    transactions.sort((a, b) => {
      let comparison = 0;
      if (a.user_id > b.user_id) {
        comparison = 1;
      } else if (a.user_id < b.user_id) {
        comparison = -1;
      }
      return comparison;
    });
    let users = [];
    let user_id = -1;
    let _transactions = [];
    transactions.forEach((item) => {
      if (user_id === item.user_id) {
        _transactions = [..._transactions, item];
      }
      else {
        if (user_id !== -1) {
          let tmp = [..._transactions];
          tmp.sort((a, b) => {
            let comparison = 0;
            if (new Date(a.created_at) > new Date(b.created_at)) {
              comparison = -1;
            } else if (new Date(a.created_at) < new Date(b.created_at)) {
              comparison = 1;
            }
            return comparison;
          });
          users = [...users, {
            isShow: false,
            transactions: tmp
          }];
        }
        _transactions = [item];
        user_id = item.user_id;
      }
    });
    _transactions.sort((a, b) => {
      let comparison = 0;
      if (new Date(a.created_at) > new Date(b.created_at)) {
        comparison = -1;
      } else if (new Date(a.created_at) < new Date(b.created_at)) {
        comparison = 1;
      }
      return comparison;
    });
    users = [...users, {
      isShow: false,
      transactions: _transactions
    }];
    users.sort((a, b) => {
      let comparison = 0;
      if (new Date(a.transactions[0].created_at) > new Date(b.transactions[0].created_at)) {
        comparison = -1;
      } else if (new Date(a.transactions[0].created_at) < new Date(b.transactions[0].created_at)) {
        comparison = 1;
      }
      return comparison;
    });
    console.log(users)
    setUsers(users)
  }
  const changeTransactions = (transactions) => {
    let _transactions = transactions.filter((item) => item.merchant_id === parseInt(setting?.auth?.signin_merchant_user_id))
    _transactions = _transactions.sort((a, b) => {
      let comparison = 0;
      if (new Date(a.created_at) > new Date(b.created_at)) {
        comparison = -1;
      } else if (new Date(a.created_at) < new Date(b.created_at)) {
        comparison = 1;
      }
      return comparison;
    })
    _transactions = _transactions.map((transaction) => {
      const datetime = new Date(transaction.created_at);
      const date = datetime.toDateString().substr(4);
      transaction.date = date
      transaction.time = datetime.toTimeString().substr(0, 8);
      var receipt_items = Array();
      try {
        receipt_items = JSON.parse(transaction.receipt || '');
        transaction.receipt_items = receipt_items;
      } catch ({name, msg}) {
        console.log(name, msg);
        console.log("fallingback")
        transaction.receipt_items = []
      }
      transaction.isPad = false;
      return transaction;
    });
    changeUsers(_transactions)
  }
  const addPad = async (user_id, trx_id) => {
    const transaction = users[user_id].transactions[trx_id]
    const res = await getCrmTrxItemList({
      sel_trx_id: transaction.id
    })
    let item_list = res.data
    let amount = 0
    item_list = item_list.map((item) => {
      let itemPrice = parseFloat(item.price) * parseInt(item.quantity);
      amount += itemPrice;
      item.amount = itemPrice;
      return item
    })
    const trxinfo = res.trxinfo
    const datetime = new Date(trxinfo.created_at);
    const date = datetime.toDateString().substr(4);
    trxinfo.date = date;
    trxinfo.time = datetime.toTimeString().substr(0, 5);
    let tax = parseFloat(trxinfo.tax) * 1000000000000000000;
    let tip = parseFloat(trxinfo.tip) * 1000000000000000000;
    amount += tax + tip;
    setPadInfo({
      item_list: item_list,
      trxinfo: trxinfo,
      amount: amount,
      tax: tax,
      tip: tip,
    })
    let _users = users.map((user, i) => {
      let _transactions = user.transactions
      _transactions = _transactions.map((item, j) => {
        if (user_id === i && trx_id === j)
          item.isPad = true
        else
          item.isPad = false
        return item
      })
      user.transactions = _transactions
      return user
    })
    setUsers(_users)
  }
  const removePad = (user_id, trx_id) => {
    let _users = [...users]
    _users[user_id].transactions[trx_id].isPad = false
    setUsers(_users)
  }
  const toggleTransactions = (user_id) => {
    let _users = [...users]
    _users[user_id].isShow = !_users[user_id].isShow;
    setUsers(_users)
  }

  useEffect(() => {
    init();
    setIsLoad(false)
  }, [run])
  useEffect(() => {
    const session_id = getCookie("signin_session_id");
    if (setting?.sel_network && !isLoad) {
      const cable = ActionCable.createConsumer(siteConfig.webSocketUrl);
      const transactions_subscription = cable.subscriptions.create(
        {
          channel: "TransactionChannel",
          session_id: session_id,
        },
        {
          connected: () => {
            console.log('Connected transaction_channel_request')
            transactions_subscription.send({
              type: "transaction_list_request",
              network: setting?.sel_network,
            });
          },
          received: (data) => {
            if (data.success === true && setting?.sel_network === "live") {
              if (!isLoad && data?.transactions?.length !== 0) {
                if (data.type === "transaction_list_response") {
                  changeTransactions(data.transactions);
                  /* main dashboard show part.*/
                } else if (data.type === "test_transaction_list_response") {
                  changeTransactions(data.transactions);
                }
                setIsLoad(true);
              }
            } else {
              console.log("error", data);
              // NotificationManager.warning(data.reason, "Warning", 3000);
            }
          },
        }
      );
    }
  }, [setting?.sel_network, isLoad]);
  return (
    <>
    <Layout> 
    <CRMTab/>  
      <div className="row">
        <div className="col col-lg-3">
          <Sidebar/>
        </div>
        <div className="col col-lg-9">
          <Search
          placeholder='Search CRM for sales, bookings, follower, email activity'
          size="large"
          allowClear
          onSearch={setKeyword}/>
          <table className="transactions" id="transactions">
            <thead>
              <th>Date</th>
              <th>Time</th>
              <th>Type</th>
              <th>From</th>
              <th>Total</th>
              <th>Username</th>
              <th>#</th>
            </thead>
            <tbody>
              {users.length === 0 ? (
                <tr>
                  <td colSpan="5" className="text-center">
                    Transaction is empty.
                  </td>
                </tr>
              ) : (
                users.map((user, user_index) => {
                  const transactions = user.isShow ? user.transactions : [user.transactions[0]]
                  const number = user?.transactions?.length
                  return (
                    <>
                      {transactions.map((transaction, trx_index) => (
                        <>
                          <tr>
                            <td style={{ cursor: "pointer" }} onClick={() => addPad(user_index, trx_index)}>
                              {transaction?.date}
                            </td>
                            <td>{transaction?.time}</td>
                            <td>{transaction?.merchant_name}</td>
                            <td>{transaction?.pay_type} / {transaction?.shipping_type}</td>
                            <td>
                              {transaction?.token_symbol}{" "}
                              {Number(transaction?.amount).toFixed(
                                2
                              )}
                            </td>
                            <td>{transaction?.first_name}</td>
                            {trx_index === 0 ?
                              <td onClick={() => toggleTransactions(user_index)} style={{color: 'red', cursor: 'pointer'}}>{number}</td> :
                              <td>{ number - trx_index }</td>
                            }
                          </tr>
                          <tr>
                            <td colSpan="5" name="dv_sel_trx">
                              {transaction?.isPad && (
                                <div class="dv-sel-trx-items">
                                  <div class="close" onClick={() => removePad(user_index, trx_index)}>
                                    <i class="icon-remove"></i>
                                  </div>
                                  <p class="title">
                                    {transaction?.merchant_name}
                                  </p>
                                  <p class="shipping-address">
                                    {transaction?.merchant_info}
                                  </p>
                                  <p class="receipt">
                                    Receipt #{" "}
                                    {padInfo?.trxinfo?.receipt_id}
                                  </p>
                                  <div class="row">
                                    <div class="col-md-1"></div>
                                    <div class="col-md-10">
                                      <div class="row border-bottom">
                                        <div class="col-md-6 text-left">
                                          {padInfo?.trxinfo?.date}
                                        </div>
                                        <div class="col-md-6 text-right">
                                          {padInfo?.trxinfo?.time}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-md-1"></div>
                                    <div class="col-md-10">
                                      <table style={{ width: "100%" }} id="id_tbl_crm_trx_item_list">
                                        <tbody>
                                          {padInfo?.item_list?.map(
                                            (item, index) => (
                                              <tr key={index}>
                                                <td>{item?.quantity}</td>
                                                <td>{item?.name}</td>
                                                <td>@</td>
                                                <td>$ {item?.price}</td>
                                                <td class="text-right" style={{ width: "50%", }}>
                                                  ${" "}
                                                  {item?.amount.toFixed(2)}
                                                </td>
                                              </tr>
                                            )
                                          )}
                                          <tr>
                                            <td colSpan="3" class="text-left">
                                              Tax
                                            </td>
                                            <td></td>
                                            <td class="text-right">
                                              ${" "}
                                              {padInfo?.tax.toFixed(
                                                2
                                              )}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td colSpan="3" class="text-left">
                                              Tip
                                            </td>
                                            <td></td>
                                            <td class="text-right">
                                              ${" "}
                                              {padInfo?.tip.toFixed(
                                                2
                                              )}
                                            </td>
                                          </tr>
                                          <tr class="total">
                                            <td colSpan="3" class="text-left">
                                              TOTAL
                                            </td>
                                            <td></td>
                                            <td class="text-right">
                                              ${" "}
                                              {Number(
                                                padInfo?.amount
                                              ).toFixed(2)}
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </td>
                          </tr>
                        </>
                      ))}
                    </>
                  )
                })
              )}
            </tbody>
          </table>
        </div>
      </div>
    </Layout>
    </>
  );
}
export default Crm
