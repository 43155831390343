import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Link, useLocation} from "react-router-dom";

const TopTab = () => {
  const location = useLocation();
  const params = location.pathname.split("/")

  const [checked, setChecked] = useState();

  useEffect(() => {
    setChecked(params?.[3] || "checkin")
  }, [params])

  const first = (checked === "business") || (checked === "legal") || (checked === "store") || (checked === "edit") || (checked === "activations") || (checked === "storelink") || (checked === "storeQR")

  return (
    <NavWrap className="container">
      <ul>
        <li className={first ? "active" : ""}>
          <Link to="/store/settings/store">
            Settings
          </Link>
        </li>
      </ul>
    </NavWrap>
  );
};

const NavWrap = styled.div`
  margin-bottom: 32px;

  ul {
    padding: 0;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    border-bottom: 1px solid #e5e5e5;

    li {
      list-style: none;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;

      a {
        text-decoration: none;
        display: inline-flex;
        align-items: center;
        padding: 8px 24px;
        color: #818182;
        font-size: 17px;
        cursor: pointer;
        margin-bottom: -1px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
      }

      &:hover {
        a {
          border: 1px solid #e5e5e5;
          font-size: 17px;
          border-bottom: 1px solid transparent;
        }
      }

      &.active {
        a {
          color: #cc0000 !important;
          font-size: 17px;
          border: 1px solid #cc0000;
          border-bottom: 1px solid #ffffff;
        }
      }
    }
  }
`

export default TopTab;
