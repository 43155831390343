import React, {useEffect, useState} from 'react';
import Layout from "../../../layouts/dashboard";
import Tab from "../../../layouts/dashboard/components/checkout-tab";
import {Button, Col, Form, Input, InputNumber, Row, Select} from "antd";
import {useSetting} from "../../../provider/setting";
import {useNavigate, useSearchParams} from "react-router-dom";
import {NotificationManager} from "react-notifications";
import { update, getAll } from '../../../api/extra';
import { update as updateCategory, getAll as getCategoriesApi } from '../../../api/category';

const {Item} = Form;

const StoreAddonEditPage = () => {
    const [setting] = useSetting();
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [id, setId] = useState("");
    const [item, setItem] = useState({});
    const [categories, setCategories] = useState([]);
    const [category, setCategory] = useState();
    const [originalCategory, setOriginalCategory] = useState()

    const finishHandle = (value) => {

        update(setting?.auth?.signin_merchant_code, id, value)
            .then((data) => {
                if (category) {
                    const cat = categories.find((c) => c.id === category)
                    let other = JSON.parse(cat.other || "[]")
                    other.push(data.id)
                    updateCategory(setting?.auth?.signin_merchant_code,
                        category,
                        {
                            other: JSON.stringify(other)
                        }
                    )
                        .then((data) => {
                        })
                        .catch((e) => {
                            console.warn(e)
                        })
                }

                if(originalCategory) {
                  const cat = categories.find((c) => c.id === originalCategory)
                  let other = JSON.parse(cat.other || "[]")
                  other = other.filter((i) => i !== data.id)
                  updateCategory(setting?.auth?.signin_merchant_code,
                      originalCategory,
                      {
                        other: JSON.stringify(other)
                      }
                  )
                      .then((data) => {
                      })
                      .catch((e) => {
                        console.warn(e)
                      })
                }

                NotificationManager.success("Successfully created an addon!", "Success", 3000)
                navigate('/store/checkout/addons')
            })
            .catch((e) => {
                console.warn(e);
                NotificationManager.error(e.response?.data?.message, "Error", 3000)
            })
    }

    useEffect(() => {
        const i = searchParams.get("id");
        setId(i);
        if (!!i && setting?.auth?.signin_merchant_code) {
            getAll(setting?.auth?.signin_merchant_code)
                .then((data) => {
                    data.forEach((item) => {
                        if (item.id.toString() === i) {
                            setItem(item)
                        }
                    })
                })
                .catch((e) => console.warn(e))
        }
    }, [setting])

    const getCategoriesHandle = async () => {
        if (setting?.auth?.signin_merchant_code) {
            getCategoriesApi(setting.auth.signin_merchant_code)
                .then((data) => {
                    setCategories(data);
                    const i = searchParams.get("id");
                    data.map((c) => {
                        if (JSON.parse(c.other || "[]").includes(parseInt(i))) {
                            form.setFieldValue("category", c.id);
                            setOriginalCategory(c.id)
                        }
                    })
                })
                .catch(e => console.warn(e))
        }
    }

    useEffect(() => {
        getCategoriesHandle()
    }, [])

    return (
        <Layout>
        <Tab/>    
            <div className="row">
                {/* <div className="col col-lg-3">
                    <Sidebar/>
                </div> */}
                <div className="col col-lg-12">
                    <h4>Edit Add-On</h4>

                    <Form
                        form={form}
                        fields={[
                            {
                                name: "name",
                                value: item.name
                            },
                            {
                                name: "price",
                                value: item.price
                            },
                        ]}
                        onFinish={finishHandle}
                        layout="vertical">

                        <Row gutter={12}>
                            <Col span={8}>
                                <Item
                                    name="category"
                                    id="category"
                                    label="Category">
                                    <Select
                                        size="large"
                                        showSearch
                                        placeholder="Select a category"
                                        optionFilterProp="children"
                                        onChange={(e) => {
                                            setCategory(e)
                                        }}
                                        value={category}
                                        filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                        }
                                        options={
                                            categories.map((category) => {
                                                category.value = category.id;
                                                category.label = category.name;
                                                return category
                                            })
                                        }/>
                                </Item>
                            </Col>
                            <Col span={8}>
                                <Item
                                    name="name"
                                    id="name"
                                    label="Name">
                                    <Input
                                        placeholder="Enter Addon Name"
                                        size="large"/>
                                </Item>
                            </Col>
                            <Col span={8}>
                                <Item
                                    name="price"
                                    id="price"
                                    label="Price">
                                    <InputNumber
                                        step="0.01"
                                        // parser={(value) => parseFloat(value.toString() || 0).toFixed(2)}
                                        prefix="$"
                                        suffix="USD"
                                        placeholder="Enter Price"
                                        size="large"/>
                                </Item>
                            </Col>
                            
                            <Col span={5}>
                            <Form.Item>
                            <Button 
                                block
                                type="primary"
                                size="large"
                                width="30px"
                                htmlType="submit">
                                Update
                            </Button>
                            </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </div>
        </Layout>
    );
};

export default StoreAddonEditPage;
