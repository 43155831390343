import React, {useState, useEffect} from 'react'
import {Styles} from '../../../layouts/style/homeStyle'

const Plans = () => {
    return (

        <div className="col-lg-12 my-auto text-left" style={{paddingTop: '40px'}}>
            <div className="container-fluid">
                <tr className="">
                    <td className="left-align p1 px3 border-right border-left rounded-left" style={{width: '55%'}}>
                        <div className="flex flex-center">
                            <div className="line-height-1">
                                <p className="title3" style={{paddingBottom: '10px', margin: '0px'}}>Plans</p>
                            </div>
                        </div>
                    </td>
                    <td className="center text-center" style={{width: '200px'}}>
                        <p className="title3" style={{paddingBottom: '10px', margin: '0px'}}>Starter</p>
                    </td>
                    <td className="center border-right text-center" style={{width: '200px'}}>
                        <p className="title3" style={{paddingBottom: '10px', margin: '0px'}}>Core</p>
                    </td>
                    <td className="center border-right text-center" style={{width: '200px'}}>
                        <p className="title3" style={{paddingBottom: '10px', margin: '0px'}}>Pro</p>
                    </td>
                </tr>

                <tr className="">
                    <td className="left-align p1 px3 border-right border-left rounded-left" style={{width: '55%'}}>
                        <div className="flex flex-center">
                            <div className="line-height-1">
                                <p className="title" style={{paddingBottom: '10px', margin: '0px'}}>Features</p>
                            </div>
                        </div>
                    </td>
                    <td className="center text-center" style={{width: '200px'}}>
                        <p className="title" style={{paddingBottom: '10px', margin: '0px'}}>$29</p>
                    </td>
                    <td className="center border-right text-center" style={{width: '200px'}}>
                        <p className="title" style={{paddingBottom: '10px', margin: '0px'}}>$60</p>
                    </td>
                    <td className="center border-right text-center" style={{width: '200px'}}>
                        <p className="title" style={{paddingBottom: '10px', margin: '0px'}}>$109</p>
                    </td>
                </tr>

                <tr className="" style={{background: '#cc0000'}}>
                    <td className="left-align p1 px3 border-right border-left rounded-left" style={{width: '55%'}}>
                        <div className="flex flex-center">
                            <div className="line-height-1">
                                <p className="title3" style={{
                                    paddingBottom: '0px',
                                    paddingLeft: '10px',
                                    color: '#ffffff',
                                    margin: '0px'
                                }}>Checkout Services</p>
                            </div>
                        </div>
                    </td>
                    <td className="center p1 border-right text-center">

                    </td>
                    <td className="center p1 border-right text-center">

                    </td>
                    <td className="center p1 border-right text-center">

                    </td>
                </tr>

                <tr className="border-bottom">
                    <td className="left-align p1 px3 border-right border-left rounded-left">
                        <div className="flex flex-center">
                            <div className="line-height-1">
                                <p className="m0"
                                   style={{paddingTop: '13px', paddingLeft: '10px', margin: '0px'}}>Accept payments from
                                    social apps</p>
                            </div>
                        </div>
                    </td>
                    <td className="center p1 border-right text-center">
                        <img src="../assets/img/24px-check-lightgreen.svg"
                             style={{margin: '4px', paddingTop: '2px', height: "24px", width: "24px"}}/>
                    </td>
                    <td className="center p1 border-right text-center">
                        <img src="../assets/img/24px-check-lightgreen.svg"
                             style={{margin: '4px', paddingTop: '2px', height: "24px", width: "24px"}}/>
                    </td>
                    <td className="center p1 border-right text-center">
                        <img src="../assets/img/24px-check-lightgreen.svg"
                             style={{margin: '4px', paddingTop: '2px', height: "24px", width: "24px"}}/>
                    </td>
                </tr>

                <tr className="border-bottom">
                    <td className="left-align p1 px3 border-right border-left rounded-left">
                        <div className="flex flex-center">
                            <div className="line-height-1">
                                <p className="m0"
                                   style={{paddingTop: '10px', paddingLeft: '10px', margin: '0px'}}>Itemized product
                                    listing and categorization</p>
                            </div>
                        </div>
                    </td>
                    <td className="center p1 border-right text-center">
                        <img src="../assets/img/24px-check-lightgreen.svg"
                             style={{margin: '4px', paddingTop: '2px', height: "24px", width: "24px"}}/>
                    </td>
                    <td className="center p1 border-right text-center">
                        <img src="../assets/img/24px-check-lightgreen.svg"
                             style={{margin: '4px', paddingTop: '2px', height: "24px", width: "24px"}}/>
                    </td>
                    <td className="center p1 border-right text-center">
                        <img src="../assets/img/24px-check-lightgreen.svg"
                             style={{margin: '4px', paddingTop: '2px', height: "24px", width: "24px"}}/>
                    </td>
                </tr>

                <tr className="border-bottom">
                    <td className="left-align p1 px3 border-right border-left rounded-left">
                        <div className="flex flex-center">
                            <div className="line-height-1">
                                <p className="m0"
                                   style={{paddingTop: '10px', paddingLeft: '10px', margin: '0px'}}>Digital invoicing
                                    merchant and customer</p>
                            </div>
                        </div>
                    </td>
                    <td className="center p1 border-right text-center">
                        <img src="../assets/img/24px-check-lightgreen.svg"
                             style={{margin: '4px', paddingTop: '2px', height: "24px", width: "24px"}}/>
                    </td>
                    <td className="center p1 border-right text-center">
                        <img src="../assets/img/24px-check-lightgreen.svg"
                             style={{margin: '4px', paddingTop: '2px', height: "24px", width: "24px"}}/>
                    </td>
                    <td className="center p1 border-right text-center">
                        <img src="../assets/img/24px-check-lightgreen.svg"
                             style={{margin: '4px', paddingTop: '2px', height: "24px", width: "24px"}}/>
                    </td>
                </tr>

                <tr className="border-bottom">
                    <td className="left-align p1 px3 border-right border-left rounded-left">
                        <div className="flex flex-center">
                            <div className="line-height-1">
                                <p className="m0"
                                   style={{paddingTop: '10px', paddingLeft: '10px', margin: '0px'}}>Mobile Order & Pay
                                    checkout app</p>
                            </div>
                        </div>
                    </td>
                    <td className="center p1 border-right text-center">
                        <img alt="" src="../assets/img/24px-check-lightgreen.svg"
                             style={{margin: '4px', paddingTop: '2px', height: "24px", width: "24px"}}/>
                    </td>
                    <td className="center p1 border-right text-center">
                        <img alt="" src="../assets/img/24px-check-lightgreen.svg"
                             style={{margin: '4px', paddingTop: '2px', height: "24px", width: "24px"}}/>
                    </td>
                    <td className="center p1 border-right text-center">
                        <img alt="" src="../assets/img/24px-check-lightgreen.svg"
                             style={{margin: '4px', paddingTop: '2px', height: "24px", width: "24px"}}/>
                    </td>
                </tr>

                <tr className="border-bottom">
                    <td className="left-align p1 px3 border-right border-left rounded-left">
                        <div className="flex flex-center">
                            <div className="line-height-1">
                                <p className="m0" style={{
                                    paddingTop: '10px',
                                    paddingBottom: '0px',
                                    paddingLeft: '10px',
                                    margin: '0px'
                                }}>In-Store Tap to Pay PoS system</p>
                            </div>
                        </div>
                    </td>
                    <td className="center p1 border-right text-center">
                        <img alt="" src="../assets/img/24px-check-lightgreen.svg"
                             style={{margin: '4px', paddingTop: '2px', height: "24px", width: "24px"}}/>
                    </td>
                    <td className="center p1 border-right text-center">
                        <img alt="" src="../assets/img/24px-check-lightgreen.svg"
                             style={{margin: '4px', paddingTop: '2px', height: "24px", width: "24px"}}/>
                    </td>
                    <td className="center p1 border-right text-center">
                        <img alt="" src="../assets/img/24px-check-lightgreen.svg"
                             style={{margin: '4px', paddingTop: '2px', height: "24px", width: "24px"}}/>
                    </td>
                </tr>

                <tr className="border-bottom">
                    <td className="left-align p1 px3 border-right border-left rounded-left">
                        <div className="flex flex-center">
                            <div className="line-height-1">
                                <p className="m0" style={{
                                    paddingTop: '10px',
                                    paddingBottom: '10px',
                                    paddingLeft: '10px',
                                    margin: '0px'
                                }}>Processing fee per transaction</p>
                            </div>
                        </div>
                    </td>
                    <td className="center p1 border-right text-center">
                        2.9% plus $0.30
                    </td>
                    <td className="center p1 border-right text-center">
                        2.9% plus $0.30
                    </td>
                    <td className="center p1 border-right text-center">
                        2.9% plus $0.30
                    </td>
                </tr>

                <tr className="" style={{background: '#cc0000'}}>
                    <td className="left-align p1 px3 border-right border-left rounded-left" style={{width: '55%'}}>
                        <div className="flex flex-center">
                            <div className="line-height-1">
                                <p className="title3" style={{
                                    paddingBottom: '0px',
                                    paddingLeft: '10px',
                                    color: '#ffffff',
                                    margin: '0px'
                                }}>Checkin Services</p>
                            </div>
                        </div>
                    </td>
                    <td className="center p1 border-right text-center">

                    </td>
                    <td className="center p1 border-right text-center">

                    </td>
                    <td className="center p1 border-right text-center">

                    </td>
                </tr>

                <tr className="border-bottom">
                    <td className="left-align p1 px3 border-right border-left rounded-left">
                        <div className="flex flex-center">
                            <div className="line-height-1">
                                <p className="m0"
                                   style={{paddingTop: '13px', paddingLeft: '10px', margin: '0px'}}>Appointment
                                    scheduling & bookings</p>
                            </div>
                        </div>
                    </td>
                    <td className="center p1 border-right text-center">

                    </td>
                    <td className="center p1 border-right text-center">
                        <img src="../assets/img/24px-check-lightgreen.svg"
                             style={{margin: '4px', paddingTop: '2px', height: "24px", width: "24px"}}/>
                    </td>
                    <td className="center p1 border-right text-center">
                        <img src="../assets/img/24px-check-lightgreen.svg"
                             style={{margin: '4px', paddingTop: '2px', height: "24px", width: "24px"}}/>
                    </td>
                </tr>

                <tr className="border-bottom">
                    <td className="left-align p1 px3 border-right border-left rounded-left">
                        <div className="flex flex-center">
                            <div className="line-height-1">
                                <p className="m0" style={{paddingTop: '10px', paddingLeft: '10px', margin: '0px'}}>RSVPs
                                    and reservations</p>
                            </div>
                        </div>
                    </td>
                    <td className="center p1 border-right text-center">

                    </td>
                    <td className="center p1 border-right text-center">
                        <img src="../assets/img/24px-check-lightgreen.svg"
                             style={{margin: '4px', paddingTop: '2px', height: "24px", width: "24px"}}/>
                    </td>
                    <td className="center p1 border-right text-center">
                        <img src="../assets/img/24px-check-lightgreen.svg"
                             style={{margin: '4px', paddingTop: '2px', height: "24px", width: "24px"}}/>
                    </td>
                </tr>

                <tr className="border-bottom">
                    <td className="left-align p1 px3 border-right border-left rounded-left">
                        <div className="flex flex-center">
                            <div className="line-height-1">
                                <p className="m0"
                                   style={{paddingTop: '10px', paddingLeft: '10px', margin: '0px'}}>Notifications and
                                    reminders</p>
                            </div>
                        </div>
                    </td>
                    <td className="center p1 border-right text-center">

                    </td>
                    <td className="center p1 border-right text-center">
                        <img alt="" src="../assets/img/24px-check-lightgreen.svg"
                             style={{margin: '4px', paddingTop: '2px', height: "24px", width: "24px"}}/>
                    </td>
                    <td className="center p1 border-right text-center">
                        <img alt="" src="../assets/img/24px-check-lightgreen.svg"
                             style={{margin: '4px', paddingTop: '2px', height: "24px", width: "24px"}}/>
                    </td>
                </tr>

                <tr className="border-bottom">
                    <td className="left-align p1 px3 border-right border-left rounded-left">
                        <div className="flex flex-center">
                            <div className="line-height-1">
                                <p className="m0" style={{paddingTop: '10px', paddingLeft: '10px', margin: '0px'}}>Guest
                                    checkin roster</p>
                            </div>
                        </div>
                    </td>
                    <td className="center p1 border-right text-center">

                    </td>
                    <td className="center p1 border-right text-center">
                        <img alt="" src="../assets/img/24px-check-lightgreen.svg"
                             style={{margin: '4px', paddingTop: '2px', height: "24px", width: "24px"}}/>
                    </td>
                    <td className="center p1 border-right text-center">
                        <img alt="" src="../assets/img/24px-check-lightgreen.svg"
                             style={{margin: '4px', paddingTop: '2px', height: "24px", width: "24px"}}/>
                    </td>
                </tr>

                <tr className="border-bottom">
                    <td className="left-align p1 px3 border-right border-left rounded-left">
                        <div className="flex flex-center">
                            <div className="line-height-1">
                                <p className="m0" style={{
                                    paddingTop: '10px',
                                    paddingBottom: '10px',
                                    paddingLeft: '10px',
                                    margin: '0px'
                                }}>Identity Verification</p>
                            </div>
                        </div>
                    </td>
                    <td className="center p1 border-right text-center">
                    </td>
                    <td className="center p1 border-right text-center">
                        $2 per instance
                    </td>
                    <td className="center p1 border-right text-center">
                        $0.25 per instance
                    </td>
                </tr>

                <tr className="" style={{background: '#cc0000'}}>
                    <td className="left-align p1 px3 border-right border-left rounded-left" style={{width: '55%'}}>
                        <div className="flex flex-center">
                            <div className="line-height-1">
                                <p className="title3" style={{
                                    paddingBottom: '0px',
                                    paddingLeft: '10px',
                                    color: '#ffffff',
                                    margin: '0px'
                                }}>CRM, Marketing & Operational Services</p>
                            </div>
                        </div>
                    </td>
                    <td className="center p1 border-right text-center">

                    </td>
                    <td className="center p1 border-right text-center">

                    </td>
                    <td className="center p1 border-right text-center">

                    </td>
                </tr>

                <tr className="border-bottom">
                    <td className="left-align p1 px3 border-right border-left rounded-left">
                        <div className="flex flex-center">
                            <div className="line-height-1">
                                <p className="m0"
                                   style={{paddingTop: '13px', paddingLeft: '10px', margin: '0px'}}>Transaction
                                    reporting by customer</p>
                            </div>
                        </div>
                    </td>
                    <td className="center p1 border-right text-center">
                    </td>
                    <td className="center p1 border-right text-center">
                    </td>
                    <td className="center p1 border-right text-center">
                        <img alt="" src="../assets/img/24px-check-lightgreen.svg"
                             style={{margin: '4px', paddingTop: '2px', height: "24px", width: "24px"}}/>
                    </td>
                </tr>

                <tr className="border-bottom">
                    <td className="left-align p1 px3 border-right border-left rounded-left">
                        <div className="flex flex-center">
                            <div className="line-height-1">
                                <p className="m0"
                                   style={{paddingTop: '10px', paddingLeft: '10px', margin: '0px'}}>Customer reviews and
                                    rating</p>
                            </div>
                        </div>
                    </td>
                    <td className="center p1 border-right text-center">
                    </td>
                    <td className="center p1 border-right text-center">
                    </td>
                    <td className="center p1 border-right text-center">
                        <img alt="" src="../assets/img/24px-check-lightgreen.svg"
                             style={{margin: '4px', paddingTop: '2px', height: "24px", width: "24px"}}/>
                    </td>
                </tr>

                <tr className="border-bottom">
                    <td className="left-align p1 px3 border-right border-left rounded-left">
                        <div className="flex flex-center">
                            <div className="line-height-1">
                                <p className="m0"
                                   style={{paddingTop: '10px', paddingLeft: '10px', margin: '0px'}}>Marketing campaigns
                                    automation</p>
                            </div>
                        </div>
                    </td>
                    <td className="center p1 border-right text-center">
                    </td>
                    <td className="center p1 border-right text-center">
                    </td>
                    <td className="center p1 border-right text-center">
                        <img alt="" src="../assets/img/24px-check-lightgreen.svg"
                             style={{margin: '4px', paddingTop: '2px', height: "24px", width: "24px"}}/>
                    </td>
                </tr>

                <tr className="border-bottom">
                    <td className="left-align p1 px3 border-right border-left rounded-left">
                        <div className="flex flex-center">
                            <div className="line-height-1">
                                <p className="m0"
                                   style={{paddingTop: '10px', paddingLeft: '10px', margin: '0px'}}>Contact database</p>
                            </div>
                        </div>
                    </td>
                    <td className="center p1 border-right text-center">

                    </td>
                    <td className="center p1 border-right text-center">

                    </td>
                    <td className="center p1 border-right text-center">
                        <img alt="" src="../assets/img/24px-check-lightgreen.svg"
                             style={{margin: '4px', paddingTop: '2px', height: "24px", width: "24px"}}/>
                    </td>
                </tr>

                <tr className="border-bottom">
                    <td className="left-align p1 px3 border-right border-left rounded-left">
                        <div className="flex flex-center">
                            <div className="line-height-1">
                                <p className="m0"
                                   style={{paddingTop: '10px', paddingLeft: '10px', margin: '0px'}}>Notification &
                                    messaging</p>
                            </div>
                        </div>
                    </td>
                    <td className="center p1 border-right text-center">

                    </td>
                    <td className="center p1 border-right text-center">

                    </td>
                    <td className="center p1 border-right text-center">
                        <img alt="" src="../assets/img/24px-check-lightgreen.svg"
                             style={{margin: '4px', paddingTop: '2px', height: "24px", width: "24px"}}/>
                    </td>
                </tr>
            </div>
        </div>

    )
}

export default Plans
