import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import HeaderHome from '../../../components/header/authhome'
import FooterHome from "../../../components/footer/footerPage";
import { Styles } from '../../../layouts/style/homeStyle'

const Home = () => {
  const [slider, setSlider] = useState('')
  const sliders = useMemo(() => [
    "../assets/img/veev_id.png",
    "../assets/img/veev_contactid.png",
    "../assets/img/veev_checkin.png",
    "../assets/img/veev_checkout.png",
    "../assets/img/veev_accessid.png",
  ], []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    setSlider(sliders[0])
    let index = 0
    let timer = setInterval(() => {
      index += 1;
      if (index >= sliders.length)
        index = 0
      setSlider(sliders[index])
    }, 2000);
    return () => clearInterval(timer);
  }, [sliders])

  const [loop, setloop] = useState('')
  const loops = useMemo(() => [
  "../assets/img/userimage2.png",
  "../assets/img/userimage3.png",
  "../assets/img/userimage4.png",
  "../assets/img/userimage5.png",
  ], []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    setloop(loops[0])
    let index = 0
    let timer = setInterval(() => {
      index += 1;
      if (index >= loops.length)
        index = 0
      setloop(loops[index])
    }, 3000);
    return () => clearInterval(timer);
  }, [loops])

  return (
    <>
      <HeaderHome />
      <Styles>
      <section className="features" style={{paddingTop: 0, width: '100%'}}>
        <div className="container container--main">
          <div className="row">
            <div className="col-lg-12 my-auto text-center">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-4 my-auto text-center" style={{ alignItems: 'center' }}>
                    
                  </div>
                  <div className="col-lg-4 my-auto text-center" style={{ alignItems: 'center' }}>
                    <div>
                    <img src="../assets/img/userimage1.png" style={{paddingTop: 0, width: '100%'}} className="img-fluid" alt="" /> 
                    </div>
                  </div>
                  <div className="col-lg-4 my-auto text-center" style={{ alignItems: 'center' }}>
                    
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-4 my-auto text-center" style={{ alignItems: 'center' }}>
                    
                  </div>
                  <div className="col-lg-4 my-auto text-center" style={{ alignItems: 'center' }}>
                    <div>
                    <img src={loop} alt="VEEV Checkin" className="img-fluid2" />
                    </div>
                  </div>
                  <div className="col-lg-4 my-auto text-center" style={{ alignItems: 'center' }}>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="guide" id="howitworks" style={{paddingTop: 0, paddingBottom: 0}}>   
      {/* <QR/> */}
      <section className="cta">
        <div className="container container--main">
          <div className="row h-100">
            <div className="col-lg-12 my-auto">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-4 my-auto text-center" style={{ alignItems: 'center' }}>

                    <div>
                    <p className="title">User Scheduling Mobile</p>
                    </div>

                  </div>
                  <div className="col-lg-1 dv-loadmoney" style={{ paddingBottom: '50px' }}>
                  </div>
                  <div className="col-lg-3 dv-loadmoney text-center" style={{ maxHeight: 'auto' }}>
                    <div className="device-mockup iphone6 portrait gold">
                        <div className="device">
                          <div className="screen">
                          <img src="../assets/img/scheduling.png" className="img-fluid2" alt="" />
                          </div>
                        </div>
                    </div>
                  </div>
                  <div className="col-lg-4" style={{ textAlign: 'left', display: 'flex', alignItems: 'center' }}>
                    <div >
                      <div style={{ width: '100%', paddingBottom: '25px', paddingTop: '25px', paddingRight: '20px', color: 'grey' }}>
                      <p className="title4">Connect</p>
                      Connect is a digital business card with community engagement and notification tools to keep your community informed.  
                      </div>
                      <div style={{ width: '100%', paddingBottom: '25px', paddingRight: '20px', color: '' }}> 
                      <p className="title4">Contact Sharing</p>
                      QR based contact sharing tool to share contact information in one click.
                      </div>
                      
                      <div style={{ width: '100%', paddingBottom: '25px', paddingRight: '20px', color: 'grey' }}> 
                      <p className="title4">Notifications</p>
                      Send notifications, call to action, alerts and updates to your community. 
                      </div>
                      <div style={{ width: '100%', paddingBottom: '0px', paddingRight: '20px', color: 'grey' }}> 
                      <a className="badge-link" style={{ display: 'block' }} href="https://apps.apple.com/us/app/veev-mobile-id/id1508649527">
                        <img src="../assets/img/appstore-apple-light.svg" style={{ marginTop: 20, height: 38 }} alt="VEEV" />
                      </a> 
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="cta">
        <div className="container container--main">
          <div className="row h-100">
            <div className="col-lg-12 my-auto">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-4 my-auto text-center" style={{ alignItems: 'center' }}>

                    <div>
                    <p className="title">Checkin Scheduling</p>
                    </div>

                  </div>
                  <div className="col-lg-1 dv-loadmoney" style={{ paddingBottom: '50px' }}>
                  </div>
                  <div className="col-lg-3 dv-loadmoney text-center" style={{ maxHeight: 'auto' }}>
                    <div className="device-mockup iphone6 portrait gold">
                        <div className="device">
                          <div className="screen">
                          <img src="../assets/img/scheduling_checkin.png" className="img-fluid2" alt="" />
                          </div>
                        </div>
                    </div>
                  </div>
                  <div className="col-lg-4" style={{ textAlign: 'left', display: 'flex', alignItems: 'center' }}>
                    <div >
                      <div style={{ width: '100%', paddingBottom: '25px', paddingTop: '25px', paddingRight: '20px', color: 'grey' }}>
                      <p className="title4">Connect</p>
                      Connect is a digital business card with community engagement and notification tools to keep your community informed.  
                      </div>
                      <div style={{ width: '100%', paddingBottom: '25px', paddingRight: '20px', color: '' }}> 
                      <p className="title4">Contact Sharing</p>
                      QR based contact sharing tool to share contact information in one click.
                      </div>
                      
                      <div style={{ width: '100%', paddingBottom: '25px', paddingRight: '20px', color: 'grey' }}> 
                      <p className="title4">Notifications</p>
                      Send notifications, call to action, alerts and updates to your community. 
                      </div>
                      <div style={{ width: '100%', paddingBottom: '0px', paddingRight: '20px', color: 'grey' }}> 
                      <a className="badge-link" style={{ display: 'block' }} href="https://apps.apple.com/us/app/veev-mobile-id/id1508649527">
                        <img src="../assets/img/appstore-apple-light.svg" style={{ marginTop: 20, height: 38 }} alt="VEEV" />
                      </a> 
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 dv-loadmoney text-center" style={{ maxHeight: 'auto' }}>
                <img src="../assets/img/scheduling_checkin.png" className="img-fluid2" alt="" />
            </div>
          </div>
        </div>
      </section>
      </div>
      </Styles>
      <FooterHome />
    </>
  );
};
export default Home;