import React, {useState} from 'react';
import Layout from "../../../layouts/dashboard";
import Tab from "../../../layouts/dashboard/components/checkout-tab";
import {useSetting} from "../../../provider/setting";
import {useNavigate} from "react-router-dom";
import {NotificationManager} from "react-notifications";
import {Button, Col, Form } from "antd";
import { create } from '../../../api/category';

const StoreCategoryCreatePage = () => {
  const navigate = useNavigate();
  const [setting] = useSetting()
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  const onsubmit = () => {
    console.log(setting?.auth?.signin_merchant_code)
    create(setting?.auth?.signin_merchant_code, {name, description})
      .then(()=>{
        NotificationManager.success("Successfully created a category!", "Success", 3000)
        navigate('/store/checkout/categories')
      })
      .catch((e)=>{
        NotificationManager.error("An error was created. try again later!", "Error", 3000)
      })
  }

  return (
    <Layout>
    <Tab/>  
      <div className="row">
        {/* <div className="col col-lg-3">
          <Sidebar/>
        </div> */}
        <div className="col col-lg-12">
          <h4 className="mb-4">New Category</h4>

          <div className="row">
            <div className="col col-lg-12">
              <label htmlFor="name">Category Name*</label>
              <input
                value={name}
                onChange={(e)=>{setName(e.target.value)}}
                name="name"
                id="name"
                className="form-control"
                placeholder="Enter Category Name"
                type="text"/>
            </div>
            <div className="col col-lg-12">
              <label htmlFor="name">Category Description</label>
              <textarea
                value={description}
                onChange={(e)=>{setDescription(e.target.value)}}
                name="description"
                id="description"
                className="form-control"
                rows={4}
                placeholder="Enter Category Description"
                type="text"/>
            </div>
          </div>

          <div>
            <Col span={5}>
            <Form.Item>
              <Button 
                block
                disabled={!name}
                onClick={onsubmit}
                type="primary"
                size="large">
                Create Category
              </Button>
            </Form.Item>
            </Col>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default StoreCategoryCreatePage;
