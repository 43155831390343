import React, {useEffect} from 'react'
import Header from "../../../components/header/authempty";
import Footer from '../../../components/footer/footerPage'

const Download = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Header />
        <div className="container" style={{ height: 'calc(100vh - 65px)' }}>
          <div>
            <div className="col-lg-12 my-auto text-center" style={{ paddingTop: '20px', paddingBottom: '0px'}}>    
                <img className="wallet" style={{textAlign: 'center', display: 'block'}}
                    src="../../assets/img/veevcoin.png"
                    style={{marginBottom: 5, height: 100}} alt="VEEV Cash"/>
                <h2 style={{fontSize: 30, fontWeight: 700}}>Download</h2>
                <h2 style={{fontSize: 30, fontWeight: 700}}>VEEV<sup style={{textDecoration: 'none'}}>&reg;</sup> Cash</h2>
            </div>
            <div className="col-lg-12 my-auto text-center" style={{ paddingTop: '3px', paddingBottom: '0px'}}>
                <br/><br/>Download App<br/>
                <a className="badge-link"
                href="https://apps.apple.com/us/app/veev-earn-rewards/id6478800230">
                <img className="wallet" style={{textAlign: 'center', display: 'block'}}
                src="https://developer.apple.com/news/images/download-on-the-app-store-badge.png"
                style={{marginTop: 5, height: 38}} alt="VEEV Cash"/></a>
            </div>
            {/* <div className="row">
                <div className="col-lg-12 text-left" style={{width: '100%', paddingTop: '0px', paddingLeft: '0px' }}>
                    <p className="title text-center" style={{paddingBottom: "5px", paddingTop: "20px"}}>Cash Back</p>
                    <div style={{ width: '100%', paddingBottom: '0px', paddingTop: '10px', paddingRight: '20px', color: 'grey' }}>
                    <img className="wallet" style={{textAlign: 'center', display: 'block'}}
                    src="../../assets/img/veevcoin_newlogo.png"
                    style={{marginBottom: 5, height: 150}} alt="VEEV Coin"/>
                    <p style={{ width: '100%', color: 'grey' }}>To get cash back a minimum of $25 is required in VEEV Cash. Cash back is subject to Terms & Conditions.</p>
                    </div>
                </div>
            </div>  */}
        </div>
      </div>
      <Footer />
    </>  
  )
}
export default Download