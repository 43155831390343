import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {Layout} from "antd";
import { makeServer } from './server_appointments';
import { makeServerReservation } from './server_reservation';

if (process.env.NODE_ENV === "development") {
    makeServer({ environment: "development" })
}

if (process.env.NODE_ENV === "development") {
  makeServerReservation({ environment: "development" })
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Layout style={{backgroundColor: "transparent"}}>
    <App />
  </Layout>
);

reportWebVitals();
