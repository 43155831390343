import React, {Fragment, useEffect, useState} from 'react';
import Layout from "../../../layouts/dashboard";
import Tab from "../../../layouts/dashboard/components/checkin-tab";
import {Avatar, Form, Select, Typography, Space} from "antd";
import {Icon} from "@iconify/react";
import {useSetting} from "../../../provider/setting";
import {get as getMerchantApi} from "../../../api/merchant";
import {getProvidersApi} from '../../../api/api';
import CalendarSchedule from '../../../components/CalendarSchedule';
import { getCookie } from '../../../service/cookie';

const { Item } = Form;
const { Option } = Select;


/**
 * @typedef {Object} TimeSlot
 * @property {string} start_time - The start time
 * @property {string} end_time - The end time
 */

/**
 * @typedef {ContactInfo} TimeSlot
 * @property {string} email - The email address
 * @property {string} phone - The phone number
 */

/** Class representing a reservation */
class Reservation {
  /**
   * Create a point.
   * @param {string} name - The name of person making the reservation.
   * @param {number} size - The size of the reservation.
   * @param {TimeSlot} timeSlot - The time when reservation starts and ends.
   * @param {Date} date - The date of the reservation.
   * @param {ContactInfo} contact - The contact info.
   * @param {string} service_id - The service this reservation is for.
   */
  constructor(name, size, timeSlot, date, contact, service_id) {
    this.name = name;
    this.size = size;
    this.timeSlot = timeSlot;
    this.date = date;
    this.contact = contact;
    this.service_id = service_id;
  }

  /**
   * Get reservation name.
   * @return {string} The name of reservation.
   */
  getName() {
    return this.name;
  }

/**
   * Get reservation size.
   * @return {number} The size of reservation.
   */
  getSize() {
    return this.size || 1;
  }

/**
   * Get reservation time slot.
   * @return {TimeSlot} The time slot of reservation.
   */
  getTimeSlotAsDate() {
    const startdate = new Date(this.date);
    const enddate = new Date(this.date);
    let startHour = parseInt(this.timeSlot.start_time.split(":")[0])
    startHour = this.timeSlot.start_time.split(" ")[1].toLowerCase() === "pm" && startHour < 12
      ? startHour + 12
      : startHour
    let endHour = parseInt(this.timeSlot.end_time.split(":")[0])
    endHour = this.timeSlot.end_time.split(" ")[1].toLowerCase() === "pm" && endHour < 12
      ? endHour + 12
      : endHour

    startdate.setHours(startHour);
    enddate.setHours(endHour);
    startdate.setMinutes(parseInt(this.timeSlot.start_time.split(":")[1]))
    enddate.setMinutes(parseInt(this.timeSlot.end_time.split(":")[1]))
    return { start: startdate, end: enddate };
  }

/**
   * Get reservation size.
   * @param {string} service_id - The service_id for reservation.
   * @return {number} The size of reservation.
   */
  setService(service_id) {
    const email = getCookie("current_user_email");
    const token = getCookie("current_ident_token");

    fetch(process.env.REACT_APP_API_URL + `/reservations`, {
      method: "PUT",
      headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
          "X-User-Email": email,
          "X-User-Token": token
      },
      body: JSON.stringify({ service_id: service_id })
    })

    this.service_id = service_id;
  }

}

const SchedulingPage = () => {
  const [setting] = useSetting()
  const [merchant, setMerchant] = useState({});
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState(false);
  const [bookings, setBookings] = useState([]);
  const [providers, setProviders] = useState([]);

  useEffect(() => {
    setLoading(true);


    const email = getCookie("current_user_email");
    const token = getCookie("current_ident_token");
    const merchant_id = merchant.id

    fetch(process.env.REACT_APP_API_URL + `/reservations?merchant_id=${merchant_id}`, {
      headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
          "X-User-Email": email,
          "X-User-Token": token
      }
    })
    .then(res => res.json())
    .catch(error => console.log(error))
    .then(json => {
      if (json.data !== undefined && json.data !== null) {
        const reservation_dates_arr = json.data;
        let bookingsArr = [];
        const services = reservation_dates_arr.map((obj) => {
          const service = obj.service;
          const reservations = obj.reservations || {};

          const all_reservations = reservations.map((reservation) => {
            const date = reservation.date;
            const slots = reservation.reserved_slots;
            let _reservations = slots.map((reserved_slot) => {
              return new Reservation(
                reserved_slot.name,
                reserved_slot.size,
                { start_time: reserved_slot.time_slot[0], end_time: reserved_slot.time_slot[1] },
                date,
                { email: reserved_slot.email, phone: reserved_slot.phone },
                service.id
              )
            });
            return _reservations
          });

          bookingsArr.push(...all_reservations.flat());

          return service
        });

        setBookings(bookingsArr)
        setProviders(services);
      }
    })
    .finally(() => setLoading(true))
  }, [merchant])

  useEffect(() => {
    if (setting?.auth?.signin_merchant_id) {
        getMerchantApi(setting?.auth?.signin_merchant_id)
          .then((data) => {
            setMerchant(data)
          })
          .catch((e) => {
            console.warn(e)
          })
    }
  }, [setting?.auth])

  return (
    <Layout>
      <Tab />
      <div className="row">
        <div className="col col-lg-12">
          <CalendarSchedule
            columns={providers.map((provider) => ({
              name: provider.name,
              id: provider.id,
            }))}
            events={bookings.map((booking, idx) => (
              { 
                id: idx,
                resourceId: booking.service_id,
                title: `${booking.getName()} - Size: ${booking.getSize()}`,
                ...booking.getTimeSlotAsDate() 
              }
            ))}
          />

          {/* <img style={{paddingBottom: 50,}} src="../../assets/img/bookings0.png" width="100%"></img> */}

          {/* <img src="../../assets/img/bookings.png" width="100%"></img> */}
        </div>
      </div>
    </Layout>
  );
};

export default SchedulingPage;
