import React, { Fragment, useState, useEffect } from 'react'
import Header from "../../components/header/authempty";
import Footer from "../../components/footer/footerEmptyPage";
import Button from '@material-ui/core/Button';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { Checkmark } from 'react-checkmark';
import Divider from "@material-ui/core/Divider";

const OrderConfirmation = () =>  {
    const params = new URLSearchParams(window.location.search);
    const [email, setEmail] = useState();
    const [phone, setPhone] = useState();
    const [status, setStatus] = useState('');
    const [isRegistered, setIsRegistered] = useState(false);

    useEffect(() => {
        console.info("status changed =>", status);
    }, [status])

    useEffect(() => {
        const getDetails = async () => {
            const res = await fetch(process.env.REACT_APP_API_URL+`/v1/checkout/confirmation?session_id=${params.get('session_id')}&order_num=${window.location.pathname.split("/")[2]}`, {
              method: "GET",
              headers: 
              {
                "Accept": "application/json",
                "Content-Type": "application/json",
              }
            });
            
            if (!res.ok) {
                console.error('invalid');
            }

            const json = await res.json();
            
            setEmail(json.email);
            setStatus(json.status);
        }
        
        getDetails();
    }, []);

    async function submitPhone() {
            const res = await fetch(process.env.REACT_APP_API_URL+`/v1/checkout/status`, {
              method: "POST",
              headers: 
              {
                "Accept": "application/json",
                "Content-Type": "application/json",
              },
              body: JSON.stringify({data: {phone: phone, order_number: window.location.pathname.split("/")[2]}})
            });

            if (!res.ok) {
                console.error('invalid');
                return;
            }
            setIsRegistered(true)      
    }

    return (

    <Fragment>
        <Header />
            <div className="container" id="confirmation" style={{height: "75vh"}}>
                <div>
                    <div className='text-center' style={{marginTop: "30px"}}>
                    <h3>Thank you!</h3>
                    <br></br>
                    <h4>
                    Order Confirmation #<br/>{window.location.pathname.split("/")[2]}
                    </h4>
                    <br></br>
                    { email !== undefined && email !== ''
                        ? <h5>Your receipt has been emailed to:<br></br>{ email }</h5>
                        : <></> }
                    </div>
                </div>
                <br />

                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%'}}>
                {status.toUpperCase() === "READY" ?
                    <div>
                        <h4 style={{textAlign: 'center'}}>Order Is Ready</h4>
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                        <Checkmark size='xLarge' />
                        </div>
                    </div>
                    :
                    <>
                    <p>_____________</p><br/>
                    <p style={{textAlign: 'center'}}> Get Notified When Order Is Ready </p>
                    {/* <div style={{display: 'flex', alignContent: 'center'}}>
                    <PhoneInput
                      style={{}}
                      country={'us'}
                      value={phone}
                      onChange={setPhone}
                      disabled={isRegistered}
                    />
                    <Button variant="contained" size="small" color="success" onClick={submitPhone} disabled={isRegistered} >
                        {isRegistered ? "Submitted" : "Submit"}
                    </Button>
                    </div> */}
                    <div style={{textAlign: 'center'}}>
                        Download App<br></br>
                        <a className="badge-link"
                        href="https://apps.apple.com/app/veev-app/id6444875499">
                        <img className="wallet" style={{textAlign: 'center', display: 'block'}}
                        src="https://developer.apple.com/news/images/download-on-the-app-store-badge.png"
                        style={{marginTop: 5, height: 38}} alt="VEEV App"/></a>
                    </div>
                    <p style={{textAlign: 'center', marginTop: 30, marginBottom: 5}}>Signup Now Earn<br />100 VEEV Coin</p>
                    <img className="wallet" style={{textAlign: 'center', display: 'block'}}
                        src="/assets/img/veevcoin.png"
                        style={{marginTop: 0, marginBottom: 7, height: 38}} alt="VEEV Coin"/>
                    <div style={{textAlign: 'center'}}>
                        Limited Time Offer.
                       <br />
                       See <a href="/terms" target='_blank'>Terms & Conditions</a>.
                    </div> 
                    </>
                    }
                </div> 
            </div>
        <Footer />
    </Fragment>
    )
}

export default OrderConfirmation;
