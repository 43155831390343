import React, {useEffect, useState} from 'react';
import Layout from "../../../layouts/dashboard";
import Tab from "../../../layouts/dashboard/components/settings-tab";
import Sidebar from "./sidebar";
import {getMerchantApi, updateMerchantApi} from "../../../api/api";
import {useSetting} from "../../../provider/setting";
import {Col, Row} from "antd";
import styled from "styled-components";
// import iPhoneImage from "../../assets/img/floorplan.png"
import { NotificationManager } from 'react-notifications';

const StoreProfilePage = () => {
  const [setting] = useSetting()
  const [merchant, setMerchant] = useState({});

  useEffect(() => {
    console.log(setting)

    getMerchantApi(setting?.auth?.signin_merchant_id)
      .then(({data}) => {
        setMerchant(data)
      })
      .catch((e) => {
        console.warn(e)
      })
  }, [setting?.auth])

  console.log(merchant)

  return (
    <Layout>
    <Tab/>  
      <div className="row">
        <div className="col col-lg-3">
          <Sidebar/>
        </div>
        <div className="col col-lg-9">
          <Row
            justify="center"
            gutter={[32, 32]}
            align="middle">
            <Col>
              <MobileView>
                <div className="content">
                  <img
                    width="100%"
                    src={merchant.logo}
                       alt=""/>
                </div>
                {/* <img className="mobile-bg" src={iPhoneImage} height="1444" width="726" alt=""/> */}
                <img className="mobile-bg" height="1444" width="726" alt=""/>
              </MobileView>
            </Col>
          </Row>
        </div>
      </div>
    </Layout>
  );
};

const MobileView = styled.div`
  width: 300px;
  height: 597px;
  position: relative;
  .mobile-bg {
    position: absolute;
    z-index: 4;
    width: 300px;
    height: auto;
  }

  .content {
    position: absolute;
    padding: 10px 20px;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    overflow: hidden;
    background-color: #f0f0f0;
    z-index: 3;
    
    img {
      height: 70%;
      object-fit: cover;
      margin-top: 64px;
    }
  }
`

export default StoreProfilePage;