import React, {useEffect, useState} from 'react';
import {NotificationManager} from "react-notifications";
import {Button, Col, Form} from "antd";
import Layout from "../../../layouts/dashboard";
import Tab from "../../../layouts/dashboard/components/checkout-tab";
import {useNavigate, useSearchParams} from "react-router-dom";
import {useSetting} from "../../../provider/setting";
import { update } from '../../../api/category';
import { getAll } from '../../../api/category';

const StoreCategoryEditPage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [setting] = useSetting()
  const [name, setName] = useState("");
  const [id, setId] = useState("");
  const [description, setDescription] = useState("");

  const onsubmit = () => {
    console.log(setting?.auth?.signin_merchant_code)
    update(setting?.auth?.signin_merchant_code, id, {name, description})
      .then(()=>{
        NotificationManager.success("Successfully created a category!", "Success", 3000)
        navigate('/store/checkout/categories')
      })
      .catch((e)=>{
        NotificationManager.error("An error was created. try again later!", "Error", 3000)
      })
  }

  useEffect(()=>{
    console.log()
    const i = searchParams.get("id");
    setId(i);
    if(!!i && setting?.auth?.signin_merchant_code) {
        getAll(setting?.auth?.signin_merchant_code)
        .then((data) => {
          data.forEach((item) => {
            if (item.id.toString() === i) {
              setName(item.name);
              setDescription(item.description)
            }
          })
        })
        .catch((e)=>console.warn(e))
    }
  }, [setting])

  return (
    <Layout>
    <Tab/>  
      <div className="row">
        {/* <div className="col col-lg-3">
          <Sidebar/>
        </div> */}
        <div className="col col-lg-12">
          <h4 className="mb-4">Edit Category</h4>

          <div className="row">
            <div className="col col-lg-12">
              <label htmlFor="name">Category Name*</label>
              <input
                value={name}
                onChange={(e)=>{setName(e.target.value)}}
                name="name"
                id="name"
                className="form-control"
                placeholder="Enter Category Name"
                type="text"/>
            </div>
            <div className="col col-lg-12">
              <label htmlFor="name">Category Description</label>
              <textarea
                value={description}
                onChange={(e)=>{setDescription(e.target.value)}}
                name="description"
                id="description"
                className="form-control"
                rows={4}
                placeholder="Enter Category Description"
                type="text"/>
            </div>
          </div>

          <div>
          <Col span={5}>
            <Form.Item>
              <Button 
                block
                disabled={!name}
                onClick={onsubmit}
                type="primary"
                size="large">
                Update
              </Button>
            </Form.Item>
            </Col>
          </div>
          {/* <div className="mt-5">
            <SubmitButton
              disabled={!name}
              onClick={onsubmit}
              type="primary"
              size="large">
              Create Category
            </SubmitButton>
          </div> */}
        </div>
      </div>
    </Layout>
  );
};

export default StoreCategoryEditPage;
