import React from 'react';
import {Link, useLocation} from "react-router-dom";

const CheckinSidebar = () => {
  const location = useLocation()

  return (
    <div className="side-nav-wrap">
      <div className="side-nav">
        <h5>Pages</h5>
        <nav>
          <li>
            <Link
              className={location.pathname.includes("create")? "" : "active"}
              to="/bank/store/checkin">
              Active
            </Link>
          </li>
          <li>
            <Link
              className={location.pathname.includes("create")? "active" : ""}
              to="/bank/store/checkin/create">
              Create</Link>
          </li>
        </nav>
      </div>
    </div>
  );
};

export default CheckinSidebar;