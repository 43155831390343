
import React, {useState, useEffect, useRef} from "react";

const CheckoutForm = ({ deadline }) =>  {
  const Ref = useRef(null);
  const [timer, setTimer] = useState();

  const updateTime = (timeAmount) => {
    const secs = Math.floor((timeAmount/1000) % 60);
    const mins = Math.floor((timeAmount/1000/60) % 60);
    const hours = Math.floor((timeAmount/(1000*60*60)) % 24);

    console.log("secs", secs)
    console.log("mins", mins)

    setTimer(
                (mins > 9 ? mins : "0" + mins) +
                ":" +
                (secs > 9 ? secs : "0" + secs)
    );
  }

  const updateClock = (time) => {
    console.log("end", Date.parse(time), time)
    console.log("now", Date.parse(new Date()))

    const timeAmount = Date.parse(time) - Date.parse(new Date());
    updateTime(timeAmount);
  }

  useEffect(() => {
    setTimer();
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      updateClock(deadline);
    }, 1000);
    Ref.current = id;
  }, [])

  return (
      <span>{timer}</span>
  )
}

export default CheckoutForm;
