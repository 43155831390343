import React, {useEffect, useMemo, useState} from "react";
import {Input, Modal} from 'antd';
import {useNavigate} from "react-router-dom";
import ActionCable from "actioncable";
import {NotificationManager} from "react-notifications";
import HeaderHome from "../../../components/header/authhome";
import FooterHome from "../../../components/footer/footerPage";
import {Styles} from '../../../layouts/style/homeStyle'
import {setCookie} from "../../../service/cookie";
import LoginPopup from "../../../components/auth/login-popup";
import SignupPopup from "../../../components/auth/signup-popup";
import {loginWithEmail} from "../../../api/auth";
import siteConfig from "../../../config/site.config";
import {useSetting} from "../../../provider/setting";

const Coin = () => {
    const [, dispatch] = useSetting()
    const navigate = useNavigate();
    const [screen, setScreen] = useState("login");
    const [open, setOpen] = useState(false);

    const {Search} = Input;

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const onSuccess = async ({ email, password }) => {
        try {
            const res = await loginWithEmail(email, password)
            console.log("logging in ...", res);
            setCookie("veev_current_user_username", res?.handle, siteConfig.expired);
            setCookie("current_ident_token", res.ident_token, siteConfig.expired);
            setCookie("current_user_email", res.email, siteConfig.expired);
            navigate(`/user/${res.handle}?new=true`);
        } catch (e) {
            console.warn(e);
        }
    };

    useEffect(() => {
        const cable = ActionCable.createConsumer(siteConfig.webSocketUrl);
        const _authChanel = cable.subscriptions.create(
            {
                channel: 'SessionChannel'
            },
            {
                connected: () => {
                    console.log('Connected authentication_request')
                    _authChanel.send({
                        type: "authentication_request",
                        session_id: true,
                    });
                },
                received: data => {
                    const session_id = data.session_id
                    setCookie("signin_session_id", session_id, siteConfig.expired);;
                    cable.subscriptions.create(
                        {
                            channel: "MerchantChannel",
                            session_id,
                        },
                        {
                            received: (data) => {
                                // `data` will contain a `merchant_select_response` message.
                                console.log("Received merchant data." + JSON.stringify(data));
                                /* Do whatever with merchant data here. : save sessionid and merchant code., user_id on the session */
                                if (data.type === "merchant_select_response") {
                                    setCookie("signin_session_id", session_id, siteConfig.expired);
                                    setCookie("signin_merchant_code", data.merchant_information.merchant_code, siteConfig.expired);
                                    setCookie("signin_merchant_id", data.selected_merchant_id, siteConfig.expired);
                                    setCookie("signin_merchant_user_id", data.merchant_user_id, siteConfig.expired);
                                    setCookie("signin_current_user_id", data.current_user_id, siteConfig.expired);
                                    setCookie("signin_merchant_name", data.merchant_information.legal_name, siteConfig.expired);
                                    setCookie("current_ident_token", data.current_user.ident_token, siteConfig.expired);
                                    setCookie("current_user_email", data.current_user.email, siteConfig.expired);
                                    const selected_merchant_address =
                                        data.merchant_information.street +
                                        " " +
                                        data.merchant_information.city +
                                        " " +
                                        data.merchant_information.state +
                                        " " +
                                        data.merchant_information.zip;
                                    setCookie("selected_merchant_address", selected_merchant_address, siteConfig.expired);
                                    setCookie("current_merchant_wallet_address", data.merchant_information.address, siteConfig.expired);
                                    setCookie("selected_merchant_bool_cannabis_merchant", data.merchant_information.cannabis_merchant === 'true', siteConfig.expired);
                                    const auth = {
                                        signin_session_id: session_id,
                                        signin_merchant_code: data.merchant_information.merchant_code,
                                        signin_merchant_id: data.selected_merchant_id,
                                        //signin_merchant_user_id : data.merchant_information.wallet.user_id,
                                        signin_merchant_user_id: data.merchant_wallet_user_id,
                                        signin_current_user_id: data.current_user_id,
                                        signin_merchant_name: data.merchant_information.legal_name,
                                        current_ident_token: data.current_user.ident_token,
                                        current_user_email: data.current_user.email,
                                        selected_merchant_address: selected_merchant_address,
                                        current_merchant_wallet_address: data.merchant_information.wallet_address,
                                        selected_merchant_bool_cannabis_merchant: data.merchant_information.cannabis_merchant === 'true',
                                    };
                                    dispatch({type: 'SET', settingName: 'auth', settingData: auth})
                                    NotificationManager.success('VEEV ID Authenticated', 'Success', 3000);
                                }
                            },
                        }
                    );
                    cable.subscriptions.create(
                        {
                            channel: "SessionChannel",
                            session_id,
                        },
                        {
                            received: (data) => {
                                // `data` will contain a `merchant_select_response` message.
                                console.log("Received Session Channel." + JSON.stringify(data));
                                /* Do whatever with merchant data here. : save sessionid and merchant code., user_id on the session */

                                if (data.type === "authentication_linking") {
                                    // get the current user info.
                                    const current_user = data.current_user;
                                    console.log("current user info" + current_user);

                                    //set the current user info on the local storage: session_id, user_id,
                                    if (session_id !== undefined) {
                                        setCookie("veev_current_user_username", current_user.handle, siteConfig.expired);
                                        setCookie("veev_session_id", session_id, siteConfig.expired);
                                        setCookie("veev_current_user_id", current_user.id, siteConfig.expired);
                                        setCookie("veev_current_user_email", current_user.email, siteConfig.expired);
                                        setCookie("veev_current_user_ident_token", current_user.ident_token, siteConfig.expired);
                                        const auth = {
                                            signin_session_id: session_id,
                                            signin_current_user_id: current_user.id,
                                            current_user_email: current_user.email,
                                            current_ident_token: current_user.ident_token,
                                        }
                                        dispatch({type: 'SET', settingName: 'auth', settingData: auth})
                                    }
                                }

                                if (data.type === "merchant_create_init") {
                                    /* go to the sign up page. */
                                    console.log("create new merchant button: " + JSON.stringify(data));
                                    if (session_id !== undefined) {
                                    }
                                }
                            },
                        }
                    );
                }
            }
        )
    }, [])

    return (
        <>
            <HeaderHome toggleModal={() => setOpen(!open)}/>
            <Styles>
              <div className="container" id="coin">
                <div>
                  <div>
                    <div className="col-lg-12 my-auto text-center" style={{ paddingTop: '20px', paddingBottom: '0px'}}>    
                    <img className="wallet" style={{textAlign: 'center', display: 'block'}}
                        src="../../assets/img/veevcoin_newlogo.png"
                        style={{marginBottom: 5, height: 150}} alt="VEEV Coin"/>
                        <h3><b>VEEV Coin</b></h3>
                    </div>
                    <h3>VEEV Coin</h3>
                    VEEV Coin is a network currency. It is the native currency of the VEEV Network including VEEV apps, wallets, websites, PoS, applications and services.
                    <br /><br />
                    Collectively referred to as 'VEEV', including VEEV Coin, related services accessed through our websites (located at www.veev.app), VEEV's mobile application or in connection with social networking, recommendation, booking and payment services (referred to below as "VEEV").
                    <br /><br />
                    The currency has no par value. It cannot be bought or sold. It is earned as a reward or like points. Given the constant evolution of crypto this information is subject to change frequently.
                    <br /><br />
                    <h3>Components</h3>
                    ​VEEV is a sponsor of the crypto currency, VEEV Coin. VEEV’s mission is to make its currency the reserve currency on its network.
                    VEEV is a vertically integrated issuer, processor and distributor for it's currency composed of:<br/>
                    <b>VEEV Coin</b> is issued on the blockchain, as a crypto currency. VEEV Coin vision is to provide a mechanism for direct exchange of value between parties.
                    <br/>
                    <b>VEEV Net</b> is the transport layer of VEEV Coin built on L1 and L2 networks. On top of it are layers to manages issuance, processing and distribution of VEEV Coin. It connects to VEEV Apps to facilitate transactions, transaction execution between parties and records VEEV Coin transactions on a secure decentralized global blockchain ledger.
                    <br/>
                    <b>VEEV Apps</b> are edge applications that serve as On Off ramps to the VEEV Network to facilitate last mile transactions last. VEEV Apps abstract the complexity of the blockchain by providing a seamless user experience without the need for a steep learning curve, yet retaining the security, transparency and usability of crypto currencies.
                    <br /><br />
                    <h3>​What Is Money</h3>
                    Money is a medium of exchange, a store of value and a unit of account.
                    <br /><br />
                    <h3>What It Does</h3>
                    Since the beginning of time, many things have been used as currencies to barter, exchange and as a store of value. Presently the most commonly used currency is fiat money issued by central governments around the world. Prior to that it was gold and silver. Prior to that it was cattle and agricultural products. Prior to that, spices and precious stones. Each iteration of money standardized it, made it more scalable, transportable and more ubiquitous. And with each currency iteration money became more centralized. Government issue fiat money is totally centralized.
                    <br /><br />
                    <h3>The Value Of Money</h3>
                    Money is a medium of exchange. It is a tool. Money derives its value from the parties involve in the exchange. This can vary every single time an exchange is done even if the underlying currencies stayed the same. Enter fiat currencies issued by central governments, have the value of the currency they issue so that parties doing an exchange with their currency have the same unit of value. Hence the value of fiat money is artificially set by issuing central government and they devise all kinds of economic metrics to justify the value of their money. Hence, the value of money is just an illusion. It used to be determine by parties doing the exchange. With fiat currencies the value is set by central banks.
                    Fiat currencies serve the same purpose as previous currencies such as precious stones, cattle or gold with one major difference. They can be created at will arbitrarily from nothing. Central governments of the world are now creating fiat currencies out of thin air in increasingly massive quantities and flooding their banking system. When fiat money is created out of thin air, the value of existing money in circulation is worth less because there is more of it in circulation. Hence, money loses its purchasing power. This phenomenon is called inflation.
                    <br /><br />
                    <h3>Inflation</h3>
                    The central banks combat inflation by reducing the money in circulation to deflate inflation. An extreme case of this is occurred during the Obama administration, when the U.S. Federal Reserve printed trillions to support its domestic and foreign policies. To combat run away inflation the Federal Reserve under the Obama administration started to remove components of the CPI (consumer price index) that were rising fast to give the impression that inflation was under control to non existent and that the reduction in purchasing power of the U.S. Dollar was just an illusion when in reality the dollar was buying less. Now you know that money is created out of thin air at will and its value is made up and supported by any story that sticks.
                    <br /><br />
                    <h3>Dollarization</h3>
                    When central governments can no longer control the money it created out of thin air you have runaway inflation. In many countries such as Seychelles, Venezuela and Zimbabwe local populations took it upon themselves to use U.S. Dollar as their currency. Essentially, they outsourced their currency to the U.S. This is called dollarization. When dollarization happens these countries and the populations are somewhat under control and highly influenced by the actions of the U.S. and its banks.
                    <div className="col-lg-12 my-auto text-center" style={{ paddingTop: '20px', paddingBottom: '0px'}}>    
                        <img className="wallet" style={{textAlign: 'center', display: 'block'}}
                            src="../../assets/img/purchasingpower.png"
                            style={{marginBottom: 5, width: '100%'}} alt="VEEV Coin"/>
                            US Dollar has lost 99.95% of its purchasing power.
                    </div>
                    <br />
                    <h3>De-Dollarization - Decoupling From The Dollar</h3>
                    As nations achieve economic prosperity they tend to want to decouple from the U.S. dollar so they are less at the whips of U.S. policy. It's no longer just nations that want to decouple from the dollar, increasingly businesses and populations are seeking ways to decouple from central government fiat money. Until the emergency of crypto currencies this was not possible. 
                    US sanctions imposed on Russia backfired badly after Russian President was able to successfully de-dollarize the Russian economy from the dollar. However, that maybe harder for most countries to pull off so they need an alternate global asset.
                    <div className="col-lg-12 my-auto text-center" style={{ paddingTop: '20px', paddingBottom: '0px'}}>    
                        <img className="wallet" style={{textAlign: 'center', display: 'block'}}
                            src="https://wp.inews.co.uk/wp-content/uploads/2023/03/SEI_147410980.jpg?crop=0px%2C137px%2C2500px%2C1411px&resize=640%2C360"
                            style={{marginBottom: 5, width: '100%'}} alt="VEEV Coin"/>
                            Russian President Vladimir Putin - The King of De-Dollarization
                    </div>
                    <br />
                    <h3>US Dollar - A Ponzi Scheme Backed By US Military</h3>
                    The US dollar is a fiat currency. It's worthless. So where does it dervive its value? It derives its value by making it the reserve curreny and that its achieved by destabilizing commodities like oil. Then the US issues printed money against imports and gets the exporting country to hold the currency in US banks instead of repatriating it to their countries. This is why many resource rich countries are poor. The US Debt is $34 Trillion as of April 1, 2024 and it is increasing $1 Trillion every 100 days. The US can no longer invade countries under the pretense of human rights, fighting terrorism or democracy to expropriate their assets to back the US debt. China, Russia, India and BRICs are making this ponzi scheme difficult to continue at the rate it has been.
                    <div className="col-lg-12 my-auto text-center" style={{ paddingTop: '20px', paddingBottom: '10px'}}>    
                        <img className="wallet" style={{textAlign: 'center', display: 'block'}}
                            src="../../assets/img/americannightmare.png"
                            style={{marginBottom: 5, width: '100%'}} alt="VEEV Coin"/>
                            The US Dollar is a ponzi scheme backed by the US military
                    </div>
                    <br />
                    <h3>Bitcoin</h3>
                    Bitcoin is a decentralized crypto currency that runs on the blockchain. It is not owned, issued or controlled by any government. It was introduced during the financial crisis of 2008, by Satoshi Nakamoto. But Bitcoin is not a new idea. The Cypherpunks were working on cryptocurrencies all the way back in the 80s. It took thirty years for the idea to stick because the world was not ready for them. People didn’t know they needed independent money yet. They still trusted the System.
                    <br /><br />
                    <h3>Bitcoin Will Change Money</h3>
                    Bitcoin is not just non government issued money. Bitcoin is a disruptive technology. Disruptive technologies change everything. There will be no going back. Agriculture was the disruptive technology 15,000 years ago. Agriculture made us farmers. We didn’t go back to being hunter and gatherers. Capital markets were the disruptive technology 500 years ago. Markets made us an industrial society. We didn’t go back to feudalism. The mechanized transportation was the disruptive technology 100 years ago. It made getting out the planet quick and made the world a smaller place. We didn’t go back to horse and buggies. The internet was the disruptive technology 20 years ago. It connected the world and made it even small. We are not going back to paper records and post offices. Bitcoin is the disruptive technology today. Bitcoin makes all of us custodians of our own money. Banks will no longer be needed to issue money, hold money or move money.
                    <br /><br />
                    <h3>Issuance & Allocation</h3> 
                    100 billion VEEV Coin will be minted and issued to wallets. VEEV Coin will be allocated as follows:<br/>
                    Community 50%<br/>
                    Strategic Partners 20%<br/>
                    Business Partners 10%<br/>
                    Founding Team 20%<br/>
                    <div className="col-lg-12 my-auto text-center" style={{ paddingTop: '0px', paddingBottom: '0px'}}>
                        <h4>Business Partner Issuance Schedule</h4>     
                        <img className="wallet" style={{textAlign: 'center', display: 'block'}}
                            src="../assets/img/allocation.png"
                            style={{marginBottom: 5, width: '50%'}} alt="VEEV Coin"/>
                            <br/>
                            Sample allocation chart - subject to change
                    </div>
                    <br />
                    <h3>Circulation</h3>
                    VEEV Coin will have a circulation of 100 billion when fully vested. It is possible that Bitcoin could have market cap of 21 Septillion one day, which is the same as a 21 Trillion Trillion or 21,000,000,000,000,000,000,000,000. The Septillion market cap is arrived dividing the total number of Bitcoin fully mined by its lowest unit of value or BTC 21,000,000 / BTC 0.000000000000000001. Likewise, VEEV Coin could be demoninated to 18 decimal places based on the issuing L1 or L2 network.   
                    <br /><br />
                    <h3>Opportunity</h3>
                    ​In 2016, the value of global payments transactions stood at $420 Trillion, or 5.5 times global GDP. Global transaction volumes are expected to grow 80% between 2015-2020 to reach 726 billion. Mobile proximity payments volume alone (such as QR codes and Near Field Communication (NFC)) is expected to be over US$4 trillion by 2020. The rise of e-commerce has made payments one of the fastest-growing sectors worldwide and has created a demand for instant digital payments and further straining the existing payment system. Internet retailing, online shopping, in-store, cross border and person to person payments are more global in nature than ever before. Online marketplaces like Amazon, eBay, Flipkart, Instacart, GrubHub, online shopping carts like Magento, Shopify, BigCommerce and point of sale systems like Square, Micros and others are ill-suited instant payments. 
                    Focusing on retail and cross boarder transactions result in a target payment volume market of approximately $200 Trillion. Conservatively, we believe the VEEV network can capture 1% of the market within 5 years. The 1% transaction fee received by VEEV results in approximately $20 Billion in annual revenue.
                    The market value of cryptocurrencies is $300 billion (June 2018) made up of over 1300 currencies. It is estimated by 2020, cryptocurrency assets will exceed $20 Trillion and 10% of the world GDP by 2025. Use of cryptocurrencies as an everyday payment method is growing rapidly. The number of unique crypto wallets in January 2018 stood at 30 million and it is expect to be 2 billion by 2020. Coinbase, the largest cryptocurrency exchange, has 13 million customers and 35 million wallets.
                    <br /><br />
                    <h3>Checkin Checkout Service</h3>
                    The VEEV Pay Terminal is a fully functional Point of Sale (POS) payment terminal that runs on the iPad available for free on the Apple App Store. It processes in-store and preordered payments on the VEEV network.<br/><br />
                    In-Store Payments<br/>
                    When a customer places an order in-store (at a store which participates in the VEEV network), VEEV Pay terminal generates a QR code for the order. The customer scans the QR code with VEEV Wallet and approves the payment. Upon approving the payment, VEEV Coin is transferred from the user’s VEEV Wallet to the merchant’s VEEV Pay terminal. Both parties get notification of the payment and a detailed receipt of the items purchased. The pay terminal also supports customers using their Credit/Debit card linked to the VEEV Wallet.<br/><br /> 
                    In-App and Online Payments<br/>
                    VEEV Pay terminal supports in-app and online payment. Orders placed with VEEV Wallet In-App or VEEV Pay Online are transmitted to the merchant’s VEEV Pay Terminal. Pre-placed orders can be sorted into various categories like pick-up, delivery, and ship. The merchant can see the details of all the remote orders and fill them as appropriate. For pick-up orders, the merchant can have everything ready when the customer arrives at the store location.<br/><br />
                    Dynamic Menu Listing<br/>
                    Menu items can be added quickly with images, description and pricing and can be made available on the Terminal or Wallet’s In-App Store. The terminal also provides a full inventory management system that tracks inventory as it moves through the store. Alerts can be generated to remind the merchant when items need to be reordered. Reporting can provide insights as to which items are most popular and most profitable.<br /><br />
                    Digital Receipting and Payment History<br/>
                    A digital receipt of every transaction is stored in the Pay Terminal eliminating the need for paper receipts. Users can quickly and easily at any time view their receipts. Every receipt has transaction details of the items purchased, cost, total payment, date, time and merchant information.
                    <br /><br />
                    <div className="col-lg-12 my-auto text-center" style={{ paddingTop: '10px', paddingBottom: '20px'}}>    
                        <img src="../assets/img/veev_checkin-out.png" width={'100%'}></img>
                    </div>  
                    <h3>Network Effects</h3>
                    VEEV’s two-sided marketing strategy is Metcalfe’s law on steroids. Metcalfe's law states that the value of a network is proportional to the square of the number of connected users of the system (n2). Every merchant partnership potentially brings the VEEV Network hundreds of users and every merchant we add to the network will potentially lead to explosive network effects in user growth and network usage.<br /><br />
                    Two-sided networks differ from traditional value chains in a fundamental way. In the traditional system, value moves from left to right: To the left of the company is cost; to the right is revenue. In two-sided networks, cost and revenue are both to the left and to the right, because the “network” has a distinct group of users on each side. The network product or service incurs costs in serving both groups and can collect revenue from each, although one side is often subsidized.<br /><br />
                    PayPal's Powerful Network Effect<br />
                    PayPal's growth is beginning to fuel a powerful network effect. The beauty of this type of growth is that it drives itself. The more users that sign up for the network the more important the service becomes for retailers to adopt it and the more places that accept it, the more attractive it becomes to new users. As of March 2017, 203 million users were paying with Paypal; 16 million retailers were accepting PayPal's core platform as a method of payment; 5 million of which had adopted PayPal's innovative One Touch checkout solution.
                    <br />
                    <div className="col-lg-12 my-auto text-center" style={{ paddingTop: '10px', paddingBottom: '20px'}}>    
                        <img src="../assets/img/networkeffects.png" width={'70%'}></img>
                    </div>
                    <h3>Links To Other Sites</h3>
                    VEEV may contain links to other sites. Any information you provide on those sites is provided directly to the owner of that site and is subject to that party's privacy policy. Our privacy policy does not apply to such sites, and we are not responsible for the content or privacy and security practices and policies of those sites.
                    <br /><br />
                    <h3>Updates</h3>
                    All information that is collected is subject to the privacy policy in effect at the time such information is collected. We may, modify and revise our privacy policy from time-to-time. If we make any material changes to this policy, we will notify you of such changes by posting them on VEEV or by sending you an email or other notification, and we will indicate when such changes will become effective. By continuing to access or use VEEV after those changes become effective, you are agreeing to be bound by the revised policy.
                    <br /><br /><br /><br /> 
                  </div>
                </div>
              </div>
            </Styles>
            <FooterHome/>
            <Modal
                onCancel={()=>setOpen(false)}
                afterOpenChange={(o) => setScreen("login")}
                width={400}
                title={
                    <img
                        style={{width: 80, height: "auto", marginBottom: "3px"}}
                        alt="VEEV Logo"
                        src="/assets/img/veev_logo_red.png"
                        width={139}
                        height={41}
                    />
                }
                footer={false}
                centered
                open={open}>
                {{
                    "login": <LoginPopup setScreen={setScreen} onSuccess={(user) => navigate(`/user/${user.handle}`)}/>,
                    "signup": <SignupPopup onSuccess={onSuccess}/>
                }[screen]}
            </Modal>
        </>
    );
};

export default Coin;