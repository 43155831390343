import React, { useEffect, useState } from 'react';
import { getCookie } from '../service/cookie'

const OnboardingPage = () => {
  useEffect(() => {
    const initFetch = async () => {
        const email = getCookie("current_user_email");
        const token = getCookie("current_ident_token");

        const response = await fetch(
            `${process.env.REACT_APP_API_URL}/v1/merchant/web/onboard`, {
            method: "POST",
            headers: {
              "Accept": "application/json",
              "Content-Type": "application/json",
              "X-User-Email": email,
              "X-User-Token": token,
            },
        });

        const result = await response.json();
        if (result.url) {
            console.log(result.url)
            window.location.replace(result.url);
        } else {
            window.location.replace('/store/settings/store');
        }
    }
    initFetch()
  }, [])

  return (
    <></>
  );
};

export default OnboardingPage;
